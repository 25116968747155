import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { FluorStudentStatistic } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
type PageData = FluorStudentStatistic & { joinrate: string } & { isFluorrate: string };

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '年級', property: 'grade' },
        { display: '學生數', property: 'studentCount' },
        { display: '不參與數', property: 'neverFluor' },
        { display: '參與率', property: 'joinrate' },
        { display: '未實施人次', property: 'yearSemDontFluor' },
        { display: '應實施人次', property: 'fluorStudentCount' },
        { display: '實施人次', property: 'isFluorStudentCount' },
        { display: '執行率', property: 'isFluorrate' },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const teeFluorStaticGrade: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    const [students, setStudents] = useState<PageData[]>([]);

    useEffect(() => {
        if (year && sem) {
            apis
                .getFluorGradeStatic(year, sem)
                .then((r) =>
                    setStudents(
                        r.map(({ ...s }) => ({
                            ...s,
                            joinrate: percent(s.neverFluor, s.studentCount),
                            isFluorrate: percent(s.isFluorStudentCount, s.fluorStudentCount),
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [year, sem]);

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <div>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${year}學年${sem}學期_含氟漱口水執行統計(年級)`,
                                values: students,
                                headers: tableHeader,
                                context: SightContext,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                />
            </Row>
        </AuthedLayout>
    );
};

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return (((value / denominator)) * 100).toFixed(2) + '%';
}

export const TeeFluorStaticGrade = connector(teeFluorStaticGrade);
