import React from 'react';
import { FunctionComponent } from 'react';
import { MenuItem } from '../../model';

type Props = { items: MenuItem };

export const SideMenuBadge: FunctionComponent<Props> = ({ items }) => {
  if (items.badge) {
    const badgeClass = ['label', 'pcoded-badge', items.badge.type];

    return <span className={badgeClass.join(' ')}>{items.badge.title}</span>;
  }

  return <React.Fragment></React.Fragment>;
};
