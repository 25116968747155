import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Col, Container, Modal, Row, FormCheck } from 'react-bootstrap';

import {
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  InputTransformField,
  NumberField,
  TextField,
  AuthedLayout,
} from '../../components';

import { ClassSem } from '../../layouts';
import { Student, TransferInfo, TransferTypeEnum } from '../../model';
import { ErrorDispatches } from '../../redux/Dispatches';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../redux/States';
import { DateTime } from 'luxon';
import { getGradeMinMax } from '../../utils';
import apis from '../../apis';
import { I18N } from '../../i18n-raw';
import { Form } from 'react-final-form';

type PageData = {
  pid: string;
  name: string;
  sex: string;
  birth: DateTime;
  dad?: string | null | undefined;
  mom?: string | null | undefined;
  guardian?: string | null | undefined;
  zip?: string | null | undefined;
  tel?: string | null | undefined;
  erTel?: string | null | undefined;
  address?: string | null | undefined;
  blood?: string | null | undefined;
  remark?: string | null | undefined;
  aborigine?: string | null | undefined;

  transferId: number;
  transferType: TransferTypeEnum;
  sid?: string | null | undefined;
  seat?: number | null | undefined;
  classGrade?: number | undefined;
  classNo?: number | undefined;
  year: number;
};

const headerDisplayKeys: HeaderDisplayKeysWithType<PageData>[] = [
  { display: '統編', property: 'pid' },
  { display: '學生', property: 'name' },
  {
    display: '生日',
    property: 'birth',
    onRender: (v) => (v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null),
  },
  { display: '血型', property: 'blood' },
  { display: '身分', property: 'aborigine' },
  { display: '父親', property: 'dad' },
  { display: '母親', property: 'mom' },
  { display: '監護人', property: 'guardian' },
  { display: '家中電話', property: 'tel' },
  { display: '郵區', property: 'zip' },
  { display: '住址', property: 'address' },
  { display: '緊急聯絡電話', property: 'erTel' },

  { display: '離校學年', property: 'year' },
  { display: '離校學號', property: 'sid' },
  { display: '離校年級', property: 'classGrade' },
  { display: '離校班級', property: 'classNo' },
  { display: '離校座號', property: 'seat' },
  {
    display: '離校原因',
    property: 'transferType',
    onRender: (v) => I18N.TransferType[v] || I18N.TransferType.None,
  },
];

const mapStates = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
  ...state.auth,
});
const mapDispatches = ErrorDispatches;
const connector = connect(mapStates, mapDispatches);
type Props = ConnectedProps<typeof connector>;

const retention: FunctionComponent<Props> = ({
  user: { currentRank, currentSemester, semesters },
  catchErrorForModal,
}) => {
  const [students, setStudent] = useState<PageData[]>([]);
  const [restoringStudent, setRestoringStudent] = useState<PageData>();
  const [modalContent, setModalContent] = useState<string>();
  const [selectedSem, setSelectedSem] = useState<string>(currentSemester);
  const { min, max } = getGradeMinMax(currentRank);
  const transferInContent = restoringStudent ? (
    <>
      <Form
        initialValues={restoringStudent}
        onSubmit={({ sid, seat, classGrade, classNo }) => {
          const { year, sem } = semesters[selectedSem];
          apis
            .transferIn(restoringStudent.transferId, {
              sid,
              seat,
              classGrade: +classGrade,
              classNo,
              year,
              sem,
            })
            .then(() => {
              setModalContent('復原成功');
              setRestoringStudent(undefined);
              getStudentsTransferList();
            })
            .catch(catchErrorForModal);
        }}
        render={(props) => {
          const { handleSubmit } = props;
          return (
            <>
              <Row className="mb-2">
                <Col xs={2}>轉入學年：</Col>
                {Object.entries(semesters)
                  .filter(([k]) => k >= currentSemester)
                  .map(([n]) => {
                    return (
                      <Col key={`sem-${n}`} xs={1}>
                        <FormCheck
                          type="radio"
                          label={n}
                          name="setSemester"
                          checked={n === selectedSem}
                          onChange={(e) => {
                            setSelectedSem(n);
                          }}
                        />
                      </Col>
                    );
                  })}
              </Row>
              <Row className="mb-2">
                <Col xs={2}>轉入學號：</Col>
                <Col xs={5} className="mr-2">
                  <TextField property="sid" />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={2}>轉入年級：</Col>
                <Col xs={4} className="mr-2">
                  <InputTransformField
                    property="classGrade"
                    type="number"
                    min={min}
                    max={max}
                    transform={(v) =>
                      (+v >= min && +v <= max && I18N.Grades[+v]) || '未知年級'
                    }
                    validate={(v) => {
                      return +v >= min && +v <= max
                        ? undefined
                        : `範圍應在${min}~${max}之間`;
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={2}>轉入班級：</Col>
                <Col xs={2} className="mr-2">
                  <NumberField property="classNo" min={1} max={50} />
                </Col>
                <Col xs={2}>班級座號：</Col>
                <Col xs={2} className="mr-2">
                  <NumberField property="seat" min={1} max={99} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} className="text-right">
                  <Button
                    onClick={() => {
                      setRestoringStudent(undefined);
                    }}
                  >
                    關閉
                  </Button>
                  <Button className="ml-3" onClick={handleSubmit}>
                    轉入
                  </Button>
                </Col>
              </Row>
            </>
          );
        }}
      />
    </>
  ) : (
    <></>
  );

  useEffect(() => {
    getStudentsTransferList();
  }, []);

  function getStudentsTransferList() {
    // 修退轉清單
    apis
      .getTransferList()
      .then((ss) => {
        setStudent(
          ss.map(
            ({
              transferId,
              transferType,
              student: { year, sid, ...student },
              yearClass,
            }) => ({
              year,
              ...student,
              ...yearClass,
              transferId,
              transferType,
            })
          )
        );
      })
      .catch(catchErrorForModal);
  }

  // 回復學生
  function onRestore(student: PageData) {
    // 請設定學生回復後的年級班級座號
    setRestoringStudent(student);
  }

  return (
    <AuthedLayout>
      <Row>
        <EditableTableDiv
          restorable
          headers={headerDisplayKeys}
          values={students}
          onRestore={onRestore}
        />
        <Modal
          show={!!restoringStudent}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          onHide={() => {
            setRestoringStudent(undefined);
          }}
        >
          <Modal.Header
            closeButton
            onHide={() => {
              setModalContent(undefined);
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              {restoringStudent?.name} - {restoringStudent?.sid}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Container>{transferInContent}</Container>
          </Modal.Body>
        </Modal>
        <Modal
          show={!!modalContent}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Header
            closeButton
            onHide={() => {
              setModalContent(undefined);
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              回復學生
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">{modalContent}</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setModalContent(undefined);
              }}
            >
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </AuthedLayout>
  );
};

export const Retention = connector(retention);
