import z from 'zod';
import { zodStringToDateTime } from '../utils';

export const PhysicalSettingZod = z.object({
  schoolId: z.string(),
  year: z.number().positive(),
  grade: z.number().positive(),
  body: z.boolean(),
  blood: z.boolean(),
  xRay: z.boolean(),
  bodyAdditional: z.array(z.string()),
  bloodAdditional: z.array(z.string()),
  bloodPressure: z.boolean(),
  pulse: z.boolean(),
  checkUnit: z.string().nullish(),
  doctor: z.string().nullish(),
  teeDoctor: z.string().nullish(),
  checkDate: zodStringToDateTime(),
});

export type PhysicalSetting = z.infer<typeof PhysicalSettingZod>;
