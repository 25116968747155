import * as ApiError from './ApiError';
import * as ClassApi from './ClassApi';
import * as UserApi from './UserApi';
import * as StudentApi from './StudentApi';
import * as SchoolApi from './SchoolApi';
import * as PhiApi from './PhiApi';
import * as NoticesApi from './PDFApi';
import * as TokenStorage from './TokenStorage';
import * as SOAP from './SOAP';
import * as Transfer from './Transfer';
import * as GradeApi from './GradeApi';
import * as SettingsApi from './SettingsApi';
import * as ZipApi from './ZipApi';
import * as NoticeApi from './NoticeApi';
import * as StatisticApi from './StatisticApi';
import * as FluorApi from './FluorApi';
import * as CheckTempApi from './CheckTempApi';
import * as DiseaseApi from './DiseaseApi';
import * as SeriousCardApi from './SeriousCardApi';
import * as BodyMindBookNApi from './BodyMindBookNApi';
import * as HospitalApi from './HospitalApi';
import * as InheritDisease from './InheritDiseaseApi';
import * as SightSolidApi from './SightSolidApi';
import * as InsuranceApi from './InsuranceApi';

export * from './ApiError';

// prevent export get/put/post function
// const { get, put, post, delet } = BaseApi;

export default {
  ...TokenStorage,
  ...ApiError,
  ...ClassApi,
  ...UserApi,
  ...StudentApi,
  ...SchoolApi,
  ...PhiApi,
  ...NoticesApi,
  ...SOAP,
  ...Transfer,
  ...GradeApi,
  ...SettingsApi,
  ...ZipApi,
  ...NoticeApi,
  ...StatisticApi,
  ...FluorApi,
  ...CheckTempApi,
  ...DiseaseApi,
  ...SeriousCardApi,
  ...BodyMindBookNApi,
  ...HospitalApi,
  ...InheritDisease,
  ...SightSolidApi,
  ...InsuranceApi,
};
