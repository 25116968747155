import React, {
  useState,
  useEffect,
  FunctionComponent,
  CSSProperties,
} from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import apis from '../apis';
import { BulletinBoard } from '../apis/SOAP';
import { AuthedLayout, EditableTableDiv, EnchanceLink } from '../components';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import stHealthPNG from '../assets/images/home/StHealth.png';
import pwssPNG from '../assets/images/home/PWSS.png';
import sheetPNG from '../assets/images/home/Sheet.png';

const btnRow: CSSProperties = {
  display: 'flex',
};
const googleSheet: CSSProperties = {
  borderRadius: '21px',
  backgroundColor: 'rgb(204,233,255)',
  width: '180px',
  maxHeight: '161px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  boxShadow: '#a4a4a4 0px 1px 5px 1px',
  color: 'black',
  fontSize: '18px',
  margin: '10px 12px',
  paddingTop: '8px',
};
const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const homePage: FunctionComponent<Props> = ({
  user,
  loading,
  catchErrorForModal,
}) => {
  const [boardContents, setBoardContents] = useState<BulletinBoard[]>([]);
  if (loading) {
    useHistory().go(0);
    return <></>;
  }

  useEffect(() => {
    apis.getBulletinBoard(user.currentSchool).then((datas) => {
      setBoardContents(datas);
    });
  }, []);

  return (
    <AuthedLayout>
      <Row className="mb-2">
        <Col xs={3}>
          <div>{user.currentSchool}</div>
          <div>{user.schools[user.currentSchool].name || 'Unknows'}</div>
        </Col>
        <Col style={btnRow}>
          <EnchanceLink className="mr-2" to={'/student/phi'}>
            <img width={'200px'} alt="健康資料查詢" src={stHealthPNG} />
          </EnchanceLink>
          <a href="https://hs.nhu.edu.tw/" target="_blank" rel="noreferrer">
            <img width={'200px'} alt="健康計畫網站" src={pwssPNG} />
          </a>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScArFDHaGGXFDfsQPX4Grb7PrCLWW6XTfTRreiYIMe2Lio1hw/viewform"
            target="_blank"
            rel="noreferrer"
            style={googleSheet}
          >
            <img width={'100px'} alt="身分證修改" src={sheetPNG} />
            <span>學生身分證異動</span>
          </a>
        </Col>
      </Row>
      <Row className="mb-2">
        <EditableTableDiv
          values={boardContents}
          headers={[
            { property: 'type', display: '分類', style: { width: '15%' } },
            {
              property: 'content',
              display: '標題',
              style: { width: '65%' },
              onRender: (v, i, e, c) => (
                <a href={c.allValues.link} target="_blank" rel="noreferrer">
                  {v}
                </a>
              ),
            },
            {
              property: 'publishAt',
              display: '發佈時間',
              style: { width: '20%' },
            },
          ]}
        />
      </Row>
    </AuthedLayout>
  );
};

export const HomePage = connector(homePage);
