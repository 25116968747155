import { DateTime } from 'luxon';
import React, {
  FunctionComponent,
  useState,
  ReactNode,
  useEffect,
  ChangeEvent,
} from 'react';
import { Modal, Button, Table, Row, Col } from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  TextField,
  OverflowWithTooltip,
} from '../../components';
import {
  PHISeriousDisease,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { InputDropdownGroup } from '../../components';
import { toast } from 'react-toastify';
import { I18N } from '../../i18n-raw';

type Props = {
  editable: boolean;
  deleteable: boolean;
  seriousDiseases: PHISeriousDisease[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};

const inlineDiseaseTableHeader: HeaderDisplayKeysWithType<PHISeriousDisease>[] =
  [
    { property: 'studentId', display: '統編' },
    { property: 'diseaseId', display: '診斷代號' },
    { property: 'diseaseName', display: '疾病名稱' },
  ];

function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}
export const SeriousDiseaseSection: FunctionComponent<Props> = ({
  editable,
  deleteable,
  seriousDiseases,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const currentSemester = semesters.find((s) => s.isNow);
  const [inputing, setInputing] = useState(false);
  const [diseaseItems, setdiseaseItems] = useState<object>();
  const [seriousDiseaseDropDownItems, setSeriousDiseaseDropDownItems] =
    useState<Record<number, string>>();
  const [seriousDiseaseDropDown, setSeriousDiseaseDropDown] =
    useState<number>();
  const [isSeriousDiseaseValid, setIsSeriousDiseaseValid] = useState<boolean>(false);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [year, setYear] = useState<number>();
  const [sem, setSem] = useState<number>();
  const wh = seriousDiseases.find(
    (s) => s.sem === currentSemester?.sem && s.year === currentSemester?.year
  );

  useEffect(() => {
    // 目前學期、學年
    const isNow = semesters.filter((y) => y.isNow == true)[0];
    setYear(isNow.year);
    setSem(isNow.sem);
    // 重大傷病下拉選單
    const results = I18N.SeriousDiseaseType;
    setSeriousDiseaseDropDownItems(results);
  }, [semesters]);

  useEffect(() => {
    setInsertBtn(false);
  }, [seriousDiseaseDropDown]);

  useEffect(() => {
    // 檢查疾病代號
    setIsSeriousDiseaseValid(false);
    if (seriousDiseaseDropDown) {
      if (I18N.SeriousDiseaseType[seriousDiseaseDropDown]) {
        setIsSeriousDiseaseValid(true);
      }
    }
  }, [seriousDiseaseDropDown]);

  yearSems.filter(
    (s) => !seriousDiseases.some((wh) => wh.sem === s.sem && wh.year === s.year)
  );

  seriousDiseases.sort((a, b) => a.sem - b.sem + (a.year - b.year) * 100);

  function onValueUpdate(value: PHISeriousDisease) {}

  function onValueDelete(value: PHISeriousDisease) {
    apis
      .deleteSeriousDiseases(value?.id, value.studentId, value?.diseaseId)
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function insertSeriousDiseaseCheck() {
    // 檢查診斷代號
    if (isSeriousDiseaseValid) {
      insertSeriousDisease();
    } else {
      toast.error('診斷代號錯誤!', {});
    }
  }

  function insertSeriousDisease() {
    if (seriousDiseaseDropDown) {
      // 送出api
      if (year && sem) {
        toast
          .promise(
            apis.insertSeriousDiseases(
              year,
              sem,
              '',
              0,
              0,
              0,
              student.pid,
              seriousDiseaseDropDown,
              1
            ),
            {
              pending: '資料新增中......',
              success: '新增成功！',
            }
          )
          .then(onValueUpdated)
          .catch(onApiError);
      }
    }
  }

  return (
    <>
      <div>
        重大傷病卡
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            重大傷病卡 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <InlineEditableTable
            values={seriousDiseases}
            headers={inlineDiseaseTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable: false,
              deleteable:
                deleteable &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
            })}
          />
          <Row className='px-2'>
            診斷代號：
            <Col sm={3}>
              <InputDropdownGroup
                className={'p-1 text-center mr-2'}
                name="diseaseId"
                type="number"
                options={seriousDiseaseDropDownItems || {}}
                defaultShow="無異常"
                value={seriousDiseaseDropDown || ''}
                onChange={(
                  e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                  >
                ) => {
                  setSeriousDiseaseDropDown(parseInt(e.target.value));
                }}
                onBlur={() => {}}
                onFocus={() => {}}
              />
            </Col>
            <Button disabled={insertBtn} onClick={insertSeriousDiseaseCheck}>
              新增
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
