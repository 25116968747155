import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { SideMenuContent } from './SideMenuContent';
import { ApplicationState } from '../../redux/States';
import { StyleDispatches } from '../../redux/Dispatches';
import background from '../../assets/images/ROC_Ministry_of_Education_Seal.svg.png';
import { EnchanceLink } from '../EnhanceLink';

const mapState = (state: ApplicationState) => ({
  ...state.style,
  ...state.auth,
});
const mapDispatch = StyleDispatches;
const connecter = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connecter>;

const sideMenu: FunctionComponent<Props> = ({
  sideMenuCollapse,
  toggleSideMenuCollapse,
  user,
}) => {
  if (!user) {
    return <Redirect to="/login" />;
  }

  const navClass = [
    'pcoded-navbar',
    'menu-item-icon-style1',
    'active-default',
    //layoutType
  ];
  const toggleClass = ['mobile-menu'];
  if (sideMenuCollapse) {
    navClass.push('navbar-collapsed');
    toggleClass.push('on');
  }

  const navBarClass = ['navbar-wrapper'];

  const navContent = (
    <div className={navBarClass.join(' ')}>
      <div className="navbar-brand header-logo">
        <EnchanceLink to="/" className="b-brand">
          <div className="b-bg">
            <img src={background} style={{ height: '50px', width: '50px' }} />
          </div>
          <span className="b-title">學生健康資訊系統</span>
        </EnchanceLink>
        <summary
          className={toggleClass.join(' ')}
          id="mobile-collapse"
          onClick={toggleSideMenuCollapse}
        >
          <span />
        </summary>
      </div>
      <SideMenuContent navigation={user?.menus} />
    </div>
  );
  return (
    <React.Fragment>
      <nav className={navClass.join(' ')}>{navContent}</nav>
    </React.Fragment>
  );
};

export const SideMenu = connecter(sideMenu);
