import React, { FunctionComponent, useMemo, useRef } from 'react';
import { DateTime } from 'luxon';
import { Field, useField } from 'react-final-form';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import {
  DATETIME_FORMAT,
  DATETIME_FORMAT_WITH_TIME,
  DateTimePicker,
} from '../DatetimePicker';

type Props = {
  property: string;
  className?: string;
  containerClass?: string;
  format?: typeof DATETIME_FORMAT_WITH_TIME | typeof DATETIME_FORMAT;
  start?: DateTime;
  end?: DateTime;
  disabled?: boolean;
  showTimeSelect?: boolean;
  validate?: (v: DateTime) => string | undefined;
};
export const DateTimeField: FunctionComponent<Props> = ({
  property,
  className,
  containerClass,
  format = DATETIME_FORMAT,
  start,
  end,
  disabled,
  showTimeSelect,
  validate,
}) => {
  const { input, meta } = useField<DateTime, HTMLInputElement, DateTime>(
    property,
    {
      validate: (v, all) => validate && v && validate(v),
      parse: (v, n) => {
        const dt = v;
        return !showTimeSelect ? dt?.startOf('day') : dt;
      },
    }
  );
  return (
    <>
      <DateTimePicker
        disabled={disabled}
        containerClass="w-100"
        className={['form-control', className].join(' ')}
        format={format}
        showTimeSelect={showTimeSelect}
        value={input.value}
        start={start}
        end={end}
        onChange={(d) => {
          input.onChange(d);
        }}
        onFocus={() => {
          input.onFocus();
        }}
        onBlur={() => {
          input.onBlur();
        }}
      />
      {meta.error && (meta.modified || meta.submitFailed) && !meta.active && (
        <span className="text-danger">{meta.error}</span>
      )}
    </>
  );
};
