import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table, Col } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { TeeGradeClassStatistic } from '../../../model';
import { useSemGrade, useInspectionType } from '../../../hook';
import apis from '../../../apis';


const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const teeStaticSex: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element } = useSemGrade();
    const { inspectiontype, inspectionTypeDP } = useInspectionType({
        yearSem,
        catchErrorForModal,
    });
    const [datas, setDatas] = useState<TeeGradeClassStatistic[]>([]);

    useEffect(() => {
        if (yearSem && inspectiontype) {
            apis.getTeethTeeStaticSex(yearSem.year, yearSem.sem).then((s) => {
                setDatas(s);
            });
        }
    }, [yearSem?.year, yearSem?.sem, inspectiontype]);

    const gradeBase = datas.reduce((grades, d) => {
        if (inspectiontype == "複檢") {
            if (d.total) {
                d.total.tsheetadultCount = d.total?.reTsheetadultCount ?? 0;
                d.total.tsheetchildrenCount = d.total?.reTsheetchildrenCount ?? 0;
            }
            if (d.male) {
                d.male.tsheetadultCount = d.male?.reTsheetadultCount ?? 0;
                d.male.tsheetchildrenCount = d.male?.reTsheetchildrenCount ?? 0;
            }
            if (d.female) {
                d.female.tsheetadultCount = d.female?.reTsheetadultCount ?? 0;
                d.female.tsheetchildrenCount = d.female?.reTsheetchildrenCount ?? 0;
            }
        }
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, TeeGradeClassStatistic[]>);

    let schoolcount = 0;
    let schoolT01count = 0;
    let schoolTsheetadultCount = 0;
    let schoolTsheetchildrenCount = 0;

    let countRow = 0;
    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const rows = gs.flatMap((g, idx) => {
                // 計算資料筆數
                countRow++;
                schoolcount += g.total?.count ?? 0;
                schoolT01count += g.total?.countT01 ?? 0
                schoolTsheetadultCount += g.total?.tsheetadultCount ?? 0
                schoolTsheetchildrenCount += g.total?.tsheetchildrenCount ?? 0
                return TeeToRows(
                    g.grade,
                    g.male?.count ?? 0,
                    g.male?.countT01 ?? 0,
                    g.male?.tsheetadultCount ?? 0,
                    g.male?.tsheetchildrenCount ?? 0,
                    g.female?.count ?? 0,
                    g.female?.countT01 ?? 0,
                    g.female?.tsheetadultCount ?? 0,
                    g.female?.tsheetchildrenCount ?? 0,
                    g.total?.count ?? 0,
                    g.total?.countT01 ?? 0,
                    g.total?.tsheetadultCount ?? 0,
                    g.total?.tsheetchildrenCount ?? 0,
                );
            });

            if (datas.length == countRow) {
                rows.push(...TeeToRows(
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    schoolcount ?? 0,
                    schoolT01count ?? 0,
                    schoolTsheetadultCount ?? 0,
                    schoolTsheetchildrenCount ?? 0,
                ));
            }
            return rows;
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級　/ 　性別 ',
                    merge: { column: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '人數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '未治療齲齒人數A',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '未治療齲齒比率',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: 'DMFT(恆齒) B',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '恆齒指數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: 'deft(乳齒) C',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '乳齒指數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
    ];

    // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <Col>
                    <BSRow>
                        <Col xs={3} className="mr-3">
                            {element}
                        </Col>
                        <Col xs={3} className="mr-3" >
                            {inspectionTypeDP}
                        </Col>
                    </BSRow>
                </Col>
                <div>
                    <Button
                        disabled={!datas.length}
                        onClick={() => {
                            const workSheet = generateSheet(
                                [
                                    {
                                        cells: [
                                            {
                                                value: `${yearSem?.year}學年第${yearSem?.sem}學期_口腔檢查結果統計(含乳齒指數_分性別)`,
                                                style: { alignment: { horizontal: 'left' } },
                                            },
                                        ],
                                    },
                                    {
                                        cells: [
                                            {
                                                value: '一、A：未治療齲齒人數（未治療齲齒資料代號為 1、3）',
                                                merge: { column: 7 },
                                                style: { alignment: { horizontal: 'left' } },
                                            }
                                        ]
                                    },
                                    {
                                        cells: [
                                            {
                                                value: '二、B：DMFT(恆齒)（口檢表恆牙上，資料代號為 d、f、m、D、F、M）',
                                                merge: { column: 7 },
                                                style: { alignment: { horizontal: 'left' } },
                                            }
                                        ]
                                    },
                                    {
                                        cells: [
                                            {
                                                value: '三、C：deft(乳齒)（口檢表乳牙上，資料代號為 d、f、m、D、F、M）',
                                                merge: { column: 7 },
                                                style: { alignment: { horizontal: 'left' } },
                                            }
                                        ]
                                    },
                                    ...content,
                                    {
                                        cells: [
                                            {
                                                value:
                                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                                style: { alignment: { horizontal: 'left' } },
                                            },
                                        ],
                                    },
                                ],
                                {
                                    alignment: cellAlignStyle,
                                }
                            );
                            downloadSheets('口腔檢查結果統計(含乳齒指數_分性別)', { sheet: workSheet, name: 'st' });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>

            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function TeeToRows(
    grade: number,
    maleCount: number,
    maleCountT01: number,
    maletsheetadultCount: number,
    maletsheetchildrenCount: number,
    femaleCount: number,
    femaleCountT01: number,
    femaletsheetadultCount: number,
    femaletsheetchildrenCount: number,
    totalCount: number,
    totalCountT01: number,
    tsheetadultCount: number,
    tsheetchildrenCount: number,
): Row[] {
    let returnhtml;
    const gradeNo = [
        {
            value: grade,
            merge: { row: 3 },
            style: { border: fullBorderStyle },
        }
    ];
    if (grade != 0) {
        returnhtml = [
            {
                cells: [
                    ...gradeNo,
                    {
                        value: "男",
                        merge: { row: 1 }
                    },
                    {
                        value: maleCount,
                    },
                    {
                        value: maleCountT01,
                    },
                    {
                        value: percent(maleCountT01, maleCount),
                    },
                    {
                        value: maletsheetadultCount ?? 0,
                    },
                    {
                        value: maletsheetadultCount == 0 ? 0 : parseFloat((maletsheetadultCount / maleCount).toFixed(2)),
                    },
                    {
                        value: maletsheetchildrenCount ?? 0,
                    },
                    {
                        value: maletsheetchildrenCount == 0 ? 0 : parseFloat((maletsheetchildrenCount / maleCount).toFixed(2)),
                    },
                ],

            },
            {
                cells: [
                    {
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: "女",
                        merge: { row: 1 }
                    },
                    {
                        value: femaleCount,
                    },
                    {
                        value: femaleCountT01,
                    },
                    {
                        value: percent(femaleCountT01, femaleCount),
                    },
                    {
                        value: femaletsheetadultCount ?? 0,
                    },
                    {
                        value: femaletsheetadultCount == 0 ? 0 : parseFloat((femaletsheetadultCount / femaleCount).toFixed(2)),
                    },
                    {
                        value: femaletsheetchildrenCount ?? 0,
                    },
                    {
                        value: femaletsheetchildrenCount == 0 ? 0 : parseFloat((femaletsheetchildrenCount / femaleCount).toFixed(2)),
                    },
                ],

            },
            {
                cells: [
                    {
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: "小計",
                        merge: { row: 1 }
                    },
                    {
                        value: totalCount,
                    },
                    {
                        value: totalCountT01,
                    },
                    {
                        value: percent(totalCountT01, totalCount),
                    },
                    {
                        value: tsheetadultCount ?? 0,
                    },
                    {
                        value: tsheetadultCount == 0 ? 0 : parseFloat((tsheetadultCount / totalCount).toFixed(2)),
                    },
                    {
                        value: tsheetchildrenCount ?? 0,
                    },
                    {
                        value: tsheetchildrenCount == 0 ? 0 : parseFloat((tsheetchildrenCount / totalCount).toFixed(2)),
                    },
                ],

            },
        ]
    } else {
        returnhtml = [
            {
                cells: [
                    {
                        value: "總計",
                        merge: { column: 2 }
                    },
                    {
                        value: totalCount,
                    },
                    {
                        value: totalCountT01,
                    },
                    {
                        value: percent(totalCountT01, totalCount),
                    },
                    {
                        value: tsheetadultCount ?? 0,
                    },
                    {
                        value: tsheetadultCount == 0 ? 0 : parseFloat((tsheetadultCount / totalCount).toFixed(2)),
                    },
                    {
                        value: tsheetchildrenCount ?? 0,
                    },
                    {
                        value: tsheetchildrenCount == 0 ? 0 : parseFloat((tsheetchildrenCount / totalCount).toFixed(2)),
                    },
                ],

            },

        ]
    }
    return returnhtml;
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}
export const TeeStaticSex = connector(teeStaticSex);
