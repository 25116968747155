import { z } from 'zod';
import { get, post, put } from './BaseApi';
import {
  StudentZod,
  StudentPidZod,
  StudentGradeNoSeatZod,
  TeeFluorLackZod,
  BrushTeeLackZod,
  NeverFluorZod,
  StudentFluorZod
} from '../model';

const StudentPidArr = z.array(StudentPidZod);
const TeeFluorLackListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      studentFluor: TeeFluorLackZod,
    })
  )
);
const BrushTeesLackListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      studentBrushTee: BrushTeeLackZod,
    })
  )
);
type TeeFluorLackList= z.infer<typeof TeeFluorLackListZod>;
type BrushTeesLackList= z.infer<typeof BrushTeesLackListZod>;

export const FluorZod = z.array(
    z.object({
      id: z.number(),
      year: z.number(),
      sem: z.number(),
      dates: z.array(z.string()),
      schoolId: z.string(),
    })
  );
export const NeverFluorArrZod = z.array(NeverFluorZod);
export type Fluor = z.infer<typeof FluorZod>;
export async function getFluorList(
  ): Promise<Fluor> {
    return await get('/api/fluor/list', FluorZod, {
    });
  }
  
export async function updateFluorDate(
    year: number,
    month: number,
    date: number,
    count: number,
    ): Promise<void> {
      await post(`/api/fluor/fluordate`, {},{year, month, date, count} );
    }

export type NeverFluor = z.infer<typeof NeverFluorArrZod>;
export async function getNeverFluorList(
  year: number,
  sem: number,
  ): Promise<NeverFluor> {
    return await get('/api/neverparticipatefluor/list', NeverFluorArrZod, {
      year,
      sem
    });
  }
  
export async function createNeverFluor(
  pid: string,
  ): Promise<void> {
    await post(`/api/neverparticipatefluor/create`, {},{pid} );
  } 

export async function deleteNeverFluor(
  id: number,
  studentId: string,
  schoolId: string,
  ): Promise<void> {
    await put(`/api/neverparticipatefluor/delete`, {
      id,
      studentId,
      schoolId
    });
  } 

export const StudentFluorArrZod = z.array(StudentFluorZod);    
export type StudentFluor = z.infer<typeof StudentFluorArrZod>;
export async function getStudentFluorList(
  year: number,
  sem: number,
  ): Promise<StudentFluor> {
    return await get('/api/studentfluors/list', StudentFluorArrZod, {
      year,
      sem
    });
  }
  export async function getStudentFluorLackList(
    year: number,
    sem: number,
  ): Promise<TeeFluorLackList> {
    return await get('/api/studentfluors/list/lack', TeeFluorLackListZod, {
      year,
      sem
    });
  }
  
export async function createStudentFluor(
  pid: string,
  participate: boolean,
  lackcount: number,
  ): Promise<void> {
    await post(`/api/studentfluors/create`, {},{pid,participate,lackcount} );
  }  

export async function createStudentFlourLack(
  year: number,
  sem: number,
  sid: string,
  seat: number,
  no: number,
  grade: number,
  pid: string,
  participate: boolean,
  lackcount: number,
  checkField: number
  ): Promise<void> {
    await post(`/api/studentfluors/create`, {}, {
      year,
      sem,
      participate,
      lackcount,
      sid,
      seat,
      no,
      grade,
      pid,
      checkField
    } );
}  

export async function updateStudentFluorLack(
  id: number,
  participate: boolean,
  lackCount: number,
  fluorId: number,
  studentId: string,
): Promise<void> {
  await put(`/api/studentfluors/update`, {
    id,
    participate,
    lackCount,
    fluorId,
    studentId,
  })
}

export async function deleteStudentFluorLack(
  id: number,
  participate: boolean,
  lackCount: number,
  fluorId: number,
  studentId: string,
): Promise<void> {
  await put(`/api/studentfluors/delete`, {
    id,
    participate,
    lackCount,
    fluorId,
    studentId,
  })
}
  
export const StudentBrushZod = 
    z.object({
      id: z.number(),
      year: z.number(),
      sem: z.number(),
      grade: z.number(),
      executeMonth: z.number(),
      executeTimes: z.number(),
      schoolId: z.string()
    });   
const StudentBrushsZod = z.array(StudentBrushZod);   
export type StudentBrush = z.infer<typeof StudentBrushZod>;


export async function getStudentBrushList(
  month: number,
  ): Promise<StudentBrush[]> {
    return await get(`/api/brushtee/${month}/list`, StudentBrushsZod, {
    });
  }
  
export async function createStudentBrush(
  month: number,
): Promise<void> {
  await post(`/api/brushtee/create`, {},{month} );
} 

export async function updateStudentBrush(
  month: number,
  grade: number,
  executeTimes: number,
): Promise<void> {
  await post(`/api/brushtee/update`, {},{month,grade,executeTimes} );
} 

export async function createStudentBrushLack(
  year: number,
  sem: number,
  sid: string,
  seat: number,
  no: number,
  grade: number,
  pid: string,
  lackMonth: number,
  lack: number,
  checkField: number
): Promise<void> {
  await post(`/api/brushtee/student/lack/create`, {
    year,
    sem,
    sid,
    seat,
    no,
    grade,
    pid,
    lackMonth,
    lack,
    checkField
  }, {});
} 

export async function getStudentBrushLackList(
  year: number,
  sem: number,
  month: number
): Promise<BrushTeesLackList> {
  return await get('/api/brushtee/student/lack/list', BrushTeesLackListZod, {
    year,
    sem,
    month
  });
}

export async function updateStudentBrushLack(
  id: number,
  year: number,
  sem: number,
  lackMonth: number,
  lack: number,
  studentId: string,
): Promise<void> {
  await put(`/api/brushtee/student/lack/update`, {
    id,
    year,
    sem,
    lackMonth,
    lack,
    studentId,
  })
}
export async function deleteStudentBrushLack(
  id: number,
  year: number,
  sem: number,
  lackMonth: number,
  lack: number,
  studentId: string,
): Promise<void> {
  await put(`/api/brushtee/student/lack/delete`, {
    id,
    year,
    sem,
    lackMonth,
    lack,
    studentId,
  })
}