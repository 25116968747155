import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';
import { WrapClassBaseLayout, ClassSem } from '../../../layouts';
import ReactDatePicker from 'react-datepicker';
type PageData = StudentGradeNoSeat & Nullable<Sight> & Nullable<WH> & { diag: string };

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '座號', property: 'seat' },
        { display: '姓名', property: 'name' },
        { display: '性別', property: 'sex' },
        { display: '身高', property: 'height' },
        { display: '體重', property: 'weight' },
        {
            display: '測量日',
            property: 'examDate',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
        },
        { display: 'BMI', property: 'bmi' },
        { display: '體位', property: 'diag' },
        { display: '裸視右', property: 'sight0R' },
        { display: '裸視左', property: 'sight0L' },
        { display: '戴鏡右', property: 'sightR' },
        { display: '戴鏡左', property: 'sightL' }
    ];

const mapStates = (state: ApplicationState, ownProps: ClassSem) => ({
    ...ownProps,
    ...state.auth,
});
const mapDispatch = ErrorDispatches;

const connector = connect(mapStates, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const wHSightClassList: FunctionComponent<Props> = ({
    classId,
    className,
    year,
    sem,
    grade,
    no,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { selectedClassGrade: classGrade, element: classGradeElement } =
        useClassGrade({
            yearSem
        });
    const [students, setStudents] = useState<PageData[]>([]);
    const [wHSightClassListPdfDate, setWHSightClassListPdfDate] = useState<null | Date>(); // 學生生日
    useEffect(() => {
        if (year && classId) {
            apis
                .getWHClassSight(year, sem, classId)
                .then((r) =>
                    // console.log(r)
                    setStudents(
                      r.map(({ sight, wh, ...s }) => ({
                        ...sight,
                        ...wh,
                        ...s,
                        diag: whbmiDiag(wh?.bmiCode?.toString()),
                      }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [classId, year, sem]);

    return (
        <>
            <Row>
                視力：	
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="全部"
                        name="sight"
                        defaultChecked
                        onChange={(e) => {
                            console.log("全部")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="正常"
                        name="sight"
                        onChange={(e) => {
                            console.log("正常")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="異常"
                        name="sight"
                        onChange={(e) => {
                            console.log("異常")
                        }}
                    />
                </Col>
                （ 
                <Col sm={1}>
                    <Form.Check
                        type="checkbox"
                        label="已複檢"
                        name="sight_check"
                        onChange={(e) => {
                            console.log("已複檢")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="checkbox"
                        label="未複檢"
                        name="sight_check"
                        onChange={(e) => {
                            console.log("未複檢")
                        }}
                    />
                </Col>
                ）
            </Row>
            <Row>
                體位：
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="全部"
                        name="wh"
                        defaultChecked
                        onChange={(e) => {
                            console.log("全部")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="正常"
                        name="wh"
                        onChange={(e) => {
                            console.log("正常")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="異常"
                        name="wh"
                        onChange={(e) => {
                            console.log("異常")
                        }}
                    />
                </Col>
            </Row>
            <hr />
            <Row className='mb-1'>
                <Col sm={12}>
                    <h3>通知單設定</h3>
                </Col>
            </Row>
            <Row className='mb-1'>
                衛教內容設定：列印視力值
                <Col sm={1} className='ml-2'>
                    <Form.Check
                        type="radio"
                        label="是"
                        name="education"
                        onChange={(e) => {
                            console.log("是")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="否"
                        name="education"
                        defaultChecked
                        onChange={(e) => {
                            console.log("否")
                        }}
                    />
                </Col>
                列印建議體重
                <Col sm={1} className='ml-2'>
                    <Form.Check
                        type="radio"
                        label="是"
                        name="fitweight"
                        onChange={(e) => {
                            console.log("是")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="否"
                        name="fitweight"
                        defaultChecked
                        onChange={(e) => {
                            console.log("否")
                        }}
                    />
                </Col>
            </Row>
            <Row className='mb-1'>
                回條內容設定：視力正常，且體位異常者 
                <Col sm={2} className='ml-2'>
                    <Form.Check
                        type="radio"
                        label="列印家長回條"
                        name="bmi_abnormal"
                        defaultChecked
                        onChange={(e) => {
                            console.log("列印家長回條")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="無回條單"
                        name="bmi_abnormal"
                        defaultChecked
                        onChange={(e) => {
                            console.log("無回條單")
                        }}
                    />
                </Col>
            </Row>
            <Row>
                視力正常，且體位正常者
                <Col sm={2}>
                    <Form.Check
                        type="radio"
                        label="列印家長回條"
                        name="whsight_normal"
                        defaultChecked
                        onChange={(e) => {
                            console.log("列印家長回條")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="無回條單"
                        name="whsight_normal"
                        onChange={(e) => {
                            console.log("無回條單")
                        }}
                    />
                </Col>
            </Row>
            <Row>
                列印繳回期限
                <Col sm={1} className='ml-2'>
                    <Form.Check
                        type="radio"
                        label="是"
                        name="print_date"
                        defaultChecked
                        onChange={(e) => {
                            console.log("是")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="否"
                        name="print_date"
                        onChange={(e) => {
                            console.log("否")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <ReactDatePicker
                        className="wid-110"
                        dateFormat="yyyy/MM/dd"
                        selected={wHSightClassListPdfDate}
                        onChange={(date) => {
                        if(date) {
                            setWHSightClassListPdfDate(date);
                        }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                通知單格式設定：衛教內容與回條單分開列印
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="是"
                        name="print_separate"
                        onChange={(e) => {
                            console.log("是")
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Form.Check
                        type="radio"
                        label="否"
                        name="print_separate    "
                        defaultChecked
                        onChange={(e) => {
                            console.log("否")
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                />
            </Row>
    </>
    );
};

export const WHSightClassList = connector(WrapClassBaseLayout(wHSightClassList));