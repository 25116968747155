import React, { FunctionComponent, useEffect, useState } from 'react';
import { School } from '../model';
import Apis from '../apis';
import { ErrorDispatches } from '../redux/Dispatches';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../components';
import { connect, ConnectedProps } from 'react-redux';
import { Row } from 'react-bootstrap';
import { I18N } from '../i18n-raw';

const mapState = () => ({});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);
type Prop = ConnectedProps<typeof connector>;

type TransformSchoolProperty = {
  name: string;
  value?: string;
  code: string;
};

function transformSchoolObj(
  school: School | undefined
): TransformSchoolProperty[] {
  return [
    {
      code: 'SchoolId',
      name: '學校代號',
      value: school?.id,
    },
    {
      code: 'SchoolName',
      name: '學校名稱',
      value: school?.name,
    },
    {
      code: 'SchoolTel',
      name: '電話',
      value: school?.tel,
    },
    {
      code: 'SchoolZip',
      name: '郵遞區號',
      value: school?.zip,
    },
    {
      code: 'SchoolAddress',
      name: '地址',
      value: school?.address,
    },
    {
      code: 'SchoolRank',
      name: '學制',
      value: I18N.SchoolRanks[school?.rank || 0] ?? '',
    },
  ];
}

const headerDisplayKeys: HeaderDisplayKeysWithType<TransformSchoolProperty>[] =
  [
    { display: '代號', property: 'code', style: { width: '30%' } },
    { display: '項目', property: 'name', style: { width: '20%' } },
    { display: '内容', property: 'value', style: { width: '50%' } },
  ];

const schoolInfo: FunctionComponent<Prop> = ({ catchErrorForModal }) => {
  const [school, setSchool] = useState<School>();
  useEffect(() => {
    Apis.getCurrentSchool()
      .then((school) => {
        setSchool(school);
      })
      .catch(catchErrorForModal);
  }, []);

  const transformedSchool = transformSchoolObj(school);

  return (
    <AuthedLayout>
      <Row>
        <EditableTableDiv
          headers={headerDisplayKeys}
          values={transformedSchool}
        />
      </Row>
    </AuthedLayout>
  );
};

export const SchoolInfo = connector(schoolInfo);
