import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { StyleDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import background from '../../assets/images/ROC_Ministry_of_Education_Seal.svg.png';

import { NavLeft } from './NavLeft';
import { NavRight } from './NavRight';
import { EnchanceLink } from '../EnhanceLink';

const mapState = (state: ApplicationState) => state.style;
const mapDispatch = StyleDispatches;
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const navBar: FunctionComponent<Props> = ({
  sideMenuCollapse,
  toggleSideMenuCollapse,
}) => {
  const headerClass = [
    'navbar',
    'pcoded-header',
    'navbar-expand-lg',
    'header-default',
    'headerpos-fixed',
  ];
  const toggleClass = ['mobile-menu'];
  if (sideMenuCollapse) {
    toggleClass.push('on');
  }

  const navToggleHandler = () => {
    toggleSideMenuCollapse();
  };

  const collapseClass = ['collapse navbar-collapse'];
  const navBar = (
    <React.Fragment>
      <div className="m-header">
        <span
          className={toggleClass.join(' ')}
          id="mobile-collapse"
          onClick={navToggleHandler}
        >
          <span />
        </span>
        <EnchanceLink to="#" className="b-brand">
          <div className="b-bg">
            <img src={background} style={{ height: '50px', width: '50px' }} />
          </div>
          <span className="b-title">學生健康資訊系統</span>
        </EnchanceLink>
      </div>
      <div className={collapseClass.join(' ')}>
        <NavLeft />
        <NavRight />
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <header className={headerClass.join(' ')}>{navBar}</header>
    </React.Fragment>
  );
};

export const NavBar = connector(navBar);
