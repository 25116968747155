import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { AuthedLayout } from '../../../components';
import { useSemGrade } from '../../../hook';
import { ClassSightRate } from '../../../model';
import apis from '../../../apis';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;
const cellAlignStyle = {
  horizontal: 'center',
  vertical: 'center',
} as const;
const bottomBorderStyle = {
  bottom: borderStyle,
};
const topBottomBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
};
const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

type sightdilatingnear = {
  grade: number;
  classNo: number;
  countD: number;
  countN: number;
  returnRate: string;
};

const sightDilatingNearRate: FunctionComponent<Props> = ({
  catchErrorForModal,
}) => {
  const { yearSem, element } = useSemGrade();
  const [datas, setSightDilatingNearRate] = useState<ClassSightRate[]>([]);

  useEffect(() => {
    if (yearSem) {
      apis
        .getSightDilatingNearRate(yearSem.year, yearSem.sem)
        .then((r) => {
          console.log('r', r);
          setSightDilatingNearRate(r);
        })
        .catch(catchErrorForModal);
    }
  }, [yearSem?.year, yearSem?.sem]);

  // 計算年級小計、全校合計
  let schoolcountD = 0;
  let schoolcountN = 0;
  let schoolcountNRate = '';

  const gradeBase = datas.reduce((grades, d) => {
    // 計算不良率、就醫率
    d.returnRate = percent(d.countN, d.countD);
    return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
  }, {} as Record<number, sightdilatingnear[]>);

  // 組成table cell
  let countRow = 0;
  console.log(gradeBase);
  console.log(datas);
  const contentRows: Row[] = Object.values(gradeBase).flatMap(
    (gs, idx, allArray) => {
      const classSight = {
        grade: 0,
        classNo: 0,
        countD: 0,
        countN: 0,
        returnRate: '',
      };
      // 同一年級下 loop每個班級
      const rows = gs.flatMap((g, gidx) => {
        // 計算資料筆數
        countRow++;

        // 年級小計
        classSight.grade = g.grade;
        classSight.countD += g.countD;
        classSight.countN += g.countN;
        classSight.returnRate = percent(classSight.countN, classSight.countD);

        // 全校合計
        schoolcountD += g.countD;
        schoolcountN += g.countN;
        schoolcountNRate = percent(schoolcountN, schoolcountD);

        return sightToRow(
          g.grade,
          g.classNo,
          g.countD,
          g.countN,
          g.returnRate!
        );
      });

      // 插入年級小計
      classSight.returnRate! = percent(classSight.countN!, classSight.countD);
      rows.push(
        ...sightToRow(
          classSight.grade,
          classSight.classNo,
          classSight.countD,
          classSight.countN,
          classSight.returnRate!
        )
      );

      // 若為最後一筆資料 插入全校小計
      if (countRow == datas.length) {
        rows.push(
          ...sightToRow(0, 0, schoolcountD, schoolcountN, schoolcountNRate)
        );
      }

      return rows;
    }
  );
  const content: Row[] = [
    {
      cells: [
        {
          value: `${yearSem?.year}學年第${yearSem?.sem}學期_經散瞳確認近視比率`,
          merge: { column: 7 },
        },
      ],
    },
    {
      cells: [
        {
          value: 'B：裸眼視力不良人數',
          merge: { column: 7 },
        },
      ],
    },
    {
      cells: [
        {
          value: 'A：經散瞳確認近視的人數',
          merge: { column: 7 },
        },
      ],
    },
    {
      cells: [
        {
          value: '年級',
          style: { border: bottomBorderStyle },
        },
        {
          value: '班級',
          style: { border: bottomBorderStyle },
        },
        {
          value: '裸眼視力不良人數B',
          style: { border: bottomBorderStyle },
        },
        {
          value: '經散瞳確認近視的人數A',
          style: { border: bottomBorderStyle },
        },
        {
          value: '經散瞳確認近視比率（A/B)',
          style: { border: bottomBorderStyle },
        },
      ],
    },
    ...contentRows,
    {
      cells: [
        {
          value: '承辦人:　　　　',
        },
        {
          value: '組長:　　　　',
        },
        {
          value: '主任:　　　　',
        },
        {
          value: '校長:　　　　',
        },
      ],
    },
  ];

  const workSheet = generateSheet(content, { alignment: cellAlignStyle });

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        <span className="mr-3">{element}</span>
        <Button
          disabled={!datas.length}
          className="text-dark"
          onClick={() => {
            downloadSheets('經散瞳確認近視比率', {
              sheet: workSheet,
              name: '工作表名稱',
            });
          }}
        >
          Excel 下載
        </Button>
      </BSRow>
      <BSRow>
        <Table>
          <tbody>
            <tr className="border-bottom border-top-0">
              <td>年級</td>
              <td>班級</td>
              <td>裸眼視力不良人數B</td>
              <td>經散瞳確認近視的人數A</td>
              <td>經散瞳確認近視比率(A/B)</td>
            </tr>
            {contentRows.map((r, i) => {
              const cells = r.cells.map((c, j) =>
                c.value != undefined ? (
                  <td
                    colSpan={c.merge?.column}
                    key={`${i}-${j}`}
                    className="border-0"
                  >
                    {c.value}
                  </td>
                ) : (
                  <Fragment key={`${i}-${j}`}></Fragment>
                )
              );
              const hasBorder = r.cells[0].merge?.column || 0;
              return (
                <tr
                  key={i}
                  className={
                    hasBorder ? 'border-bottom border-top-0' : 'border-0'
                  }
                >
                  {cells}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </BSRow>
    </AuthedLayout>
  );
};

function sightToRow(
  grade: number,
  classNo: number,
  countD: number,
  countN: number,
  returnRate: string
): Row[] {
  let gradeClass = [
    {
      value: grade,
    },
    {
      value: classNo,
    },
  ] as Cell[];
  let styleCss = {};

  if (grade == 0) {
    // 全校合計
    styleCss = { border: bottomBorderStyle };
    gradeClass = [
      {
        value: '全校合計',
        merge: { column: 2 },
        style: { border: bottomBorderStyle },
      },
    ];
  } else if (classNo == 0) {
    // 年級小計
    styleCss = { border: topBottomBorderStyle };
    gradeClass = [
      {
        value: grade + '年級小計',
        merge: { column: 2 },
        style: styleCss,
      },
    ];
  }

  return [
    {
      cells: [
        ...gradeClass,
        {
          value: countD,
          style: styleCss,
        },
        {
          value: countN,
          style: styleCss,
        },
        {
          value: returnRate,
          style: styleCss,
        },
      ],
    },
  ];
}

function percent(value: number, denominator: number): string {
  if (!denominator) return '0.00%';
  return ((value / denominator) * 100).toFixed(2) + '%';
}
export const SightDilatingNearRate = connector(sightDilatingNearRate);
