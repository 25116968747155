import { z } from 'zod';
import { menuItemZod } from './MenuItem';
import { schoolRankZod, simpleSchoolZod } from './School';

export const userZod = z.object({
  account: z.string(),
  name: z.string(),
  // schoolId: z.string(),
  // roleId: z.string(),
});

export const yearSemZod = z.object({
  sem: z.number(),
  year: z.number(),
});

export const semesterZod = z.object({
  sem: z.number(),
  year: z.number(),
  isNow: z.boolean(),
});

export const loginResponseZod = z.object({
  token: z.string(),
  user: userZod,
  currentSchool: z.string(),
  currentRank: schoolRankZod,
  menus: z.array(menuItemZod),
  schools: z.array(simpleSchoolZod),
  semesters: z.array(semesterZod),
  currentDate: z.string(),
});

export type LoginResponse = z.infer<typeof loginResponseZod>;
export type User = z.infer<typeof userZod>;
export type YearSem = z.infer<typeof yearSemZod>;
export type Semester = z.infer<typeof semesterZod>;
