import { School, SchoolRank } from '../model';

export function getPhysicalGradeByRank(rank: SchoolRank): number[] {
  switch (rank) {
    case 1:
      return [1, 4];
    case 7:
      return [7];
    case 10:
    case 11:
      return [10];
    case 6:
      return [1, 4, 7];
    case 8:
      return [7, 10];
    default:
      return [1, 4, 7, 10];
  }
}

export function getGradeMinMax(rank: SchoolRank): { min: number; max: number } {
  switch (rank) {
    case SchoolRank.Primary: // primary
      return { min: 1, max: 6 };
    case SchoolRank.Junior: // junior
      return { min: 7, max: 9 };
    case SchoolRank.Senior: // senior
      return { min: 10, max: 12 };
    case SchoolRank.PrimaryJunior: // primary and senior
      return { min: 1, max: 9 };
    case SchoolRank.JuniorSenior: // junior and senior
      return { min: 7, max: 12 };
    case SchoolRank.Senior4: // 4 years senior
      return { min: 7, max: 13 };
    default:
      throw new Error(`${rank} is not school rank`);
  }
}

export function getGradeRange(rank: SchoolRank): number[] {
  const { min, max } = getGradeMinMax(rank);

  return Array.from({ length: max - min + 1 }).map((_, i) => min + i);
}
