import React, { ReactNode } from "react";
import Chart from 'react-apexcharts'

export interface ChartOptions {
    chart: {
        id: string;
    };
    xaxis: {
        title: { text: string };
        categories: number[] | string[];
    };
    yaxis: {
        title: { text: string };
    },
}

export interface Series {
    name: string;
    data: number[] | string[];
}

export interface ChartState {
    options: ChartOptions;
    series: Series[];
    charttype: string
}



export const useApexBarChart = ({
    options,
    series,
    charttype
}: ChartState): {
    element: ReactNode;
} => {
    return {
        element: (
            // @ts-ignore
            <Chart options={options} series={series} type={charttype} width={1100} height={600} />
        ),
    };
}


export default useApexBarChart;