import React, { ReactNode } from 'react';
import { DateTime } from 'luxon';
import {
  DateTimeField,
  HeaderDisplayKeysWithType,
  NumberField,
} from '../../components';
import { PHISundry } from '../../model';
import { semesterRange } from '../../utils/date';

export const SundryPropertyFields = {
  examDate: (start?: DateTime, end?: DateTime) => (
    <DateTimeField
      property={'examDate'}
      start={start}
      end={end}
      validate={(v) => {
        return v
          ? v instanceof DateTime && v.isValid
            ? undefined
            : v instanceof Date && v.valueOf()
            ? undefined
            : '日期格式不正確'
          : '日期格式不正確';
      }}
    />
  ),
} as const;

const transformNumberField = (
  editingNode: ReactNode,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? editingNode : (v as string));
const checkKind = [
  {
    Id: 1,
    Checks: '心臟病篩檢'
  },
  {
    Id: 2,
    Checks: '胸部X光'
  },
  {
    Id: 3,
    Checks: '肝功能'
  },
  {
    Id: 4,
    Checks: '腎功能'
  },
  {
    Id: 5,
    Checks: '蛔蟲'
  },
  {
    Id: 6,
    Checks: '蟯蟲'
  },
{
    Id: 7,
    Checks: 'A型肝炎'
  },
  {
    Id: 8,
    Checks: 'B型肝炎'
  },
  {
    Id: 9,
    Checks: '結核菌素測驗'
  },
  {
    Id: 10,
    Checks: '頭蝨'
  },
  {
    Id: 11,
    Checks: '尿液'
  },
  {
    Id: 12,
    Checks: '心電圖'
  },
  {
    Id: 13,
    Checks: '心臟超音波'
  }
];

export const CheckTempInputTableHeader: HeaderDisplayKeysWithType<PHISundry>[] = [
  { 
    property: 'sundryId',
    display: '檢查項目', 
    onRender(value: unknown, idx, editing, context) {
      if(value) {
        return typeof(value) == 'number' ? checkKind[value - 1].Checks : '無檢查項目';
      }
    },
  },
  {
    property: 'examDate',
    display: '測量日',
    onRender: (v: unknown, i, e, c) => {
      if (e) {
        const { year, sem } = c.allValues;
        const [start, end] = semesterRange(year, sem);
        return SundryPropertyFields.examDate(start, end);
      }
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
  },
  { property: 'agencies', display: '檢查單位' },
];
