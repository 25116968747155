import React, { ReactNode } from 'react';
import { InputDropdownGroupField, TableRowContext } from '../components';
import { ExaminedResultEnum, TeeInspection } from '../model';
import { I18N } from '../i18n-raw';
import { Nullable } from '../types';

export function validAndDeserializeTeeSheet(rawSheet: string): {
  valid: boolean;
  tSheet: Record<number, string>;
} {
  const tSheet = deserializeTeeSheet(rawSheet);
  const serialized = serializeTeeSheet(tSheet);

  return {
    valid: serialized.length === rawSheet.length,
    tSheet,
  };
}

export function deserializeTeeSheet(rawSheet: string): Record<number, string> {
  const matchGroup = rawSheet.matchAll(/([1-8]{2})([dmfegsh]{1,2})/gi);
  const tSheet = {} as Record<number, string>;
  for (const g of matchGroup) {
    tSheet[+g[1]] = g[2];
  }

  return tSheet;
}

export function serializeTeeSheet(tSheet: Record<number, string>): string {
  return Object.entries(tSheet).reduce((pv, [k, v]) => {
    return pv + `${k}${v}`;
  }, '');
}

export enum TeeInitialExaminationEnum {
  /**無異狀 */ NoProblem = 0,
  /**初檢異常 */ InitialAbnoraml = 1,
  /**未實施 */ NoExamined = 9,
}

export enum TeeFollowUpExaminationEnum {
  /**複診正常 */ FollowUpNormal = 2,
  /**複診異常 */ FollowUpAbnormal = 3,
}

export function ExaminedResultToTeeEnum(
  e: ExaminedResultEnum
): TeeInitialExaminationEnum {
  switch (e) {
    case ExaminedResultEnum.NoProblem:
      return TeeInitialExaminationEnum.NoProblem;
    case ExaminedResultEnum.InitialAbnoraml:
    case ExaminedResultEnum.FollowUpNormal:
    case ExaminedResultEnum.FollowUpAbnormal:
      return TeeInitialExaminationEnum.InitialAbnoraml;
    case ExaminedResultEnum.NoExamined:
    default:
      return TeeInitialExaminationEnum.NoExamined;
  }
}

export function ExaminedResultToTeeFollowUpEnum(
  e: ExaminedResultEnum
): TeeFollowUpExaminationEnum | null {
  switch (e) {
    case ExaminedResultEnum.FollowUpNormal:
      return TeeFollowUpExaminationEnum.FollowUpNormal;
    case ExaminedResultEnum.FollowUpAbnormal:
      return TeeFollowUpExaminationEnum.FollowUpAbnormal;
    default:
      return null;
  }
}

export function TeeToExaminedEnum(
  initial: TeeInitialExaminationEnum | null | undefined,
  followup?: TeeFollowUpExaminationEnum | null | undefined
): ExaminedResultEnum {
  if (initial == TeeInitialExaminationEnum.NoExamined)
    return ExaminedResultEnum.NoExamined;
  if (initial == TeeInitialExaminationEnum.InitialAbnoraml) {
    if (followup == undefined || followup == null)
      return ExaminedResultEnum.InitialAbnoraml;
    if (followup == TeeFollowUpExaminationEnum.FollowUpAbnormal)
      return ExaminedResultEnum.FollowUpAbnormal;
    else return ExaminedResultEnum.FollowUpNormal;
  }
  return ExaminedResultEnum.NoProblem;
}

export const TEE_CONTEXT_MAP = 'ValueMap';
export const TEE_CONTEXT_BABY_MAP = 'BabyValueMap';
export const TEE_CONTEXT_SPECIAL_MAP = 'SpecialValueMap';
export const TEE_CONTEXT_SPECIAL_TEE = 'SpecialTee';
export const TEE_CONTEXT_SHEET = 'TeeSheet';
export const TEE_CONTEXT_FIRST_EXAMINATE = 'FirstExaminate';
export const TEE_CONTEXT_FOLLOW_EXAMINATE = 'FollowExaminate';
export const TEE_CONTEXT_SINGLE_EXAMINATE = 'SingleExaminate';

export const DefaultTeeContext = {
  [TEE_CONTEXT_MAP]: {
    D: '齲齒',
    M: '缺牙',
    F: '已矯治',
    E: '待拔牙',
    G: '阻生齒',
    S: '贅生牙',
  } as Record<string, string>,
  [TEE_CONTEXT_BABY_MAP]: {
    D: '齲齒',
    M: '缺牙',
    F: '已矯治',
    E: '待拔牙',
    G: '阻生齒',
    S: '贅生牙',
    H: '乳牙待拔',
  } as Record<string, string>,
  [TEE_CONTEXT_SPECIAL_MAP]: {
    SF: '窩溝封填',
  } as Record<string, string>,
  [TEE_CONTEXT_SPECIAL_TEE]: ['6', '7'] as string[],
  [TEE_CONTEXT_SHEET]: {} as Record<string, string>,
  [TEE_CONTEXT_FIRST_EXAMINATE]: I18N.FirstExaminationOnlyResult,
  [TEE_CONTEXT_FOLLOW_EXAMINATE]: I18N.FollowUpExaminationResult,
  [TEE_CONTEXT_SINGLE_EXAMINATE]: I18N.SingleExaminationOnlyResult,
} as const;

export const teeTransformFirstExaminationField = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean,
  c?: TableRowContext
): ReactNode => {
  if (!e) return v as string;

  const { [TEE_CONTEXT_FIRST_EXAMINATE]: firstExaminate } =
    (c?.context as typeof DefaultTeeContext) ?? DefaultTeeContext;
  return (
    <InputDropdownGroupField
      type="number"
      content={firstExaminate}
      property={property}
    />
  );
};

export const teeTransformFollowUpExaminationField = (
  firstProperty: string,
  property: string,
  v: unknown,
  i: number,
  e?: boolean,
  c?: TableRowContext
): ReactNode => {
  if (!e)
    return c?.allValues &&
      c?.allValues[firstProperty] == ExaminedResultEnum.InitialAbnoraml &&
      !c?.allValues[property]
      ? ExaminedResultEnum.InitialAbnoraml
      : (v as string);

  if (
    c?.allValues &&
    c?.allValues[firstProperty] != ExaminedResultEnum.InitialAbnoraml
  ) {
    return '-';
  }

  const { [TEE_CONTEXT_FOLLOW_EXAMINATE]: followExaminate } =
    (c?.context as typeof DefaultTeeContext) ?? DefaultTeeContext;
  return (
    <InputDropdownGroupField
      type="number"
      content={followExaminate}
      property={property}
    />
  );
};

export const teeTransformSingleExaminationField = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean,
  c?: TableRowContext
): ReactNode => {
  if (!e) return v as string;

  const { [TEE_CONTEXT_SINGLE_EXAMINATE]: singleExaminate } =
    (c?.context as typeof DefaultTeeContext) ?? DefaultTeeContext;
  return (
    <InputDropdownGroupField
      type="number"
      content={singleExaminate}
      property={property}
    />
  );
};

export type BasePHITee = {
  tee: boolean;
  sem: number;
  year: number;
  t01: ExaminedResultEnum;
  t02: ExaminedResultEnum;
  t03: ExaminedResultEnum;
  t04: ExaminedResultEnum;
  t06: number;
  t05: ExaminedResultEnum;
  t07: ExaminedResultEnum;
  t08: ExaminedResultEnum;
  t99: ExaminedResultEnum;
  t99state?: string | null;
  tSheet?: Record<string, string> | null;
  t09: ExaminedResultEnum;
  t11: TeeInspection;
  t12: TeeInspection;
  t13: TeeInspection;
  t15: TeeInspection;
  t14: number;
  t16: ExaminedResultEnum;
  t17: ExaminedResultEnum;
  t18: ExaminedResultEnum;
  t19: ExaminedResultEnum;
  reT99state?: string | null;
  reTSheet?: Record<string, string> | null;
};

export type ExtractedPHITee = {
  tee: boolean;
  sem: number;
  year: number;
  t01: TeeInitialExaminationEnum;
  ret01?: TeeFollowUpExaminationEnum | null;
  t02: TeeInitialExaminationEnum;
  ret02?: TeeFollowUpExaminationEnum | null;
  t03: TeeInitialExaminationEnum;
  ret03?: TeeFollowUpExaminationEnum | null;
  t04: TeeInitialExaminationEnum;
  ret04?: TeeFollowUpExaminationEnum | null;
  t06: number;
  t05: TeeInitialExaminationEnum;
  ret05?: TeeFollowUpExaminationEnum | null;
  t07: TeeInitialExaminationEnum;
  ret07?: TeeFollowUpExaminationEnum | null;
  t08: TeeInitialExaminationEnum;
  ret08?: TeeFollowUpExaminationEnum | null;
  t99: TeeInitialExaminationEnum;
  ret99?: TeeFollowUpExaminationEnum | null;
  t99state?: string | null;
  tSheet?: Record<string, string> | null;
  t09: TeeInitialExaminationEnum;
  ret09?: TeeFollowUpExaminationEnum | null;
  t11: TeeInspection;
  t12: TeeInspection;
  t13: TeeInspection;
  t14: number;
  t15: TeeInspection;
  t16: TeeInitialExaminationEnum;
  ret16?: TeeFollowUpExaminationEnum | null;
  t17: TeeInitialExaminationEnum;
  ret17?: TeeFollowUpExaminationEnum | null;
  t18: TeeInitialExaminationEnum;
  ret18?: TeeFollowUpExaminationEnum | null;
  t19: TeeInitialExaminationEnum;
  ret19?: TeeFollowUpExaminationEnum | null;
  reT99state?: string | null;
  reTSheet?: Record<string, string> | null;
};

export function extractFirstAndFollowUpExamination({
  t01,
  t02,
  t03,
  t04,
  t05,
  t07,
  t08,
  t99,
  t09,
  t16,
  t17,
  t18,
  t19,
  ...rest
}: BasePHITee): ExtractedPHITee {
  return {
    ...rest,
    t01: ExaminedResultToTeeEnum(t01),
    ret01: ExaminedResultToTeeFollowUpEnum(t01),
    t02: ExaminedResultToTeeEnum(t02),
    ret02: ExaminedResultToTeeFollowUpEnum(t02),
    t03: ExaminedResultToTeeEnum(t03),
    ret03: ExaminedResultToTeeFollowUpEnum(t03),
    t04: ExaminedResultToTeeEnum(t04),
    ret04: ExaminedResultToTeeFollowUpEnum(t04),
    t05: ExaminedResultToTeeEnum(t05),
    ret05: ExaminedResultToTeeFollowUpEnum(t05),
    t07: ExaminedResultToTeeEnum(t07),
    ret07: ExaminedResultToTeeFollowUpEnum(t07),
    t08: ExaminedResultToTeeEnum(t08),
    ret08: ExaminedResultToTeeFollowUpEnum(t08),
    t99: ExaminedResultToTeeEnum(t99),
    ret99: ExaminedResultToTeeFollowUpEnum(t99),
    t09: ExaminedResultToTeeEnum(t09),
    ret09: ExaminedResultToTeeFollowUpEnum(t09),
    t16: ExaminedResultToTeeEnum(t16),
    ret16: ExaminedResultToTeeFollowUpEnum(t16),
    t17: ExaminedResultToTeeEnum(t17),
    ret17: ExaminedResultToTeeFollowUpEnum(t17),
    t18: ExaminedResultToTeeEnum(t18),
    ret18: ExaminedResultToTeeFollowUpEnum(t18),
    t19: ExaminedResultToTeeEnum(t19),
    ret19: ExaminedResultToTeeFollowUpEnum(t19),
  };
}

export function combindFirstAndFollowUpExamination({
  t01,
  ret01,
  t02,
  ret02,
  t03,
  ret03,
  t04,
  ret04,
  t05,
  ret05,
  t07,
  ret07,
  t08,
  ret08,
  t99,
  ret99,
  t09,
  ret09,
  t16,
  ret16,
  t17,
  ret17,
  t18,
  ret18,
  t19,
  ret19,
  tee,
  sem,
  year,
  t06,
  t11,
  t12,
  t13,
  t14,
  t15,
  ...rest
}: Nullable<ExtractedPHITee>): BasePHITee {
  return {
    ...rest,
    tee: tee || false,
    sem: sem || 0,
    year: year || 0,
    t06: t06 || ExaminedResultEnum.NoProblem,
    t11: t11 || TeeInspection.None,
    t12: t12 || TeeInspection.None,
    t13: t13 || TeeInspection.None,
    t14: t14 || TeeInspection.None,
    t15: t15 || TeeInspection.None,
    t01: TeeToExaminedEnum(t01, ret01),
    t02: TeeToExaminedEnum(t02, ret02),
    t03: TeeToExaminedEnum(t03, ret03),
    t04: TeeToExaminedEnum(t04, ret04),
    t05: TeeToExaminedEnum(t05, ret05),
    t07: TeeToExaminedEnum(t07, ret07),
    t08: TeeToExaminedEnum(t08, ret08),
    t99: TeeToExaminedEnum(t99, ret99),
    t09: TeeToExaminedEnum(t09, ret09),
    t16: TeeToExaminedEnum(t16, ret16),
    t17: TeeToExaminedEnum(t17, ret17),
    t18: TeeToExaminedEnum(t18, ret18),
    t19: TeeToExaminedEnum(t19, ret19),
  };
}
