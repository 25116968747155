import { z } from 'zod';
import { DateTime } from 'luxon';
import { GetSemester } from '../utils/semester';
import {
  CheckStudentBaseData,
  CheckDate,
  CheckCode,
  CheckDateInSemester,
} from '../utils/checkFormat';
import {
  zodStringOrNumberToString,
  zodStringOrNumberToNumber,
  zodStringToDateTime,
} from '../utils/zod';
import { ClassStudentZod } from './Class';

export const PhiSundryZod = ClassStudentZod.merge(
  z.object({
    /*身分證	學號	年級班級座號	檢查項目代號	檢查單位	檢查日期	檢查結果*/
    // pid: z.string(),
    // sid: z.string(),
    // grade: z.string(),
    // no: z.string(),
    // seat: z.string(),
    sem: z.string(),
    sundryId: z.number(),
    agencies: z.string(),
    examDate: zodStringToDateTime().nullish(),
    sundryResult: z.number(),
  })
);

export type PhiSundry = z.infer<typeof PhiSundryZod>;

export const PhiSundryFileZod = z
  .object({
    // TODO: 身分證	學號	年級班級座號	檢查項目代號	檢查單位	檢查日期	檢查結果
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    檢查項目代號: z.string().nullish(),
    檢查單位: z.string().nullish(),
    檢查日期: z.string().nullish(),
    檢查結果: z.string().nullish(),
    year: z.number(),
    sem: z.number(),
  })
  .transform((arg) => {
    let _okUpload = true;
    let _errorMsg = '';

    //檢查【身分證】、【學號】、【年級班級座號】格式
    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const _pid = checkStudentBaseDataResult.pid;
    const _sid = checkStudentBaseDataResult.sid;
    const _grade = checkStudentBaseDataResult.grade;
    const _no = checkStudentBaseDataResult.no;
    const _seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    //檢查 檢查項目代號[1...13]
    const _sundryIdCode = [...Array(13).keys()].map((_, i) => i + 1 + '');
    // _code = ['1','2',...,'13']
    const checkSundryId = CheckCode(
      arg.檢查項目代號,
      _sundryIdCode,
      '檢查項目代號'
    );
    if (!checkSundryId.okUpload) {
      _okUpload = checkSundryId.okUpload;
      _errorMsg += checkSundryId.errorMsg;
    }
    const _sundryId = Number(checkSundryId.code);

    //To-Check: 檢查單位是否必填
    let _agencies = '';
    if (!arg.檢查單位) {
      _okUpload = false;
      _errorMsg += '請填入【檢查單位】，';
    } else {
      _agencies = arg.檢查單位;
    }

    //To-Check: 檢查日期是否必填

    const checkDate = CheckDateInSemester(
      arg.檢查日期 || '',
      arg.year,
      arg.sem,
      '檢查日期'
    );
    const _examDate = arg.檢查日期;
    if (checkDate.isNull || !checkDate.okUpload) {
      _okUpload = false;
      _errorMsg += checkDate.errorMsg;
    }

    //To-Check: 檢查結果是否必填
    //檢查 檢查結果[0,1,2,3]
    const _sundryResCode = Array.from({ length: 4 }).map((_, i) => `${i}`);
    const checksundryResult = CheckCode(
      arg.檢查結果,
      _sundryResCode,
      '檢查結果'
    );
    if (!checksundryResult.okUpload) {
      _okUpload = checksundryResult.okUpload;
      _errorMsg += checksundryResult.errorMsg;
    }
    const _sundryResult = Number(checksundryResult.code);

    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid: _pid,
      sid: _sid,
      grade: _grade,
      no: _no,
      seat: _seat,
      sem: GetSemester,
      sundryId: _sundryId,
      agencies: _agencies,
      sundryResult: _sundryResult,
      examDate: _examDate,
      yearClassId: 0,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

export type PhiSundryFile = z.infer<typeof PhiSundryFileZod>;
