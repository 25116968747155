import { get, put } from './BaseApi';
import { z } from 'zod';
import { 
  StudentGradeNoSeatZod,
  PHISightSolidZod
} from '../model';

const SightSolidListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      sightSolid: PHISightSolidZod,
    })
  )
);
type SightSolidList= z.infer<typeof SightSolidListZod>;
export async function insertStudentSightSolid(
  studentId: string,
  sightSolid: number,
  states: string,
  hospital: string,
): Promise<void> {
  await put(`/api/sightsolid/student/insert`, {
    sightSolid,
    states,
    hospital,
    studentId
  })
}

export async function insertNoticeSightSolid(
  year: number,
  sem: number,
  sid: string,
  seat: number,
  no: number,
  grade: number,
  pid: string,
  sightSolid: number,
  states: string,
  hospital: string,
  checkField: number,
): Promise<void> {
  await put(`/api/sightsolid/notice/insert`, {
    year,
    sem,
    sid,
    seat,
    no,
    grade,
    pid,
    sightSolid,
    states,
    hospital,
    checkField
  })
}

export async function deleteStudentSightSolid(
  id: number,
  sightSolid: number,
  states: string,
  hospital: string,
  studentId: string,
): Promise<void> {
  await put(`/api/sightsolid/student/delete`, {
    id,
    sightSolid,
    states,
    hospital,
    studentId
  })
}

export async function updateStudentSightSolid(
  id: number,
  sightSolid: number,
  states: string,
  hospital: string,
  studentId: string,
): Promise<void> {
  await put(`/api/sightsolid/student/update`, {
    id,
    sightSolid,
    states,
    hospital,
    studentId
  })
}

export async function getStudentSightSolidList(
  year: number,
  sem: number,
  classGrade: number,
): Promise<SightSolidList> {
  return await get(`/api/sightsolid/student/list`, SightSolidListZod, {
    year,
    sem,
    classGrade,
  })
}

export async function getStudentSightSolidListByGrade(
  classGrade: number,
  year: number,
  sem: number,
): Promise<SightSolidList> {
  return await get(`/api/sightsolid/grade/student/list`, SightSolidListZod, {
    year,
    sem,
    classGrade,
  })
}