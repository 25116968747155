import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';
type PageData = StudentGradeNoSeat & Nullable<Sight> & Nullable<WH> & { diag: string };

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '座號', property: 'seat' },
        { display: '姓名', property: 'name' },
        { display: '性別', property: 'sex' },
        { display: '身高', property: 'height' },
        { display: '體重', property: 'weight' },
        {
            display: '測量日',
            property: 'examDate',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
        },
        { display: 'BMI', property: 'bmi' },
        { display: '體位', property: 'diag' },
        { display: '裸視右', property: 'sight0R' },
        { display: '裸視左', property: 'sight0L' },
        { display: '戴鏡右', property: 'sightR' },
        { display: '戴鏡左', property: 'sightL' }
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const whClassSight: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { selectedClassGrade: classGrade, element: classGradeElement } =
        useClassGrade({
            yearSem
        });
    const [students, setStudents] = useState<PageData[]>([]);

    useEffect(() => {
        if (yearSem && classGrade) {
            apis
                .getWHClassSight(yearSem.year, yearSem.sem, classGrade.id)
                .then((r) =>
                    setStudents(
                      r.map(({ sight, wh, ...s }) => ({
                        ...sight,
                        ...wh,
                        ...s,
                        diag: whbmiDiag(wh?.bmiCode?.toString()),
                      }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [classGrade?.id, yearSem?.year, yearSem?.sem]);

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                        <Col xs={3} className="mr-3">
                            {classGradeElement}
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!yearSem}
                        variant="info"
                        className="text-dark mr-3"
                        onClick={() => {
                            if (!yearSem) return;
                            apis
                                .getWHClassSight(yearSem.year, yearSem.sem)
                                .then((r) => {
                                    const students = r.map(({ sight, wh, ...s }) => ({
                                        ...sight,
                                        ...wh,
                                        ...s,
                                        diag: whbmiDiag(wh?.bmiCode?.toString()),
                                    }))
                                    downloadDataAsExcel({
                                        title: `${yearSem?.year}學年${yearSem?.sem}學期_班級身高體重視力清單`,
                                        values: students,
                                        headers: tableHeader,
                                        context: SightContext,
                                        groupBy: (v) => `${v.grade}年${v.no}班`,
                                        footer:
                                            '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                    });
                                })
                                .catch(catchErrorForModal);
                        }}
                    >
                        所有班級 Excel 下載
                    </Button>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_班級身高體重視力清單`,
                                values: students,
                                headers: tableHeader,
                                context: SightContext,
                                groupBy: (v) => `${classGrade?.grade}年${classGrade?.no}班`,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                />
            </Row>
        </AuthedLayout>
    );
};

export const WHClassSight = connector(whClassSight);