import { z } from 'zod';
import {
  HospitalZod,
} from '../model';
import { get, put } from './BaseApi';

const HospitalArr = z.array(HospitalZod);
type HospitalList = z.infer<typeof HospitalArr>;

export async function createHospital(
  schoolId: string,
  name: string
): Promise<void> {
  return await put(`/api/hospital/insert`, {
    schoolId,
    name
  });
}

export async function deleteHospital(
  id: number,
  name: string,
  schoolId: string,
): Promise<void> {
  await put(`/api/hospital/delete`, {
    id,
    name,
    schoolId,
  });
}

export async function updateHospital(
  id: number,
  name: string,
  schoolId: string,
): Promise<void> {
  await put(`/api/hospital/update`, {
    id,
    name,
    schoolId,
  });
}

export async function createStudentHospital(
  hospitalId: number,
  studentId: string
): Promise<void> {
  return await put(`/api/hospital/student/insert`, {
    hospitalId,
    studentId
  });
}

export async function updateStudentHospital(
  hospitalId: number,
  studentId: string
): Promise<void> {
  await put(`/api/hospital/student/update`, {
    hospitalId,
    studentId
  });
}

export async function deleteStudentHospital(
  hospitalId: number,
  studentId: string
): Promise<void> {
  await put(`/api/hospital/student/delete`, {
    hospitalId,
    studentId
  });
}

export async function getHospitalList(
): Promise<HospitalList> {
  return await get(`/api/hospital/list`, HospitalArr);
}
