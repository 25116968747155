import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { AuthedLayout } from '../../../components';
import { useSemGrade } from '../../../hook';
import { CleanTeethStudentStatistic } from '../../../model';
import apis from '../../../apis';
import {
    Cell,
    Row,
    downloadSheets,
    generateSheet,
    listToObject,
} from '../../../utils';
import XLSX from 'xlsx-js-style';


const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const bottomBorderStyle = {
    bottom: borderStyle,
};
const topBottomBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
};
const cellAlignStyle = {
    horizontal: 'left',
    vertical: 'center',
    wrapText: true,
} as const;


const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const teeCleanStaticGrade: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element } = useSemGrade();
    const [datas, setCleanTeethStudent] = useState<CleanTeethStudentStatistic[]>(
        []
    );

    useEffect(() => {
        if (yearSem) {
            apis
                .getTeethTeeGradeCleanStatic(yearSem.year, yearSem.sem)
                .then((r) => {
                    setCleanTeethStudent(r);
                })
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);


    // 計算年級小計、全校合計
    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, CleanTeethStudentStatistic[]>);
    
    // 組成table cell
    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            // 同一年級下 loop每個班級
            const rows = teethToRow(Object.keys(gradeBase)[idx], gs);
            return rows;
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級',
                    style: { border: fullBorderStyle },
                },
                {
                    value: '月份',
                    style: { border: fullBorderStyle },
                },
                {
                    value: '學生數',
                    style: { border: fullBorderStyle },
                },
                {
                    value: '實施日數',
                    style: { border: fullBorderStyle },
                },
                {
                    value: '未實施人次',
                    style: { border: fullBorderStyle },
                },
                {
                    value: '執行率',
                    style: { border: fullBorderStyle },
                }
            ],
        },
        ...contentRows,
        {
            cells: [
                {
                    value: '總計',
                    style: { border: fullBorderStyle },
                },
                {
                    value: '',
                    style: { border: fullBorderStyle },
                },
                {
                    value: schoolmonthstudentcount,
                    style: { border: fullBorderStyle },
                },
                {
                    value: schoolbrushtime,
                    style: { border: fullBorderStyle },
                },
                {
                    value: schooldontbrush,
                    style: { border: fullBorderStyle },
                },
                {
                    value: percent(schooldontbrush, (schoolbrushtime * schoolmonthstudentcount)),
                    style: { border: fullBorderStyle },
                }
            ],
        }
    ];

    const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <span className="mr-3">{element}</span>
                <Button
                    disabled={!datas.length}
                    variant="success"
                    className="text-dark"
                    onClick={() => {
                        downloadSheets('潔牙執行成果統計(年級)', {
                            sheet: workSheet,
                            name: '工作表名稱',
                        });
                    }}
                >
                    Excel下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

let schoolmonthstudentcount = 0;
let schoolbrushtime = 0;
let schooldontbrush = 0;

function teethToRow(
    grade: string,
    gradedata: { classNo?: number | null | undefined; grade: number; month: number ; studentCount: number; brushTimes: number; stDontBrushTimes:number}[],
): Row[] {
    let monthstudentcount = 0;
    let brushtime = 0;
    let dontbrush = 0;
    const graderows = gradedata.flatMap(
        (gs, idx, allArray) => {
            monthstudentcount += gs.studentCount;
            brushtime += gs.brushTimes;
            dontbrush += gs.stDontBrushTimes;
            schoolmonthstudentcount += gs.studentCount;
            schooldontbrush += gs.stDontBrushTimes;
            schoolbrushtime += gs.brushTimes;

            return [
                {
                    cells: [
                        {
                            value: "",
                            style: { border: fullBorderStyle },
                        },
                        {
                            value: gs.month,
                            style: { border: fullBorderStyle },
                        },
                        {
                            value: gs.studentCount,
                            style: { border: fullBorderStyle },
                        },
                        {
                            value: gs.brushTimes,
                            style: { border: fullBorderStyle },
                        },
                        {
                            value: gs.stDontBrushTimes,
                            style: { border: fullBorderStyle },
                        },
                        {
                            value: percent(gs.stDontBrushTimes, (gs.brushTimes * gs.studentCount)),
                            style: { border: fullBorderStyle },
                        }]
                }
            ]
        })

    return [
        {
            cells: [
                {
                    value: grade,
                    style: { border: fullBorderStyle },
                },
                {
                    value: "",
                    style: { border: fullBorderStyle },
                },
                {
                    value: "",
                    style: { border: fullBorderStyle },
                },
                {
                    value: "",
                    style: { border: fullBorderStyle },
                },
                {
                    value: dontbrush,
                    style: { border: fullBorderStyle },
                },
                {
                    value: percent(dontbrush, (brushtime * monthstudentcount)),
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...graderows
    ];
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((1 - (value / denominator)) * 100).toFixed(2) + '%';
}

export const TeeCleanStaticGrade = connector(teeCleanStaticGrade);
