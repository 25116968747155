import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EditableTableDiv, HeaderDisplayKeysWithType } from '../components';
import Apis from '../apis';
import { ErrorDispatches } from '../redux/Dispatches';
import { Nullable } from '../types';
import { PHIWH, StudentPHIWH } from '../model';
import {
  Row,
  Modal,
  Container,
  Button,
  Col,
  FormControl,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { ClassSem, WrapClassBaseLayout } from '../layouts';
import { ApplicationState } from '../redux/States';
import { WHPropertyFields, WHTableHeader } from './TableHeaders/WHHeader';
import { semesterRange } from '../utils/date';

type MetricPageData = StudentPHIWH & Nullable<PHIWH>;

const displayHeader: HeaderDisplayKeysWithType<MetricPageData>[] = [
  { property: 'seat', display: '座號' },
  { property: 'name', display: '學生' },
  ...(WHTableHeader as HeaderDisplayKeysWithType<MetricPageData>[]),
];

const modalDisplayHeader: HeaderDisplayKeysWithType<PHIWH>[] = [
  { property: 'year', display: '學年' },
  { property: 'sem', display: '學期' },
  ...WHTableHeader,
];

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
  ...state.auth,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;
const classPHIWHPage: FunctionComponent<Props> = ({
  classId,
  year,
  sem,
  catchErrorForModal,
}) => {
  const [students, setStudent] = useState([] as MetricPageData[]);
  const [inputing, setInputing] = useState(false);
  const [editingStudent, setEditingStudent] = useState<MetricPageData>();

  useEffect(() => {
    refreshStudentMetric();
  }, [classId, year, sem, inputing]);

  function refreshStudentMetric() {
    if (classId && year && sem) {
      Apis.getClassStudentMetric(classId, year, sem)
        .then((metrics) => {
          const datas: MetricPageData[] = metrics.map((m) => {
            const latestMetric =
              m.phiWHs.length > 0
                ? m.phiWHs.find((cv) => cv.year === year && cv.sem === sem)
                : undefined;
            return {
              ...m,
              ...latestMetric,
            };
          });
          setStudent(datas);
        })
        .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }

  function onHide() {
    setInputing(false);
    setEditingStudent(undefined);
  }

  function onEditMetric(metric: MetricPageData) {
    setInputing(true);
    setEditingStudent(metric);
  }

  async function onUpdateStudentMetric(metric: PHIWH) {
    if (editingStudent && editingStudent.pid) {
      toast
        .promise(Apis.InsertStudentBodyMetrix(editingStudent?.pid, metric), {
          pending: '資料上傳中......',
          success: '上傳成功！',
          error: '上傳失敗！請查看錯誤資訊。',
        })
        .then(() => {
          onHide();
        })
        .catch(catchErrorForModal);
    }
  }

  return (
    <React.Fragment>
      <Row>
        <EditableTableDiv
          editable
          values={students}
          headers={displayHeader}
          onEdit={onEditMetric}
        />
      </Row>
      <Modal
        show={inputing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            身高體重 -{' '}
            <strong>
              {editingStudent?.seat} {editingStudent?.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={editingStudent}
          onSubmit={(value) => {
            const data = {
              id: 0,
              sem,
              year,
              height: value.height,
              weight: value.weight,
              examDate: value.examDate,
              yearClassId: 0,
            };
            onUpdateStudentMetric(data);
          }}
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { handleSubmit } = prop;
            const [fromDate, toDate] = semesterRange(year, sem);
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row>
                      <Col sm="8">
                        <EditableTableDiv
                          values={editingStudent?.phiWHs || []}
                          headers={modalDisplayHeader}
                        />
                      </Col>
                      <Col sm="4">
                        <Row className="pb-3 justify-content-center align-items-center">
                          <Col sm="3">身高:</Col>
                          <Col sm="8">{WHPropertyFields['height']}</Col>
                        </Row>

                        <Row className="pb-3 justify-content-center align-items-center">
                          <Col sm="3">體重:</Col>
                          <Col sm="8">{WHPropertyFields['weight']}</Col>
                        </Row>

                        <Row className="pb-3 justify-content-center align-items-center">
                          <Col sm="3">檢查日期:</Col>
                          <Col sm="8">
                            {WHPropertyFields['examDate'](fromDate, toDate, {
                              height: editingStudent?.height ?? -9,
                              weight: editingStudent?.weight ?? -9,
                            })}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHide}>
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

export const ClassPHIWHPage = connector(WrapClassBaseLayout(classPHIWHPage));
