import React, {
  FunctionComponent,
  useRef,
  useEffect,
  PropsWithChildren,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router';
import { useOutsideClick } from '../hook';
import { ErrorDispatches, StyleDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';

import { SideMenu } from './SideMenu';
import { NavBar } from './NavBar';
import { Breadcrumb } from './Breadcrumb';
import { ErrorModal } from './ErrorModal';
import { Card, Container } from 'react-bootstrap';

type OwnProps = {
  onLoaded?: () => void;
};
const mapState = (
  state: ApplicationState,
  ownProps: PropsWithChildren<OwnProps>
) => ({
  ...state.style,
  ...state.auth,
  ...state.error,
  ...ownProps,
});
const mapDispatch = {
  ...StyleDispatches,
  ...ErrorDispatches,
};
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const authedLayout: FunctionComponent<Props> = ({
  sideMenuCollapse,
  toggleSideMenuCollapse,
  children,
  isAuth,
  user,
  onLoaded = () => {},
}) => {
  const ref = useRef();

  const qs = new URLSearchParams(window.location.search);
  const lastPath = qs.get('lastPath');
  if (lastPath && isAuth) {
    return <Redirect to={lastPath} />;
  }

  useOutsideClick(ref, () => {
    if (sideMenuCollapse) {
      toggleSideMenuCollapse();
    }
  });

  useEffect(() => {
    onLoaded();
  }, [user, user?.currentSchool]);

  return (
    <React.Fragment>
      <SideMenu />
      <NavBar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Card>
                <Card.Body>
                  <Container fluid>{children}</Container>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal />
    </React.Fragment>
  );
};

export const AuthedLayout = connector(authedLayout);
