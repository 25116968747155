import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { ClassWHAvgStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';

const HDID = {
  // Total: -9,
  UnderWeight: -1,
  Healty: 0,
  OverWeight: 1,
  Obesity: 2,
};

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;
const cellAlignStyle = {
  horizontal: 'center',
  vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const whClassAvg: FunctionComponent<Props> = ({ catchErrorForModal }) => {
  const { yearSem, element } = useSemGrade();
  const [datas, setDatas] = useState<ClassWHAvgStatistic[]>([]);

  useEffect(() => {
    if (yearSem) {
      apis.getWHClassAvg(yearSem.year, yearSem.sem).then((s) => {
        console.log(s)
        setDatas(s);
      });
    }
  }, [yearSem?.year, yearSem?.sem]);

  const gradeBase = datas.reduce((grades, d) => {
    return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
  }, {} as Record<number, ClassWHAvgStatistic[]>);

  const contentRows: Row[] = Object.values(gradeBase).flatMap(
    (gs, idx, allArray) => {
      const rows = gs.flatMap((g, idx) => {
        return whavgToRows(
          g.grade,
          g.maleHavg,
          g.maleWavg,
          g.maleBMIavg,
          g.femaleHavg,
          g.femaleWavg,
          g.femaleBMIavg,
          g.totalHavg,
          g.totalWavg,
          g.totalBMIavg
        );
      });
      return rows;
    }
  );
  const content: Row[] = [
    {
      cells: [
        {
          value: '年級',
          merge: { column: 1, row: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '男',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
        {
          value: '女',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
        {
          value: '男女平均',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
      ],
    },
    {
      cells: [
        {
          merge: { column: 1, row: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: '身高A',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體重B',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: 'BMI（C）',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: '身高A',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體重B',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: 'BMI（C）',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: '身高A',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體重B',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
        {
          value: 'BMI（C）',
          merge: { column: 1 },
          style: { border: fullBorderStyle },
        },
      ],
    },
    ...contentRows,
  ];

  const workSheet = generateSheet(content, { alignment: cellAlignStyle });

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        <span className="mr-3">{element}</span>
        <Button
          disabled={!datas.length}
          onClick={() => {
            const workSheet = generateSheet(
              [
                {
                  cells: [
                    {
                      value: `${yearSem?.year}學年第${yearSem?.sem}學期_生長發育統計報表`,
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
                {
                  cells: [
                    {
                      value: `一、A：身高`,
                      style: { alignment: { horizontal: 'left' } },
                      merge: { column: 3 },
                    },
                    {
                      value: ` B：體重`,
                      style: { alignment: { horizontal: 'left' } },
                      merge: { column: 3 },
                    },
                    {
                      value: `C：BMI（ B / A(公尺)² ）`,
                      style: { alignment: { horizontal: 'left' } },
                      merge: { column: 3 },
                    },
                  ],
                },
                ...content,
                {
                  cells: [
                    {
                      value:
                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
              ],
              {
                alignment: cellAlignStyle,
              }
            );
            downloadSheets(`${yearSem?.year}學年第${yearSem?.sem}學期_生長發育統計報表`, { sheet: workSheet, name: 'st' });
          }}
        >
          Excel 下載
        </Button>
      </BSRow>
      <hr />
      <BSRow>
        <Table
          striped
          bordered
          className="text-center"
          style={{ tableLayout: 'fixed' }}
        >
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '6%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
          </colgroup>
          <tbody>
            {content.map((r, i) => {
              const cells = r.cells.map((c, j) =>
                c.value != undefined ? (
                  <td
                    colSpan={c.merge?.column}
                    rowSpan={c.merge?.row}
                    key={`${i}-${j}`}
                  >
                    {c.value}
                  </td>
                ) : (
                  <Fragment key={`${i}-${j}`}></Fragment>
                )
              );
              return <tr key={i}>{cells}</tr>;
            })}
          </tbody>
        </Table>
      </BSRow>
    </AuthedLayout>
  );
};

function whavgToRows(
  grade: number,
  mHavg: number,
  mWavg: number,
  mBMIavg: number,
  fHavg: number,
  fWavg: number,
  fBMIavg: number,
  totalHavg: number,
  totalWavg: number,
  totalBMIavg: number,
): Row[] {
  return [
    {
      cells: [
        {
          value: grade,
          style: { border: { left: borderStyle } },
        },
        {
          value: mHavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: mWavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: mBMIavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: fHavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: fWavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: fBMIavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: totalHavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: totalWavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
        {
          value: totalBMIavg.toFixed(2),
          style: { border: { right: borderStyle } },
        },
      ],
    },
  ];
}

export const WHClassAvg = connector(whClassAvg);
