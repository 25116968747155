import { z } from 'zod';
import {
  CheckField,
  DiseaseZod,
  StudentDiseaseUpload,
  StudentDiseaseZod,
  StudentGradeNoSeatZod,
  StudentPidZod,
} from '../model';
import { get, post, put } from './BaseApi';

const Diseaseitem = z.array(DiseaseZod);
const StudentPidArr = z.array(StudentPidZod);
const StudentDiseaseListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      studentDisease: StudentDiseaseZod,
    })
  )
);

type Diseases = z.infer<typeof Diseaseitem>;
type StudentPid = z.infer<typeof StudentPidArr>;
type StudentDiseaseList = z.infer<typeof StudentDiseaseListZod>;

export async function insertPhiDisease(
  year: number,
  sem: number,
  sid: string,
  seat: number,
  no: number,
  grade: number,
  pid: string,
  diseaseId: number,
  description: string,
  treatment: string,
  enterDate: string,
  checkField: number
): Promise<StudentPid> {
  const response = await post(`/api/Disease/insert`, StudentPidArr, {
    year,
    sem,
    sid,
    seat,
    no,
    grade,
    pid,
    diseaseId,
    description,
    treatment,
    enterDate,
    checkField,
  });
  return response;
}
export async function updateStudentDisease(
  id: number,
  diseaseId: number,
  diseaseName: string,
  description: string,
  treatment: string,
  enterDate: string,
  yearClassId: number
): Promise<void> {
  await put(`/api/disease/student/update`, {
    id,
    diseaseId,
    diseaseName,
    description,
    treatment,
    enterDate,
    yearClassId,
  });
}

export async function deleteStudentDisease(
  id: number,
  diseaseId: number,
  diseaseName: string,
  description: string,
  treatment: string,
  enterDate: string,
  yearClassId: number
): Promise<void> {
  await put(`/api/disease/student/delete`, {
    id,
    diseaseId,
    diseaseName,
    description,
    treatment,
    enterDate,
    yearClassId,
  });
}

export async function GetDiseaseDropDown(): Promise<Diseases> {
  return await get(`/api/disease/item`, Diseaseitem, {});
}

export async function GetStudentDiseaseAll(
  year: number,
  sem: number
): Promise<StudentDiseaseList> {
  return await get(`/api/disease/student/all`, StudentDiseaseListZod, {
    year,
    sem,
  });
}

export async function GetStudentDiseaseInSchoolList(
  year: number,
  sem: number
): Promise<StudentDiseaseList> {
  return await get(`/api/disease/student/inschool`, StudentDiseaseListZod, {
    year,
    sem,
  });
}

export async function GetStudentDiseaseByPid(
  year: number,
  sem: number,
  pid: string
): Promise<StudentDiseaseList> {
  return await get(`/api/disease/student/${pid}`, StudentDiseaseListZod, {
    year,
    sem,
    pid,
  });
}

export async function insertStudentDiseases(
  students: StudentDiseaseUpload[],
  checkField: CheckField
): Promise<void> {
  await put(`/api/disease/batch`, {
    checkField,
    students,
  });
}
