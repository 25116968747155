import XLSX, {
  CellStyle,
  BorderType,
  CellStyleColor,
  CellObject,
  RowInfo,
  Range,
  WorkSheet,
} from 'xlsx-js-style';
import { ExcelDataType } from 'xlsx';
import { downloadBlob } from './element';


const borderStyle: { color: CellStyleColor; style?: BorderType } = {
  color: { rgb: '0c0c0c' },
  style: 'medium',
};

const fullBorderStyle: CellStyle['border'] = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
};

const headerStyle: CellStyle = {
  fill: {
    fgColor: {
      rgb: 'efefef',
    },
  },
  font: {
    color: { rgb: '3f4d67' },
  },
  border: fullBorderStyle,
};
const oddRowStyle: CellStyle = {
  fill: { fgColor: { rgb: 'a6f0ff' } },
};
const cellStyle: CellStyle = {
  border: fullBorderStyle,
};

export type SheetHeaderDisplay<
  T extends Record<string, unknown>,
  K extends keyof T = keyof T
> = {
  display: string;
  property: K;
  sheetStyle?: CellStyle;
  onSheetRender?: (
    value: T[K],
    idx: number,
    context: {
      context: Record<string, unknown>;
      allValues: T;
    }
  ) => string | number | Date;
};

type Props<T extends Record<string, unknown>> = {
  title: string;
  values: T[];
  headers: SheetHeaderDisplay<T>[];
  merge?: Range[];
  context?: Record<string, unknown>;
  groupBy?: (v: T) => string;
  footer?: string;
};

export function downloadDataAsExcel<T extends Record<string, unknown>>({
  title,
  values,
  headers,
  merge = [],
  context = {},
  groupBy = () => title,
  footer,
}: Props<T>): void {
  const headerArray: CellObject[] = headers.map((h) => ({
    v: h.display,
    t: 's',
    s: { ...h.sheetStyle, ...headerStyle },
  }));

  const grouped = values.reduce((pv, v) => {
    const key = groupBy(v);
    pv[key] = [...(pv[key] || []), v];
    return pv;
  }, {} as Record<string, T[]>);

  const book = XLSX.utils.book_new();
  Object.entries(grouped).forEach(([tab, datas]) => {
    const rows: CellObject[][] = datas.map((v, idx) =>
      headers.map((h) => {
        const value =
          (h.onSheetRender
            ? h.onSheetRender(v[h.property], idx, { allValues: v, context })
            : (v[h.property] as any)) || '';
        return {
          v: value,
          t: 's' as ExcelDataType,
          s: { ...h.sheetStyle, ...cellStyle, ...(idx % 2 ? oddRowStyle : {}) },
        };
      })
    );
    const fullRows = [
      [{ v: tab, t: 's' }],
      [],
      headerArray,
      ...rows,
      [],
      footer ? [{ v: footer, t: 's' }] : [],
    ];
    const sheet = XLSX.utils.aoa_to_sheet(fullRows, {
      sheetStubs: true,
      cellStyles: true,
    });
    sheet['!rows'] = fullRows.map((_, i) => {
      return { level: 0 } as RowInfo;
    });
    sheet['!merges'] = [
      { s: { c: 0, r: 0 }, e: { c: headerArray.length - 1, r: 0 } },
      ...merge,
    ];
    XLSX.utils.book_append_sheet(book, sheet, tab);
  });

  const buffer = XLSX.write(book, {
    type: 'array',
    cellStyles: true,
  });

  const blob = new Blob([buffer], { type: 'application/excel' });
  downloadBlob(blob, `${title}.xlsx`);
}

export type Cell = {
  value?: string | number | boolean | Date;
  merge?: { row?: number; column?: number };
  style?: CellStyle;
};

export type Row = {
  cells: Cell[];
  level?: number;
};

export type StatisticProps = {
  cells: Row[];
};

export function generateSheet(
  cells: Row[],
  defaultStyle: CellStyle = {}
): WorkSheet {
  const { rows, rowInfos, merges } = cells.reduce(
    (sheet, s, rowIdx) => {
      const columns = s.cells.reduce((cols, cell) => {
        const cellObjs = cellToCellObject(cell, defaultStyle);
        cols.push(cellObjs);
        if (cell.merge) {
          sheet.merges.push({
            s: { r: rowIdx, c: cols.length - 1 },
            e: {
              r: rowIdx + (cell.merge.row ?? 1) - 1,
              c: cols.length - 1 + (cell.merge.column ?? 1) - 1,
            },
          });
          if (cell.merge.column) {
            cols.push(
              ...Array.from({ length: cell.merge.column - 1 }).map((v) =>
                emptyCell(cell.style, defaultStyle)
              )
            );
          }
        }
        return cols;
      }, [] as CellObject[]);
      sheet.rows.push(columns);
      sheet.rowInfos.push({ level: s.level });
      return sheet;
    },
    { rows: [], rowInfos: [], merges: [], rowMergeStack: {} } as {
      rows: CellObject[][];
      rowInfos: RowInfo[];
      merges: Range[];
      rowMergeStack: Record<number, Cell[]>;
    }
  );

  const sheet = XLSX.utils.aoa_to_sheet(rows);
  sheet['!merges'] = merges;
  sheet['!rows'] = rowInfos;

  return sheet;
}


export function downloadSheets(
  fileName: string,
  ...sheets: { name: string; sheet: WorkSheet }[]
): void {
  const book = XLSX.utils.book_new();
  sheets.forEach(({ sheet, name }) => {
    XLSX.utils.book_append_sheet(book, sheet, name);
  });

  const buffer = XLSX.write(book, {
    type: 'array',
    cellStyles: true,
  });

  const blob = new Blob([buffer], { type: 'application/excel' });
  downloadBlob(blob, `${fileName}.xlsx`);
}




function emptyCell(
  style: CellStyle | undefined,
  defaultStyle: CellStyle = {}
): CellObject {
  return { t: 's', s: { ...defaultStyle, ...style }, v: '' };
}

function cellToCellObject(
  cell: Cell,
  defaultStyle: CellStyle = {}
): CellObject {
  if (!cell || cell.value === undefined)
    return emptyCell(cell.style, defaultStyle);
  return {
    s: { ...defaultStyle, ...cell.style },
    t: cellValueType(cell.value),
    v: cell.value,
  };
}

enum CellTypeEnum {
  string = 's',
  number = 'n',
  bool = 'b',
  date = 'd',
}

function cellValueType(value: string | number | boolean | Date): ExcelDataType {
  if (typeof value === 'string') {
    return CellTypeEnum.string;
  }
  if (typeof value === 'boolean') {
    return CellTypeEnum.bool;
  }
  if (typeof value === 'number') {
    return CellTypeEnum.number;
  }
  if (typeof value === 'object' && value instanceof Date) {
    return CellTypeEnum.date;
  }
  return CellTypeEnum.string;
}
