import { PHIWH, Sight } from '../model';

export function sightDiag(sight: Sight | undefined): string {
  if (!sight) return '';

  let s = '';
  if (sight.eNear) s += '近視';
  if (sight.eFar) s += '遠視';
  if (sight.eWeak) s += '弱視';
  if (sight.eSan) s += '散光';
  if (sight.eSight99) s += '其他';
  if (sight.noProblem && s.length == 0) s += '複檢無異狀';

  return s;
}

export function whbmiDiag(bmiCode: string | undefined): string {
  if (!bmiCode) return '';

  let bmi = '';
  switch (bmiCode) {
    case "-1":
      bmi = '體重過輕';
      break;
    case "-2":
      bmi = '體型瘦弱';
      break;
    case "0":
      bmi = '體重適中';
      break;
    case "1":
      bmi = '體重過重';
      break;
    case "2":
      bmi = '體重超重';
      break;
    default:
      bmi = '未測量';
      break;
  }

  return bmi;
}
