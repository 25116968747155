import { Reducer } from 'redux';
import { AppThunkAction } from '..';
import { MenuItem } from '../../model';

export interface StyleState {
  sideMenuCollapse: boolean;
  menuItemDepth: MenuItem[];
}

export enum StyleActionType {
  ToggleSideMenuCollapse = 'ToggleSideMenuCollapse',
  ToggleMenuItem = 'ToggleMenuItem',
}

export interface ToggleSideMenuCollapseAction {
  type: StyleActionType.ToggleSideMenuCollapse;
}
type ToggleMenuData = { showType: 'main' | 'sub' } & MenuItem;
export interface ToggleMenuItemAction {
  type: StyleActionType.ToggleMenuItem;
  item: ToggleMenuData;
}

export type StyleActions = ToggleSideMenuCollapseAction | ToggleMenuItemAction;

export const StyleDispatches = Object.freeze({
  toggleSideMenuCollapse:
    (): AppThunkAction<ToggleSideMenuCollapseAction> => async (dispatch) => {
      return dispatch({ type: StyleActionType.ToggleSideMenuCollapse });
    },
  toggleMenuItem:
    (item: ToggleMenuData): AppThunkAction<ToggleMenuItemAction> =>
    async (dispatch) => {
      return dispatch({
        type: StyleActionType.ToggleMenuItem,
        item,
      });
    },
});

export const styleReducer: Reducer<StyleState, StyleActions> = (
  state,
  incomingAction
) => {
  if (state === undefined) {
    return {
      menuItemDepth: [],
      sideMenuCollapse: false,
    };
  }
  switch (incomingAction.type) {
    case StyleActionType.ToggleSideMenuCollapse:
      return {
        ...state,
        sideMenuCollapse: !state.sideMenuCollapse,
      };
    case StyleActionType.ToggleMenuItem: {
      let { menuItemDepth } = state;
      const {
        item,
        item: { showType },
      } = incomingAction;
      if (menuItemDepth.some((i) => i.id === item.id)) {
        menuItemDepth = menuItemDepth.filter((i) => i.id !== item.id);
      } else {
        if (item.showType === 'main') {
          menuItemDepth = [item];
        } else {
          menuItemDepth = [...menuItemDepth, item];
        }
      }
      // console.log('after', menuItemDepth, item);
      return {
        ...state,
        menuItemDepth,
      };
    }
    default:
      return state;
  }
};
