import { z } from 'zod';
import { DateTime } from 'luxon';
import { CheckStudentBaseData, CheckCode, checkExaminedResultEnum } from '../utils/checkFormat';
import { zodStringOrNumberToNumber, zodStringToDateTime } from '../utils/zod';
import { GetSemester, GetAcademicYear } from '../utils/semester';
import { validAndDeserializeTeeSheet } from '../utils/tee';
import { ExaminedResultEnum, ExaminedResultEnumZod } from './Enums';
import { PhysicalSetting} from '../model';
import { ClassStudentZod } from './Class';
import apis from '../apis';
import {
  CheckShouldUseIdentify,
} from '../utils';
type physicalSettingObject = {
  [key: number]: PhysicalSetting;
};

export const PhiPhysicalZod = ClassStudentZod.merge(
  z.object({
    /*收縮壓	舒張壓	血壓判讀	腰圍	脈搏	脈搏判讀
    辨色力異常	眼其他	眼其他陳述
    聽力異常    聽力異常左右	耳道畸型	耳道畸型左右	耳膜破損	耳膜破損左右	耵聹栓塞	耵聹栓塞左右	扁桃腺腫大	耳鼻喉其他	耳鼻喉其他陳述
    斜頸	異常腫塊	甲狀腺腫	淋巴腺腫大	其他異常腫塊	頭頸其他	頭頸其他陳述
    心肺疾病	心雜音	心律不整	呼吸聲異常	其他心肺疾病	胸廓異常	胸部其他	胸部其他陳述
    腹部異常腫大	腹部其他	腹部其他陳述
    脊柱側彎	肢體畸形	多併指（趾）	關節變形	其他肢體畸形	蹲踞困難	脊柱四肢其他	脊柱四肢其他陳述
    隱睪	包皮異常	精索靜脈曲張	泌尿生殖其他	泌尿生殖其他陳述
    癬	疥瘡	疣	異位性皮膚炎	溼疹	皮膚其他	皮膚其他陳述
    未治療齲齒	已治療齲齒	上顎恆牙第一大臼齒齲齒經驗	下顎恆牙第一大臼齒齲齒經驗	恆牙臼齒窩溝封填	口腔衛生不良	牙結石	牙齦炎	咬合不正
    口腔黏膜異常	牙周病H	乳牙待拔牙	待拔牙	贅生牙	缺牙	阻生牙	口腔其他	口腔其他陳述	口檢表	複檢口腔其他陳述	複檢口檢表
    斜視	斜視類型	睫毛倒插	眼球震顫	眼瞼下垂	唇顎裂	構音異常	耳前瘻管	耳前瘻管左右	慢性鼻炎	過敏性鼻炎	疝氣	水腫	陰囊腫大	紫斑
    檢查日期*/

    // pid: z.string(),
    // sid: z.string(),
    // grade: z.string(),
    // no: z.string(),
    // seat: z.string(),
    sem: z.string(),
    year: z.number(),

    sbp: z.number().nullish(),
    dbp: z.number().nullish(),
    bpResult: z.string(),
    waistline: z.number().nullish(),
    pulse: z.number().nullish(),
    pulseResult: z.string(),
    colorBlind: z.string(),
    e99: z.string(),
    e99State: z.string().nullish(),

    hearing: z.string(),
    hearingLR: z.string().nullish(),
    earMisshapen: z.string(),
    earMisshapenLR: z.string().nullish(),
    eardrum: z.string(),
    eardrumLR: z.string().nullish(),
    cerumen: z.string(),
    cerumenLR: z.string().nullish(),
    tonsillitis: z.string(),
    o99: z.string(),
    o99State: z.string().nullish(),

    torticollis: z.string(),
    mass: z.string(),
    goiter: z.string(),
    lymphadenectasis: z.string(),
    mass99: z.string(),
    n99: z.string(),
    n99State: z.string().nullish(),

    cardiopulmonary: z.string(),
    heartMurmur: z.string(),
    arrhythmia: z.string(),
    wheeze: z.string(),
    cardiopulmonary99: z.string(),
    thorax: z.string(),
    c99: z.string(),
    c99State: z.string().nullish(),

    abdomen: z.string(),
    a99: z.string(),
    a99State: z.string().nullish(),

    scoliosis: z.string(),
    dysmelia: z.string(),
    polydactyly: z.string(),
    dysarthrosis: z.string(),
    dysmelia99: z.string(),
    squatting: z.string(),
    l99: z.string(),
    l99State: z.string().nullish(),

    cryptorchidism: z.string(),
    prepuce: z.string(),
    varicocele: z.string(),
    u99: z.string(),
    u99State: z.string().nullish(),

    epidermophytosis: z.string(),
    scabies: z.string(),
    wart: z.string(),
    atopicDermatitis: z.string(),
    eczema: z.string(),
    d99: z.string(),
    d99State: z.string().nullish(),

    t01: z.string(),
    t02: z.string(),
    t03: z.string(),
    t04: z.string(),
    t05: z.string(),
    t06: z.string(),
    t07: z.string(),
    t08: z.string(),
    t09: z.string(),
    t11: z.string(),
    t12: z.string(),
    t13: z.string(),
    t15: z.string(),
    t16: z.string(),
    t17: z.string(),
    t18: z.string(),
    t19: z.string(),
    t99: z.string(),
    t99state: z.string().nullish(),
    tSheet: z.string().nullish(),
    ret99state: z.string().nullish(),
    retSheet: z.string().nullish(),

    strabismus: z.string().nullish(),
    strabismustype: z.string().nullish(),
    trichiasis: z.string().nullish(),
    nystagmus: z.string().nullish(),
    blepharoptosis: z.string().nullish(),
    clp: z.string().nullish(),
    articulationDisorders: z.string().nullish(),
    preauricularFistula: z.string().nullish(),
    preauricularFistulaLR: z.string().nullish(),
    rhinitis: z.string().nullish(),
    allergicRhinitis: z.string().nullish(),
    hernia: z.string().nullish(),
    edema: z.string().nullish(),
    scrotalSwelling: z.string().nullish(),
    purpura: z.string().nullish(),

    checkUnit: z.string().nullish(),
    doctor: z.string().nullish(),
    teeDoctor: z.string().nullish(),

    examDate: zodStringToDateTime(),
    yearClassId: z.number(),
  })
);
export type PhiPhysical = z.infer<typeof PhiPhysicalZod>;

export const PhiPhysicalFileZod = z
  .object({
    /*身分證	學號	年級班級座號	
    收縮壓	舒張壓	血壓判讀	腰圍	脈搏	脈搏判讀
    辨色力異常	眼其他	眼其他陳述
    聽力異常    聽力異常左右    耳道畸型	耳道畸型左右	耳膜破損	耳膜破損左右	耵聹栓塞	耵聹栓塞左右	
    扁桃腺腫大	耳鼻喉其他	耳鼻喉其他陳述
    斜頸	異常腫塊	甲狀腺腫	淋巴腺腫大	其他異常腫塊	頭頸其他	頭頸其他陳述
    心肺疾病	心雜音	心律不整	呼吸聲異常	其他心肺疾病	胸廓異常	胸部其他	胸部其他陳述
    腹部異常腫大	腹部其他	腹部其他陳述
    脊柱側彎	肢體畸形	多併指（趾）	關節變形	其他肢體畸形	蹲踞困難	脊柱四肢其他	脊柱四肢其他陳述
    隱睪	包皮異常	精索靜脈曲張	泌尿生殖其他	泌尿生殖其他陳述
    癬	疥瘡	疣	異位性皮膚炎	溼疹	皮膚其他	皮膚其他陳述
    未治療齲齒	已治療齲齒	上顎恆牙第一大臼齒齲齒經驗	下顎恆牙第一大臼齒齲齒經驗	恆牙臼齒窩溝封填	口腔衛生不良
    牙結石	牙齦炎	咬合不正	口腔黏膜異常	牙周病	乳牙待拔牙	待拔牙	贅生牙	缺牙	阻生牙	
    口腔其他	口腔其他陳述	口檢表	複檢口腔其他陳述	複檢口檢表	
    //加購
    斜視	斜視類型	睫毛倒插	眼球震顫	眼瞼下垂	唇顎裂	
    構音異常	耳前瘻管	耳前瘻管左右	慢性鼻炎	過敏性鼻炎	疝氣	水腫	陰囊腫大	紫斑
    檢查日期*/

    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    收縮壓: zodStringOrNumberToNumber('收縮壓必須爲數字').nullish(),
    舒張壓: zodStringOrNumberToNumber('舒張壓必須爲數字').nullish(),
    血壓判讀: z.string().nullish(),
    腰圍: zodStringOrNumberToNumber('腰圍必須爲數字').nullish(),
    脈搏: zodStringOrNumberToNumber('脈搏必須爲數字').nullish(),
    脈搏判讀: z.string().nullish(),
    辨色力異常: z.string().nullish(),
    眼其他: z.string().nullish(),
    眼其他陳述: z.string().nullish(),
    聽力異常: z.string().nullish(),
    聽力異常左右: z.string().nullish(),
    耳道畸型: z.string().nullish(),
    耳道畸型左右: z.string().nullish(),
    耳膜破損: z.string().nullish(),
    耳膜破損左右: z.string().nullish(),
    耵聹栓塞: z.string().nullish(),
    耵聹栓塞左右: z.string().nullish(),
    扁桃腺腫大: z.string().nullish(),
    耳鼻喉其他: z.string().nullish(),
    耳鼻喉其他陳述: z.string().nullish(),

    斜頸: z.string().nullish(),
    異常腫塊: z.string().nullish(),
    甲狀腺腫: z.string().nullish(),
    淋巴腺腫大: z.string().nullish(),
    其他異常腫塊: z.string().nullish(),
    頭頸其他: z.string().nullish(),
    頭頸其他陳述: z.string().nullish(),

    心肺疾病: z.string().nullish(),
    心雜音: z.string().nullish(),
    心律不整: z.string().nullish(),
    呼吸聲異常: z.string().nullish(),
    其他心肺疾病: z.string().nullish(),
    胸廓異常: z.string().nullish(),
    胸部其他: z.string().nullish(),
    胸部其他陳述: z.string().nullish(),

    腹部異常腫大: z.string().nullish(),
    腹部其他: z.string().nullish(),
    腹部其他陳述: z.string().nullish(),
    脊柱側彎: z.string().nullish(),
    肢體畸形: z.string().nullish(),
    ['多併指（趾）']: z.string().nullish(),
    關節變形: z.string().nullish(),
    其他肢體畸形: z.string().nullish(),
    蹲踞困難: z.string().nullish(),
    脊柱四肢其他: z.string().nullish(),
    脊柱四肢其他陳述: z.string().nullish(),

    隱睪: z.string().nullish(),
    包皮異常: z.string().nullish(),
    精索靜脈曲張: z.string().nullish(),
    泌尿生殖其他: z.string().nullish(),
    泌尿生殖其他陳述: z.string().nullish(),

    癬: z.string().nullish(),
    疥瘡: z.string().nullish(),
    疣: z.string().nullish(),
    異位性皮膚炎: z.string().nullish(),
    溼疹: z.string().nullish(),
    皮膚其他: z.string().nullish(),
    皮膚其他陳述: z.string().nullish(),

    未治療齲齒: z.string().nullish(),
    已治療齲齒: z.string().nullish(),
    上顎恆牙第一大臼齒齲齒經驗: z.string().nullish(),
    下顎恆牙第一大臼齒齲齒經驗: z.string().nullish(),
    恆牙臼齒窩溝封填: z.string().nullish(),
    口腔衛生不良: z.string().nullish(),
    牙結石: z.string().nullish(),
    牙齦炎: z.string().nullish(),
    咬合不正: z.string().nullish(),
    口腔黏膜異常: z.string().nullish(),
    牙周病: z.string().nullish(),
    乳牙待拔牙: z.string().nullish(),
    待拔牙: z.string().nullish(),
    贅生牙: z.string().nullish(),
    缺牙: z.string().nullish(),
    阻生牙: z.string().nullish(),
    口腔其他: z.string().nullish(),
    口腔其他陳述: z.string().nullish(),
    口檢表: z.string().nullish(),
    複檢口腔其他陳述: z.string().nullish(),
    複檢口檢表: z.string().nullish(),

    斜視: z.string().nullish(),
    斜視類型: z.string().nullish(),
    睫毛倒插: z.string().nullish(),
    眼球震顫: z.string().nullish(),
    眼瞼下垂: z.string().nullish(),

    唇顎裂: z.string().nullish(),
    構音異常: z.string().nullish(),
    耳前瘻管: z.string().nullish(),
    耳前瘻管左右: z.string().nullish(),
    慢性鼻炎: z.string().nullish(),
    過敏性鼻炎: z.string().nullish(),
    疝氣: z.string().nullish(),
    水腫: z.string().nullish(),
    陰囊腫大: z.string().nullish(),
    紫斑: z.string().nullish(),
  })
  .transform(async (arg) => {
    let _errorMsg = '';
    let _warn = '';
    let _okUpload = true;
    let studentGrade = 0;
    let physicalSettingsData:string[] = [];
    const _code = ['0', '1', '2', '3', '9'];
    const _codeLR = ['1', '2', '3'];
    const _codeTee = ['0', '1', '9'];

    //檢查【身分證】、【學號】、【年級班級座號】格式
    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const _pid = checkStudentBaseDataResult.pid;
    const _sid = checkStudentBaseDataResult.sid;
    const _grade = checkStudentBaseDataResult.grade;
    const _no = checkStudentBaseDataResult.no;
    const _seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }
    // 取得學生年級
    await getStudentGrade();
    // 取得追加設定
    await apis.getPhysicalSettings().then((s) => {
      const settingData: physicalSettingObject = s.reduce((acc: physicalSettingObject, item: PhysicalSetting) => {
        acc[(item.grade)] = item;
        return acc;
      }, {});
      physicalSettingsData = settingData[studentGrade] ? settingData[studentGrade].bodyAdditional : [];
    });
    
    async function getStudentGrade() {
      const student = [{
        pid: _pid,
        sid: _sid,
        grade: _grade,
        no: _no,
        seat: _seat,
      }];
      const [cf, matchFunc] = CheckShouldUseIdentify(student);
      await apis.checkClassesStudentExistsWithGrade(
        student,
        cf
      )
      .then((result) => {
          if(result.grade) {
            studentGrade = result.grade;
          }
      })  
      .catch((e) => {
        console.log('error',e)
      });
    }

    //收縮壓
    const _sbp = arg.收縮壓;
    if (_sbp != null) {
      if (_sbp < 30 || _sbp > 250) {
        // 提醒高於或低於正常值，但仍允許匯入
        _warn += '請確認【收縮壓】是否為正常值，';
      }
    }
    //舒張壓
    const _dbp = arg.舒張壓;
    if (_dbp != null) {
      if (_dbp < 40 || _dbp > 260) {
        _warn += '請確認【舒張壓】是否為正常值，';
      }
    }

    //血壓判讀
    const checkBpResult = CheckCode(arg.血壓判讀, _code, '血壓判讀');
    if(_sbp != null && _dbp != null) {//有實施收縮壓、舒張壓
      if(checkBpResult.isNull) {//血壓判讀欄位空白 顯示0無異常
        checkBpResult.code = '0';
      } 
    } else if(checkBpResult.isNull) {//血壓判讀欄位空白 顯示9
      checkBpResult.code = '9';
    }
    const _bpResult = checkBpResult.code;
    //腰圍
    const _waistline = arg.腰圍;
    if (_waistline != null) {
      if (_waistline < 40 || _waistline > 260) {
        _okUpload = false;
        _errorMsg += '請確認【腰圍】資料，';
      }
    }
    //脈搏
    const _pulse = arg.脈搏;
    if (_pulse != null) {
      if (_pulse < 40 || _pulse > 260) {
        _okUpload = false;
        _errorMsg += '請確認【脈搏】資料，';
      }
    }
    //脈搏判讀
    const checkPulseResult = CheckCode(arg.脈搏判讀, _code, '脈搏判讀');
    if(_pulse != null) {//有實施脈搏
      if(checkPulseResult.isNull) {//脈搏判讀欄位空白 顯示0無異常
        checkPulseResult.code = '0';
      } 
    } else { //未實施脈搏
      if(checkPulseResult.isNull) {//脈搏判讀欄位空白 顯示9未受檢
        checkPulseResult.code = '9';
      } 
    }
    const _pulseResult = checkPulseResult.code;
    //辨色力異常
    const checkColorBlindResult = CheckCode(
      arg.辨色力異常,
      _code,
      '辨色力異常'
    );
    // 若欄位為空白 顯示0無異常
    if (!checkColorBlindResult.okUpload && !checkColorBlindResult.isNull) {
      _okUpload = checkColorBlindResult.okUpload;
      _errorMsg += checkColorBlindResult.errorMsg;
    }
    if(checkColorBlindResult.isNull) {
      checkColorBlindResult.code = '0';
    }
    const _colorBlind = checkColorBlindResult.code;
    //眼其他
    const checkE99Result = CheckCode(arg.眼其他, _code, '眼其他');
    // 若欄位為空白 顯示0無異常
    if (!checkE99Result.okUpload && !checkE99Result.isNull) {
      _okUpload = checkE99Result.okUpload;
      _errorMsg += checkE99Result.errorMsg;
    }
    if(checkE99Result.isNull) {
      checkE99Result.code = '0';
    }
    const _e99 = checkE99Result.code;
    //眼其他陳述 無異常則免填
    const checkE99State = CheckCode(arg.眼其他陳述, _code, '眼其他陳述');
    const _e99State = checkE99State.code;
    //聽力異常
    const checkHearingResult = CheckCode(arg.聽力異常, _code, '聽力異常');
    if(checkHearingResult.isNull) { //若欄位為空白 顯示0無異常
      checkHearingResult.code = '0';
    }
    const _hearing = checkHearingResult.code;
    //聽力異常左右
    const checkHearingLRResult = CheckCode(
      arg.聽力異常左右,
      _codeLR,
      '聽力異常左右'
    );
    if(_hearing != '9' && _hearing != '0') { //聽力異常有受檢時 需輸入聽力異常左右耳代碼
      if(!checkHearingLRResult.okUpload && checkHearingLRResult.isNull) {
        _okUpload = checkHearingLRResult.okUpload;
        _errorMsg += checkHearingLRResult.errorMsg;
      }
    } else {
      checkHearingLRResult.code = '0';
    }
    const _hearingLR = checkHearingLRResult.code;
    //耳道畸型
    const checkEarMisshapenResult = CheckCode(arg.耳道畸型, _code, '耳道畸型');
    if(checkEarMisshapenResult.isNull) {
      // 若有追加項目 顯示0無異常
      if(physicalSettingsData.includes('f')) {
        checkEarMisshapenResult.code = '0';   
      } else {
        // 若無追加項目 顯示9未受檢
        checkEarMisshapenResult.code = '9';
      }
    }
    const _earMisshapen = checkEarMisshapenResult.code;
    //耳道畸型左右
    const checkEarMisshapenLRResult = CheckCode(
      arg.耳道畸型左右,
      _codeLR,
      '耳道畸型左右'
    );
    if(_earMisshapen != '9' && _earMisshapen != '0') { //耳道畸型有受檢時 需輸入耳道畸型左右耳代碼
      if (!checkEarMisshapenLRResult.okUpload && checkEarMisshapenLRResult.isNull) {
        _okUpload = checkEarMisshapenLRResult.okUpload;
        _errorMsg += checkEarMisshapenLRResult.errorMsg;
      }
    } else {
      checkEarMisshapenLRResult.code = '0';
    }
    const _earMisshapenLR = checkEarMisshapenLRResult.code;
    //耳膜破損
    const checkEardrumResult = CheckCode(arg.耳膜破損, _code, '耳膜破損');
    if(checkEardrumResult.isNull) {
      if(physicalSettingsData.includes('g')) {// 若有追加項目 顯示0無異常
        checkEardrumResult.code = '0';   
      } else { // 若無追加項目 顯示9未受檢
        checkEardrumResult.code = '9';
      }
    }
    const _eardrum = checkEardrumResult.code;
    //耳膜破損左右
    const checkEardrumLRResult = CheckCode(
      arg.耳膜破損左右,
      _codeLR,
      '耳膜破損左右'
    );
    if(_eardrum != '9' && _eardrum != '0') { //耳膜破損有受檢時 需輸入耳膜破損左右耳代碼
      if (!checkEardrumLRResult.okUpload && checkEardrumLRResult.isNull) {
        _okUpload = checkEardrumLRResult.okUpload;
        _errorMsg += checkEardrumLRResult.errorMsg;
      }
    } else {
      checkEardrumLRResult.code = '0';
    }
    const _eardrumLR = checkEardrumLRResult.code;
    //耵聹栓塞
    const checkCerumenResult = CheckCode(arg.耵聹栓塞, _code, '耵聹栓塞');
    if(checkCerumenResult.isNull) {
      if(physicalSettingsData.includes('h')) { //若有追加項目 顯示0無異常
        checkCerumenResult.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkCerumenResult.code = '9';
      }
    } else if (!checkCerumenResult.okUpload) {
      _okUpload = checkCerumenResult.okUpload;
      _errorMsg += checkCerumenResult.errorMsg;
    }
    const _cerumen = checkCerumenResult.code;
    //耵聹栓塞左右
    const checkCerumenLRResult = CheckCode(
      arg.耵聹栓塞左右,
      _codeLR,
      '耵聹栓塞左右'
    );
    //耵聹栓塞有受檢時 需輸入耵聹栓塞左右耳代碼
    if(_cerumen != '9' && _cerumen != '0') {
      if (!checkCerumenLRResult.okUpload && checkCerumenLRResult.isNull) {
        _okUpload = checkCerumenLRResult.okUpload;
        _errorMsg += checkCerumenLRResult.errorMsg;
      }
    } else {
      checkCerumenLRResult.code = '0';
    }
    const _cerumenLR = checkCerumenLRResult.code;
    //扁桃腺腫大
    const checkTonsillitisResult = CheckCode(
      arg.扁桃腺腫大,
      _code,
      '扁桃腺腫大'
    );
    if(checkTonsillitisResult.isNull) {
      if(physicalSettingsData.includes('i')) { //若有追加項目 顯示0無異常
        checkTonsillitisResult.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkTonsillitisResult.code = '9';
      }
    } else if (!checkTonsillitisResult.okUpload) {
      _okUpload = checkTonsillitisResult.okUpload;
      _errorMsg += checkTonsillitisResult.errorMsg;
    }
    const _tonsillitis = checkTonsillitisResult.code;
    //耳鼻喉其他
    const checkO99Result = CheckCode(arg.耳鼻喉其他, _code, '耳鼻喉其他');
    if (!checkO99Result.okUpload && !checkO99Result.isNull) {
      _okUpload = checkO99Result.okUpload;
      _errorMsg += checkO99Result.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkO99Result.isNull) {
      checkO99Result.code = '0';
    }
    const _o99 = checkO99Result.code;
    //耳鼻喉其他陳述
    const checkO99State = CheckCode(arg.耳鼻喉其他陳述, _code, '耳鼻喉其他陳述');
    const _o99State = checkO99State.code;
    //斜頸
    const checkTorticollisResult = CheckCode(arg.斜頸, _code, '斜頸');
    if (!checkTorticollisResult.okUpload && !checkTorticollisResult.isNull) {
      _okUpload = checkTorticollisResult.okUpload;
      _errorMsg += checkTorticollisResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkTorticollisResult.isNull) {
      checkTorticollisResult.code = '0';
    }
    const _torticollis = checkTorticollisResult.code;
    //異常腫塊
    const checkMassResult = CheckCode(arg.異常腫塊, _code, '異常腫塊');
    if (!checkMassResult.okUpload && !checkMassResult.isNull) {
      _okUpload = checkMassResult.okUpload;
      _errorMsg += checkMassResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkMassResult.isNull) {
      checkMassResult.code = '0';
    }
    const _mass = checkMassResult.code;
    //甲狀腺腫
    const checkGoiterResult = CheckCode(arg.甲狀腺腫, _code, '甲狀腺腫');
    if (!checkGoiterResult.okUpload && !checkGoiterResult.isNull) {
      _okUpload = checkGoiterResult.okUpload;
      _errorMsg += checkGoiterResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkGoiterResult.isNull) {
      checkGoiterResult.code = '0';
    }
    const _goiter = checkGoiterResult.code;
    //淋巴腺腫大
    const checkLymphadenectasisResult = CheckCode(
      arg.淋巴腺腫大,
      _code,
      '淋巴腺腫大'
    );
    if (!checkLymphadenectasisResult.okUpload && !checkLymphadenectasisResult.isNull) {
      _okUpload = checkLymphadenectasisResult.okUpload;
      _errorMsg += checkLymphadenectasisResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkLymphadenectasisResult.isNull) {
      checkLymphadenectasisResult.code = '0';
    }
    const _lymphadenectasis = checkLymphadenectasisResult.code;
    //其他異常腫塊
    const checkMass99Result = CheckCode(
      arg.其他異常腫塊,
      _code,
      '其他異常腫塊'
    );
    if (!checkMass99Result.okUpload && !checkMass99Result.isNull) {
      _okUpload = checkMass99Result.okUpload;
      _errorMsg += checkMass99Result.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkMass99Result.isNull) {
      checkMass99Result.code = '0';
    }
    const _mass99 = checkMass99Result.code;
    //頭頸其他
    const checkN99Result = CheckCode(arg.頭頸其他, _code, '頭頸其他');
    if (!checkN99Result.okUpload && !checkN99Result.isNull) {
      _okUpload = checkN99Result.okUpload;
      _errorMsg += checkN99Result.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkN99Result.isNull) {
      checkN99Result.code = '0';
    }
    const _n99 = checkN99Result.code;
    //頭頸其他陳述
    const checkN99State = CheckCode(arg.頭頸其他陳述, _code, '頭頸其他陳述');
    const _n99State = checkN99State.code;
    //心肺疾病
    const checkCardiopulmonaryResult = CheckCode(
      arg.心肺疾病,
      _code,
      '心肺疾病'
    );
    if (!checkCardiopulmonaryResult.okUpload && !checkCardiopulmonaryResult.isNull) {
      _okUpload = checkCardiopulmonaryResult.okUpload;
      _errorMsg += checkCardiopulmonaryResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkCardiopulmonaryResult.isNull) {
      checkCardiopulmonaryResult.code = '0';
    }
    const _cardiopulmonary = checkCardiopulmonaryResult.code;
    //心雜音
    const checkHeartMurmurResult = CheckCode(arg.心雜音, _code, '心雜音');
    if (!checkHeartMurmurResult.okUpload && !checkHeartMurmurResult.isNull) {
      _okUpload = checkHeartMurmurResult.okUpload;
      _errorMsg += checkHeartMurmurResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkHeartMurmurResult.isNull) {
      checkHeartMurmurResult.code = '0';
    }
    const _heartMurmur = checkHeartMurmurResult.code;
    //心律不整
    const checkArrhythmiaResult = CheckCode(arg.心律不整, _code, '心律不整');
    if (!checkArrhythmiaResult.okUpload && !checkArrhythmiaResult.isNull) {
      _okUpload = checkArrhythmiaResult.okUpload;
      _errorMsg += checkArrhythmiaResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkArrhythmiaResult.isNull) {
      checkArrhythmiaResult.code = '0';
    }
    const _arrhythmia = checkArrhythmiaResult.code;
    //呼吸聲異常
    const checkWheezeResult = CheckCode(arg.呼吸聲異常, _code, '呼吸聲異常');
    if (!checkWheezeResult.okUpload &&!checkWheezeResult.isNull) {
      _okUpload = checkWheezeResult.okUpload;
      _errorMsg += checkWheezeResult.errorMsg;
    }
    // 若欄位為空白 顯示0無異常
    if(checkWheezeResult.isNull) {
      checkWheezeResult.code = '0';
    }
    const _wheeze = checkWheezeResult.code;
    //其他心肺疾病
    const checkCardiopulmonary99Result = CheckCode(
      arg.其他心肺疾病,
      _code,
      '其他心肺疾病'
    );
    if (!checkCardiopulmonary99Result.okUpload && !checkCardiopulmonary99Result.isNull) {
      _okUpload = checkCardiopulmonary99Result.okUpload;
      _errorMsg += checkCardiopulmonary99Result.errorMsg;
    }
    if(checkCardiopulmonary99Result.isNull) {
      checkCardiopulmonary99Result.code = '0';
    }
    const _cardiopulmonary99 = checkCardiopulmonary99Result.code;
    //胸廓異常
    const checkThorax99Result = CheckCode(arg.胸廓異常, _code, '胸廓異常');
    if (!checkThorax99Result.okUpload && !checkThorax99Result.isNull) {
      _okUpload = checkThorax99Result.okUpload;
      _errorMsg += checkThorax99Result.errorMsg;
    }
    if(checkThorax99Result.isNull) {
      checkThorax99Result.code = '0';
    }
    const _thorax = checkThorax99Result.code;
    //胸部其他
    const checkC99Result = CheckCode(arg.胸部其他, _code, '胸部其他');
    if (!checkC99Result.okUpload && !checkC99Result.isNull) {
      _okUpload = checkC99Result.okUpload;
      _errorMsg += checkC99Result.errorMsg;
    }
    if(checkC99Result.isNull) {
      checkC99Result.code = '0';
    }
    const _c99 = checkC99Result.code;
    // 胸部其他陳述
    const checkC99State = CheckCode(arg.胸部其他陳述, _code, '胸部其他陳述');
    const _c99State = checkC99State.code;
    //腹部異常腫大
    const checkAbdomenResult = CheckCode(
      arg.腹部異常腫大,
      _code,
      '腹部異常腫大'
    );
    if (!checkAbdomenResult.okUpload && !checkAbdomenResult.isNull) {
      _okUpload = checkAbdomenResult.okUpload;
      _errorMsg += checkAbdomenResult.errorMsg;
    }
    if(checkAbdomenResult.isNull) {
      checkAbdomenResult.code = '0';
    }
    const _abdomen = checkAbdomenResult.code;
    //腹部其他
    const checkA99Result = CheckCode(arg.腹部其他, _code, '腹部其他');
    if (!checkA99Result.okUpload && !checkA99Result.isNull) {
      _okUpload = checkA99Result.okUpload;
      _errorMsg += checkA99Result.errorMsg;
    }
    if(checkA99Result.isNull) {
      checkA99Result.code = '0';
    }
    const _a99 = checkA99Result.code;
    // 腹部其他陳述
    const checkA99State = CheckCode(arg.腹部其他陳述, _code, '腹部其他陳述');
    const _a99State = checkA99State.code;
    //const _scoliosis = arg.脊柱側彎;
    const checkScoliosisResult = CheckCode(arg.脊柱側彎, _code, '脊柱側彎');
    if (!checkScoliosisResult.okUpload && !checkScoliosisResult.isNull) {
      _okUpload = checkScoliosisResult.okUpload;
      _errorMsg += checkScoliosisResult.errorMsg;
    }
    if(checkScoliosisResult.isNull) {
      checkScoliosisResult.code = '0';
    }
    const _scoliosis = checkScoliosisResult.code;
    //肢體畸形
    const checkDysmeliaResult = CheckCode(arg.肢體畸形, _code, '肢體畸形');
    if (!checkDysmeliaResult.okUpload && !checkDysmeliaResult.isNull) {
      _okUpload = checkDysmeliaResult.okUpload;
      _errorMsg += checkDysmeliaResult.errorMsg;
    }
    if(checkDysmeliaResult.isNull) {
      checkDysmeliaResult.code = '0';
    }
    const _dysmelia = checkDysmeliaResult.code;
    //['多併指（趾）']
    const checkPolydactylyResult = CheckCode(
      arg['多併指（趾）'],
      _code,
      '多併指（趾）'
    );
    if (!checkPolydactylyResult.okUpload && !checkPolydactylyResult.isNull) {
      _okUpload = checkPolydactylyResult.okUpload;
      _errorMsg += checkPolydactylyResult.errorMsg;
    }
    if(checkPolydactylyResult.isNull) {
      checkPolydactylyResult.code = '0';
    }
    const _polydactyly = checkPolydactylyResult.code;
    //關節變形
    const checkDysarthrosisResult = CheckCode(arg.關節變形, _code, '關節變形');
    if (!checkDysarthrosisResult.okUpload && !checkDysarthrosisResult.isNull) {
      _okUpload = checkDysarthrosisResult.okUpload;
      _errorMsg += checkDysarthrosisResult.errorMsg;
    }
    if(checkDysarthrosisResult.isNull) {
      checkDysarthrosisResult.code = '0';
    }
    const _dysarthrosis = checkDysarthrosisResult.code;
    //其他肢體畸形
    const checkDysmelia99Result = CheckCode(
      arg.其他肢體畸形,
      _code,
      '其他肢體畸形'
    );
    if (!checkDysmelia99Result.okUpload && !checkDysmelia99Result.isNull) {
      _okUpload = checkDysmelia99Result.okUpload;
      _errorMsg += checkDysmelia99Result.errorMsg;
    }
    if(checkDysmelia99Result.isNull) {
      checkDysmelia99Result.code = '0';
    }
    const _dysmelia99 = checkDysmelia99Result.code;
    //蹲踞困難;
    const checkSquattingResult = CheckCode(arg.蹲踞困難, _code, '蹲踞困難');
    if (!checkSquattingResult.okUpload && !checkSquattingResult.isNull) {
      _okUpload = checkSquattingResult.okUpload;
      _errorMsg += checkSquattingResult.errorMsg;
    }
    if(checkSquattingResult.isNull) {
      checkSquattingResult.code = '0';
    }
    const _squatting = checkSquattingResult.code;
    //脊柱四肢其他
    const checkL99Result = CheckCode(arg.脊柱四肢其他, _code, '脊柱四肢其他');
    if (!checkL99Result.okUpload && !checkL99Result.isNull) {
      _okUpload = checkL99Result.okUpload;
      _errorMsg += checkL99Result.errorMsg;
    }
    if(checkL99Result.isNull) {
      checkL99Result.code = '0';
    }
    const _l99 = checkL99Result.code;
    //脊柱四肢其他陳述
    const checkL99State = CheckCode(arg.脊柱四肢其他陳述, _code, '脊柱四肢其他陳述');
    const _l99State = checkL99State.code;
    //隱睪
    const checkCryptorchidismResult = CheckCode(arg.隱睪, _code, '隱睪');
    if(checkCryptorchidismResult.isNull) {
      if(physicalSettingsData.includes('d')) { //若有追加項目 顯示0無異常
        checkCryptorchidismResult.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkCryptorchidismResult.code = '9';
      }
    } else if (!checkCryptorchidismResult.okUpload) {
      _okUpload = checkCryptorchidismResult.okUpload;
      _errorMsg += checkCryptorchidismResult.errorMsg;
    }
    const _cryptorchidism = checkCryptorchidismResult.code;
    //包皮異常
    const checkPrepuceResult = CheckCode(arg.包皮異常, _code, '包皮異常');
    if (!checkPrepuceResult.okUpload && !checkPrepuceResult.isNull) {
      _okUpload = checkPrepuceResult.okUpload;
      _errorMsg += checkPrepuceResult.errorMsg;
    }
    if(checkPrepuceResult.isNull) {
      checkPrepuceResult.code = '0';
    }
    const _prepuce = checkPrepuceResult.code;
    //精索靜脈曲張
    const checkVaricoceleResult = CheckCode(
      arg.精索靜脈曲張,
      _code,
      '精索靜脈曲張'
    );
    if (!checkVaricoceleResult.okUpload && !checkVaricoceleResult.isNull) {
      _okUpload = checkVaricoceleResult.okUpload;
      _errorMsg += checkVaricoceleResult.errorMsg;
    }
    if(checkVaricoceleResult.isNull) {
      checkVaricoceleResult.code = '0';
    }
    const _varicocele = checkVaricoceleResult.code;
    //泌尿生殖其他
    const checkU99Result = CheckCode(arg.泌尿生殖其他, _code, '泌尿生殖其他');
    if (!checkU99Result.okUpload && !checkU99Result.isNull) {
      _okUpload = checkU99Result.okUpload;
      _errorMsg += checkU99Result.errorMsg;
    }
    if(checkU99Result.isNull) {
      checkU99Result.code = '0';
    }
    const _u99 = checkU99Result.code;
    //泌尿生殖其他陳述
    const checkU99State = CheckCode(arg.泌尿生殖其他陳述, _code, '泌尿生殖其他陳述');
    const _u99State = checkU99State.code;
    //癬
    const checkEpidermophytosisResult = CheckCode(arg.癬, _code, '癬');
    if (!checkEpidermophytosisResult.okUpload && !checkEpidermophytosisResult.isNull) {
      _okUpload = checkEpidermophytosisResult.okUpload;
      _errorMsg += checkEpidermophytosisResult.errorMsg;
    }
    if(checkEpidermophytosisResult.isNull) {
      checkEpidermophytosisResult.code = '0';
    }
    const _epidermophytosis = checkEpidermophytosisResult.code;
    //疥瘡
    const checkScabiesResult = CheckCode(arg.疥瘡, _code, '疥瘡');
    if (!checkScabiesResult.okUpload && !checkScabiesResult.isNull) {
      _okUpload = checkScabiesResult.okUpload;
      _errorMsg += checkScabiesResult.errorMsg;
    }
    if(checkScabiesResult.isNull) {
      checkScabiesResult.code = '0';
    }
    const _scabies = checkScabiesResult.code;
    //const _wart = arg.疣;
    const checkWartResult = CheckCode(arg.疣, _code, '疣');
    if (!checkWartResult.okUpload && !checkWartResult.isNull) {
      _okUpload = checkWartResult.okUpload;
      _errorMsg += checkWartResult.errorMsg;
    }
    if(checkWartResult.isNull) {
      checkWartResult.code = '0';
    }
    const _wart = checkWartResult.code;
    //異位性皮膚炎
    const checkAtopicDermatitisResult = CheckCode(
      arg.異位性皮膚炎,
      _code,
      '異位性皮膚炎'
    );
    if (!checkAtopicDermatitisResult.okUpload && !checkAtopicDermatitisResult.isNull) {
      _okUpload = checkAtopicDermatitisResult.okUpload;
      _errorMsg += checkAtopicDermatitisResult.errorMsg;
    }
    if(checkAtopicDermatitisResult.isNull) {
      checkAtopicDermatitisResult.code = '0';
    }
    const _atopicDermatitis = checkAtopicDermatitisResult.code;
    //溼疹
    const checkEczemaResult = CheckCode(arg.溼疹, _code, '溼疹');
    if (!checkEczemaResult.okUpload && !checkEczemaResult.isNull) {
      _okUpload = checkEczemaResult.okUpload;
      _errorMsg += checkEczemaResult.errorMsg;
    }
    if(checkEczemaResult.isNull) {
      checkEczemaResult.code = '0';
    }
    const _eczema = checkEczemaResult.code;
    //皮膚其他
    const checkD99Result = CheckCode(arg.皮膚其他, _code, '皮膚其他');
    if (!checkD99Result.okUpload && !checkD99Result.isNull) {
      _okUpload = checkD99Result.okUpload;
      _errorMsg += checkD99Result.errorMsg;
    }
    if(checkD99Result.isNull) {
      checkD99Result.code = '0';
    }
    const _d99 = checkD99Result.code;
    //皮膚其他陳述
    const checkD99State = CheckCode(arg.皮膚其他陳述, _code, '皮膚其他陳述');
    const _d99State = checkD99State.code;
    //檢查【未治療齲齒】欄位，必填欄位，t01
    const checkT01Result = CheckCode(arg.未治療齲齒, _code, '未治療齲齒');
    if (!checkT01Result.okUpload && !checkT01Result.isNull) {
      _okUpload = checkT01Result.okUpload;
      _errorMsg += checkT01Result.errorMsg;
    }
    if(checkT01Result.isNull) {
      checkT01Result.code = '0';
    }
    //檢查【缺牙】欄位，必填欄位，t02
    const checkT02 = CheckCode(arg.缺牙, _code, '缺牙');
    if (!checkT02.okUpload && !checkT02.isNull) {
      _okUpload = checkT02.okUpload;
      _errorMsg += checkT02.errorMsg;
    }
    if(checkT02.isNull) {
      checkT02.code = '0';
    }
    //檢查【待拔牙】欄位，必填欄位，t03
    const checkT03 = CheckCode(arg.待拔牙, _code, '待拔牙');
    if (!checkT03.okUpload && !checkT03.isNull) {
      _okUpload = checkT03.okUpload;
      _errorMsg += checkT03.errorMsg;
    }
    if(checkT03.isNull) {
      checkT03.code = '0';
    }
    //檢查【口腔衛生不良】欄位，必填欄位，t04
    const checkT04 = CheckCode(arg.口腔衛生不良, _code, '口腔衛生不良');
    if (!checkT04.okUpload && !checkT04.isNull) {
      _okUpload = checkT04.okUpload;
      _errorMsg += checkT04.errorMsg;
    }
    if(checkT04.isNull) {
      checkT04.code = '0';
    }
    const _t04 = checkT04.code;
    //檢查【牙結石】欄位，必填欄位，t05
    const checkT05 = CheckCode(arg.牙結石, _code, '牙結石');
    if (!checkT05.okUpload && !checkT05.isNull) {
      _okUpload = checkT05.okUpload;
      _errorMsg += checkT05.errorMsg;
    }
    if(checkT05.isNull) {
      checkT05.code = '0';
    }
    const _t05 = checkT05.code;
    //檢查【牙週炎】欄位，t06
    // const checkT06 = CheckCode(arg.牙週炎, _code, '牙週炎');
    // console.log('checkT06',checkT06)
    // if(!checkT06.okUpload && checkT06.isNull) {
    //   checkT06.code = '0';
    // }
    // const _t06 = checkT06.code;
    //檢查【咬合不正】欄位，必填欄位，t07
    const checkT07 = CheckCode(arg.咬合不正, _code, '咬合不正');
    if (!checkT07.okUpload && !checkT07.isNull) {
      _okUpload = checkT07.okUpload;
      _errorMsg += checkT07.errorMsg;
    }
    if(checkT07.isNull) {
      checkT07.code = '0';
    }
    const _t07 = checkT07.code;
    //檢查【牙齦炎】欄位，必填欄位，t08
    const checkT08 = CheckCode(arg.牙齦炎, _code, '牙齦炎');
    if (checkT08.isNull) {
      checkT08.code = '0';
    } else {
      _okUpload = checkT08.okUpload;
      _errorMsg += checkT08.errorMsg;
    }
    const _t08 = checkT08.code;

    //檢查【口腔黏膜異常】欄位，必填欄位，t09
    const checkT09 = CheckCode(arg.口腔黏膜異常, _code, '口腔黏膜異常');
    if(checkT09.isNull) {
      if(physicalSettingsData.includes('e')) { //若有追加項目 顯示0無異常
        checkT09.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkT09.code = '9';
      }
    } else if (!checkT09.okUpload && !checkT09.isNull) {
      _okUpload = checkT09.okUpload;
      _errorMsg += checkT09.errorMsg;
    }
    if(checkT09.isNull) {
      checkT09.code = '0';
    }
    const _t09 = checkT09.code;
    //檢查【已治療齲齒】欄位，t11
    const checkT11 = CheckCode(arg.未治療齲齒, _codeTee, '已治療齲齒');
    if(checkT01Result.code == '9') {
      checkT11.code = '9';
    } else if(checkT01Result.code == '1' || checkT01Result.code == '2' || checkT01Result.code == '3') {
      checkT11.code = '0';
    }
    if (!checkT11.okUpload && checkT11.isNull) { //若欄位為空白 顯示0無
      checkT11.code = '0';
    }
    //檢查【上顎恆牙第一大臼齒齲齒經驗】欄位，t12
    const checkT12 = CheckCode(
      arg.上顎恆牙第一大臼齒齲齒經驗,
      _codeTee,
      '上顎恆牙第一大臼齒齲齒經驗'
    );
    if (!checkT12.okUpload) { //若欄位為空白 顯示0無
      checkT12.code = '0';
    }
    
    //檢查【下顎恆牙第一大臼齒齲齒經驗】欄位，必填欄位，t13
    const checkT13 = CheckCode(
      arg.下顎恆牙第一大臼齒齲齒經驗,
      _codeTee,
      '下顎恆牙第一大臼齒齲齒經驗'
    );
    if (!checkT13.okUpload) { //若欄位為空白 顯示0無
      checkT13.code = '0';
    }
    //const _t14 = arg.治療急迫性
    //檢查【恆牙臼齒窩溝封填】欄位，必填欄位，t15
    const checkT15 = CheckCode(
      arg.恆牙臼齒窩溝封填,
      _codeTee,
      '恆牙臼齒窩溝封填'
    );
    if(checkT15.isNull) {
      if(physicalSettingsData.includes('j')) { //若有追加項目 顯示0無異常
        checkT15.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkT15.code = '9';
      }
    } else if (!checkT15.okUpload) {//若欄位為空白 顯示0無
      checkT15.code = '0';
    }
    //檢查【牙周病】欄位，必填欄位，t16
    const checkT16 = CheckCode(arg.牙周病, _code, '牙周病');
    if (!checkT16.okUpload && !checkT16.isNull) {
      _okUpload = checkT16.okUpload;
      _errorMsg += checkT16.errorMsg;
    }
    if(checkT16.isNull) {
      checkT16.code = '0';
    }
    const _t16 = checkT16.code;
    //檢查【乳牙待拔牙】欄位，必填欄位，t17
    const checkT17 = CheckCode(arg.乳牙待拔牙, _code, '乳牙待拔牙');
    if (!checkT17.okUpload && !checkT17.isNull) {
      _okUpload = checkT17.okUpload;
      _errorMsg += checkT17.errorMsg;
    }
    if(checkT17.isNull) {
      checkT17.code = '0';
    }
    //檢查【贅生牙】欄位，必填欄位，t18
    const checkT18 = CheckCode(arg.贅生牙, _code, '贅生牙');
    if (!checkT18.okUpload && !checkT18.isNull) {
      _okUpload = checkT18.okUpload;
      _errorMsg += checkT18.errorMsg;
    }
    if(checkT18.isNull) {
      checkT18.code = '0';
    }
    //檢查【阻生牙】欄位，必填欄位，t19
    const checkT19 = CheckCode(arg.阻生牙, _code, '阻生牙');
    if (!checkT19.okUpload && !checkT19.isNull) {
      _okUpload = checkT19.okUpload;
      _errorMsg += checkT19.errorMsg;
    }
    if(checkT19.isNull) {
      checkT19.code = '0';
    }
    //口腔其他陳述
    const checkT99State = CheckCode(arg.口腔其他陳述, _code, '口腔其他陳述');
    const _t99State = checkT99State.code;
    //檢查【口腔其他】欄位，必填欄位，t99
    const checkT99 = CheckCode(arg.口腔其他, _code, '口腔其他');
    if (!checkT99.okUpload && !checkT99.isNull) {
      _okUpload = checkT99.okUpload;
      _errorMsg += checkT99.errorMsg;
    }
    if(checkT99.isNull) {
      checkT99.code = '0';
    }
    if(checkT99State.code && checkT99.code == '0') {
      checkT99.code = '1';
    }
    
    const _t99 = checkT99.code;
    //口檢表
    const tSheet = arg.口檢表;
    if(tSheet) {
      const tSheetArr = tSheet.match(/(\d+[A-Za-z]+)/g);
      const checkT12Code = ['16D', '16E', '16F', '26D', '26E', '26F'];
      const checkT13Code = ['36D', '36E', '36F', '46D', '46E', '46F'];
   
      if(tSheetArr?.length) {
        if(tSheetArr.some(element => checkT12Code.includes(element))) { // 口檢表有 16D、16E、16F、26D、26E、26F，則「上顎恆牙第一大臼齒齲齒經驗」設為「有」
          checkT12.code = '1';
        }
        if(tSheetArr.some(element => checkT13Code.includes(element))) { // 口檢表有 36D、36E、36F、46D、46E、46F，則「下顎恆牙第一大臼齒齲齒經驗」設為「有」
          checkT13.code = '1';
        }
        if(tSheet.includes('D') || tSheet.includes('E') || tSheet.includes('d') || tSheet.includes('e')) { // 口檢表有D（齲齒）或 E（待拔牙），將「未治療齲齒」設定為 1（初檢異常），「已治療齲齒」設為 0（無）
          checkT01Result.code = '1';
          checkT11.code = '0';
        } else if(tSheet.includes('F') || tSheet.includes('f')) { // 口檢表有 F（已矯治）時，將「未治療齲齒」設定為 0（無異狀），「已治療齲齒」設為 1（有）
          checkT01Result.code = '0';
          checkT11.code = '1';
        } 
        if((tSheet.includes('SF') || tSheet.includes('sf')) && (checkT15.code == '0' || checkT15.code == '9')) { // 口檢表有 SF（恆牙臼齒窩溝封填），對應的「恆牙臼齒窩溝封填」判讀欄位聯動修改判讀欄位為 1（有）
          checkT15.code = '1';
        }
        if((tSheet.includes('E') || tSheet.includes('e')) && (checkT03.code == '0' || checkT03.code == '9')) { // 口檢表有 E（待拔牙），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT03.code = '1';
        }
        if((tSheet.includes('G') || tSheet.includes('g')) && (checkT19.code == '0' || checkT19.code == '9')) { // 口檢表有 G （阻生齒），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT19.code = '1';
        }
        if((tSheet.includes('S') || tSheet.includes('s')) && (checkT18.code == '0' || checkT18.code == '9')) { // 口檢表有 S（贅生牙），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT18.code = '1';
        }
        if((tSheet.includes('M') || tSheet.includes('m')) && (checkT02.code == '0' || checkT02.code == '9')) { // 口檢表有 M（缺牙），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT02.code = '1';
        }
        if((tSheet.includes('H') || tSheet.includes('h')) && (checkT17.code == '0' || checkT17.code == '9')) { // 口檢表有 H（乳牙待拔），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT17.code = '1';
        }
      }
    }
    const _t01 = checkT01Result.code;
    const _t02 = checkT02.code;
    const _t03 = checkT03.code;
    const _t11 = checkT11.code;
    const _t12 = checkT12.code;
    const _t13 = checkT13.code;
    const _t15 = checkT15.code;
    const _t17 = checkT17.code;
    const _t18 = checkT18.code;
    const _t19 = checkT19.code;


    //複檢口腔其他陳述
    const checkT99RecheckState = CheckCode(arg.複檢口腔其他陳述, _code, '複檢口腔其他陳述');
    const _t99RecheckState = checkT99RecheckState.code;
    //非必要檢查
    //斜視
    const checkStrabismus = CheckCode(arg.斜視, _code, '斜視');
    if(checkStrabismus.isNull) {
      if(physicalSettingsData.includes('0')) { //若有追加項目 顯示0無異常
        checkStrabismus.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkStrabismus.code = '9';
      }
    } else if (!checkStrabismus.okUpload) {
      _okUpload = checkStrabismus.okUpload;
      _errorMsg += checkStrabismus.errorMsg;
    }
    const _strabismus = checkStrabismus.code;
    //斜視類型
    const _strabismusCode = ['1', '2', '3', '4', '5', '6', '7', '9'];
    const checkStrabismusType = CheckCode(arg.斜視類型, _strabismusCode, '斜視類型');
    if(_strabismus != '9' && _strabismus != '0') {
      if (!checkStrabismusType.okUpload && checkStrabismusType.isNull) {
        _okUpload = checkStrabismusType.okUpload;
        _errorMsg += checkStrabismusType.errorMsg;
      }
    } else {
      checkStrabismusType.code = '0';
    }
    const _strabismusType = checkStrabismusType.code;
    //睫毛倒插
    const checkTrichiasis = CheckCode(arg.睫毛倒插, _code, '睫毛倒插');
    if(checkTrichiasis.isNull) {
      if(physicalSettingsData.includes('1')) { //若有追加項目 顯示0無異常
        checkTrichiasis.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkTrichiasis.code = '9';
      }
    } else if (!checkTrichiasis.okUpload && !checkTrichiasis.isNull) {
      _okUpload = checkTrichiasis.okUpload;
      _errorMsg += checkTrichiasis.errorMsg;
    }
    const _trichiasis = checkTrichiasis.code;
    //眼球震顫
    const checkNystagmus = CheckCode(arg.眼球震顫, _code, '眼球震顫');
    if(checkNystagmus.isNull) {
      if(physicalSettingsData.includes('2')) { //若有追加項目 顯示0無異常
        checkNystagmus.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkNystagmus.code = '9';
      }
    } else if (!checkNystagmus.okUpload && !checkNystagmus.isNull) {
      _okUpload = checkNystagmus.okUpload;
      _errorMsg += checkNystagmus.errorMsg;
    }
    const _nystagmus = checkNystagmus.code;
    //眼瞼下垂
    const checkBlepharoptosis = CheckCode(arg.眼瞼下垂, _code, '眼瞼下垂');
    if(checkBlepharoptosis.isNull) {
      if(physicalSettingsData.includes('3')) { //若有追加項目 顯示0無異常
        checkBlepharoptosis.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkBlepharoptosis.code = '9';
      }
    } else if (!checkBlepharoptosis.okUpload && !checkBlepharoptosis.isNull) {
      _okUpload = checkBlepharoptosis.okUpload;
      _errorMsg += checkBlepharoptosis.errorMsg;
    }
    const _blepharoptosis = checkBlepharoptosis.code;
    //唇顎裂
    const checkClp = CheckCode(arg.唇顎裂, _code, '唇顎裂');
    if(checkClp.isNull) {
      if(physicalSettingsData.includes('4')) { //若有追加項目 顯示0無異常
        checkClp.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkClp.code = '9';
      }
    } else if (!checkClp.okUpload && !checkClp.isNull) {
      _okUpload = checkClp.okUpload;
      _errorMsg += checkClp.errorMsg;
    }
    const _clp = checkClp.code;
    //構音異常
    const checkArticulationDisorders = CheckCode(
      arg.構音異常,
      _code,
      '構音異常'
    );
    if(checkArticulationDisorders.isNull) {
      if(physicalSettingsData.includes('5')) { //若有追加項目 顯示0無異常
        checkArticulationDisorders.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkArticulationDisorders.code = '9';
      }
    } else if (!checkArticulationDisorders.okUpload && !checkArticulationDisorders.isNull) {
      _okUpload = checkArticulationDisorders.okUpload;
      _errorMsg += checkArticulationDisorders.errorMsg;
    }
    const _articulationDisorders = checkArticulationDisorders.code;
    //耳前瘻管
    const checkPreauricularFistula = CheckCode(arg.耳前瘻管, _code, '耳前瘻管');
    if(checkPreauricularFistula.isNull) {
      if(physicalSettingsData.includes('6')) { //若有追加項目 顯示0無異常
        checkPreauricularFistula.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkPreauricularFistula.code = '9';
      }
    }
    const _preauricularFistula = checkPreauricularFistula.code;
    //耳前瘻管左右
    const checkPreauricularFistulaLR = CheckCode(
      arg.耳前瘻管左右,
      _codeLR,
      '耳前瘻管左右'
    );
    if(_preauricularFistula != '9' && _preauricularFistula != '0') { //耳前瘻管有受檢時 需輸入耳前瘻管左右耳代碼
      if (!checkPreauricularFistulaLR.okUpload && checkPreauricularFistulaLR.isNull) {
        _okUpload = checkPreauricularFistulaLR.okUpload;
        _errorMsg += checkPreauricularFistulaLR.errorMsg;
      }
    } else {
      checkPreauricularFistulaLR.code = '0';
    }
    const _preauricularFistulaLR = checkPreauricularFistulaLR.code;
    //慢性鼻炎
    const checkRhinitis = CheckCode(arg.慢性鼻炎, _code, '慢性鼻炎');
    if(checkRhinitis.isNull) {
      if(physicalSettingsData.includes('7')) { //若有追加項目 顯示0無異常
        checkRhinitis.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkRhinitis.code = '9';
      }
    } else if (!checkRhinitis.okUpload && !checkRhinitis.isNull) {
      _okUpload = checkRhinitis.okUpload;
      _errorMsg += checkRhinitis.errorMsg;
    }
    const _rhinitis = checkRhinitis.code;
    //過敏性鼻炎
    const checkAllergicRhinitis = CheckCode(
      arg.過敏性鼻炎,
      _code,
      '過敏性鼻炎'
    );
    if(checkAllergicRhinitis.isNull) {
      if(physicalSettingsData.includes('8')) { //若有追加項目 顯示0無異常
        checkAllergicRhinitis.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkAllergicRhinitis.code = '9';
      }
    } else if (!checkAllergicRhinitis.okUpload && !checkAllergicRhinitis.isNull) {
      _okUpload = checkAllergicRhinitis.okUpload;
      _errorMsg += checkAllergicRhinitis.errorMsg;
    }
    const _allergicRhinitis = checkAllergicRhinitis.code;
    //疝氣
    const checkHernia = CheckCode(arg.疝氣, _code, '疝氣');
    if(checkHernia.isNull) {
      if(physicalSettingsData.includes('a')) { //若有追加項目 顯示0無異常
        checkHernia.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkHernia.code = '9';
      }
    } else if (!checkHernia.okUpload && !checkHernia.isNull) {
      _okUpload = checkHernia.okUpload;
      _errorMsg += checkHernia.errorMsg;
    }
    const _hernia = checkHernia.code;
    //水腫
    const checkEdema = CheckCode(arg.水腫, _code, '水腫');
    if(checkEdema.isNull) {
      if(physicalSettingsData.includes('9')) { //若有追加項目 顯示0無異常
        checkEdema.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkEdema.code = '9';
      }
    } else if (!checkEdema.okUpload && !checkEdema.isNull) {
      _okUpload = checkEdema.okUpload;
      _errorMsg += checkEdema.errorMsg;
    }
    const _edema = checkEdema.code;
    // const _scrotalSwelling = arg.陰囊腫大;
    const checkScrotalSwelling = CheckCode(arg.陰囊腫大, _code, '陰囊腫大');
    if(checkScrotalSwelling.isNull) {
      if(physicalSettingsData.includes('b')) { //若有追加項目 顯示0無異常
        checkScrotalSwelling.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkScrotalSwelling.code = '9';
      }
    } else if (!checkScrotalSwelling.okUpload && !checkScrotalSwelling.isNull) {
      _okUpload = checkScrotalSwelling.okUpload;
      _errorMsg += checkScrotalSwelling.errorMsg;
    }
    const _scrotalSwelling = checkScrotalSwelling.code;
    //紫斑
    const checkPurpura = CheckCode(arg.紫斑, _code, '紫斑');
    if(checkPurpura.isNull) {
      if(physicalSettingsData.includes('c')) { //若有追加項目 顯示0無異常
        checkPurpura.code = '0';   
      } else { //若無追加項目 顯示9未受檢
        checkPurpura.code = '9';
      }
    } else if (!checkPurpura.okUpload && !checkPurpura.isNull) {
      _okUpload = checkPurpura.okUpload;
      _errorMsg += checkPurpura.errorMsg;
    }
    const _purpura = checkPurpura.code;

    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid: _pid,
      sid: _sid,
      grade: _grade,
      no: _no,
      seat: _seat,
      sem: GetSemester,
      year: GetAcademicYear,
      sbp: _sbp,
      dbp: _dbp,
      bpResult: _bpResult,
      waistline: _waistline,
      waistResult: _waistline ? 1 : 9,
      pulse: _pulse,
      pulseResult: _pulseResult,
      colorBlind: _colorBlind,
      e99: _e99,
      e99State: _e99State,

      hearing: _hearing,
      hearingLR: _hearingLR,
      earMisshapen: _earMisshapen,
      earMisshapenLR: _earMisshapenLR,
      eardrum: _eardrum,
      eardrumLR: _eardrumLR,
      cerumen: _cerumen,
      cerumenLR: _cerumenLR,
      tonsillitis: _tonsillitis,
      o99: _o99,
      o99State: _o99State,

      torticollis: _torticollis,
      mass: _mass,
      goiter: _goiter,
      lymphadenectasis: _lymphadenectasis,
      mass99: _mass99,
      n99: _n99,
      n99State: _n99State,

      cardiopulmonary: _cardiopulmonary,
      heartMurmur: _heartMurmur,
      arrhythmia: _arrhythmia,
      wheeze: _wheeze,
      cardiopulmonary99: _cardiopulmonary99,
      thorax: _thorax,
      c99: _c99,
      c99State: _c99State,

      abdomen: _abdomen,
      a99: _a99,
      a99State: _a99State,

      scoliosis: _scoliosis,
      dysmelia: _dysmelia,
      polydactyly: _polydactyly,
      dysarthrosis: _dysarthrosis,
      dysmelia99: _dysmelia99,
      squatting: _squatting,
      l99: _l99,
      l99State: _l99State,

      cryptorchidism: _cryptorchidism,
      prepuce: _prepuce,
      varicocele: _varicocele,
      u99: _u99,
      u99State: _u99State,

      epidermophytosis: _epidermophytosis,
      scabies: _scabies,
      wart: _wart,
      atopicDermatitis: _atopicDermatitis,
      eczema: _eczema,
      d99: _d99,
      d99State: _d99State,

      t01: _t01,
      t02: _t02,
      t03: _t03,
      t04: _t04,
      t05: _t05,
      t06: _t08,
      t07: _t07,
      t08: _t08,
      t09: _t09,
      t11: _t11,
      t12: _t12,
      t13: _t13,
      //t14: _t14 ,
      t15: _t15,
      t16: _t16,
      t17: _t17,
      t18: _t18,
      t19: _t19,
      t99: _t99,
      t99state: _t99State,
      tSheet: arg.口檢表,
      ret99state: _t99RecheckState,
      retSheet: arg.複檢口檢表,

      strabismus: _strabismus,
      strabismusType: _strabismusType,
      trichiasis: _trichiasis,
      nystagmus: _nystagmus,
      blepharoptosis: _blepharoptosis,
      clp: _clp,
      articulationDisorders: _articulationDisorders,
      preauricularFistula: _preauricularFistula,
      preauricularFistulaLR: _preauricularFistulaLR,
      rhinitis: _rhinitis,
      allergicRhinitis: _allergicRhinitis,
      hernia: _hernia,
      edema: _edema,
      scrotalSwelling: _scrotalSwelling,
      purpura: _purpura == null ? '9' : _purpura,

      examDate: null as DateTime | null,
      yearClassId: 0,
      okUpload: _okUpload,
      remark: _errorMsg,
      warn: _warn
    };
  });

export type PhiPhysicalFile = z.infer<typeof PhiPhysicalFileZod>;

export const PhiPhysicalApiZod = z.object({
  /*收縮壓	舒張壓	血壓判讀	腰圍	脈搏	脈搏判讀
    辨色力異常	眼其他	眼其他陳述
    聽力異常    聽力異常左右	耳道畸型	耳道畸型左右	耳膜破損	耳膜破損左右	耵聹栓塞	耵聹栓塞左右	扁桃腺腫大	耳鼻喉其他	耳鼻喉其他陳述
    斜頸	異常腫塊	甲狀腺腫	淋巴腺腫大	其他異常腫塊	頭頸其他	頭頸其他陳述
    心肺疾病	心雜音	心律不整	呼吸聲異常	其他心肺疾病	胸廓異常	胸部其他	胸部其他陳述
    腹部異常腫大	腹部其他	腹部其他陳述
    脊柱側彎	肢體畸形	多併指（趾）	關節變形	其他肢體畸形	蹲踞困難	脊柱四肢其他	脊柱四肢其他陳述
    隱睪	包皮異常	精索靜脈曲張	泌尿生殖其他	泌尿生殖其他陳述
    癬	疥瘡	疣	異位性皮膚炎	溼疹	皮膚其他	皮膚其他陳述
    未治療齲齒	已治療齲齒	上顎恆牙第一大臼齒齲齒經驗	下顎恆牙第一大臼齒齲齒經驗	恆牙臼齒窩溝封填	口腔衛生不良	牙結石	牙齦炎	咬合不正
    口腔黏膜異常	牙周病H	乳牙待拔牙	待拔牙	贅生牙	缺牙	阻生牙	口腔其他	口腔其他陳述	口檢表	複檢口腔其他陳述	複檢口檢表
    斜視	斜視類型	睫毛倒插	眼球震顫	眼瞼下垂	唇顎裂	構音異常	耳前瘻管	耳前瘻管左右	慢性鼻炎	過敏性鼻炎	疝氣	水腫	陰囊腫大	紫斑
    檢查日期*/

  pid: z.string(),
  sid: z.string(),
  grade: z.string(),
  no: z.string(),
  seat: z.string(),
  sem: z.string(),
  year: z.number(),

  sbp: z.number().nullish(),
  dbp: z.number().nullish(),
  bpResult: z.string(),
  waistline: z.number().nullish(),
  pulse: z.number().nullish(),
  pulseResult: z.string(),
  colorBlind: z.string(),
  e99: z.string(),
  e99State: z.string().nullish(),

  hearing: z.string(),
  hearingLR: z.string(),
  earMisshapen: z.string(),
  earMisshapenLR: z.string(),
  eardrum: z.string(),
  eardrumLR: z.string(),
  cerumen: z.string(),
  cerumenLR: z.string(),
  tonsillitis: z.string(),
  o99: z.string(),
  o99State: z.string().nullish(),
  torticollis: z.string(),
  mass: z.string(),
  goiter: z.string(),
  lymphadenectasis: z.string(),
  mass99: z.string(),
  n99: z.string(),
  n99State: z.string().nullish(),

  cardiopulmonary: z.string(),
  heartMurmur: z.string(),
  arrhythmia: z.string(),
  wheeze: z.string(),
  cardiopulmonary99: z.string(),
  thorax: z.string(),
  c99: z.string(),
  c99State: z.string().nullish(),

  abdomen: z.string(),
  a99: z.string(),
  a99State: z.string().nullish(),

  scoliosis: z.string(),
  dysmelia: z.string(),
  polydactyly: z.string(),
  dysarthrosis: z.string(),
  dysmelia99: z.string(),
  squatting: z.string(),
  l99: z.string(),
  l99State: z.string().nullish(),

  cryptorchidism: z.string(),
  prepuce: z.string(),
  Varicocele: z.string(),
  U99: z.string(),
  U99State: z.string().nullish(),

  epidermophytosis: z.string(),
  scabies: z.string(),
  wart: z.string(),
  atopicDermatitis: z.string(),
  eczema: z.string(),
  d99: z.string(),
  d99State: z.string().nullish(),

  t01: z.string(),
  t02: z.string(),
  t03: z.string(),
  t04: z.string(),
  t05: z.string(),
  t06: z.string(),
  t07: z.string(),
  t08: z.string(),
  t09: z.string(),
  t11: z.string(),
  t12: z.string(),
  t13: z.string(),
  t15: z.string(),
  t16: z.string(),
  t17: z.string(),
  t18: z.string(),
  t19: z.string(),
  t99: z.string(),
  t99state: z.string().nullish(),
  tSheet: z.string().nullish(),
  ret99state: z.string().nullish(),
  retSheet: z.string().nullish(),

  strabismus: z.string().nullish(),
  strabismustype: z.string().nullish(),
  trichiasis: z.string().nullish(),
  nystagmus: z.string().nullish(),
  blepharoptosis: z.string().nullish(),
  clp: z.string().nullish(),
  articulationDisorders: z.string().nullish(),
  preauricularFistula: z.string().nullish(),
  preauricularFistulaLR: z.string().nullish(),
  rhinitis: z.string().nullish(),
  allergicRhinitis: z.string().nullish(),
  hernia: z.string().nullish(),
  edema: z.string().nullish(),
  scrotalSwelling: z.string().nullish(),
  purpura: z.string().nullish(),

  examDate: zodStringToDateTime(),
  yearClassId: z.number(),
});
export type PhiPhysicalApi = z.infer<typeof PhiPhysicalApiZod>;
