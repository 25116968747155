import React, { FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../redux/States';
import { ErrorDispatches } from '../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { LoadingPage } from './Loading';
import { useClassGrade, useClassStudents } from '../hook';
import apis from '../apis';
import {
  InjuryMomentEnum,
  InjuryPlaceEnum,
  StudentHealthInfo,
  StudentInjury,
} from '../model';
import { DateTime } from 'luxon';
import {
  AuthedLayout,
  CheckBoxField,
  DATETIME_FORMAT_WITH_TIME,
  DateTimeField,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  InputDropdownGroupField,
  MyInputGroup,
  NumberField,
  TextField,
} from '../components';
import {
  Button,
  Col,
  FormControl,
  Modal,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import { DEFAULT_DATETIME_FORMAT } from '../utils/date';
import { Form } from 'react-final-form';
import { I18N } from '../i18n-raw';
import { toast } from 'react-toastify';
import { serializeInjury } from '../utils';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = ErrorDispatches;
const bodyMindBookNDegree: { [key: string]: string } = {
  1: '輕度',
  2: '中度',
  3: '極重度',
};
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;
const ObservationMins = Array.from<number>({ length: 12 }).reduce(
  (obj, _, i) => ({ ...obj, [(i + 1) * 5]: '1' }),
  {} as Record<number, string>
);

const InjuryHistoriesHeader: HeaderDisplayKeysWithType<StudentInjury>[] = [
  { display: '學年', property: 'year' },
  {
    display: '月',
    property: 'date',
    onRender: (v) => {
      return v instanceof DateTime ? v.toFormat('M') : null;
    },
  },
  {
    display: '學年',
    property: 'date',
    onRender: (v) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
  },
  {
    display: '時段',
    property: 'momentEnum',
    onRender: (v) =>
      typeof v === 'number' ? I18N.InjuryMoment[v as InjuryMomentEnum] : '',
  },
  {
    display: '地點',
    property: 'placeEnum',
    onRender: (v) =>
      typeof v === 'number' ? I18N.InjuryPlace[v as InjuryPlaceEnum] : '',
  },
  {
    display: '情形',
    property: 'memos',
    onRender: (v, i, e, c) => {
      const { allValues } = c;

      return serializeInjury(allValues);
    },
  },
];

const inheritDiseaseMap1: { [key: number]: string } = {
  1: '糖尿病',
  2: '血友病',
  5: '蠶豆症',
  31: '高血壓',
  99: '其他',
};
const relativeMap1: { [key: number]: string } = {
  1: '父親',
  2: '母親',
  3: '祖父',
  4: '祖母',
  5: '兄',
  6: '弟',
  7: '姐',
  8: '妹',
  13: '外祖父',
  14: '外祖母',
};
const InsuranceMap1: { [key: number]: string } = {
  1: '全民健保',
  2: '學生團保',
  9: '其他',
};
type InjuryAreaProps = {
  area: string;
  show: boolean;
};
type InjuryProps = {
  property: string;
  displayClassName?: string;
};

const injuryPage: FunctionComponent<Props> = ({ user, catchErrorForModal }) => {
  if (!user) return <LoadingPage />;

  const [isInternal, setIsInternal] = useState(false);
  const [memos, setMemos] = useState('');
  const [injury, setInjury] = useState<StudentInjury>(DEFAULT_INJURY);
  const [healthInfo, setHealthInfo] = useState<StudentHealthInfo>();
  const [insert, setInsert] = useState(true);

  const { currentSemester, semesters } = user;
  const yearSem = semesters[currentSemester];

  const { selectedClassGrade, element: classGradeButton } = useClassGrade({
    disableQueryParamStore: true,
    yearSem,
  });

  const { selectedStudent, element: studentButton } = useClassStudents({
    yearSem,
    classId: selectedClassGrade?.id,
  });

  useEffect(() => {
    refreshStudent();
  }, [selectedStudent?.pid]);

  function refreshStudent() {
    if (selectedStudent) {
      apis
        .GetHealthInfo(selectedStudent.id)
        .then((s) => {
          setHealthInfo(s);
        })
        .catch(catchErrorForModal);
    }
  }

  function showInjuryUpdate(injury: StudentInjury) {
    setInjury(injury);
    setMemos(injury.memos || '');
    setInsert(false);
  }

  function deleteInjury(injury: StudentInjury) {
    if (selectedStudent) {
      toast
        .promise(apis.RemoveStudentInjuries(selectedStudent.pid, injury.id), {
          pending: '傷病資料刪除中......',
          success: '傷病資料刪除成功！',
          error: '傷病資料刪除失敗！請查看錯誤資訊。',
        })
        .then(() => {
          refreshStudent();
        })
        .catch(catchErrorForModal);
    }
  }

  function insertOrUpdateInjuryToDB(injury: StudentInjury) {
    if (selectedStudent) {
      toast
        .promise(
          insert
            ? apis.InsertStudentInjuries(selectedStudent.pid, injury)
            : apis.UpdateStudentInjuries(selectedStudent.pid, injury),
          {
            pending: '傷病資料更新中......',
            success: '傷病資料更新成功！',
            error: '傷病資料更新失敗！請查看錯誤資訊。',
          }
        )
        .then(() => {
          reset();
          refreshStudent();
        })
        .catch(catchErrorForModal);
    }
  }

  function reset() {
    setInjury(DEFAULT_INJURY);
    setInsert(true);
    setMemos('');
  }

  const InjuryPlace: FunctionComponent<InjuryAreaProps> = ({
    area,
    show = false,
  }) => {
    const className = ['wid-100'];
    if (!show) className.push('d-none');
    return (
      <Button
        className={className.join(' ')}
        onClick={() => {
          setMemos(memos + I18N.Injury[area]);
        }}
      >
        {I18N.Injury[area]}
      </Button>
    );
  };

  const InjuryColumn: FunctionComponent<InjuryProps> = ({
    property,
    displayClassName = 'wid-80',
  }) => {
    return (
      <MyInputGroup>
        <CheckBoxField property={property} />
        <span className={displayClassName}>{I18N.Injury[property]}</span>
      </MyInputGroup>
    );
  };

  let injuryContent = <></>;

  if (selectedStudent) {
    injuryContent = (
      <Row className="align-items-start">
        <Col sm={2} className="mr-2">
          <Table bordered className="student-phi mb-2">
            <colgroup>
              <col width={'30%'} />
              <col width={'70%'} />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={2}>基本資料</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>統編</td>
                <td>{selectedStudent.pid}</td>
              </tr>
              <tr>
                <td>座號</td>
                <td>{selectedStudent.seat}</td>
              </tr>
              <tr>
                <td>學生</td>
                <td>{selectedStudent.name}</td>
              </tr>
              <tr>
                <td>生日</td>
                <td>
                  {selectedStudent.birth.toFormat(DEFAULT_DATETIME_FORMAT)}
                </td>
              </tr>
              <tr>
                <td>父親</td>
                <td>{selectedStudent.dad}</td>
              </tr>
              <tr>
                <td>母親</td>
                <td>{selectedStudent.mom}</td>
              </tr>
              <tr>
                <td>緊急連絡</td>
                <td>{selectedStudent.erTel}</td>
              </tr>
            </tbody>
          </Table>
          <Table bordered className="student-phi mb-2">
            <colgroup>
              <col width={'30%'} />
              <col width={'70%'} />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={2}>健康基本資料</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>個人疾病史</td>
                {healthInfo?.disease.length ? (
                  <td
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {healthInfo.disease.map(
                      (d, i) =>
                        `${i + 1}.${d.diseaseName}(${d.description})${
                          d.treatment
                        }\n`
                    )}
                  </td>
                ) : (
                  <td>無資料</td>
                )}
              </tr>
              <tr>
                <td>重大傷病卡</td>
                {healthInfo?.seriousDisease.length ? (
                  <td
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {healthInfo?.seriousDisease.map(
                      (d, i) => `${i + 1}.${d.diseaseName}\n`
                    )}
                  </td>
                ) : (
                  <td>無資料</td>
                )}
              </tr>
              {/* <tr>
                <td>身心障礙手冊</td>
                <td>???</td>
              </tr> */}
              <tr>
                <td>身心障礙手冊新制</td>
                {healthInfo?.bodyMindBookN.length ? (
                  <td
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {healthInfo.bodyMindBookN.map(
                      (d, i) =>
                        `${i + 1}.${d.bodyMindName}(${
                          bodyMindBookNDegree[d.degree]
                        })\n`
                    )}
                  </td>
                ) : (
                  <td>無資料</td>
                )}
              </tr>
              <tr>
                <td>家族疾病史</td>
                {healthInfo?.inheritDisease.length ? (
                  <td
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {healthInfo.inheritDisease.map((d, i) => {
                      const inheritDisease =
                        d.inheritDisease === 99
                          ? `其他(${d.states})`
                          : inheritDiseaseMap1[d.inheritDisease];
                      return `${i + 1}.${inheritDisease}_${
                        relativeMap1[d.relative]
                      }\n`;
                    })}
                  </td>
                ) : (
                  <td>無資料</td>
                )}
              </tr>
              <tr>
                <td>護送醫院</td>
                <td>
                  {healthInfo?.hospital.length
                    ? healthInfo.hospital.map((h, i) => `${h.hospitalName}\n`)
                    : '特約醫院'}
                </td>
              </tr>
              <tr>
                <td>保險</td>
                <td>
                  {healthInfo?.insurance.length
                    ? healthInfo.insurance.map((v, i) => {
                        let studentInsurance = ``;
                        if (v.insurance === 9) {
                          studentInsurance = v.states
                            ? `${v.states}\n`
                            : `其他保險\n`;
                        } else {
                          studentInsurance = InsuranceMap1[v.insurance];
                        }
                        return `${studentInsurance}\n`;
                      })
                    : ''}
                </td>
              </tr>
              <tr>
                <td>立體感</td>
                <td>{healthInfo?.sightSolid.length ? '有異狀' : '無異狀'}</td>
              </tr>
            </tbody>
          </Table>
          <Table bordered className="student-phi mb-2">
            <thead>
              <tr>
                <th>在校期間重大傷病事故</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {healthInfo?.injuries
                    ?.filter((i) => i.isSerious)
                    .map((i, idx) => (
                      <p key={idx}>
                        {`${i.place ?? ''} ${i.moment ?? ''} ${serializeInjury(
                          i
                        )}`}
                        <br /> ({i.date?.toFormat(DEFAULT_DATETIME_FORMAT)})
                      </p>
                    ))}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <Form
            initialValues={injury}
            onSubmit={(value: StudentInjury, form) => {
              // put memos into value;
              insertOrUpdateInjuryToDB({ ...value, memos });
              form.reset(DEFAULT_INJURY);
            }}
            subscription={{ values: true }}
            render={(prop) => {
              const { handleSubmit, form } = prop;
              const state = form.getState().values;
              return (
                <>
                  <Row noGutters className="mb-2">
                    <MyInputGroup className="mr-2">
                      <span className="wid-50">日期</span>
                      <DateTimeField
                        showTimeSelect
                        property="date"
                        format={DATETIME_FORMAT_WITH_TIME}
                        end={DateTime.now()}
                      />
                    </MyInputGroup>
                    <MyInputGroup className="mr-2">
                      <span>時段:</span>
                      <InputDropdownGroupField
                        groupClassName="wid-60"
                        property="momentEnum"
                        content={I18N.InjuryMoment}
                        selectShow="value"
                        resultShow="value"
                        type="number"
                      />
                      <span>中午時段為 12：00～12：59</span>
                    </MyInputGroup>
                    <MyInputGroup>
                      <span>休息觀查時間</span>
                      <InputDropdownGroupField
                        className="wid-60"
                        content={ObservationMins}
                        property="observationMins"
                        selectShow="key"
                        resultShow="key"
                      />
                      <span>分鐘</span>
                    </MyInputGroup>
                    <MyInputGroup>
                      <span>受傷地點</span>
                      <InputDropdownGroupField
                        content={I18N.InjuryPlace}
                        resultClassName="wid-160"
                        property="placeEnum"
                        selectShow="value"
                        resultShow="value"
                        type="number"
                      />
                      <TextField
                        property="otherPlace"
                        className={
                          state.placeEnum === InjuryPlaceEnum.Other
                            ? ''
                            : 'd-none'
                        }
                      />
                    </MyInputGroup>
                    <MyInputGroup>
                      <span>體溫</span>
                      <NumberField
                        property="temperature"
                        min={34.0}
                        max={40.0}
                      />
                    </MyInputGroup>
                  </Row>
                  <hr />
                  <ul className="nav nav-tabs">
                    <li>
                      <summary
                        className={`text-dark font-weight-bold wid-100 nav-link ${
                          !isInternal ? 'active' : ''
                        }`}
                        onClick={() => setIsInternal(false)}
                      >
                        外傷
                      </summary>
                    </li>
                    <li>
                      <summary
                        className={`text-dark font-weight-bold wid-100 nav-link ${
                          isInternal ? 'active' : ''
                        }`}
                        onClick={() => setIsInternal(true)}
                      >
                        內科
                      </summary>
                    </li>
                  </ul>
                  <div className="border-right border-left border-bottom p-1 py-2">
                    {isInternal ? (
                      <>
                        <Row noGutters>內科</Row>
                        <Row noGutters className="mb-2 rounded-lg border">
                          <InjuryColumn property="fever" />
                          <InjuryColumn property="dizzy" />
                          <InjuryColumn property="puke" />
                          <InjuryColumn property="headache" />
                          <InjuryColumn property="toothache" />
                          <InjuryColumn property="stomachache" />
                          <InjuryColumn property="bellyache" />
                          <InjuryColumn property="diarrhoea" />
                          <InjuryColumn property="menses" />
                          <InjuryColumn property="pant" />
                          <InjuryColumn property="noseBlood" />
                          <InjuryColumn property="rash" />
                          <InjuryColumn property="eyeache" />
                          <InjuryColumn property="hOther" />
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row noGutters>受傷部位</Row>
                        <Row noGutters className="mb-2 rounded-lg border">
                          <InjuryColumn property="head" />
                          <InjuryColumn property="neck" />
                          <InjuryColumn property="shoulder" />
                          <InjuryColumn property="chest" />
                          <InjuryColumn property="belly" />
                          <InjuryColumn property="back" />
                          <InjuryColumn property="eye" />
                          <InjuryColumn property="face" />
                          <InjuryColumn property="mouth" />
                          <InjuryColumn property="nose" />
                          <InjuryColumn property="arm" />
                          <InjuryColumn property="waist" />
                          <InjuryColumn property="leg" />
                          <InjuryColumn property="buttock" />
                          <InjuryColumn property="perineum" />
                        </Row>
                        {state.eye || state.arm || state.leg ? (
                          <>
                            <Row noGutters>加入備註說明</Row>
                            <Row
                              noGutters
                              className="mb-2 p-1 rounded-lg border justify-content-around"
                            >
                              <InjuryPlace area="rightEye" show={state.eye} />
                              <InjuryPlace area="leftEye" show={state.eye} />
                              <InjuryPlace area="rightArm" show={state.arm} />
                              <InjuryPlace area="leftArm" show={state.arm} />
                              <InjuryPlace area="rightLeg" show={state.leg} />
                              <InjuryPlace area="leftLeg" show={state.leg} />
                            </Row>
                          </>
                        ) : (
                          <></>
                        )}
                        <Row noGutters>外傷</Row>
                        <Row noGutters className="mb-2 rounded-lg border">
                          <InjuryColumn property="friction" />
                          <InjuryColumn property="slash" />
                          <InjuryColumn property="press" />
                          <InjuryColumn property="strick" />
                          <InjuryColumn property="twist" />
                          <InjuryColumn property="burn" />
                          <InjuryColumn property="sting" />
                          <InjuryColumn property="fracture" />
                          <InjuryColumn property="old" />
                          <InjuryColumn property="aOther" />
                        </Row>
                      </>
                    )}
                  </div>
                  <Row noGutters>處置作為</Row>
                  <Row noGutters className="mb-2 rounded-lg border">
                    <InjuryColumn property="woundCare" />
                    <InjuryColumn property="ice" />
                    <InjuryColumn property="hot" />
                    <InjuryColumn property="rest" />
                    <InjuryColumn property="noteParent" />
                    <InjuryColumn property="backHome" />
                    <InjuryColumn property="hospital" />
                    <InjuryColumn property="education" />
                    <InjuryColumn property="mOther" />
                  </Row>
                  <Row noGutters className="mb-2">
                    <Col sm={8}>
                      <FormControl
                        type="text"
                        value={memos}
                        onChange={(e) => {
                          setMemos(e.target.value);
                        }}
                      />
                    </Col>
                    <InjuryColumn
                      property="isSerious"
                      displayClassName="wid-100"
                    />
                  </Row>
                  <Row noGutters className="mb-2">
                    <Button
                      className="mr-2"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      確定{insert ? '新增' : '修改'}
                    </Button>
                    <Button
                      className="mr-2"
                      onClick={() => {
                        form.reset(DEFAULT_INJURY);
                        reset();
                      }}
                      variant="secondary"
                    >
                      取消
                    </Button>
                  </Row>
                </>
              );
            }}
          />
          <hr />
          <div className="mt-3 mb-1">顯示歷史資料</div>
          <EditableTableDiv
            values={healthInfo?.injuries || []}
            headers={InjuryHistoriesHeader}
            editable
            onEdit={(v) => showInjuryUpdate(v)}
            deleteable
            onDelete={(v) => deleteInjury(v)}
          />
        </Col>
      </Row>
    );
  }

  return (
    <AuthedLayout>
      <Row>
        <div className="wid-130 mr-2">{classGradeButton}</div>
        <div className="mr-2">{studentButton}</div>
      </Row>
      <hr />
      {injuryContent}
    </AuthedLayout>
  );
};

export const InjuryPage = connector(injuryPage);

export const DEFAULT_INJURY: StudentInjury = {
  id: 0,
  yearClassId: 0,
  date: DateTime.now(),
  year: 0,
  isSerious: false,
  observationMins: 0,
  temperature: 0,
  memos: '',
  place: '',
  otherPlace: '',
  placeEnum: InjuryPlaceEnum.None,
  moment: '',
  momentEnum: InjuryMomentEnum.None,
  head: false,
  eye: false,
  mouth: false,
  face: false,
  nose: false,
  chest: false,
  belly: false,
  back: false,
  shoulder: false,
  arm: false,
  waist: false,
  leg: false,
  neck: false,
  buttock: false,
  perineum: false,
  friction: false,
  slash: false,
  press: false,
  strick: false,
  twist: false,
  burn: false,
  sting: false,
  fracture: false,
  old: false,
  aOther: false,
  fever: false,
  dizzy: false,
  puke: false,
  headache: false,
  toothache: false,
  stomachache: false,
  bellyache: false,
  diarrhoea: false,
  menses: false,
  pant: false,
  noseBlood: false,
  rash: false,
  eyeache: false,
  hOther: false,
  woundCare: false,
  ice: false,
  hot: false,
  rest: false,
  noteParent: false,
  backHome: false,
  hospital: false,
  education: false,
  mOther: false,
};
