import React, {
  FunctionComponent,
  FocusEvent,
  useState,
  useRef,
  ChangeEvent,
  RefObject,
  KeyboardEvent,
  useMemo,
} from 'react';
import { FormControl, InputGroup, Overlay, Popover } from 'react-bootstrap';

type Props = {
  groupClassName?: string;
  resultClassName?: string;
  className?: string;
  name: string;
  value?: string | number;
  type?: 'number' | 'text';
  max?: number;
  min?: number;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  options: Record<string | number, string>;
  defaultShow?: string;
  resultShow?: 'key' | 'both' | 'value';
  selectShow?: 'key' | 'both' | 'value';
  needGroup?: boolean;
  allowValueNotInContent?: boolean;
  onFocus: (e: FocusEvent<HTMLElement>) => void;
  onBlur: (e: FocusEvent<HTMLElement>) => void;
  onChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
};
export const InputDropdownGroup: FunctionComponent<Props> = ({
  onFocus,
  onBlur,
  onChange,
  groupClassName,
  resultClassName,
  options,
  defaultShow,
  needGroup = true,
  selectShow = 'both',
  resultShow = 'key',
  type,
  ...rawProps
}) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLInputElement>(null);
  const formControlRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLSelectElement>(null);
  const resultRef = useRef<HTMLInputElement>(null);
  const id = useMemo(() => Math.random().toString(16).substring(-6), []);

  let { className } = rawProps;
  const { className: _, ...props } = {
    type: type || 'text',
    ...rawProps,
  };
  let s = Object.entries(options).length + +!!defaultShow;
  s = s > 10 ? 10 : s;
  const wrapOnFocus = (e: FocusEvent<HTMLElement>) => {
    setShow(true);
    onFocus(e);
  };
  const wrapOnBlur = (e: FocusEvent<HTMLElement>) => {
    setShow(false);
    onBlur(e);
  };
  if (resultShow === 'value') {
    className = `${className ?? ''} d-none`;
  }
  let content = (
    <>
      <FormControl
        {...props}
        className={className}
        value={props.value || (type === 'number' ? 0 : '')}
        ref={formControlRef}
        autoComplete="off"
        onChange={(e) => {
          if (dropdownRef.current) {
            dropdownRef.current.value = e.target.value;
          }
          if (resultRef.current)
            resultRef.current.value = options[e.target.value] ?? '';
          onChange(e);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
          }
        }}
        onFocus={(e: FocusEvent<HTMLInputElement>) => {
          setShow(true);
          wrapOnFocus(e);
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          setShow(false);
          wrapOnBlur(e);
        }}
      />
      {resultShow !== 'key' ? (
        <FormControl
          className={resultClassName}
          type="text"
          ref={resultRef}
          readOnly
          autoComplete="off"
          defaultValue={options[props.value ?? ''] ?? ''}
          onChange={(e) => {
            console.log(e.target.value);
          }}
          onFocus={(e: FocusEvent<HTMLInputElement>) => {
            setShow(true);
            wrapOnFocus(e);
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setShow(false);
            wrapOnBlur(e);
          }}
        />
      ) : null}
      <Overlay
        container={containerRef}
        target={
          resultShow !== 'key' ? resultRef.current : formControlRef.current
        }
        show={show}
        placement="bottom"
      >
        <Popover id={id}>
          <Popover.Content>
            <FormControl
              {...props}
              ref={dropdownRef}
              value={props.value + ''}
              name={props.name}
              autoComplete="off"
              onChange={(e) => {
                e.preventDefault();
                e.persist();
                if (formControlRef.current) {
                  formControlRef.current.value = e.currentTarget.value;
                }
                if (resultRef.current)
                  resultRef.current.value =
                    options[e.currentTarget.value] ?? '';

                onChange(e as any);
                // formControlRef.current?.focus();
              }}
              onFocus={wrapOnFocus}
              onBlur={wrapOnBlur}
              as="select"
              custom
              tabIndex={1}
              htmlSize={s}
              style={{
                width: 'max-content',
                minWidth: '100%',
              }}
            >
              {defaultShow ? <option value="">- {defaultShow}</option> : null}
              {Object.entries(options).map(([key, value]) => (
                <option
                  value={key}
                  key={key}
                  onClick={(e) => {
                    e.preventDefault();
                    e.persist();
                    if (formControlRef.current) {
                      formControlRef.current.value = e.currentTarget.value;
                    }
                    if (resultRef.current)
                      resultRef.current.value =
                        options[e.currentTarget.value] ?? '';

                    onChange(e as any);
                    // formControlRef.current?.focus();
                  }}
                >
                  {selectShow !== 'value' ? key : ''}{' '}
                  {selectShow !== 'key' ? value : ''}
                </option>
              ))}
            </FormControl>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
  content = (
    <InputGroup ref={containerRef} className={groupClassName}>
      {content}
    </InputGroup>
  );
  return content;
};
