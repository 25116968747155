import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Button, Col, Row, FormCheck } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { sightDiag, whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';

type PageData = StudentGradeNoSeat & Nullable<Sight> & { diag: string };


const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { property: 'pid', display: '統編' },
        { property: 'name', display: '學生' },
        {
            display: '性別', property: 'sex',
            onRender: (v) => (v === '1' ? '男' : '女'),
        },
        { property: 'grade', display: '年級' },
        { property: 'no', display: '班級' },
        { property: 'seat', display: '座號' },
        { display: '裸視右', property: 'sight0R' },
        { display: '裸視左', property: 'sight0L' },
        { display: '戴鏡右', property: 'sightR' },
        { display: '戴鏡左', property: 'sightL' },
        {
            display: '陳述', property: 'sight0Level',
            onRender: (v) => getsight0levelname(v),
        },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const sightCantCheck: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<PageData[]>([]);
    const [sight0levelCode0, setsight0levelCode0] = useState(false);
    const [sight0levelCode, setsight0levelCode] = useState(false);
    const [sight0levelCode1, setsight0levelCode1] = useState(false);
    const [sight0levelCode2, setsight0levelCode2] = useState(false);
    const [sight0levelCode3, setsight0levelCode3] = useState(false);

    const filterstudents = useMemo(filterBmicode, [
        sight0levelCode0,
        sight0levelCode,
        sight0levelCode1,
        sight0levelCode2,
        sight0levelCode3,
        students
    ]);

    function filterBmicode() {
        let newStudents = students;
        const filtercode: number[] = [];
        if (sight0levelCode0 || sight0levelCode || sight0levelCode1 || sight0levelCode2 || sight0levelCode3) {
            if (sight0levelCode0)
                filtercode.push(-6)
            if (sight0levelCode)
                filtercode.push(5)
            if (sight0levelCode1)
                filtercode.push(-7)
            if (sight0levelCode2)
                filtercode.push(-8)
            if (sight0levelCode3)
                filtercode.push(-9)
            newStudents = students.filter((s) => {
                if (filtercode.includes(s.sight0Level ?? 0)) {
                    return s
                }
            })
        }
        return newStudents
    }

    useEffect(() => {
        if (yearSem) {
            apis
                .getSightCantCheck(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(
                        r.map(({ sight, ...s }) => ({
                            ...sight,
                            ...s,
                            diag: sightDiag(sight),
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);


    return (
        <AuthedLayout>

            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                        <Col xs={6} className="mr-3">
                            <Row>
                                <FormCheck
                                    className="mr-4"
                                    label="全盲"
                                    checked={sight0levelCode}
                                    onChange={() => setsight0levelCode(!sight0levelCode)}
                                    value={5}
                                />
                                <FormCheck
                                    className="mr-4"
                                    label="鐳射治療"
                                    checked={sight0levelCode0}
                                    onChange={() => setsight0levelCode0(!sight0levelCode0)}
                                    value={-6}
                                />
                                <FormCheck
                                    className="mr-4"
                                    label="角膜塑形片"
                                    checked={sight0levelCode1}
                                    onChange={() => setsight0levelCode1(!sight0levelCode1)}
                                    value={-7}
                                />
                                <FormCheck
                                    className="mr-4"
                                    label="隱形眼鏡"
                                    checked={sight0levelCode2}
                                    onChange={() => setsight0levelCode2(!sight0levelCode2)}
                                    value={-8}
                                />
                                <FormCheck
                                    className="mr-4"
                                    label="無法測量"
                                    checked={sight0levelCode3}
                                    onChange={() => setsight0levelCode3(!sight0levelCode3)}
                                    value={-9}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!filterstudents.length}
                        variant="info"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_視力無法測量名單`,
                                values: filterstudents,
                                headers: tableHeader,
                                context: SightContext,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={filterstudents}
                    context={SightContext}
                />
            </Row>
        </AuthedLayout>
    );
};

function getsight0levelname(item : number){
    let returnstr = "";
    switch (item) {
        case 5:
            returnstr = "全盲";
            break;
        case -6:
            returnstr = "鐳射治療";
            break;
        case -7:
            returnstr = "角膜塑形片";
            break;
        case -8:
            returnstr = "隱形眼鏡";
            break;
        default:
            returnstr = "無法測量";
            break;
    }
    return returnstr
}

export const SightCantCheck = connector(sightCantCheck);