export function downloadBlob(blob: Blob, name: string) {
  const objectUrl = window.URL.createObjectURL(blob);
  const invisible = createAnchor(objectUrl, name, true);
  downloadAndRevoke(invisible);
}

export function downloadObjectUrl(objectUrl: string, name: string) {
  const invisible = createAnchor(objectUrl, name, true);
  downloadAndRevoke(invisible);
}

function createAnchor(
  objectUrl: string,
  name: string,
  invisible: boolean = false
): HTMLAnchorElement {
  const a = document.createElement('a');
  a.setAttribute('href', objectUrl);
  a.setAttribute('download', name);
  //   a.target = '_blank';
  if (!invisible) {
    a.text = name;
  }
  return a;
}

function downloadAndRevoke(anchor: HTMLAnchorElement) {
  anchor.addEventListener('click', (s) => {
    setTimeout(() => {
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    }, 1);
  });
  document.body.appendChild(anchor);
  anchor.click();

  //   const mouseEvent = new Event('click');
  //   anchor.dispatchEvent(mouseEvent);
}
