import { LoginResponse, loginResponseZod } from '../model';
import { post } from './BaseApi';

export async function login(
  account: string,
  password: string
): Promise<LoginResponse> {
  return post('/api/user/login', loginResponseZod, { account, password });
}

export async function refreshToken(schoolId?: string): Promise<LoginResponse> {
  return post('/api/token/refresh', loginResponseZod, { schoolId });
}
