import { Location, LocationDescriptor } from 'history';
import React, { FunctionComponent } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const EnchanceLink: FunctionComponent<LinkProps> = ({
  children,
  to,
  ...props
}) => {
  const toProp = (location: Location): LocationDescriptor => {
    if (typeof to === 'string') {
      return {
        ...location,
        pathname: to,
      };
    }
    if (typeof to === 'function') {
      return to(location);
    }
    // object type
    return to;
  };
  return (
    <Link {...props} to={toProp}>
      {children}
    </Link>
  );
};
