import React, { FunctionComponent } from 'react';
import { FormControl } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { Nullable } from '../../types';
import { DropdownCheckList } from '../DropdownCheckList';

type Props = {
  property: string;
  content: Record<string, string>;
  validate?: (v: string[]) => string[] | undefined;
};
export const DropdownCheckListField: FunctionComponent<Props> = ({
  property,
  content,
  validate,
}) => {
  return (
    <Field
      type="string"
      name={property}
      validate={validate}
      formatOnBlur
      format={(v) => {
        if (typeof v === 'string') {
          return (v as string)
            .replaceAll('，', ',')
            .split(',')
            .filter((v) => !!v);
        }
        return v;
      }}
    >
      {({ input, meta }) => {
        const checked =
          typeof input.value === 'string'
            ? (input.value as string).split(',')
            : input.value || [];
        return (
          <>
            <DropdownCheckList {...input} content={content} checked={checked} />
            {meta.error &&
              (meta.modified || meta.submitFailed) &&
              !meta.active && <span className="text-danger">{meta.error}</span>}
          </>
        );
      }}
    </Field>
  );
};
