import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Form,
  Tabs,
  Tab,
  Nav,
  Button,
  Table,
} from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../../apis';
import { AuthedLayout } from '../../components';
import { SchoolRank, Semester, Student, StudentPHIAll } from '../../model';
import { AuthDispatches, ErrorDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import { ClassMapObj } from '../../types';
import { listToObject } from '../../utils/transform';
import { SightSection } from './SightSection';
import { TeeSection } from './TeeSection';
import { WHSection } from './WHSection';
import { DiseaseSection } from './DiseaseSection';
import { SeriousDiseaseSection } from './SeriousDiseaseSection';
import { BodyMindBookNSection } from './BodyMindBookNSection';
import { PhysicalSection } from './PhysicalSection';
import { LabParasiteSection } from './LabParasiteSection';
import { LabUrineSection } from './LabUrineSection';
import { BloodSection } from './BloodSection';
import { XraySection } from './XraySection';
import { SundrySection } from './SundrySection';
import { HospitalSection } from './HospitalSection';
import { SolidSection } from './SolidSection';
import { InheritSection } from './InheritSection';
import { InsuranceSection } from './InsuranceSection';
import { RemarkSection } from './RemarkSection';
import { useHistory } from 'react-router';
import { getPhysicalGradeByRank } from '../../utils';
import { toast } from 'react-toastify';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = { ...AuthDispatches, ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;
const bodyMindBookNDegree: { [key: string]: string } = {
  1: '輕度',
  2: '中度',
  3: '極重度',
};
const relativeMap1: { [key: number]: string } = {
  1: '父親',
  2: '母親',
  3: '祖父',
  4: '祖母',
  5: '兄',
  6: '弟',
  7: '姐',
  8: '妹',
  13: '外祖父',
  14: '外祖母',
};
const inheritDiseaseMap1: { [key: number]: string } = {
  1: '糖尿病',
  2: '血友病',
  5: '蠶豆症',
  31: '高血壓',
  99: '其他',
};
const InsuranceMap1: { [key: number]: string } = {
  1: '全民健保',
  2: '學生團保',
  9: '其他',
};
const graduatedStudentPHIPage: FunctionComponent<Props> = ({
  user,
  loading,
  catchErrorForModal,
}) => {
  if (loading || !user) {
    useHistory().go(0);
    return <></>;
  }

  const [student, setStudent] = useState<StudentPHIAll>();
  const [classes, setClasses] = useState<ClassMapObj>({ '': '無年級資料' });
  const [year, setYear] = useState<number>(0);
  const [classId, setClassId] = useState<string>('');
  const [studentId, setStudentId] = useState<string>('');
  const [classStudents, setClassStudents] = useState<{
    [k: string]: Student;
  }>({});
  const semesters = [{ sem: 2, year, isNow: true }] as Semester[];
  const physicalGradeRange = getPhysicalGradeByRank(
    user.schools[user.currentSchool].rank ?? SchoolRank.Junior
  );

  console.log(physicalGradeRange);
  const [radioGrade, setRadioGrade] = useState<number>(
    physicalGradeRange[physicalGradeRange.length - 1]
  );
  useEffect(() => {
    if (classId && year) {
      setStudent(undefined);
      apis
        .getClassStudents(classId, year, 2)
        .then((students) => {
          setClassStudents(
            listToObject(
              students,
              (s) => s.pid,
              (s) => s
            )
          );
        })
        .catch(catchErrorForModal);
    }
  }, [classId, year]);

  useEffect(refreshStudent, [studentId]);

  function refreshStudent() {
    if (studentId && year) {
      apis
        .GetStudentWithPHIAll(studentId, year)
        .then((student) => {
          setStudent(student);
        })
        .catch(catchErrorForModal);
    }
  }

  let studentView = <></>;
  if (student && classStudents[studentId]) {
    studentView = (
      <>
            <Row>
          <Button
            className="mb-2"
            onClick={() => {
              const pids = [classStudents[studentId].pid];
              // eslint-disable-next-line no-debugger
              console.log(pids);
              toast
                .promise(
                  apis.downloadWHHealthCard(student, classStudents[studentId], pids),
                  {
                    pending: '下載中...',
                    success: '下載成功！',
                    error: '下載失敗！請查看錯誤資訊。',
                  }
                )
                .then((blob) => {
                  const objlink = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = objlink;
                  a.setAttribute(
                    'download',
                    `國民中小學學生健康通知卡-${classStudents[studentId].name}.pdf`
                  );
                  document.body.appendChild(a);
                  a.click();

                  a.parentNode?.removeChild(a);
                })
                .catch(catchErrorForModal);
            }}
          >
            <span className="feather icon-download">
              列印學生健康檢查紀錄卡
            </span>
          </Button>
        </Row>
        <Row sm={{ cols: 12 }} className="align-items-start">
          {/* 學生基本資料 */}
          <Col lg="2" className="mr-2 mb-2">
            <div className="sheet-title">學生資料</div>
            <Table bordered className="student-phi">
              <colgroup>
                <col span={1} style={{ width: '20%' }} />
                <col span={1} style={{ width: '80%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>身份證</th>
                  <td>{student.pid}</td>
                </tr>
                <tr>
                  <th>座號</th>
                  <td>{student.seat}</td>
                </tr>
                <tr>
                  <th>學生</th>
                  <td>{student.name}</td>
                </tr>
                <tr>
                  <th>學號</th>
                  <td>{student.sid}</td>
                </tr>
                <tr>
                  <th>生日</th>
                  <td>
                    {classStudents[studentId]?.birth?.toFormat('yyyy/MM/dd')}
                  </td>
                </tr>
                <tr>
                  <th>父親</th>
                  <td>{classStudents[studentId].dad}</td>
                </tr>
                <tr>
                  <th>母親</th>
                  <td>{classStudents[studentId].mom}</td>
                </tr>
                <tr>
                  <th>緊急連絡</th>
                  <td>{classStudents[studentId].erTel}</td>
                </tr>
              </tbody>
            </Table>
            <div className="sheet-title">健康基本資料</div>
            <Table bordered className="student-phi">
              <colgroup>
                <col span={1} style={{ width: '30%' }} />
                <col span={1} style={{ width: '70%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <DiseaseSection
                      editable
                      deleteable
                      diseases={student.disease}
                      student={classStudents[studentId]}
                      semesters={semesters}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></DiseaseSection>
                  </th>
                  {student.disease.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.disease.map(
                        (d, i) =>
                          `${i + 1}.${d.diseaseName}(${d.description})${d.treatment
                          }\n`
                      )}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <SeriousDiseaseSection
                      editable
                      deleteable
                      seriousDiseases={student.seriousDisease}
                      student={classStudents[studentId]}
                      semesters={semesters}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></SeriousDiseaseSection>
                  </th>
                  {student.seriousDisease.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.seriousDisease.map(
                        (d, i) => `${i + 1}.${d.diseaseName}\n`
                      )}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <BodyMindBookNSection
                      editable
                      deleteable
                      bodyMindBookN={student.bodyMindBookN}
                      student={classStudents[studentId]}
                      semesters={semesters}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></BodyMindBookNSection>
                  </th>
                  {student.bodyMindBookN.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.bodyMindBookN.map(
                        (d, i) =>
                          `${i + 1}.${d.bodyMindName}(${bodyMindBookNDegree[d.degree]
                          })\n`
                      )}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <InheritSection
                      editable
                      deleteable
                      inherit={student.inheritDisease}
                      student={classStudents[studentId]}
                      semesters={semesters}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></InheritSection>
                  </th>
                  {student.inheritDisease.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.inheritDisease.map((d, i) => {
                        const inheritDisease =
                          d.inheritDisease === 99
                            ? `其他(${d.states})`
                            : inheritDiseaseMap1[d.inheritDisease];
                        return `${i + 1}.${inheritDisease}_${relativeMap1[d.relative]
                          }\n`;
                      })}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <HospitalSection
                      editable
                      deleteable
                      hospitals={student.hospital}
                      student={classStudents[studentId]}
                      semesters={semesters}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></HospitalSection>
                  </th>
                  <td>
                    {student.hospital.length
                      ? student.hospital.map((h, i) => `${h.hospitalName}\n`)
                      : '特約醫院'}
                  </td>
                </tr>
                <tr>
                  <th>
                    <InsuranceSection
                      editable
                      deleteable
                      insurance={student.insurance}
                      student={classStudents[studentId]}
                      semesters={semesters}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></InsuranceSection>
                  </th>
                  <td>
                    {student.insurance.length
                      ? student.insurance.map((v, i) => {
                        let studentInsurance = ``;
                        if (v.insurance === 9) {
                          studentInsurance = v.states
                            ? `${v.states}\n`
                            : `其他保險\n`;
                        } else {
                          studentInsurance = InsuranceMap1[v.insurance];
                        }
                        return `${studentInsurance}\n`;
                      })
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>
                    <SolidSection
                      editable
                      deleteable
                      solid={student.sightSolid}
                      student={classStudents[studentId]}
                      semesters={semesters}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></SolidSection>
                  </th>
                  <td>{student.sightSolid.length ? '有異狀' : '無異狀'}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          {/* 身高體重視力口腔 */}
          <Col lg="3" className="mr-3 mb-3">
            <WHSection
              editable={false}
              whs={student.wh || []}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <SightSection
              editable={false}
              sights={student.sight || []}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <TeeSection
              editable={false}
              tee={student.tee || []}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
          </Col>
          {/* 1、4、7、10年級 */}
          {/* 身體診察、實驗室檢查、血液檢查、X光檢查 */}
          {/* TODO: 資料內容待調整 */}
          <Col lg="3" className="mr-3 mb-3">
            <div
              className="sheet-title mb-3 py-2"
              style={{
                backgroundColor: 'rgba(194,245,245,0.5)',
                borderRadius: '5px',
              }}
            >
              <span className="mr-2">年級 :</span>
              {physicalGradeRange.map((g) => {
                return (
                  <Form.Check
                    key={`grade-${g}`}
                    inline
                    name="group-grade"
                    type="radio"
                    label={g}
                    value={g}
                    checked={radioGrade === g}
                    onChange={(e) => {
                      setRadioGrade(g);
                    }}
                  />
                );
              })}
            </div>
            <PhysicalSection
              editable={false}
              physicals={student.physical}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <br />
            <LabParasiteSection
              editable
              parasite={student.parasite || []}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <LabUrineSection
              editable
              urine={student.urine}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <BloodSection
              editable={false}
              blood={student.blood}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <XraySection
              editable={false}
              xRay={student.xRay}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
          </Col>
          {/* 臨時性檢查、在校期間重大傷病事故 */}
          <Col lg="3" className="mr-3 mb-3">
            {/*臨時性檢查*/}
            <SundrySection
              editable={false}
              sundry={student.sundry || []}
              student={classStudents[studentId]}
              semesters={semesters}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <br />
            <div className="sheet-title">
              在校期間重大傷病事故
              <Button
                key="cell-edit"
                className="border-0 px-2 py-1"
                variant="outline-primary"
                size="sm"
                // onClick={() => {
                //   setInputing(true);
                // }}
              >
                <span className="feather icon-edit" />
              </Button>
            </div>
            <Table className="student-phi mb-2">
              <tbody>
                <tr>
                  <td>無</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row sm={{ cols: 12 }}>
          <Col lg="12" className="mr-12 mb-12">
            <Form.Group>
              <Form.Label className="sheet-title">
                {/* 學生備註  */}
                <RemarkSection
                  remark={student.remark ?? ""}
                  student={classStudents[studentId]}
                  semesters={semesters}
                  yearSems={student.classes || []}
                  onApiError={catchErrorForModal}
                  onValueUpdated={refreshStudent}
                >
                </RemarkSection>
              </Form.Label>
              <Form.Control as="textarea" rows={3} disabled />
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  }

  const currentClassIdAndYear = serialize(classId, year);
  return (
    <AuthedLayout
      onLoaded={() => {
        apis
          .getGraduatedClasses()
          .then((classes) => {
            if (classes.length)
              setClasses(
                listToObject(
                  classes,
                  (c) => serialize(c.id, c.year),
                  (c) => c.name
                )
              );
            setClassId('');
            setYear(0);
          })
          .catch(catchErrorForModal);
      }}
    >
      <Row className="mb-2 justify-content-start">
        <Col sm={2}>
          <DropdownButton
            className="mr-2"
            title={classes[currentClassIdAndYear] || '請選擇班級'}
            onSelect={(k) => {
              if (k && classes[k]) {
                const [classId, year] = deserialize(k);
                setClassId(classId);
                setYear(year);
              }
            }}
          >
            {Object.entries(classes).map(([k, n]) => (
              <Dropdown.Item key={`class-${k}`} eventKey={k}>
                {n}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        <Col sm={2}>
          <DropdownButton
            disabled={!classId}
            className="mr-2"
            title={
              (studentId &&
                classStudents[studentId] &&
                `${classStudents[studentId].seat} ${classStudents[studentId].name}`) ||
              '請選擇學生'
            }
            onSelect={(k) => {
              if (k && classStudents[k]) {
                setStudentId(k);
              }
            }}
          >
            {Object.entries(classStudents).map(([k, n]) => (
              <Dropdown.Item key={`student-${k}`} eventKey={k}>
                {n.seat} {n.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
      <hr />
      {studentView}
    </AuthedLayout>
  );
};

const serialize = (classId: string, year: number): string =>
  `${classId}:${year}`;

const deserialize = (str: string): [string, number] => {
  const splited = str.split(':');
  return [splited[0], +splited[1]];
};

export const GraduatedStudentPHIPage = connector(graduatedStudentPHIPage);
