import { RouterState } from 'connected-react-router';
import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteProps, RouterProps } from 'react-router';
import { ApplicationState, AuthState } from '../redux/States';

// type Props = RouteProps & AuthState;

const mapState = (state: ApplicationState, ownProps: RouteProps) => ({
  ...state.auth,
  ...ownProps,
});
const connecter = connect(mapState);
type Props = ConnectedProps<typeof connecter>;

const privateRoute: FunctionComponent<Props> = (props) => {
  const { component, isAuth, path, ...rest } = props;
  if (isAuth) {
    return <Route path={path} {...rest} component={component} />;
  }
  const queryString = new URLSearchParams();
  if (path) {
    queryString.append('lastPath', Array.isArray(path) ? path.join('/') : path);
  }
  return (
    <Route
      render={() => {
        return <Redirect to={`/login?${queryString.toString()}`} />;
      }}
    />
  );
};

export const PrivateRoute = connecter(privateRoute);
