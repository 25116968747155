import { DateTime } from 'luxon';

export const DEFAULT_DATETIME_FORMAT = 'yyyy/MM/dd';

export function semesterRange(
  mingGuo: number,
  sem: number
): [DateTime, DateTime] {
  const year = mingGuo + 1911;
  const month = sem === 1 ? 8 : 2;

  const fromDateTime = DateTime.fromObject({ year, month, day: 1 });
  const toDateTime = fromDateTime.plus({ month: 17 }).endOf('month'); // 三個學期內

  return [fromDateTime, toDateTime];
}

export function dateTimeFromString(raw: string): DateTime {
  let date = DateTime.fromFormat(raw, DEFAULT_DATETIME_FORMAT);
  if (!date.isValid) {
    date = DateTime.fromFormat(raw, 'y/M/d');
  }
  if (!date.isValid) {
    return date;
  }
  if (date.year < 200) {
    date = date.plus({ year: 1911 });
  }
  return date;
}
