import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { ClassHDIDStatistic, HDIDStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';

const mockData: ClassHDIDStatistic[] = [
  {
    grade: 1,
    classNo: 1,
    hdids: {
      '-1': { hdid: -1, total: 10, male: 5, female: 5 },
      '0': { hdid: 0, total: 40, male: 22, female: 18 },
      '1': { hdid: 1, total: 30, male: 12, female: 18 },
      '2': { hdid: 2, total: 21, male: 14, female: 7 },
      '-9': { hdid: -9, total: 101, male: 53, female: 48 },
    },
  },
  {
    grade: 1,
    classNo: 2,
    hdids: {
      '-1': { hdid: -1, total: 10, male: 5, female: 5 },
      '0': { hdid: 0, total: 40, male: 22, female: 18 },
      '1': { hdid: 1, total: 30, male: 12, female: 18 },
      '2': { hdid: 2, total: 20, male: 14, female: 6 },
      '-9': { hdid: -9, total: 100, male: 53, female: 47 },
    },
  },
  {
    grade: 2,
    classNo: 2,
    hdids: {
      '-1': { hdid: -1, total: 10, male: 5, female: 5 },
      '0': { hdid: 0, total: 40, male: 22, female: 18 },
      '1': { hdid: 1, total: 30, male: 12, female: 18 },
      '2': { hdid: 2, total: 20, male: 14, female: 6 },
      '-9': { hdid: -9, total: 100, male: 53, female: 47 },
    },
  },
];

const HDID = {
  // Total: -9,
  UnderWeight: -1,
  Healty: 0,
  OverWeight: 1,
  Obesity: 2,
};

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;
const cellAlignStyle = {
  horizontal: 'center',
  vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const whClassStatistic: FunctionComponent<Props> = ({ catchErrorForModal }) => {
  const { yearSem, element } = useSemGrade();
  const [datas, setDatas] = useState<ClassHDIDStatistic[]>([]);

  useEffect(() => {
    if (yearSem) {
      apis.getWHClassStatistic(yearSem.year, yearSem.sem).then((s) => {
        setDatas(s);
      });
    }
  }, [yearSem?.year, yearSem?.sem]);

  const gradeBase = datas.reduce((grades, d) => {
    return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
  }, {} as Record<number, ClassHDIDStatistic[]>);

  const totalHdidObj = {
    // [HDID.Total]: { hdid: HDID.Total, total: 0, male: 0, female: 0 },
    [HDID.Obesity]: { hdid: HDID.Obesity, total: 0, male: 0, female: 0 },
    [HDID.OverWeight]: {
      hdid: HDID.OverWeight,
      total: 0,
      male: 0,
      female: 0,
    },
    [HDID.Healty]: { hdid: HDID.Healty, total: 0, male: 0, female: 0 },
    [HDID.UnderWeight]: {
      hdid: HDID.UnderWeight,
      total: 0,
      male: 0,
      female: 0,
    },
  };
  const contentRows: Row[] = Object.values(gradeBase).flatMap(
    (gs, idx, allArray) => {
      const rows = gs.flatMap((g, idx) => {
        return hdidToRows(
          g.grade,
          g.classNo,
          g.hdids,
          idx === 0 ? { row: (gs.length + 1) * 2 } : undefined
        );
      });

      const gradeHDID = gs.reduce(
        (pv, g) => {
          Object.values(g.hdids).forEach((h) => {
            const hdid = pv[h.hdid];
            if (hdid) {
              hdid.total += h.total;
              hdid.female += h.female;
              hdid.male += h.male;
            }
            const totalHdid = totalHdidObj[h.hdid];
            if (totalHdid) {
              totalHdid.total += h.total;
              totalHdid.female += h.female;
              totalHdid.male += h.male;
            }
          });

          return pv;
        },
        {
          // [HDID.Total]: { hdid: HDID.Total, total: 0, male: 0, female: 0 },
          [HDID.Obesity]: { hdid: HDID.Obesity, total: 0, male: 0, female: 0 },
          [HDID.OverWeight]: {
            hdid: HDID.OverWeight,
            total: 0,
            male: 0,
            female: 0,
          },
          [HDID.Healty]: { hdid: HDID.Healty, total: 0, male: 0, female: 0 },
          [HDID.UnderWeight]: {
            hdid: HDID.UnderWeight,
            total: 0,
            male: 0,
            female: 0,
          },
        }
      );

      rows.push(...hdidToRows('', '年級合計', gradeHDID));
      if (idx === allArray.length - 1) {
        const lastRow = rows[rows.length - 1];

        lastRow.cells = lastRow?.cells.map((c) => {
          const { style: { border = {} } = {} } = c;
          border.bottom = borderStyle;
          c.style = { ...c.style, border };
          return c;
        });
      }

      return rows;
    }
  );
  const content: Row[] = [
    {
      cells: [
        {
          value: '身高體重檢查人數',
          merge: { column: 6 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體位判讀',
          merge: { column: 12 },
          style: { border: fullBorderStyle },
        },
      ],
    },
    {
      cells: [
        {
          value: '年級',
          merge: { row: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '班級',
          merge: { row: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '人數',
          style: {
            border: { top: borderStyle, left: borderStyle, right: borderStyle },
          },
        },
        {
          value: '合計',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體位過輕',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體位適中',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體位過重',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
        {
          value: '體位肥胖',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
      ],
    },
    {
      cells: [
        { style: { border: fullBorderStyle } },
        { style: { border: fullBorderStyle } },
        {
          value: '比率',
          style: {
            border: {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            },
          },
        },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
      ],
    },
    ...contentRows,
    ...hdidToRows('全校合計', '', totalHdidObj, { column: 2, row: 2 }),
  ];

  // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        <span className="mr-3">{element}</span>
        <Button
          disabled={!datas.length}
          onClick={() => {
            const workSheet = generateSheet(
              [
                {
                  cells: [
                    {
                      value: `${yearSem?.year}學年第${yearSem?.sem}學期_體位判讀結果統計報表(班級)`,
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
                ...content,
                {
                  cells: [
                    {
                      value:
                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
              ],
              {
                alignment: cellAlignStyle,
              }
            );
            downloadSheets('體位判讀結果統計報表(班級)`', { sheet: workSheet, name: 'st' });
          }}
        >
          Excel 下載
        </Button>
      </BSRow>
      <hr />
      <BSRow>
        <Table
          striped
          bordered
          className="text-center"
          style={{ tableLayout: 'fixed' }}
        >
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '6%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
          </colgroup>
          <tbody>
            {content.map((r, i) => {
              const cells = r.cells.map((c, j) =>
                c.value != undefined ? (
                  <td
                    colSpan={c.merge?.column}
                    rowSpan={c.merge?.row}
                    key={`${i}-${j}`}
                  >
                    {c.value}
                  </td>
                ) : (
                  <Fragment key={`${i}-${j}`}></Fragment>
                )
              );
              return <tr key={i}>{cells}</tr>;
            })}
          </tbody>
        </Table>
      </BSRow>
    </AuthedLayout>
  );
};

function hdidToRows(
  grade: number | string,
  classNo: number | string,
  hdidObj: Record<number, HDIDStatistic>,
  gradeMerge?: { row?: number; column?: number }
): Row[] {
  const totalCount = Object.values(hdidObj).reduce(
    (p, v) => {
      p.total += v.total;
      p.male += v.male;
      p.female += v.female;
      return p;
    },
    {
      total: 0,
      male: 0,
      female: 0,
    }
  );
  const gradeNo = [
    {
      value: gradeMerge ? grade : undefined,
      merge: gradeMerge,
      style: { border: fullBorderStyle },
    },
  ];
  if (!gradeMerge?.column) {
    gradeNo.push({
      value: classNo,
      merge: { row: 2 },
      style: { border: fullBorderStyle },
    });
  }
  return [
    {
      cells: [
        ...gradeNo,
        {
          value: '人數',
          style: { border: fullBorderStyle },
        },
        {
          value: totalCount?.total ?? 0,
          style: { border: { left: borderStyle } },
        },
        {
          value: totalCount?.male ?? 0,
        },
        {
          value: totalCount?.female ?? 0,
          style: { border: { right: borderStyle } },
        },
        ...[
          HDID.UnderWeight,
          HDID.Healty,
          HDID.OverWeight,
          HDID.Obesity,
        ].flatMap((hdid) => {
          return [
            {
              value: hdidObj[hdid]?.total ?? 0,
              style: { border: { left: borderStyle } },
            },
            {
              value: hdidObj[hdid]?.male ?? 0,
            },
            {
              value: hdidObj[hdid]?.female ?? 0,
              style: { border: { right: borderStyle } },
            },
          ] as Cell[];
        }),
      ],
    },
    {
      cells: [
        {
          style: { border: fullBorderStyle },
        },
        {
          style: { border: fullBorderStyle },
        },
        {
          value: '比率',
          style: { border: fullBorderStyle },
        },
        {
          value: percent(totalCount.total ?? 0, totalCount.total),
          style: { border: { left: borderStyle, bottom: borderStyle } },
        },
        {
          value: percent(totalCount.male ?? 0, totalCount.total),
          style: { border: { bottom: borderStyle } },
        },
        {
          value: percent(totalCount.female ?? 0, totalCount.total),
          style: { border: { right: borderStyle, bottom: borderStyle } },
        },
        ...[
          HDID.UnderWeight,
          HDID.Healty,
          HDID.OverWeight,
          HDID.Obesity,
        ].flatMap((hdid) => {
          return [
            {
              value: percent(hdidObj[hdid]?.total ?? 0, totalCount.total),
              style: { border: { left: borderStyle, bottom: borderStyle } },
            },
            {
              value: percent(hdidObj[hdid]?.male ?? 0, totalCount.total),
              style: { border: { bottom: borderStyle } },
            },
            {
              value: percent(hdidObj[hdid]?.female ?? 0, totalCount.total),
              style: { border: { right: borderStyle, bottom: borderStyle } },
            },
          ] as Cell[];
        }),
      ],
    },
  ];
}

function percent(value: number, denominator: number): string {
  if (!denominator) return '0.00%';
  return ((value / denominator) * 100).toFixed(2) + '%';
}

export const WHClassStatistic = connector(whClassStatistic);
