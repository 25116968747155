import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import { z } from 'zod';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTable,
  HeaderDisplayKeysWithType,
} from '../components';
import Apis from '../apis';
import { CheckField, UpdateStudentFile, UpdateStudentFileZod } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { checkOkToUpload, takeClassStudent, checkOkToUpdate } from '../utils';
import { ClassSem } from '../layouts';
import { useActiveSems } from '../hook';

const inputFileParser = z.array(UpdateStudentFileZod);
const headerDisplayKeys: HeaderDisplayKeysWithType<UpdateStudentFile>[] = [
  { display: '備註', property: 'remark' },
  { display: '身份證字號', property: 'pid', style: { width: '7%' } },
  { display: '姓名', property: 'name', style: { width: '5%' } },
  { display: '學號', property: 'sid', style: { width: '6%' } },
  { display: '入學年', property: 'year', style: { width: '5%' } },
  // {
  //   display: '年級',
  //   property: 'grade',
  //   onRender: (v) => I18N.Grades[v] || 'Unknown',
  // },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '性別', property: 'sex' },
  {
    display: '生日',
    property: 'birth',
    style: { width: '7%' },
    onRender: (v) => (v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null),
  },
  { display: '血型', property: 'blood' },
  { display: '身分', property: 'aborigine' },
  { display: '父親', property: 'dad' },
  { display: '母親', property: 'mom' },
  { display: '監護人', property: 'guardian', style: { width: '5%' } },
  { display: '電話', property: 'tel', style: { width: '8%' } },
  { display: '郵區', property: 'zip' },
  { display: '住址', property: 'address', style: { width: '16%' } },
  { display: '緊急聯絡電話', property: 'erTel', style: { width: '8%' } },
];

function filterOutNonStudentField({
  remark,
  ...student
}: UpdateStudentFile): UpdateStudentFile {
  return { ...student, remark: '' };
}

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...state.auth,
});
const mapDispatch = ErrorDispatches;
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const updateStudent: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案');
  const [fileData, setFileData] = useState([] as UpdateStudentFile[]);
  const [okToUpdate, setOkToUpdate] = useState(false);

  const { selectedSemester, yearSemElement: yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );

  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        <a href="/Doc/基本資料17.xls">學生資料匯入格式</a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const ss = (await inputFileParser.parseAsync(jsonData))?.map(
                    (s) => {
                      s.grade = selectedSemester.year - s.year + 1;
                      return s;
                    }
                  );
                  if (ss) {
                    // 檢查年班級座號有無重複 
                    const duplicates = ss.filter((student, index, self) =>
                      self.some((s, i) =>
                        i !== index &&
                        s.grade === student.grade &&
                        s.no === student.no &&
                        s.seat === student.seat
                      )
                    );      
                    if(duplicates.length) {
                      showError('檔案內容有重複的入學年、班級、座號，請檢查資料內容是否正確');
                    } else {
                      //TODO:檢查有無此學生
                      await Apis.checkClassesSeatStudentExists(
                      takeClassStudent(ss),
                      CheckField.Pid,
                      selectedSemester
                    )
                      .then((existsResult) => {
                        const students = existsResult.students;
                        const seatStudents = existsResult.seatStudents;
                        const studentfile= ss.map((s) => {
                          const result = students.find(
                            (r) => r.pid === s.pid
                          );
                          if (!result) return s;
                          if (!result.exists) {
                            s.remark += (s.remark.length ? `，找不到學生` : `找不到學生`);
                          }
                          return s;
                        });
                        setFileData(studentfile);
                        setOkToUpdate(checkOkToUpdate(true, ss));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpdate(false);
                      });
                    }
                  } else {
                    // TODO: print error on UI
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              console.error(e);
              setFileName('檔案');
              setFileData([]);
              setOkToUpdate(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            }

            e.target.value = '';
          }}
          custom
        />
        <Button
          className="ml-auto"
          disabled={!okToUpdate}
          onClick={() => {
            if (fileData.length > 0)
              console.log('更新學生資料')
              toast
                .promise(
                  Apis.updateClassesStudent(
                    fileData.map(filterOutNonStudentField),
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpdate(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          更新資料
        </Button>
      </Row>
      <Row>
        <EditableTable
          headers={headerDisplayKeys}
          values={fileData}
          scrollable={true}
          onRowRender={({ remark }) => ({
            rowClassName: remark ? 'bg-danger text-white' : undefined,
          })}
        />
      </Row>
    </AuthedLayout>
  );
};

export const UpdateStudentPage = connector(updateStudent);
