import React from 'react';
import { FunctionComponent } from 'react';

type Props = {
  animationDuration: any;
  children: any;
  isFinished: any;
};

export const Container: FunctionComponent<Props> = ({
  animationDuration,
  children,
  isFinished,
}) => {
  return (
    <div
      style={{
        opacity: isFinished ? 0 : 1,
        pointerEvents: 'none',
        transition: `opacity ${animationDuration}ms linear`,
      }}
    >
      {children}
    </div>
  );
};
