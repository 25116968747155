import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
    Button, Col, Row as BSRow, FormCheck, Table, Dropdown,
    DropdownButton,
} from 'react-bootstrap';
import { Cell, Row, downloadSheets, generateSheet, getGradeMinMax, getPhysicalGradeByRank } from '../../../utils';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { listToObject } from './../../../utils/transform';
import { I18N } from '../../../i18n-raw';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useCheckClass, useCheckGrade, useClassGrade, useSemGrade } from '../../../hook';
import { School, SchoolRank, Semester, Student, StudentPHIAll, UnpivotedBloodCheck } from '../../../model';
import apis from '../../../apis';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { ClassMapObj } from '../../../types';

type ExaminationResultsType = {
    "physical": {
        [key: string]: {
            [key: string]: string;
        };
    };
    "blood": {
        [key: string]: string;
    };
    "xRay": {
        [key: string]: string;
    };
    "Lab": {
        [key: string]: string;
    };
};
interface Filter {
    category: (keyof StudentPHIAll)[];
    criteria: string[];
    value: any[];
    matchAll: boolean;
}
interface ExtractedData {
    grade: string;
    no: string;
    name: string;
    seat: string;
    sex: string;
    results: { [key: string]: any };
}
const ExaminationResults: ExaminationResultsType = {
    physical: {
        bloodPressureWaistline: {
            bpResult: '血壓判讀',
            pulseResult: '脈搏判讀',
        },
        eye: {
            strabismus: '斜視',
            trichiasis: '睫毛倒插',
            nystagmus: '眼球震顫',
            blepharoptosis: '眼瞼下垂',
            colorBlind: '辨色力異常',
            e99: '眼科其他',
        },
        earNoseThroat: {
            hearing: '聽力異常',
            eardrum: '耳膜破損',
            earMisshapen: '耳道畸型',
            clp: '唇顎裂',
            articulationDisorders: '構音異常',
            preauricularFistula: '耳前瘻管',
            cerumen: '耵聹栓塞',
            rhinitis: '慢性鼻炎',
            allergicRhinitis: '過敏性鼻炎',
            tonsillitis: '扁桃腺腫大',
            o99: '耳鼻喉其他',
        },
        headNeck: {
            torticollis: '斜頸',
            mass: '異常腫塊',
            goiter: '甲狀腺腫',
            lymphadenectasis: '淋巴腺腫大',
            mass99: '其他異常腫塊',
            n99: '頭頸其他',
        },
        chest: {
            c03: '胸廓異常',
            heartMurmur: '心雜音',
            arrhythmia: '心律不整',
            wheeze: '呼吸聲異常',
            cardiopulmonary: '心肺疾病',
            cardiopulmonary99: '其他心肺疾病',
            c99: '胸部其他',
        },
        abdomen: {
            abdomen: '腹部異常腫大',
            hernia: '疝氣',
            a99: '腹部其他',
        },
        spineLimbs: {
            scoliosis: '脊柱側彎',
            polydactyly: '多併指（趾）',
            squatting: '蹲踞困難',
            dysarthrosis: '關節變形',
            dysmelia: '肢體畸形',
            dysmelia99: '其他肢體畸形',
            edema: '水腫',
            l99: ' 四肢其他',
        },
        urogenital: {
            cryptorchidism: '隱睪',
            scrotalSwelling: '陰囊腫大',
            prepuce: '包皮異常',
            varicocele: '精索靜脈曲張',
            u99: '泌尿生殖其他',
        },
        skin: {
            epidermophytosis: '癬',
            wart: '疣',
            purpura: '紫斑',
            scabies: '疥瘡',
            eczema: '溼疹',
            atopicDermatitis: '異位性皮膚炎',
            d99: '皮膚其他',
        },
        teeth: {
            t01: '未治療齲齒',
            t04: '口腔衛生不良',
            t05: '牙結石',
            t08: '牙齦炎',
            t07: '咬合不正',
            t11: '已治療齲齒',
            t12: '上顎恆牙第一大臼齒齲齒經驗',
            t13: '下顎恆牙第一大臼齒齲齒經驗',
            t15: '恆牙臼齒窩溝封填',
            t09: '口腔黏膜異常',
            t99: '口腔其他',
            t16: '牙周病',
            t17: '乳牙待拔牙',
            t03: '待拔牙',
            t18: '贅生牙',
            t02: '缺牙',
            t19: '阻生牙',
        },
    },
    blood: {
        hbResult: '血色素',
        wbcResult: '白血球',
        rbcResult: '紅血球',
        plResult: '血小板',
        mcvResult: '平均血球容積',
        htResult: '血球容積比',
        clResult: '總膽固醇',
        crResult: '肌酸酐',
        uaResult: '尿酸',
        bunResult: '血尿素氮',
        gotResult: 'SGOT',
        gptResult: 'SGPT',
        hbsaResult: 'B型肝炎表面抗原',
        antiHBResult: 'B型肝炎表面抗體',
        tgResult: '三酸甘油酯',
        hdlResult: '高密度膽固醇',
        ldlResult: '低密度膽固醇',
        acResult: '飯後血糖',
        pcResult: '空腹血糖',
        hBeAgResult: 'B型肝炎e抗原',
    },
    xRay: {
        xRay: '胸部X光檢查',
        phthisis: '肺結核病徵',
        calcify: '肺結核鈣化',
        c03: '胸廓異常',
        water: '肋膜腔積水',
        c02: '脊柱側彎',
        bigHeart: '心臟肥大',
        brochus: '支氣管擴張',
        x99: 'X光其他',
    },
    Lab: {
        urine: '尿液檢查',
        parasite: '寄生蟲檢查',
    },
};
const Physicalsubcatoryname: Record<string, string> = {
    "bloodPressureWaistline": "血壓腰圍",
    "eye": "眼",
    "earNoseThroat": "耳鼻喉",
    "headNeck": "頭頸",
    "chest": "胸部",
    "abdomen": "腹部",
    "spineLimbs": "脊柱四肢",
    "urogenital": "泌尿生殖",
    "skin": "皮膚",
    "teeth": "口腔"
};

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;
const bottomBorderStyle = {
    bottom: borderStyle,
};
const topBottomBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
};
const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const checkAllQuery: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const [examinedresult1, setexaminedresult1] = useState(false);
    const [examinedresult2, setexaminedresult2] = useState(false);
    const [examinedresult3, setexaminedresult3] = useState(false);
    const [examinedresult9, setexaminedresult9] = useState(false);

    const { currentSemester = '', semesters = {}, currentRank } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];

    const checksemesters = [{ sem: 2, year, isNow: true }] as Semester[];
    const [student, setStudent] = useState<StudentPHIAll[]>();
    const [classes, setClasses] = useState<ClassMapObj>({ '': '無年級資料' });
    // const [year, setYear] = useState<number>(0);
    const [classId, setClassId] = useState<string>('');
    const [studentId, setStudentId] = useState<string>('');
    const [classStudents, setClassStudents] = useState<{
        [k: string]: Student;
    }>({});
    // const semesters = [{ sem: 2, year, isNow: true }] as Semester[];
    const physicalGradeRange = getPhysicalGradeByRank(
        user.schools[user.currentSchool].rank ?? SchoolRank.Junior
    );
    const [radioAction, setRadioAction] = useState<boolean>(true);
    const [conditionRadioAction, setConditionRadioAction] = useState<boolean>(true);
    const handleRadioChange = (isNowGrade: boolean) => {
        setRadioAction(isNowGrade);
    };
    const conditionhandleRadioChange = (isAnd: boolean) => {
        setConditionRadioAction(isAnd);
    };
    const { selectedYearGrade, element: selectgradeElement } = useCheckGrade({ year: year, isNowGrade: radioAction })
    const [radioGrade, setRadioGrade] = useState<number>(
        physicalGradeRange[physicalGradeRange.length - 1]
    );
    const [school, setSchool] = useState<School>();
    const { maxOffset, min } = useMemo(() => {
        const { min, max } = getGradeMinMax(currentRank);
        return { maxOffset: max - min + 1, min };
    }, []);

    useEffect(() => {
        if (selectedYearGrade?.grade && selectedYearGrade?.year) {
            setStudent(undefined);
            apis
                .getCheckAllQuery(selectedYearGrade.year, selectedYearGrade.grade)
                .then((student) => {
                    setStudent(student);
                })
                .catch(catchErrorForModal);
        }
    }, [selectedYearGrade?.grade, selectedYearGrade?.year]);

    const [selectedItems, setSelectedItems] = useState<Record<string, any>>({});
    const [selectAll, setSelectAll] = useState({
        physical: false,
        blood: false,
        xRay: false,
        Lab: false,
    });
    console.log(student)
    console.log(selectedItems)

    const handleCheckboxChange = (
        category: keyof ExaminationResultsType,
        subCategory: string,
        item: string
    ) => {
        setSelectedItems(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                ...(subCategory
                    ? {
                        [subCategory]: {
                            ...prevState[category]?.[subCategory],
                            [item]: !prevState[category]?.[subCategory]?.[item],
                        },
                    }
                    : {
                        [item]: !prevState[category]?.[item],
                    }),
            },
        }));
    };

    const handleSelectAllChange = (category: keyof ExaminationResultsType, isNested: boolean = false, subCategory?: string) => {
        const isSelected = !selectAll[category];
        setSelectAll(prevState => ({
            ...prevState,
            [category]: isSelected,
        }));

        if (isNested && subCategory) {
            const updatedNested: Record<string, any> = {};
            Object.keys(ExaminationResults[category][subCategory]).forEach(item => {
                updatedNested[item] = isSelected;
            });

            setSelectedItems(prevState => ({
                ...prevState,
                [category]: {
                    ...prevState[category],
                    [subCategory]: updatedNested,
                },
            }));
        } else if (category === 'physical') {
            // Handle second-level categories under 'Physical'
            const updatedCategory: Record<string, any> = {};
            Object.keys(ExaminationResults[category]).forEach(subCategory => {
                updatedCategory[subCategory] = {};
                Object.keys(ExaminationResults[category][subCategory]).forEach(item => {
                    updatedCategory[subCategory][item] = isSelected;
                });
            });

            setSelectedItems(prevState => ({
                ...prevState,
                [category]: updatedCategory,
            }));
        } else {
            // Handle single-level categories (Blood, XRay, Lab)
            const updatedCategory: Record<string, boolean> = {};
            Object.keys(ExaminationResults[category]).forEach(item => {
                updatedCategory[item] = isSelected;
            });

            setSelectedItems(prevState => ({
                ...prevState,
                [category]: updatedCategory,
            }));
        }
    };

    const createCheckBoxList = (category: keyof ExaminationResultsType, categoryName: string, subCategory?: string) => {
        if (subCategory && category === 'physical') {
            // Render checkboxes for nested categories under 'Physical'
            const subCategoryItems = Object.entries(ExaminationResults[category][subCategory]);
            return (
                <fieldset key={subCategory} style={{ border: '1px solid #000', margin: '5px 0', padding: '10px' }}>
                    <legend className="legendbackgropminor" style={{ fontSize: '16px', marginBottom: '10px', width: "fit-content" }}>
                        <FormCheck
                            className="mr-2"
                            label={`${Physicalsubcatoryname[subCategory]}`}
                            checked={selectAll[category] && selectedItems[category]?.[subCategory] && Object.keys(selectedItems[category][subCategory]).every(item => selectedItems[category]?.[subCategory]?.[item])}
                            onChange={() => handleSelectAllChange(category, true, subCategory)}
                        />
                    </legend>
                    <BSRow style={{ margin: '0px 10px' }}>
                        {subCategoryItems.map(([key, label]) => (
                            <Col xs={2} key={`${category}_${subCategory}_${key}`}>
                                <FormCheck
                                    id={`${category}_${subCategory}_${key}`}
                                    className="mr-2"
                                    label={label}
                                    checked={selectedItems[category]?.[subCategory]?.[key] || false}
                                    onChange={() => handleCheckboxChange(category, subCategory, key)}
                                />
                            </Col>
                        ))}
                    </BSRow>
                </fieldset>
            );
        } else {
            // Render checkboxes for single-level categories (Blood, XRay, Lab)
            const categoryItems = Object.entries(ExaminationResults[category]);
            return (
                <BSRow key={category} style={{ margin: '0px 10px' }}>
                    {categoryItems.map(([key, label]) => (
                        <Col xs={2} key={`${category}_${key}`}>
                            <FormCheck
                                id={`${category}_${key}`}
                                className="mr-2"
                                label={label}
                                checked={selectedItems[category]?.[key] || false}
                                onChange={() => handleCheckboxChange(category, '', key)}
                            />
                        </Col>
                    ))}
                </BSRow>
            );
        }
    };

    const createCategorySections = (category: keyof ExaminationResultsType, categoryName: string) => {
        return (
            <fieldset style={{ border: '1px solid #000', margin: '5px 0', padding: '10px' }} key={category}>
                <legend className="legendbackgropminor" style={{ display: 'flex', alignItems: 'center', width: "fit-content" }}>
                    <FormCheck
                        id={`chb_${category}`}
                        className="check"
                        checked={selectAll[category]}
                        onChange={() => handleSelectAllChange(category)}
                    />
                    <span className="spanbackgropminor" style={{ fontSize: '22px', marginLeft: '10px' }}>{categoryName}</span>
                </legend >
                {category === 'physical' ? (
                    // Render nested categories under 'Physical'
                    Object.keys(ExaminationResults[category]).map(subCategory => (
                        createCheckBoxList(category, '', subCategory)
                    ))
                ) : (
                    // Render single-level categories (Blood, XRay, Lab)
                    createCheckBoxList(category, categoryName)
                )}
            </fieldset>
        );
    };

    const generateFilter = () => {

        const categories: (keyof StudentPHIAll)[] = Object.keys(selectedItems) as (keyof StudentPHIAll)[];

        const criteria: string[] = [];
        categories.forEach(category => {
            const items = selectedItems[category];
            Object.keys(items).forEach(item => {
                if (items[item]) {
                    criteria.push(item);
                }
            });
        });

        const value: number[] = [];
        if (examinedresult1) value.push(1);
        if (examinedresult2) value.push(2);
        if (examinedresult3) value.push(3);
        if (examinedresult9) value.push(9);

        const matchAll = conditionRadioAction;

        const filter: Filter = {
            category: categories,
            criteria: criteria,
            value: value,
            matchAll: matchAll,
        };

        return filter;
    };

    const filterData = extractChineseNames(filterDataByIndexValue(student, generateFilter()), ExaminationResults, generateFilter().value);
    // console.log(filterData)
    const contentRows: Row[] = Object.values(filterData).flatMap(
        (gs, idx, allArray) => {
            // 同一年級下 loop每個班級
            return checktoRow(
                gs.grade,
                gs.no,
                gs.name,
                gs.seat,
                gs.sex,
                gs.results.join(",")
            );
        }
    );
    const content: Row[] = [
        {
            cells: [
                {
                    value: `7年級健康檢查詢清單`,
                    merge: { column: 6 },
                },
            ],
        },
        {
            cells: [
                {
                    value: '年',
                    merge: { column: 1 },
                },
                {
                    value: '班',
                    merge: { column: 1},
                },
                {
                    value: '姓名',
                    merge: { column: 1},
                },
                {
                    value: '座號',
                    merge: { column: 1 },
                },
                {
                    value: '性別',
                    merge: { column: 1},
                },
                {
                    value: '健檢結果',
                    merge: { column: 1 },
                },
            ],
        },
        ...contentRows,
    ];


    return (
        <AuthedLayout>
            <BSRow className="mb-2 justify-content-start">
                <Col className='d-flex mr-2' xs={2} >
                    <FormCheck
                        className="mr-5"
                        value={'在學'}
                        type="radio"
                        label={`在學`}
                        name="list-action"
                        checked={radioAction === true}
                        onChange={() => handleRadioChange(true)}
                    />
                    <FormCheck
                        className="mr-5"
                        value={`歷屆年級`}
                        type="radio"
                        label={`歷屆年級`}
                        name="list-action"
                        checked={radioAction === false}
                        onChange={() => handleRadioChange(false)}
                    />
                </Col>
                <Col className='d-flex mr-3' xs={2}>
                    {selectgradeElement}
                </Col>
                <Col className='mr-3' xs={3} >
                    <div>
                        <FormCheck
                            className="mr-12"
                            value={'and'}
                            type="radio"
                            label={`AND（勾選項目皆須符合）`}
                            name="condition"
                            checked={conditionRadioAction === true}
                            onChange={() => conditionhandleRadioChange(true)}
                        />
                    </div>
                    <div>
                        <FormCheck
                            className="mr-12"
                            value={`or`}
                            type="radio"
                            label={`OR（勾選項目至少一項以上符合）`}
                            name="condition"
                            checked={conditionRadioAction === false}
                            onChange={() => conditionhandleRadioChange(false)}
                        />
                    </div>
                </Col>
                <Col className='mr-3' xs={2} >
                    <FormCheck
                        label="初檢異常"
                        checked={examinedresult1}
                        onChange={() => setexaminedresult1(!examinedresult1)}
                        value={"1"}
                    />
                    <FormCheck
                        label="複檢正常－偽陽性"
                        checked={examinedresult2}
                        onChange={() => setexaminedresult2(!examinedresult2)}
                        value={"2"}
                    />
                    <FormCheck
                        label="複檢異常－確診"
                        checked={examinedresult3}
                        onChange={() => setexaminedresult3(!examinedresult3)}
                        value={"3"}
                    />
                    <FormCheck
                        label="未受檢"
                        checked={examinedresult9}
                        onChange={() => setexaminedresult9(!examinedresult9)}
                        value={"9"}
                    />
                </Col>
                <div>
                    <Button
                        disabled={!filterData.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            const workSheet = generateSheet(
                                [
                                    ...content,
                                    {
                                        cells: [
                                            {
                                                value:
                                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                                style: { alignment: { horizontal: 'left' } },
                                            },
                                        ],
                                    },
                                ],
                                {
                                    alignment: cellAlignStyle,
                                }
                            );
                            downloadSheets(`7年級健康檢查詢清單`, { sheet: workSheet, name: 'st' });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </BSRow>
            <hr />
            <BSRow>
                <div>
                    {[
                        { category: 'physical', name: '身體診察' },
                        { category: 'blood', name: '血液檢查' },
                        { category: 'xRay', name: 'Ｘ光檢查' },
                        { category: 'Lab', name: '實驗室檢查' },
                    ].map(item => (
                        <React.Fragment key={item.category}>
                            {createCategorySections(item.category as keyof ExaminationResultsType, item.name)}
                        </React.Fragment>
                    ))}
                </div>
            </BSRow>
        </AuthedLayout>
    );
};



function filterDataByIndexValue(data: StudentPHIAll[] | undefined, filters: Filter): StudentPHIAll[] {
    if (!data) return [];
    return data.filter(item => {
        return filters.category.some((category, i) => {
            if (Array.isArray(item[category])) {
                const categoryArray = item[category] as any[];
                if (categoryArray.length === 0) {
                    return false;
                } else {
                    if (filters.matchAll) {
                        return filters.criteria.every((criteria, j) => categoryArray.some(subItem => subItem[criteria] === filters.value[j]));
                    } else {
                        return filters.criteria.some((criteria, j) => categoryArray.some(subItem => subItem[criteria] === filters.value[j]));
                    }
                }
            } else {
                return false;
            }
        });
    });
}

function extractChineseNames(data: any[], examResults: ExaminationResultsType, selectedValues: number[]): ExtractedData[] {
    const extractedData: ExtractedData[] = [];

    data.forEach((entry) => {
        const { grade, no, name, seat, sex, classes } = entry;
        const results: [string, any][] = []; // 修改为数组格式

        for (const key in entry) {
            if (Object.prototype.hasOwnProperty.call(entry, key)) {
                const value = entry[key];
                const category = examResults[key as keyof ExaminationResultsType];

                if (category) {
                    if (Array.isArray(value)) {
                        value.forEach((subEntry: any) => {
                            for (const subKey in subEntry) {
                                if (Object.prototype.hasOwnProperty.call(subEntry, subKey)) {
                                    const subValue = subEntry[subKey];
                                    if (selectedValues.includes(subValue)) {
                                        if ((category as any)[subKey]) {
                                            results.push([(category as any)[subKey], subValue]);
                                        } else {
                                            for (const subCategoryKey in category) {
                                                if (Object.prototype.hasOwnProperty.call(category, subCategoryKey)) {
                                                    const subCategory = (category as any)[subCategoryKey];
                                                    if (typeof subCategory === 'object' && subCategory[subKey]) {
                                                        results.push(subCategory[subKey] + subValue);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    } else {
                        for (const subKey in value) {
                            if (Object.prototype.hasOwnProperty.call(value, subKey)) {
                                const subValue = value[subKey];
                                if (selectedValues.includes(subValue)) {
                                    if ((category as any)[subKey]) {
                                        results.push([(category as any)[subKey], subValue]);
                                    } else {
                                        for (const subCategoryKey in category) {
                                            if (Object.prototype.hasOwnProperty.call(category, subCategoryKey)) {
                                                const subCategory = (category as any)[subCategoryKey];
                                                if (typeof subCategory === 'object' && subCategory[subKey]) {
                                                    results.push(subCategory[subKey] + subValue);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const latestNo = classes[classes.length - 1].no;
        extractedData.push({ grade, no: latestNo, name, seat, sex, results });
    });

    return extractedData;
}

function checktoRow(
    grade: string,
    classno: string,
    name: string,
    seat: string,
    sex: string,
    checkdata: string
): Row[] {
    return [
        {
            cells: [
                {
                    value: grade,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: classno,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: name,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: seat,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: (sex == "1" ? "男" : "女"),
                    style: { border: { left: borderStyle } },
                },
                {
                    value: checkdata,
                    style: { border: { left: borderStyle } },
                }
            ],
        },
    ];
}
export const CheckAllQuery = connector(checkAllQuery);  