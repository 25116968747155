import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { FluorStudentStatistic } from '../../../model';
import apis from '../../../apis';


const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const teeFluorStaticClass: FunctionComponent<Props> = ({ user, catchErrorForModal }) => {
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    const [datas, setDatas] = useState<FluorStudentStatistic[]>([]);

    useEffect(() => {
        if (year && sem) {
            apis.getFluorClassStatic(year, sem).then((s) => {
                setDatas(s);
            });
        }
    }, [year, sem]);

    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, FluorStudentStatistic[]>);


    let schoolcount = 0;
    let schoolneverfluor = 0;
    let schoolyearsemdont = 0;
    let schoolfluorcont = 0;
    let schoolisfluorcount = 0;
    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const classFluor = {
                grade: 0,
                classNo: 0,
                neverFluor: 0,
                studentCount: 0,
                yearSemDontFluor: 0,
                fluorStudentCount: 0,
                isFluorStudentCount: 0,
                fluorWeek: 0
            };
            const rows = gs.flatMap((g, idx) => {
                return FluorStaticToRows(
                    g.grade,
                    g.classNo ?? 0,
                    g.neverFluor,
                    g.studentCount,
                    g.yearSemDontFluor,
                    g.fluorStudentCount,
                    g.isFluorStudentCount,
                    "",
                    idx === 0 ? { row: (gs.length + 1) } : undefined
                );
            });
            gs.forEach(st => {
                classFluor.neverFluor += st.neverFluor;
                classFluor.studentCount += st.studentCount;
                classFluor.yearSemDontFluor = st.yearSemDontFluor;
                classFluor.fluorStudentCount += st.fluorStudentCount;
                classFluor.isFluorStudentCount += st.isFluorStudentCount;
                classFluor.fluorWeek = st.fluorWeek ?? 0;

                schoolneverfluor += st.neverFluor;
                schoolcount += st.studentCount;
                schoolyearsemdont = st.yearSemDontFluor;
                schoolfluorcont += st.fluorStudentCount;
                schoolisfluorcount += st.isFluorStudentCount;
            });
            rows.push(...FluorStaticToRows('', '小計', classFluor.neverFluor, classFluor.studentCount, classFluor.yearSemDontFluor, classFluor.fluorStudentCount, classFluor.isFluorStudentCount, classFluor.fluorWeek));
            if (idx === allArray.length - 1) {
                const lastRow = rows[rows.length - 1];

                lastRow.cells = lastRow?.cells.map((c) => {
                    const { style: { border = {} } = {} } = c;
                    border.bottom = borderStyle;
                    c.style = { ...c.style, border };
                    return c;
                });
            }

            return rows;
        }
    );
    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級班級',
                    merge: { column: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '學生數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '不參與數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '實施週數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '參與率',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '未實施人次',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '應實施人次',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '實施人次',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '執行率',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
        ...FluorStaticToRows('全校合計', '', schoolneverfluor, schoolcount, schoolyearsemdont, schoolfluorcont, schoolisfluorcount, "", { column: 2, row: 2 }),
    ];


    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <Button
                    disabled={!datas.length}
                    onClick={() => {
                        const workSheet = generateSheet(
                            [
                                {
                                    cells: [
                                        {
                                            value: `${year}學年第${sem}學期_含氟漱口水執行統計(班級)`,
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                                ...content,
                                {
                                    cells: [
                                        {
                                            value:
                                                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets(`${year}學年第${sem}學期_含氟漱口水執行統計(班級)`, { sheet: workSheet, name: 'st' });
                    }}
                >
                    Excel 下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function FluorStaticToRows(
    grade: number | string,
    classNo: number | string,
    neverFluor: number,
    studentCount: number,
    yearSemDontFluor: number,
    fluorStudentCount: number,
    isFluorStudentCount: number,
    fluorWeek: number | string,
    gradeMerge?: { row?: number; column?: number }
): Row[] {
    const gradeNo = [
        {
            value: gradeMerge ? grade : undefined,
            merge: gradeMerge,
            style: { border: fullBorderStyle },
        },
    ];
    if (!gradeMerge?.column) {
        gradeNo.push({
            value: classNo,
            merge: { row: 1 },
            style: { border: fullBorderStyle },
        });
    }
    return [
        {
            cells: [
                ...gradeNo,
                {
                    value: studentCount ?? 0,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: neverFluor ?? 0,
                },
                {
                    value: fluorWeek ?? 0,
                },
                {
                    value: percent(neverFluor, studentCount) ?? "0%",
                    style: { border: { right: borderStyle } },
                },
                {
                    value: yearSemDontFluor,
                },
                {
                    value: fluorStudentCount,
                },
                {
                    value: isFluorStudentCount,
                },
                {
                    value: percent(isFluorStudentCount, fluorStudentCount) ?? "0%",
                    style: { border: { right: borderStyle } },
                },
            ],
        },

    ];
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}

export const TeeFluorStaticClass = connector(teeFluorStaticClass);
