import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';
import { Modal, Button, InputGroup, Form, Col } from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
} from '../../components';
import {
  PHIInheritDisease,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { InputDropdownGroup, InputDropdownGroupField } from '../../components';

import { toast } from 'react-toastify';

type Props = {
  editable: boolean;
  deleteable: boolean;
  inherit: PHIInheritDisease[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};
const relativeMap1: { [key: number]: string } = {
  1: '父親',
  2: '母親',
  3: '祖父',
  4: '祖母',
  5: '兄',
  6: '弟',
  7: '姐',
  8: '妹',
  13: '外祖父',
  14: '外祖母',
};
const inheritDiseaseMap1: { [key: number]: string } = {
  1: '糖尿病',
  2: '血友病',
  5: '蠶豆症',
  31: '高血壓',
  99: '其他',
};

const inlineDiseaseTableHeader: HeaderDisplayKeysWithType<PHIInheritDisease>[] =
  [
    { property: 'relative',
      display: '親屬' ,
      onRender: (value: unknown) => {
        if (value) {
          return typeof value == 'number'
            ? relativeMap1[value]
            : '無檢查項目';
        }
      },
    },
    { property: 'inheritDisease' , 
      display: '遺傳性疾病',
      onRender: (value: unknown) => {
        if (value) {
          return typeof value == 'number'
            ? inheritDiseaseMap1[value]
            : '無檢查項目';
        }
      },
    }
  ];

export const InheritSection: FunctionComponent<Props> = ({
  editable,
  deleteable,
  inherit,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
    useState<Record<number, string>>();
  const [inheritDiseaseKindDropDown, setInheritDiseaseKindDropDown] = useState<number>();
  const [relativeDropDown, setRelativeDropDown] = useState<number>();
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [year, setYear] = useState<number>();
  const [sem, setSem] = useState<number>();
  const inheritStatesInputRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    // 目前學期、學年
    const isNow = semesters.filter((y) => y.isNow == true)[0];
    setYear(isNow.year);
    setSem(isNow.sem);
  }, [semesters]);

  useEffect(() => {
    setInsertBtn(true);
    if(relativeDropDown) {
      if(inheritDiseaseKindDropDown) {
        setInsertBtn(false);
      }
    }
  }, [relativeDropDown, inheritDiseaseKindDropDown]);

  function onValueUpdate(value: PHIInheritDisease) {
  }

  function onValueDelete(value: PHIInheritDisease) {
    apis
      .deleteStudentDeleteInheritDisease(
        value?.id,
        value?.inheritDisease,
        value?.relative,
        value?.states || "",
        value.studentId,
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function insertInheritDisease() {
    if (inheritDiseaseKindDropDown && relativeDropDown && year && sem) {
      toast
        .promise(
          apis.insertStudentInheritDisease(
            year,
            sem,
            student.sid || "",
            student.seat || 0,
            student.classNo || 0,
            student.pid,
            inheritDiseaseKindDropDown,
            relativeDropDown,
            inheritStatesInputRef.current?.value || "",
          ),
          {
            pending: '資料新增中......',
            success: '新增成功！',
          }
        )
        .then(onValueUpdated)
        .catch(onApiError);
    }
  }

  return (
    <>
      <div>
        家族疾病史
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            家族疾病史 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <InlineEditableTable
            values={inherit}
            headers={inlineDiseaseTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable: false,
              deleteable: deleteable,
            })}
          />
          <div className="d-flex align-items-center">
            家屬：
            <Col sm={1}>
              <InputDropdownGroup
                className={'p-1 text-center mr-2'}
                name="reltive"
                type="number"
                options={{
                  1: '父親',
                  2: '母親',
                  3: '祖父',
                  4: '祖母',
                  5: '兄',
                  6: '弟',
                  7: '姐',
                  8: '妹',
                  13: '外祖父',
                  14: '外祖母',
                }}
                defaultShow="無異常"
                value={relativeDropDown}
                onChange={(
                  e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                  >
                ) => {
                  setRelativeDropDown(parseInt(e.target.value));
                }}
                onBlur={() => {}}
                onFocus={() => {}}
              />
            </Col>
            遺傳性疾病：
            <Col sm={3}>
              <InputDropdownGroup
                className={'p-1 text-center mr-2'}
                name="diseaseId"
                type="number"
                options={{
                  1: '糖尿病',
                  2: '血友病',
                  5: '蠶豆症',
                  31: '高血壓',
                  99: '其他',
                }}
                defaultShow="無異常"
                value={inheritDiseaseKindDropDown || ''}
                onChange={(
                  e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                  >
                ) => {
                  setInheritDiseaseKindDropDown(parseInt(e.target.value));
                }}
                onBlur={() => {}}
                onFocus={() => {}}
              />
            </Col>
            其他：
            <Col sm={2}>
              <InputGroup>
                <Form.Control
                  className="mr-2"
                  placeholder="其他"
                  aria-label="Agency"
                  aria-describedby="basic-addon1"
                  ref={inheritStatesInputRef}
                />
              </InputGroup>
            </Col>
            <Button disabled={insertBtn} onClick={insertInheritDisease}>
              新增
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
