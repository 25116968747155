import { DateTime } from 'luxon';
import React, {
  ReactNode,
  FocusEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  Button,
  Col,
  Container,
  FormControl,
  Modal,
  Row,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { connect, ConnectedProps } from 'react-redux';
import Apis from '../apis';
import {
  CheckBoxField,
  CustomAccordionToggle,
  DateTimeField,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  InputDropdownGroupField,
  SightManageSheet,
} from '../components';
import { PHISight, SimpleStudent } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { Nullable } from '../types';
import { ClassSem, WrapClassBaseLayout } from '../layouts';
import { ApplicationState } from '../redux/States';
import {
  DefaultSightManageMap,
  denormalizeStudentSight,
  normalizeStudentSight,
  SightCalculators,
  SightPropertyFields,
  SightTableHeader,
} from './TableHeaders/SightHeader';

type EyeSightPageData = SimpleStudent & Nullable<PHISight>;

const SightManageAccordionKey = '0';

const modalDisplayHeader: HeaderDisplayKeysWithType<EyeSightPageData>[] = [
  { property: 'seat', display: '座號' },
  { property: 'name', display: '學生' },
  ...(SightTableHeader as HeaderDisplayKeysWithType<EyeSightPageData>[]),
];
const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const classPHISightPage: FunctionComponent<Props> = ({
  classId,
  year,
  sem,
  catchErrorForModal,
}) => {
  const [students, setStudent] = useState([] as EyeSightPageData[]);
  const [inputing, setInputing] = useState(false);
  const [sightManageMap, setSightManageMap] = useState(DefaultSightManageMap);
  const [editingStudent, setEditingStudent] = useState<EyeSightPageData>();
  const [manageIdStr, setManageIdStr] = useState('');
  useEffect(() => {
    // TODO: fetch sight manage map from server
    // updateSightManagerMap({});
    // setSightManageMap();
  }, []);

  useEffect(() => {
    refreshStudents();
  }, [classId, year, sem, inputing]);

  function refreshStudents() {
    if (classId && year && sem) {
      Apis.getClassStudentSight(classId, year, sem)
        .then((ss) =>
          setStudent(
            ss.map(({ sight, ...s }) => ({
              ...s,
              ...sight,
            }))
          )
        )
        .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }

  function onHide() {
    setInputing(false);
    setEditingStudent(undefined);
  }

  function onEdit(sight: EyeSightPageData) {
    setInputing(true);
    setEditingStudent(sight);
    setManageIdStr(sight.manageID?.join(',') || '');
  }

  function normalizeManageId(source?: string): string[] {
    const manageIds = (source || '')
      .split(/[,]/i)
      .map((v) => v.trim())
      .filter((v) => !!sightManageMap[v]);
    return manageIds;
  }

  function onUpdateStudentSight(sight: PHISight) {
    if (editingStudent && editingStudent.pid) {
      // const manageIds = normalizeManageId(manageIdStr);
      // sight.manageID = manageIds;
      toast
        .promise(Apis.InsertStudentSight(editingStudent?.pid, sight), {
          pending: '視力資料上傳中......',
          success: '視力資料上傳成功！',
          error: '視力資料上傳失敗！請查看錯誤資訊。',
        })
        .then(() => onHide())
        .catch(catchErrorForModal);
    }
  }

  const rowClassName = 'pb-3 align-items-center';
  let modalContent = <React.Fragment />;

  if (inputing) {
    modalContent = (
      <Modal
        show={inputing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        dialogClassName="modal-dialog-full"
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            視力數據 -{' '}
            <strong>
              {editingStudent?.seat} {editingStudent?.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={
            editingStudent ? denormalizeStudentSight(editingStudent) : undefined
          }
          decorators={SightCalculators}
          onSubmit={(value) => {
            onUpdateStudentSight({
              ...value,
              ...normalizeStudentSight(value),
              id: 0,
              sem,
              year,
              yearClassId: 0,
            });
          }}
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { values, handleSubmit } = prop;
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row className={rowClassName}>
                      <Col sm={12} className="text-left">
                        視力請直接輸入整數，例如 0.8 輸入 8 視力值＜0.1請輸入-1
                        <br />
                        「散瞳治療」是指點長效型散瞳眼藥治療，「散瞳」是指檢驗屈光度數前的短效散瞳。
                        <br />
                        裸眼視力無法測量代號說明：-8 戴隱形眼鏡；-7 角膜塑型；-6
                        雷射治療；-5 全盲
                        <br />
                        無法表達或在家自學僅輸入裸視右眼為 -9
                        <br />
                        「定期檢查」日期格式：1999/01/01 或點兩下顯示萬年曆選單
                        <br />
                        如「近視右（左）」或「遠視右（左）」有小數點，請將小數點四捨五入至個位數後以整數輸入
                        <br />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {generateCheckedField('散瞳治療', 'isDilated')}
                      {generateNumberField(
                        '裸視右',
                        SightPropertyFields.sight0R
                      )}
                      {generateNumberField(
                        '裸視左',
                        SightPropertyFields.sight0L
                      )}
                    </Row>
                    <Row className={rowClassName}>
                      <Col sm={4} />
                      {generateNumberField(
                        '戴鏡右',
                        SightPropertyFields.sightR
                      )}
                      {generateNumberField(
                        '戴鏡左',
                        SightPropertyFields.sightL
                      )}
                    </Row>
                    <Row className={rowClassName}>
                      {generateCheckedField('近視', 'eNear')}
                      {generateNumberField(
                        '近視右',
                        SightPropertyFields.eNearR
                      )}
                      {generateNumberField(
                        '近視左',
                        SightPropertyFields.eNearL
                      )}
                    </Row>
                    <Row className={rowClassName}>
                      {generateCheckedField('遠視', 'eFar')}
                      {generateNumberField('遠視右', SightPropertyFields.eFarR)}
                      {generateNumberField('遠視左', SightPropertyFields.eFarL)}
                    </Row>
                    <Row className={rowClassName}>
                      {generateCheckedField('散光', 'eSan')}
                      {generateNumberField('散光右', SightPropertyFields.eSanR)}
                      {generateNumberField('散光左', SightPropertyFields.eSanL)}
                    </Row>
                    <Row className={rowClassName}>
                      {generateCheckedField('散瞳後檢查', 'isDilating')}
                      {generateCheckedField('弱視', 'eWeak')}
                      {generateCheckedField('複檢無異狀', 'noProblem')}
                    </Row>
                    <Row className={rowClassName}>
                      {generateCheckedField('其他', 'eSight99')}
                      <Col sm="1">其他陳述:</Col>
                      <Field name="eSight99State">
                        {({ input, meta }) => (
                          <Col>
                            <FormControl {...input} type="text" />
                            {meta.error && meta.modified && !meta.active && (
                              <span className="text-danger">{meta.error}</span>
                            )}
                          </Col>
                        )}
                      </Field>
                    </Row>
                    <Row className={rowClassName}>
                      <Col sm="1">處置代號:</Col>
                      <Col sm="5">
                        {SightPropertyFields.manageID(sightManageMap)}
                      </Col>
                      <Col sm="1">定期檢查:</Col>
                      <Col sm="5">
                        <DateTimeField property={'periodical'} />
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHide}>
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        />
      </Modal>
    );
  }
  return (
    <React.Fragment>
      <Row>
        <EditableTableDiv
          editable
          headers={modalDisplayHeader}
          values={students}
          onEdit={onEdit}
        />
      </Row>
      {modalContent}
    </React.Fragment>
  );
};

function generateNumberField(display: string, node: ReactNode): ReactElement {
  return (
    <React.Fragment>
      <Col sm="1">{display}:</Col>
      <Col sm="3">{node}</Col>
    </React.Fragment>
  );
}

function generateCheckedField(display: string, property: string): ReactElement {
  return (
    <React.Fragment>
      <Col sm="1">{display}:</Col>
      <Col sm="3">
        <CheckBoxField property={property} />
      </Col>
    </React.Fragment>
  );
}

export const ClassPHISightPage = connector(
  WrapClassBaseLayout(classPHISightPage)
);
