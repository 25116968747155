import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { AuthedLayout } from '../components';
import { ClassMapObj } from '../types';
import Apis from '../apis';
import { ApplicationState } from '../redux/States';
import { AuthDispatches, ErrorDispatches } from '../redux/Dispatches';
import { connect, ConnectedProps } from 'react-redux';
import { listToObject } from '../utils/transform';

export interface ClassSem {
  classId?: string;
  className: string;
  year: number;
  sem: number;
  grade: number;
  no: number;
}

type ChildRender = (classSem: ClassSem) => ReactElement;
type ClassInfoObj = {
  [k: string]: {
    name: string;
    grade: number;
    no: number;
  };
};
type OwnProps = {
  children: ReactNode | ChildRender;
  onClassesLoaded?: () => void;
  onClassChanged?: (year: number, sem: number, classId?: string) => void;
};
const mapState = (state: ApplicationState, ownProps: OwnProps) => ({
  ...state.auth,
  ...ownProps,
});
const mapDispatch = { ...ErrorDispatches, ...AuthDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const classBaseLayout: FunctionComponent<Props> = ({
  children,
  catchErrorForModal,
  onClassesLoaded = () => {},
  onClassChanged = () => {},
  user,
}) => {
  const { currentSemester = '', semesters = {} } = user || {};
  const [showClassId, setShowClassId] = useState<string>();
  const [classes, setClasses] = useState<ClassMapObj>({ '': '無班級資料' });
  const [classesInfo, setClassesInfo] = useState<ClassInfoObj>({
    '': {
      name: '無班級資料',
      grade: 0,
      no: 0,
    },
  });
  const [currentSem, setCurrentSem] = useState(currentSemester);

  const { year = -1, sem = -1 } = semesters[currentSem];

  useEffect(() => {
    refreshClasses();
  }, [user, year]);

  function refreshClasses() {
    Apis.getClasses(year, sem)
      .then((classes) => {
        if (classes.length) {
          const transformedData = classes.reduce((acc, cur) => {
            acc[cur.id] = {
              name: cur.name,
              grade: cur.grade,
              no: cur.no,
            };
            return acc;
          }, {} as { [key: string]: { name: string; grade: number; no: number } });
          setClasses(
            listToObject(
              classes,
              (c) => c.id,
              (c) => c.name
            )
          );
          setClassesInfo(transformedData);
        } else {
          setClasses({ '': '無班級資料' });
        }

        setShowClassId(undefined);
        onClassesLoaded();
      })
      .catch(catchErrorForModal);
  }

  return (
    <AuthedLayout>
      <Row>
        <Col xs={2}>
          <DropdownButton
            title={(showClassId && classes[showClassId]) || '請選擇'}
            onSelect={(k: string | null) => {
              if (k == null || !classes[k] || !semesters[currentSem]) return;
              setShowClassId(k);
              const semester = semesters[currentSem];
              onClassChanged(semester.year, semester.sem, k);
            }}
          >
            {Object.entries(classes).map(([i, n]) => (
              <Dropdown.Item key={`class-${i}`} eventKey={i}>
                {n}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        {Object.entries(semesters).map(([n, s]) => {
          return (
            <Col key={`sem-${n}`} xs={1}>
              <Form.Check
                type="radio"
                label={n}
                name="setSemester"
                checked={n === currentSem}
                onChange={(e) => {
                  setCurrentSem(n);
                  const { year: newYear } = semesters[n];
                  const { year: currentYear } = semesters[currentSem];
                  if (newYear !== currentYear) {
                    // if year not same, then clear classId
                    setShowClassId(undefined);
                    onClassChanged(s.year, s.sem);
                  } else {
                    onClassChanged(s.year, s.sem, showClassId);
                  }
                }}
              />
            </Col>
          );
        })}
      </Row>
      <hr />
      {typeof children === 'function'
        ? (children as ChildRender)({
            classId: showClassId,
            year,
            sem,
            className: classes[showClassId ?? ''] ?? '',
            grade: showClassId ? classesInfo[showClassId]['grade'] : 0,
            no: showClassId ? classesInfo[showClassId]['no'] : 0,
          })
        : children}
    </AuthedLayout>
  );
};

export const ClassBaseLayout = connector(classBaseLayout);

export function WrapClassBaseLayout<T extends ClassSem>(
  Compoment: FunctionComponent<T>
): FunctionComponent<T> {
  return function WrappedClassBaseLayout(props: T): ReactElement {
    return (
      <ClassBaseLayout>
        {(classSem) => <Compoment {...classSem} {...props} />}
      </ClassBaseLayout>
    );
  };
}
