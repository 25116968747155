import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
} from '../../../components';
import { useDownloadChart, useSemGrade } from '../../../hook';
import { WHChartStatistic } from '../../../model';
import apis from '../../../apis';
import useApexBarChart, { ChartState } from '../../../hook/useApexBarChart';

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const whWeightChart: FunctionComponent<Props> = ({
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<WHChartStatistic[]>([]);
    const chartRef = useRef(null);
    useEffect(() => {
        if (yearSem) {
            apis
                .getWeightChart(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(
                        r.map(({ ...s }) => ({
                            ...s,
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);


    const state: ChartState = {
        options: {
            chart: {
                id: 'heightchart'
            },
            xaxis: {
                title: { text: "年級" },
                categories: students.map((s) => (s.grade)).filter((value, index, array) => array.indexOf(value) === index)
            },
            yaxis: {
                title: { text: "體重(公斤)" },
            },
        },
        series: [
            {
                name: '男',
                data: students.filter(x => x.sex == 1).map((s) => (s.count.toFixed(2)))
            }, {
                name: '女',
                data: students.filter(x => x.sex == 2).map((s) => (s.count.toFixed(2)))
            }],
        charttype: 'bar'
    }
    const { element: barchart } = useApexBarChart(state);
    const { element: downloadChart } = useDownloadChart({ chartdata: state.series[0].data, yearSem: yearSem, chartRef: chartRef, title: `${yearSem?.year}學年第${yearSem?.sem}學期_體重統計圖` });

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col xs={3} className="mr-3">
                    {semGradeElement}
                </Col>
                <Col xs={2} className="mr-2">
                    {downloadChart}
                </Col>
            </Row>
            <hr />
            <Row>
                <Col className="text-center">
                    {yearSem?.year}學年第{yearSem?.sem}學期_體重統計圖
                </Col>
            </Row>
            <Row className="justify-content-center">
                <div ref={chartRef}>
                    {barchart}
                </div>
            </Row>
        </AuthedLayout>
    );
};

export const WHWeightChart = connector(whWeightChart);
