import z from 'zod';
import { zodStringToDateTime } from '../utils';

export const LabSemZod = z.object({
  id: z.number(),
  year: z.number().positive(), // 入學年
  gradeID: z.number().positive(), // 檢查年級
  labKind: z.number(), // 檢查種類
  checkDate: zodStringToDateTime(), // 初查日期
  checkDate2: zodStringToDateTime(), // 複查日期
  schoolId: z.string().nullish(), // 複查日期
});

export const LabSemWithNullZod = z.object({
  id: z.number(),
  year: z.number().positive(), // 入學年
  gradeID: z.number().positive(), // 檢查年級
  labKind: z.number(), // 檢查種類
  checkDate: zodStringToDateTime(), // 初查日期
  checkDate2: zodStringToDateTime(), // 複查日期
  schoolId: z.string().nullish(), // 複查日期
}).nullish();

export const LabSemCheckDateZod =  zodStringToDateTime(); // 複查日期

export type LabSem = z.infer<typeof LabSemZod>;
export type LabSemWithNull = z.infer<typeof LabSemWithNullZod>;
export type LabSemCheckDate = z.infer<typeof LabSemCheckDateZod>;
