import { z } from 'zod';
import { Primitive } from '../types';
import {
  CheckPid,
  CheckDate,
  CheckCode,
  CheckNumberRange,
} from '../utils/checkFormat';
import { zodStringOrNumberToNumber } from '../utils/zod';

export const StudentFileZod = z
  .object({
    身分證字號: z.string().nullish(),
    學號: z.string().nullish(),
    姓名: z.string().nullish(),
    性別: z.string().nullish(),
    入學年: zodStringOrNumberToNumber('入學年必須爲數字'),
    班級: z.string().nullish(),
    座號: z.string().nullish(),
    生日: z.string().nullish(),
    血型: z.string().nullish(),
    身分: z.string().nullish(),
    父親姓名: z.string().nullish(),
    母親姓名: z.string().nullish(),
    監護人: z.string().nullish(),
    // TODO: 判斷填入的郵遞區號是否真實存在，若無改以學校郵遞區號填入
    郵遞區號: z.string().nullish(),
    電話: z
      .string()
      .nullish()
      .transform((v) => v?.slice(0, 12)),
    // TODO: 去除與郵遞區號相符的字
    住址: z.string().nullish(),
    緊急聯絡方式: z.string().nullish(),
  })
  .transform((arg) => {
    let _okUpload = true;
    let _errorMsg = '';

    const now = new Date();

    const checkPidResult = CheckPid(arg.身分證字號, true);
    const _pid = checkPidResult.pid;
    if (!checkPidResult.okUpload) {
      _okUpload = checkPidResult.okUpload;
      _errorMsg += checkPidResult.errorMsg;
    }

    const _sid = arg.學號 ?? ' ';
    const _name = arg.姓名;
    if (_name == null) {
      _okUpload = false;
      _errorMsg += '請填入【姓名】，';
    }

    //檢查性別
    const checkSexRes = CheckCode(arg.性別, ['1', '2', '男', '女'], '性別');
    let _sex = '';
    if (!checkSexRes.okUpload) {
      _okUpload = checkSexRes.okUpload;
      _errorMsg += checkSexRes.errorMsg;
    } else {
      if (checkSexRes.code == '男' || checkSexRes.code == '1') {
        _sex = '1';
      }
      if (checkSexRes.code == '女' || checkSexRes.code == '2') {
        _sex = '2';
      }
    }

    if (_sex != null && _pid != null) {
      if (_pid[1] != _sex) {
        _okUpload = false;
        _errorMsg += '【身分證字號】與【性別】資料不符，';
      }
    }

    const currentYear =
      now.getFullYear() - 1911 - (now.getMonth() >= 7 ? 0 : 1) + 1;
    const checkYearRes = CheckNumberRange(
      arg.入學年,
      currentYear,
      { min: currentYear - 8, max: currentYear },
      '入學年'
    );
    const _year = checkYearRes.code;
    if (!checkYearRes.okUpload) {
      _okUpload = checkYearRes.okUpload;
      _errorMsg += checkYearRes.errorMsg;
    }

    const _no = +(arg.班級 ?? '');
    const _seat = +(arg.座號 ?? '');
    if (!arg.班級 || !+arg.班級) {
      _okUpload = false;
      _errorMsg += '請確認【班級】格式，';
    }
    if (!arg.座號 || !+arg.座號) {
      _okUpload = false;
      _errorMsg += '請確認【座號】格式，';
    }

    const _birString = arg.生日;
    let _birth = null;
    if (!_birString) {
      _okUpload = false;
      _errorMsg += '請填入【生日】，';
    } else {
      const checkDate = CheckDate(_birString);
      _birth = checkDate.resDate;
    }

    const checkBloodRes = CheckCode(arg.血型, ['A', 'B', 'AB', 'O'], '血型');
    let _blood = '';
    if (checkBloodRes.isNull) {
      _blood = '';
    } else if (!checkBloodRes.okUpload) {
      _okUpload = checkBloodRes.okUpload;
      _errorMsg += checkBloodRes.errorMsg;
      _blood = checkBloodRes.code;
    } else {
      _blood = checkBloodRes.code;
    }

    const checkAborigineRes = CheckCode(arg.身分, ['0', '1', '2', '3'], '身分');
    let _aborigine = '';
    if (checkAborigineRes.isNull) {
      _aborigine = '';
    } else if (!checkAborigineRes.okUpload) {
      _okUpload = checkAborigineRes.okUpload;
      _errorMsg += checkAborigineRes.errorMsg;
    } else {
      _aborigine = checkAborigineRes.code;
    }

    ////To-Check: 尚未提供防呆格式
    const _dad = arg.父親姓名;
    const _mom = arg.母親姓名;
    const _zip = arg.郵遞區號;
    const _tel = arg.電話;
    const _erTel = arg.緊急聯絡方式;
    const _address = arg.住址;

    // _grade not student data, addition field
    const _grade =
      now.getFullYear() - +arg.入學年 - 1911 + (now.getMonth() >= 7 ? 1 : 0);

    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid: _pid,
      sid: _sid,
      name: _name,
      sex: _sex,
      year: _year,
      grade: _grade,
      no: _no,
      seat: _seat,
      birth: _birth,
      blood: _blood,
      aborigine: _aborigine,
      dad: _dad,
      mom: _mom,
      guardian: _dad ? _dad : _mom ? _mom : arg.監護人,
      zip: _zip,
      tel: _tel,
      erTel: _erTel,
      address: _address,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

export type StudentFile = z.infer<typeof StudentFileZod>;

export function transformStudentFileToPrimitive({
  birth,
  ...rest
}: StudentFile): {
  [K in keyof StudentFile]: null | undefined | Primitive;
} {
  return { ...rest, birth: birth?.toISO() ?? '' };
}
