import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from '../../redux/States';
import { Breadcrumb } from '../Breadcrumb';

const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const navLeft: FunctionComponent<Props> = ({ user, loading }) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  return <Breadcrumb />;
};

export const NavLeft = connector(navLeft);
