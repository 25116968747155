import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
    Col,
    Dropdown,
    DropdownButton,
    Row as BSRow,
    Form,
    Tabs,
    Tab,
    Nav,
    Button,
    Table,
    FormCheck,
} from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import apis from './../../../apis';
import { AuthedLayout } from './../../../components';
import { School, SchoolRank, Semester, Student, StudentPHIAll, PHITee, PHIUrine, PHIParasite, PHIPhysical, PHIBlood, PHIXRay, ExaminedResultEnum, TeeInspection, PHISundry } from './../../../model';
import { AuthDispatches, ErrorDispatches } from './../../../redux/Dispatches';
import { ApplicationState } from './../../../redux/States';
import { ClassMapObj } from './../../../types';
import { listToObject } from './../../../utils/transform';
import { SightSection } from '../../StudentPHIs/SightSection';
import { TeeSection } from '../../StudentPHIs/TeeSection';
import { WHSection } from '../../StudentPHIs/WHSection';
import { PHIPhysicalToPageData, PageData, PhysicalSection, invalidStatus } from '../../StudentPHIs/PhysicalSection';
import { BloodSection } from '../../StudentPHIs/BloodSection';
import { XraySection } from '../../StudentPHIs/XraySection';
import { SundrySection, checkKind } from '../../StudentPHIs/SundrySection';
import { useHistory } from 'react-router';
import { Cell, Row, downloadSheets, generateSheet, getGradeMinMax, getPhysicalGradeByRank } from '../../../utils';
import { useCheckClass } from '../../../hook';
import DateTime from 'react-datetime';
import { sightDiag, whbmiDiag } from '../../../utils/sight';
import { I18N } from '../../../i18n-raw';
import { TeeToExaminedEnum } from '../../../utils/tee';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = { ...AuthDispatches, ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const bottomBorderStyle = {
    bottom: borderStyle,
};
const topBottomBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
};
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const inheritDiseaseMap1: { [key: number]: string } = {
    1: '糖尿病',
    2: '血友病',
    5: '蠶豆症',
    31: '高血壓',
    99: '其他',
};
const relativeMap1: { [key: number]: string } = {
    1: '父親',
    2: '母親',
    3: '祖父',
    4: '祖母',
    5: '兄',
    6: '弟',
    7: '姐',
    8: '妹',
    13: '外祖父',
    14: '外祖母',
};
const bodyMindBookNDegree: { [key: string]: string } = {
    1: '輕度',
    2: '中度',
    3: '極重度',
};
const InsuranceMap1: { [key: number]: string } = {
    1: '全民健保',
    2: '學生團保',
    9: '其他',
};

const SeniorGradename: { [key: number]: string } = {
    1: '高一',
    2: '高二',
    3: '高三',
};

const JuniorGradename: { [key: number]: string } = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
};

const exportDivToPDF = async (divId: string) => {
    const div = document.getElementById(divId);

    if (!div) {
        console.error('Div not found');
        return;
    }

    const canvas = await html2canvas(div);
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF({
        orientation: 'p', // Portrait orientation
        unit: 'px',       // Use pixels for units
        format: [canvas.width + 20, canvas.height + 20] // Add some margin for the border
    });

    // Define border size
    const borderSize = 10;

    // Draw a border
    pdf.setLineWidth(2);
    pdf.setDrawColor(0, 0, 0); // Black color for the border
    pdf.rect(borderSize / 2, borderSize / 2, canvas.width + borderSize, canvas.height + borderSize);

    // Add the image with border padding
    pdf.addImage(imgData, 'PNG', borderSize, borderSize, canvas.width, canvas.height);
    pdf.save('學生健康檢查紀錄.pdf');
};

const checkHealthCard: FunctionComponent<Props> = ({
    user,
    loading,
    catchErrorForModal,
}) => {
    // if (loading || !user) {
    //     useHistory().go(0);
    //     return <></>;
    // }
    const { currentSemester = '', semesters = {}, currentRank } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];

    const checksemesters = [{ sem: 2, year, isNow: true }] as Semester[];
    const [student, setStudent] = useState<StudentPHIAll>();
    const [classes, setClasses] = useState<ClassMapObj>({ '': '無年級資料' });
    // const [year, setYear] = useState<number>(0);
    const [classId, setClassId] = useState<string>('');
    const [studentId, setStudentId] = useState<string>('');
    const [classStudents, setClassStudents] = useState<{
        [k: string]: Student;
    }>({});
    // const semesters = [{ sem: 2, year, isNow: true }] as Semester[];
    const physicalGradeRange = getPhysicalGradeByRank(
        user.schools[user.currentSchool].rank ?? SchoolRank.Junior
    );
    const [radioAction, setRadioAction] = useState<boolean>(true);
    const handleRadioChange = (isNowGrade: boolean) => {
        setRadioAction(isNowGrade);
    };
    const { selectedYearGrade, element: selectgradeElement } = useCheckClass({ year: year, isNowGrade: radioAction })
    const [radioGrade, setRadioGrade] = useState<number>(
        physicalGradeRange[physicalGradeRange.length - 1]
    );
    const [school, setSchool] = useState<School>();
    const { maxOffset, min } = useMemo(() => {
        const { min, max } = getGradeMinMax(currentRank);
        return { maxOffset: max - min + 1, min };
    }, []);

    const handleExportClick = () => {
        exportDivToPDF('healthcard');
    };
    useEffect(() => {
        if (selectedYearGrade?.classId && selectedYearGrade?.year) {
            setStudent(undefined);
            apis
                .getClassStudents(selectedYearGrade?.classId, selectedYearGrade?.year, 2)
                .then((students) => {
                    setClassStudents(
                        listToObject(
                            students,
                            (s) => s.pid,
                            (s) => s
                        )
                    );
                })
                .catch(catchErrorForModal);
            apis.getCurrentSchool()
                .then((school) => {
                    setSchool(school);
                })
                .catch(catchErrorForModal);
        }
    }, [selectedYearGrade?.classId, selectedYearGrade?.year]);

    useEffect(refreshStudent, [studentId]);


    function refreshStudent() {
        if (studentId && selectedYearGrade?.year) {
            apis
                .GetStudentWithPHIAll(studentId, selectedYearGrade?.year)
                .then((student) => {
                    setStudent(student);
                })
                .catch(catchErrorForModal);
        }
    }

    const allSightWHYearSem = Array.from(
        new Set([
            ...(student?.wh ? student.wh.map((item) => `${item.year}-${item.sem}`) : []),
            ...(student?.sight ? student.sight.map((item) => `${item.year}-${item.sem}`) : []),
        ])
    ).map((str) => {
        const [year, sem] = str.split('-').map(Number);
        return { year, sem };
    });

    // 根据 year 和 sem 合并数据
    const combinedData = allSightWHYearSem.map(({ year, sem }) => {
        const whItem = student?.wh.find((item) => item.year === year && item.sem === sem);
        const sightItem = student?.sight.find((item) => item.year === year && item.sem === sem);
        return {
            year,
            sem,
            wh: { "height": whItem?.height, "weight": whItem?.weight, "bmiCode": whbmiDiag(whItem?.bmiCode?.toString() ?? '') },
            sight: { "sight0R": sightItem?.sight0R, "sight0L": sightItem?.sight0L, "sightR": sightItem?.sightR, "sightL": sightItem?.sightL, "diag": sightDiag(sightItem) },
        };
    });

    const contentRows: Row[] = whsightToRow(
        combinedData,
        maxOffset,
        classStudents[studentId]?.year ?? 0
    )

    const contentTeethRows: Row[] = student?.tee
        ? teethToRow(student?.tee, maxOffset, classStudents[studentId]?.year ?? 0)
        : [
            {
                cells: [
                    {
                        value: `無資料資料`,
                        merge: { column: 20, row: 1 },
                        style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                    },
                ]
            }
        ]

    const currentClassIdAndYear = serialize(classId, selectedYearGrade?.year ?? 0);

    const whsightcontent: Row[] = [
        {
            cells: [
                {
                    value: `${school?.name ?? ""}學生健康檢查`,
                    merge: { column: 12, row: 1 },
                    style: { alignment: { horizontal: 'center' } },
                },
            ]
        },
        {
            cells: [
                {
                    value: `${selectedYearGrade?.grade ?? ""}年${selectedYearGrade?.classNo ?? ""}班${student?.name ?? ""}`,
                    merge: { column: 6, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `入學年：${classStudents[studentId]?.year ?? ""}`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `性別：${(student?.sex == "1" ? "男" : "女") ?? ""}血型：${classStudents[studentId]?.blood ?? ""}`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
            ]
        },
        {
            cells: [
                {
                    value: `生日：${classStudents[studentId]?.birth?.toFormat('yyyy/MM/dd') ?? ""}`,
                    merge: { column: 6, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `父：${classStudents[studentId]?.dad ?? ""} 母：${classStudents[studentId]?.mom ?? ""}監護人：${classStudents[studentId]?.guardian ?? ""}`,
                    merge: { column: 6, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },

            ]
        },
        {
            cells: [
                {
                    value: `郵遞區號：${classStudents[studentId]?.zip ?? ""}`,
                    merge: { column: 4, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `住址：${classStudents[studentId]?.address ?? ""}`,
                    merge: { column: 8, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },

            ]
        },
        {
            cells: [
                {
                    value: `電話：${classStudents[studentId]?.tel ?? ""}`,
                    merge: { column: 4, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `緊急聯絡：${classStudents[studentId]?.erTel ?? ""}`,
                    merge: { column: 8, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },

            ]
        },
        {
            cells: [
                {
                    value: `個人疾病史`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: student?.disease.length ?
                        (
                            student?.disease.map((d, i) => `${i + 1}.${d.diseaseName}(${d.description})${d.treatment}`).toString()
                        ) : (
                            "無資料"
                        ),
                    merge: { column: 9, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },

            ]
        },
        {
            cells: [
                {
                    value: `家族疾病史`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: student?.inheritDisease.length ?
                        (
                            student?.inheritDisease.map((d, i) => {
                                const inheritDisease = d.inheritDisease === 99 ? `其他(${d?.states})` : inheritDiseaseMap1[d.inheritDisease];
                                return `${i + 1}.${inheritDisease}_${relativeMap1[d.relative]}`;
                            }).toString()
                        ) : (
                            "無資料"
                        ),
                    merge: { column: 9, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },

            ]
        },
        {
            cells: [
                {
                    value: `重大傷病`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    // value: `重大傷病：` + student?.inheritDisease.length ?
                    //     (
                    //         student?.inheritDisease.map((d, i) => {
                    //             const inheritDisease = d.inheritDisease === 99 ? `其他(${d?.states})` : inheritDiseaseMap1[d.inheritDisease];
                    //             return `${i + 1}.${inheritDisease}_${relativeMap1[d.relative]}`;
                    //         }).toString()
                    //     ) : (
                    //         "無"
                    //     ),
                    value: "無資料",
                    merge: { column: 9, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },

            ]
        },
        {
            cells: [
                {
                    value: `重大傷病卡`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: student?.seriousDisease.length ?
                        (
                            student.seriousDisease.map(
                                (d, i) => `${i + 1}.${d.diseaseName}`
                            ).toString()
                        ) : (
                            "無"
                        ),
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `護送醫院`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: student?.hospital.length ?
                        (
                            student.hospital.map((h, i) => `${h.hospitalName}`).toString()
                        ) : (
                            "無"
                        ),
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
            ]
        },
        {
            cells: [
                {
                    value: `身心障礙手冊`,
                    merge: { column: 3, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: student?.bodyMindBookN.length ?
                        (
                            student.bodyMindBookN.map(
                                (d, i) =>
                                    `${i + 1}.${d.bodyMindName}(${bodyMindBookNDegree[d.degree]
                                    }`
                            ).toString()
                        ) : (
                            "無"
                        ),
                    merge: { column: 2, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `立體感`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: student?.sightSolid.length ? "有異狀" : "無異狀",
                    merge: { column: 2, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `保險`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: student?.insurance.length ?
                        (
                            student.insurance.map(
                                (v, i) => {
                                    let studentInsurance = ``;
                                    if (v.insurance === 9) {
                                        studentInsurance = v.states ? `${v.states}\n` : `其他保險\n`;
                                    } else {
                                        studentInsurance = InsuranceMap1[v.insurance];
                                    }
                                    return `${studentInsurance}`;
                                }
                            ).toString()
                        ) : (
                            "無"
                        ),
                    merge: { column: 2, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
            ]
        },
        {
            cells: [
                {
                    value: `年級`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `學期`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `身高`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `體重`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `評價`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `年級`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `學期`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `裸視右`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `裸視左`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `戴鏡右`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `戴鏡左`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
                {
                    value: `診斷`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'center' } },
                },
            ]
        },
        ...contentRows,
    ];

    const teethcontent: Row[] = [
        {
            cells: [
                {
                    value: `年級`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `學期`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `未治療齲齒`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `已治療齲齒`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `UP`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `DN`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `窩溝封填`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `口腔衛生不良`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `咬合不正`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `牙結石`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `牙齦炎`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `口腔黏膜異常`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `牙周病`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `乳牙待拔牙`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `待拔牙`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `贅生牙`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `缺牙`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `阻生牙`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `其他`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
                {
                    value: `陳述`,
                    merge: { column: 1, row: 1 },
                    style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
                },
            ]
        },
        // ...contentTeethRows
    ];

    const parasiteurinecontent: Row[] = parasiteurineToRow(
        student?.parasite.filter((s) => s.inspect == 1 && s.sem === 1)[0],
        student?.parasite.filter((s) => s.inspect == 2 && s.sem === 1)[0],
        student?.urine.filter((s) => s.inspect == 1 && s.sem === 1)[0],
        student?.urine.filter((s) => s.inspect == 2 && s.sem === 1)[0]
    )

    const physicalcontent: Row[] = physicalToRow(
        student?.physical.filter((s) => s.sem === 1)[0]
    )

    const bloodcontent: Row[] = bloodToRow(
        student?.blood.filter((s) => s.sem === 1)[0]
    )

    const xRaycontent: Row[] = xRayToRow(
        student?.xRay.filter((s) => s.sem === 1)[0]
    )

    const sundrycontent: Row[] = sundryToRow(
        student?.sundry
    )

    return (
        <AuthedLayout
            onLoaded={() => {
                apis
                    .getGraduatedClasses()
                    .then((classes) => {
                        if (classes.length)
                            setClasses(
                                listToObject(
                                    classes,
                                    (c) => serialize(c.id, c.year),
                                    (c) => c.name
                                )
                            );
                        setClassId('');
                        // setYear(0);
                    })
                    .catch(catchErrorForModal);
            }}
        >
            <BSRow className="mb-2 justify-content-start">
                <Col className='d-flex mr-3' xs={3} >
                    <FormCheck
                        className="mr-2"
                        value={'在學'}
                        type="radio"
                        label={`在學`}
                        name="list-action"
                        checked={radioAction === true}
                        onChange={() => handleRadioChange(true)}
                    />
                    <FormCheck
                        className="mr-2"
                        value={`歷屆年級`}
                        type="radio"
                        label={`歷屆年級`}
                        name="list-action"
                        checked={radioAction === false}
                        onChange={() => handleRadioChange(false)}
                    />
                </Col>
                <Col sm={2}>
                    {selectgradeElement}
                </Col>
                <Col sm={2}>
                    <DropdownButton
                        disabled={!selectedYearGrade?.classId}
                        className="mr-2"
                        title={
                            (studentId &&
                                classStudents[studentId] &&
                                `${classStudents[studentId].seat} ${classStudents[studentId].name}`) ||
                            '請選擇學生'
                        }
                        onSelect={(k) => {
                            if (k && classStudents[k]) {
                                setStudentId(k);
                            }
                        }}
                    >
                        {Object.entries(classStudents).map(([k, n]) => (
                            <Dropdown.Item key={`student-${k}`} eventKey={k}>
                                {n.seat} {n.name}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col sm={2}>
                    <Button
                        disabled={!student}
                        variant="success"
                        className="text-dark mr-3"
                        onClick={handleExportClick}>
                        PDF 下載
                    </Button>
                </Col>
            </BSRow>
            <hr />
            <div id='healthcard'>
                <BSRow>
                    <Table
                        striped
                        bordered
                        className="text-center"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {whsightcontent.map((r, i) => {
                                const cells = r.cells.map((c, j) =>
                                    c.value != undefined ? (
                                        <td
                                            colSpan={c.merge?.column}
                                            rowSpan={c.merge?.row}
                                            key={`${i}-${j}`}
                                        >
                                            {c.value}
                                        </td>
                                    ) : (
                                        <Fragment key={`${i}-${j}`}></Fragment>
                                    )
                                );
                                return <tr key={i}>{cells}</tr>;
                            })}
                        </tbody>
                    </Table>
                </BSRow>
                <hr />
                <BSRow>
                    <Table
                        striped
                        bordered
                        className="text-center"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {teethcontent.concat(contentTeethRows).map((r, i) => {
                                const cells = r.cells.map((c, j) =>
                                    c.value != undefined ? (
                                        <td
                                            colSpan={c.merge?.column}
                                            rowSpan={c.merge?.row}
                                            key={`${i}-${j}`}
                                        >
                                            {c.value}
                                        </td>
                                    ) : (
                                        <Fragment key={`${i}-${j}`}></Fragment>
                                    )
                                );
                                return <tr key={i}>{cells}</tr>;
                            })}
                        </tbody>
                    </Table>
                </BSRow>
                <BSRow noGutters>欄位說明</BSRow>
                <BSRow noGutters>UP:上顎恆牙第一大臼齒齲齒經驗  DN:下顎恆牙第一大臼齒齲齒經驗</BSRow>
                <hr />
                <div className="sheet-title">{(maxOffset == 6 ? "四年級" : "高一")}實驗室檢查</div>
                <BSRow>
                    <Table
                        striped
                        bordered
                        className="text-center"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {parasiteurinecontent.map((r, i) => {
                                const cells = r.cells.map((c, j) =>
                                    c.value != undefined ? (
                                        <td
                                            colSpan={c.merge?.column}
                                            rowSpan={c.merge?.row}
                                            key={`${i}-${j}`}
                                        >
                                            {c.value}
                                        </td>
                                    ) : (
                                        <Fragment key={`${i}-${j}`}></Fragment>
                                    )
                                );
                                return <tr key={i}>{cells}</tr>;
                            })}
                        </tbody>
                    </Table>
                </BSRow>
                <hr />
                <div className="sheet-title">■■■ 高一 全身健康檢查 (0 無異狀,1 初檢異常,2 複診正常,3 複診異常)</div>
                <BSRow>
                    <Table
                        striped
                        bordered
                        className="text-center"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {physicalcontent.map((r, i) => {
                                const cells = r.cells.map((c, j) =>
                                    c.value != undefined ? (
                                        <td
                                            colSpan={c.merge?.column}
                                            rowSpan={c.merge?.row}
                                            key={`${i}-${j}`}
                                        >
                                            {c.value}
                                        </td>
                                    ) : (
                                        <Fragment key={`${i}-${j}`}></Fragment>
                                    )
                                );
                                return <tr key={i}>{cells}</tr>;
                            })}
                        </tbody>
                    </Table>
                </BSRow>
                <hr />
                <div className="sheet-title">■■■ 高一 血液檢查</div>
                <BSRow>
                    <Table
                        striped
                        bordered
                        className="text-center"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {bloodcontent.map((r, i) => {
                                const cells = r.cells.map((c, j) =>
                                    c.value != undefined ? (
                                        <td
                                            colSpan={c.merge?.column}
                                            rowSpan={c.merge?.row}
                                            key={`${i}-${j}`}
                                        >
                                            {c.value}
                                        </td>
                                    ) : (
                                        <Fragment key={`${i}-${j}`}></Fragment>
                                    )
                                );
                                return <tr key={i}>{cells}</tr>;
                            })}
                        </tbody>
                    </Table>
                </BSRow>
                <hr />
                <div className="sheet-title">■■■ 高一 X光檢查 (0 無異狀,1 初檢異常,2 複診正常,3 複診異常)</div>
                <BSRow>
                    <Table
                        striped
                        bordered
                        className="text-center"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {xRaycontent.map((r, i) => {
                                const cells = r.cells.map((c, j) =>
                                    c.value != undefined ? (
                                        <td
                                            colSpan={c.merge?.column}
                                            rowSpan={c.merge?.row}
                                            key={`${i}-${j}`}
                                        >
                                            {c.value}
                                        </td>
                                    ) : (
                                        <Fragment key={`${i}-${j}`}></Fragment>
                                    )
                                );
                                return <tr key={i}>{cells}</tr>;
                            })}
                        </tbody>
                    </Table>
                </BSRow>
                <hr />
                <div className="sheet-title">臨時性檢查</div>
                <BSRow>
                    <Table
                        striped
                        bordered
                        className="text-center"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                            {sundrycontent.map((r, i) => {
                                const cells = r.cells.map((c, j) =>
                                    c.value != undefined ? (
                                        <td
                                            colSpan={c.merge?.column}
                                            rowSpan={c.merge?.row}
                                            key={`${i}-${j}`}
                                        >
                                            {c.value}
                                        </td>
                                    ) : (
                                        <Fragment key={`${i}-${j}`}></Fragment>
                                    )
                                );
                                return <tr key={i}>{cells}</tr>;
                            })}
                        </tbody>
                    </Table>
                </BSRow>
            </div>
        </AuthedLayout>
    );
};

function whsightToRow(
    data: {
        year: number;
        sem: number;
        wh: {
            height: number | null | undefined;
            weight: number | null | undefined;
            bmiCode: string;
        };
        sight: {
            sight0R: number | null | undefined;
            sight0L: number | null | undefined;
            sightR: number | null | undefined;
            sightL: number | null | undefined;
            diag: string;
        };
    }[],
    maxOffset: number,
    studentyear: number
): Row[] {
    let returnrows: Row[] = [];
    if (maxOffset == 6) {
        returnrows = [
            createRow("一", "1", studentyear, data),
            createRow("一", "2", studentyear, data),
            createRow("二", "1", studentyear + 1, data),
            createRow("二", "2", studentyear + 1, data),
            createRow("三", "1", studentyear + 2, data),
            createRow("三", "2", studentyear + 2, data),
            createRow("四", "1", studentyear + 3, data),
            createRow("四", "2", studentyear + 3, data),
            createRow("五", "1", studentyear + 4, data),
            createRow("五", "2", studentyear + 4, data),
            createRow("六", "1", studentyear + 5, data),
            createRow("六", "2", studentyear + 5, data)
        ]
    } else {
        returnrows = [
            createRow("高一", "1", studentyear, data),
            createRow("高一", "2", studentyear, data),
            createRow("高二", "1", studentyear + 1, data),
            createRow("高二", "2", studentyear + 1, data),
            createRow("高三", "1", studentyear + 2, data),
            createRow("高三", "2", studentyear + 2, data),]
    }
    return returnrows;
}

function teethToRow(
    data: PHITee[],
    maxOffset: number,
    studentyear: number
): Row[] {
    const returnrows = data?.flatMap(
        (gs, idx, allArray) => {
            return [createTeethRow(gs.sem, studentyear, gs, true, maxOffset)]
        })
    return returnrows;
}

function createCell(value: number | string, bottomBorder = true) {
    return {
        value,
        style: bottomBorder ? { border: bottomBorderStyle } : {}
    };
}

function parasiteurineToRow(
    firstparasite: PHIParasite | undefined,
    secondparasite: PHIParasite | undefined,
    firsturine: PHIUrine | undefined,
    secondurine: PHIUrine | undefined,
): Row[] {
    const returnrows = [
        {
            cells: [
                createCell("寄生蟲子"),
                (firstparasite === undefined) ? createCell("尚未受檢") :
                    createCell((firstparasite?.parasite == 9) ? "尚未受檢" : `初/複查結果：${firstparasite?.parasite} 初查日期：${firstparasite?.examDate ? firstparasite?.examDate.toFormat('yyyy/MM/dd') : ''}  ${firstparasite?.drug ? '已服藥' : '未服藥'}` + ((secondparasite != undefined && firstparasite?.parasite !== 0 && firstparasite != undefined) ? ` 複查日期:${secondparasite?.examDate?.toFormat('yyyy/MM/dd')}` : ""))
            ]
        },
        {
            cells: [
                createCell("尿液"),
                (firsturine === undefined) ? createCell("尚未受檢") :
                    createCell((firsturine?.urine == 9) ? "尚未受檢" :
                        `初/複查結果：${firsturine?.urine} 初查日期：${firsturine?.examDate ? firsturine.examDate.toFormat('yyyy/MM/dd') : ''}  尿蛋白：${firsturine?.uProtein} 潛血：${firsturine?.uob} 尿糖：${firsturine?.uGlucose} 酸鹼度：${firsturine?.uPh}`)
            ]
        }
    ]
    return returnrows;
}

function bloodToRow(
    first: PHIBlood | undefined,
): Row[] {

    const returnrows = (first === undefined) ?
        [{
            cells: [
                createCell("受檢"),
                createCell("尚未受檢")]
        }] : [
            {
                cells: [
                    createCell("受檢"),
                    createCell(serializeBlood(first).join(","))
                ]
            }

        ]
    return returnrows;
}

function xRayToRow(
    first: PHIXRay | undefined,
): Row[] {

    const returnrows = (first === undefined) ?
        [{
            cells: [
                createCell("受檢"),
                createCell("尚未受檢")]
        }] : [
            {
                cells: [
                    createCell("受檢"),
                    createCell(serializeXRay(first).join(","))
                ]
            }

        ]
    return returnrows;
}

function sundryToRow(
    data: PHISundry[] | undefined,
): Row[] {
    const returnrows = (data === undefined) ?
        [{
            cells: [
                createCell("受檢"),
                createCell("尚未受檢")]
        }] :
        data?.flatMap(
            (gs, idx, allArray) => {
                return [{
                    cells: [
                        createCell(checkKind[gs.sundryId - 1].Checks),
                        createCell(gs.sundryResult == 0 ? '-' : '+ ' + gs.followUpDescription),
                    ]
                }]
            })
    return returnrows;
}
function physicalToRow(
    first: PHIPhysical | undefined,
): Row[] {
    const returnrows = (first === undefined) ?
        [{
            cells: [
                createCell("學期/日期"),
                createCell("尚未受檢")]
        }] : [
            {
                cells: [
                    createCell("學期/日期"),
                    createCell(`第${first.sem}學期 ${first?.examDate?.toFormat('yyyy/MM/dd')}`)
                ]
            },
            ...generatePhysicalSummarize(PHIPhysicalToPageData(first))
        ]
    return returnrows;
}

interface Record {
    wh?: { [key: string]: any };
    sight?: { [key: string]: any };
}

function createRow(grade: string, semester: string, studentyear: number, data: {
    year: number;
    sem: number;
    wh: {
        height: number | null | undefined;
        weight: number | null | undefined;
        bmiCode: string;
    };
    sight: {
        sight0R: number | null | undefined;
        sight0L: number | null | undefined;
        sightR: number | null | undefined;
        sightL: number | null | undefined;
        diag: string;
    };
}[], bottomBorder = true) {
    let record: Record = {};
    record = data.find((x) => x.year == studentyear && x.sem == Number(semester)) || {};
    const wh = record?.wh || {};
    const sight = record?.sight || {};

    return {
        cells: [
            createCell(grade, bottomBorder),
            createCell(semester, bottomBorder),
            createCell(wh.height ?? "", bottomBorder),
            createCell(wh.weight ?? "", bottomBorder),
            createCell(wh.bmiCode ?? "", bottomBorder),
            createCell(grade, bottomBorder),
            createCell(semester, bottomBorder),
            createCell(sight.sight0R ?? "", bottomBorder),
            createCell(sight.sight0L ?? "", bottomBorder),
            createCell(sight.sightR ?? "", bottomBorder),
            createCell(sight.sightL ?? "", bottomBorder),
            createCell(sight.diag ?? "", bottomBorder),
        ]
    };
}

function createTeethRow(semester: number, studentyear: number, data: PHITee, bottomBorder = true, maxOffset: number) {
    return {
        cells: [
            createCell((maxOffset == 6 ? JuniorGradename[Number(data?.year) - studentyear + 1] : SeniorGradename[Number(data?.year) - studentyear + 1]), bottomBorder),
            createCell(semester, bottomBorder),
            createCell(data?.t01 ?? "", bottomBorder),
            createCell(data?.t11 ?? "", bottomBorder),
            createCell(data?.t12 ?? "", bottomBorder),
            createCell(data?.t13 ?? "", bottomBorder),
            createCell(data?.t15 ?? "", bottomBorder),
            createCell(data?.t04 ?? "", bottomBorder),
            createCell(data?.t05 ?? "", bottomBorder),
            createCell(data?.t08 ?? "", bottomBorder),
            createCell(data?.t07 ?? "", bottomBorder),
            createCell(data?.t09 ?? "", bottomBorder),
            createCell(data?.t16 ?? "", bottomBorder),
            createCell(data?.t17 ?? "", bottomBorder),
            createCell(data?.t03 ?? "", bottomBorder),
            createCell(data?.t18 ?? "", bottomBorder),
            createCell(data?.t02 ?? "", bottomBorder),
            createCell(data?.t19 ?? "", bottomBorder),
            createCell(data?.t99 ?? "", bottomBorder),
            createCell(data?.t99state ?? "", bottomBorder),
        ]
    };
}

const serialize = (classId: string, year: number): string =>
    `${classId}:${year}`;

const deserialize = (str: string): [string, number] => {
    const splited = str.split(':');
    return [splited[0], +splited[1]];
};


// 全身健康檢查
function generatePhysicalSummarize(pageData: PageData) {
    const rows: Row[] = [];

    const addRowIfNeeded = (category: string, cells: string[]) => {
        if (cells.length > 0) {
            rows.push({
                cells: [
                    createCell(category),
                    createCell(cells.join(''))
                ],
            });
        }
    };
    // Eye
    if (invalidStatus(pageData.colorBlind) ||
        invalidStatus(pageData.strabismus) ||
        invalidStatus(pageData.trichiasis) ||
        invalidStatus(pageData.nystagmus) ||
        invalidStatus(pageData.blepharoptosis) ||
        invalidStatus(pageData.e99)) {
        const eyeCells = [
            generateUnhealthy(pageData.colorBlind, 'colorBlind'),
            generateUnhealthy(pageData.strabismus, 'strabismus', I18N.StrabismusResult[pageData.strabismusType]),
            generateUnhealthy(pageData.trichiasis, 'trichiasis'),
            generateUnhealthy(pageData.nystagmus, 'nystagmus'),
            generateUnhealthy(pageData.blepharoptosis, 'blepharoptosis'),
            generateUnhealthy(pageData.e99, 'e99', pageData.e99State),
        ];
        addRowIfNeeded('眼', eyeCells);
    }

    // Ear, Nose, Throat
    if (invalidStatus(pageData.hearing) ||
        invalidStatus(pageData.eardrum) ||
        invalidStatus(pageData.earMisshapen) ||
        invalidStatus(pageData.clp) ||
        invalidStatus(pageData.articulationDisorders) ||
        invalidStatus(pageData.preauricularFistula) ||
        invalidStatus(pageData.cerumen) ||
        invalidStatus(pageData.rhinitis) ||
        invalidStatus(pageData.allergicRhinitis) ||
        invalidStatus(pageData.tonsillitis) ||
        invalidStatus(pageData.o99)) {
        const entCells = [
            generateUnhealthy(pageData.hearing, 'hearing', I18N.LeftRightResult[pageData.hearingLR]),
            generateUnhealthy(pageData.eardrum, 'eardrum', I18N.LeftRightResult[pageData.eardrumLR]),
            generateUnhealthy(pageData.earMisshapen, 'earMisshapen', I18N.LeftRightResult[pageData.earMisshapenLR]),
            generateUnhealthy(pageData.clp, 'clp'),
            generateUnhealthy(pageData.articulationDisorders, 'articulationDisorders'),
            generateUnhealthy(pageData.preauricularFistula, 'preauricularFistula', I18N.LeftRightResult[pageData.preauricularFistulaLR]),
            generateUnhealthy(pageData.cerumen, 'cerumen', I18N.LeftRightResult[pageData.cerumenLR]),
            generateUnhealthy(pageData.rhinitis, 'rhinitis'),
            generateUnhealthy(pageData.allergicRhinitis, 'allergicRhinitis'),
            generateUnhealthy(pageData.o99, 'o99', pageData.o99State),
        ];
        addRowIfNeeded('耳鼻喉', entCells);
    }

    // Neck
    if (invalidStatus(pageData.torticollis) ||
        invalidStatus(pageData.mass) ||
        invalidStatus(pageData.goiter) ||
        invalidStatus(pageData.lymphadenectasis) ||
        invalidStatus(pageData.mass99) ||
        invalidStatus(pageData.n99)) {
        const neckCells = [
            generateUnhealthy(pageData.torticollis, 'torticollis'),
            generateUnhealthy(pageData.mass, 'mass'),
            generateUnhealthy(pageData.goiter, 'goiter'),
            generateUnhealthy(pageData.lymphadenectasis, 'lymphadenectasis'),
            generateUnhealthy(pageData.mass99, 'mass99'),
            generateUnhealthy(pageData.n99, 'n99', pageData.n99State),
        ];
        addRowIfNeeded('頭頸', neckCells);
    }

    // Chest

    if (invalidStatus(pageData.cardiopulmonary) ||
        invalidStatus(pageData.heartMurmur) ||
        invalidStatus(pageData.arrhythmia) ||
        invalidStatus(pageData.wheeze) ||
        invalidStatus(pageData.cardiopulmonary99) ||
        invalidStatus(pageData.thorax) ||
        invalidStatus(pageData.c99)) {
        const chestCells = [
            generateUnhealthy(pageData.cardiopulmonary, 'cardiopulmonary'),
            generateUnhealthy(pageData.heartMurmur, 'heartMurmur'),
            generateUnhealthy(pageData.arrhythmia, 'arrhythmia'),
            generateUnhealthy(pageData.wheeze, 'wheeze'),
            generateUnhealthy(pageData.cardiopulmonary99, 'cardiopulmonary99'),
            generateUnhealthy(pageData.thorax, 'thorax'),
            generateUnhealthy(pageData.c99, 'c99', pageData.c99State),
        ];
        addRowIfNeeded('胸部', chestCells);
    }

    // Abdomen
    if (invalidStatus(pageData.abdomen) ||
        invalidStatus(pageData.hernia) ||
        invalidStatus(pageData.a99)) {
        const abdomenCells = [
            generateUnhealthy(pageData.abdomen, 'abdomen'),
            generateUnhealthy(pageData.hernia, 'hernia'),
            generateUnhealthy(pageData.a99, 'a99', pageData.a99State),
        ];
        addRowIfNeeded('腹部', abdomenCells);
    }

    // Spine, Limbs
    if (invalidStatus(pageData.scoliosis) ||
        invalidStatus(pageData.dysmelia) ||
        invalidStatus(pageData.polydactyly) ||
        invalidStatus(pageData.dysarthrosis) ||
        invalidStatus(pageData.dysmelia99) ||
        invalidStatus(pageData.squatting) ||
        invalidStatus(pageData.edema) ||
        invalidStatus(pageData.l99)) {
        const spineCells = [
            generateUnhealthy(pageData.scoliosis, 'scoliosis'),
            generateUnhealthy(pageData.dysmelia, 'dysmelia'),
            generateUnhealthy(pageData.polydactyly, 'polydactyly'),
            generateUnhealthy(pageData.dysarthrosis, 'dysarthrosis'),
            generateUnhealthy(pageData.dysmelia99, 'dysmelia99'),
            generateUnhealthy(pageData.squatting, 'squatting'),
            generateUnhealthy(pageData.edema, 'edema'),
            generateUnhealthy(pageData.l99, 'l99', pageData.l99State),
        ];
        addRowIfNeeded('脊柱四肢', spineCells);
    }

    // Urogenital
    if (invalidStatus(pageData.cryptorchidism) ||
        invalidStatus(pageData.scrotalSwelling) ||
        invalidStatus(pageData.prepuce) ||
        invalidStatus(pageData.varicocele) ||
        invalidStatus(pageData.u99)) {
        const urogenitalCells = [
            generateUnhealthy(pageData.cryptorchidism, 'cryptorchidism'),
            generateUnhealthy(pageData.scrotalSwelling, 'scrotalSwelling'),
            generateUnhealthy(pageData.prepuce, 'prepuce'),
            generateUnhealthy(pageData.varicocele, 'varicocele'),
            generateUnhealthy(pageData.u99, 'u99', pageData.u99State),
        ];
        addRowIfNeeded('泌尿生殖', urogenitalCells);
    }

    // Skin
    if (invalidStatus(pageData.epidermophytosis) ||
        invalidStatus(pageData.wart) ||
        invalidStatus(pageData.purpura) ||
        invalidStatus(pageData.scabies) ||
        invalidStatus(pageData.eczema) ||
        invalidStatus(pageData.atopicDermatitis) ||
        invalidStatus(pageData.d99)) {
        const skinCells = [
            generateUnhealthy(pageData.epidermophytosis, 'epidermophytosis'),
            generateUnhealthy(pageData.wart, 'wart'),
            generateUnhealthy(pageData.purpura, 'purpura'),
            generateUnhealthy(pageData.scabies, 'scabies'),
            generateUnhealthy(pageData.eczema, 'eczema'),
            generateUnhealthy(pageData.atopicDermatitis, 'atopicDermatitis'),
            generateUnhealthy(pageData.d99, 'd99', pageData.d99State),
        ];
        addRowIfNeeded('皮膚', skinCells);
    }

    // Oral
    if (invalidStatus(TeeToExaminedEnum(pageData.t01, pageData.ret01)) ||
        invalidStatus(pageData.t11) ||
        invalidStatus(pageData.t12) ||
        invalidStatus(pageData.t13) ||
        invalidStatus(pageData.t15) ||
        invalidStatus(TeeToExaminedEnum(pageData.t04, pageData.ret04)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t05, pageData.ret05)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t08, pageData.ret08)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t07, pageData.ret07)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t09, pageData.ret09)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t16, pageData.ret16)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t17, pageData.ret17)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t03, pageData.ret03)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t18, pageData.ret18)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t02, pageData.ret02)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t19, pageData.ret19)) ||
        invalidStatus(TeeToExaminedEnum(pageData.t99, pageData.ret99))) {
        const oralCells = [
            generateUnhealthy(TeeToExaminedEnum(pageData.t01, pageData.ret01), 't01'),
            generateUnhealthy(pageData.t11, 't11'),
            generateUnhealthy(pageData.t12, 't12'),
            generateUnhealthy(pageData.t13, 't13'),
            generateUnhealthy(pageData.t15, 't15'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t04, pageData.ret04), 't04'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t05, pageData.ret05), 't05'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t08, pageData.ret08), 't08'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t07, pageData.ret07), 't07'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t09, pageData.ret09), 't09'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t16, pageData.ret16), 't16'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t17, pageData.ret17), 't17'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t03, pageData.ret03), 't03'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t18, pageData.ret18), 't18'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t02, pageData.ret02), 't02'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t19, pageData.ret19), 't19'),
            generateUnhealthy(TeeToExaminedEnum(pageData.t99, pageData.ret99), 't99', pageData.t99state),
        ];
        addRowIfNeeded('口腔', oralCells);
    }

    return rows;
}

function generateUnhealthy(
    data: ExaminedResultEnum | TeeInspection,
    i18nKey: string,
    subType: string | null | undefined = ''
): string {
    return invalidStatus(data) ? (
        `*${I18N.Physical[i18nKey]}：${data} ${subType ? `-${subType}` : ''}`
    ) : (
        ''
    );
}

// 血液檢查
function serializeBlood(blood?: PHIBlood): string[] {
    if (!blood) return [];

    const nodes = Object.entries(I18N.Blood).reduce((nodes, [key, display]) => {
        const result = (blood as any)[key];
        if (invalidStatus(result)) {
            nodes.push(
                `*${display}：${result}`);
        }

        return nodes;
    }, [] as string[]);

    if (blood.bloodCheckMemo) {
        nodes.push(`*$備註：${blood.bloodCheckMemo}`);
    }

    return nodes;
}

function serializeXRay(xRay?: PHIXRay): string[] {
    if (!xRay) return [];

    const nodes = Object.entries(I18N.XRay).reduce((nodes, [key, display]) => {
        const result = (xRay as any)[key];
        if (invalidStatus(result)) {
            nodes.push(`*${display}：${result}`);
        }

        return nodes;
    }, [] as string[]);

    if (invalidStatus(xRay.x99)) {
        nodes.push(`*其他：${xRay.x99}`);
        nodes.push(`*其他陳述：${xRay.x99State}`);
    }
    if (xRay.memos) {
        nodes.push(`*備註：${xRay.memos}`);
    }

    return nodes;
}



export const CheckHealthCard = connector(checkHealthCard);
