import React, { FunctionComponent } from 'react';
import { ListGroup } from 'react-bootstrap';

import { MenuItem } from '../../model';
import { SideMenuBadge } from './SideMenuBadge';
import { SideMenuIcon } from './SideMenuIcon';
import { EnchanceLink } from '../EnhanceLink';

type Props = { item: MenuItem };

export const SideMenuItem: FunctionComponent<Props> = ({ item }) => {
  let content = <span>{item.title}</span>;
  const className = ['nav-link'];
  if (item.icon) {
    content = <span className="pcoded-mtext">{item.title}</span>;
  }

  const isThisCollapse =
    item.url && document.location.pathname.endsWith(`${item.url}`);
  if (isThisCollapse) {
    className.push('active');
  }

  return (
    <React.Fragment>
      <ListGroup.Item as="li" bsPrefix=" ">
        <EnchanceLink to={item.url ?? '#'} className={className.join(' ')}>
          <SideMenuIcon items={item} />
          {content}
          <SideMenuBadge items={item} />
        </EnchanceLink>
      </ListGroup.Item>
    </React.Fragment>
  );
};
