import React, { FunctionComponent } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { ErrorActionEnum } from '../apis';
import { useHistory } from 'react-router';

const mapState = (state: ApplicationState) => ({
  ...state.error,
  ...state.auth,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const errorModal: FunctionComponent<Props> = ({
  errorMessage,
  errorTitle,
  errorShow,
  errorAction,
  hideError,
  kickUser,
  isAuth,
}) => {
  if (!isAuth && errorAction === ErrorActionEnum.AC_REDIRECT_TO_LOGIN_IN) {
    hideError();
    return <></>;
  }
  const history = useHistory();

  function onHide() {
    switch (errorAction) {
      case ErrorActionEnum.AC_REDIRECT_TO_LOGIN_IN: {
        const qs = new URLSearchParams();
        qs.append('lastPath', `${history.location.pathname}`);
        history.push(`/login?${qs.toString()}`);
        kickUser();
        break;
      }
      case ErrorActionEnum.AC_GO_TO_LAST_PAGE:
        history.goBack();
        hideError();
        break;
      default:
        hideError();
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={errorShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            {errorTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>關閉</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export const ErrorModal = connector(errorModal);
