import { z } from 'zod';
import { 
    PHISundryZod,
    SundryExamDateZod,
    SundryExamDate,
    StudentGradeNoSeatZod,
    PHISundryGroupByGradeClass, 
    PHISundryGroupByGradeClassZod
} from '../model';
import { get, put, post } from './BaseApi';
import { DateTime } from 'luxon';

const PHISundryGroupByGradeClassArr = z.array(PHISundryGroupByGradeClassZod);
const SundryExamDateArr = z.array(SundryExamDateZod);
const PHISundryListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      sundry: PHISundryZod,
    })
  )
);
type PHISundry = z.infer<typeof PHISundryListZod>;

export async function insertCheckTemp(
  grade: number,
  year: number,
  sem: number,
  agencies: string,
  sundryId: number,
  examDate: string,
): Promise<void> {
  await put(`/api/CheckTemp/insert/list`, {
    grade,
    year,
    sem,
    agencies,
    sundryId,
    examDate,
  })
}
  
export async function getSundryGroupByGradeClass(
  year: number,
  sem: number,
): Promise<PHISundryGroupByGradeClass[]> {
  return await get(`/api/CheckTemp/sundry/grade/class`, PHISundryGroupByGradeClassArr, {
    year,
    sem,
  })
}

export async function getSundryList(
  year: number,
  sem: number,
  sundryId: number,
  examDate: string,
  action: string
): Promise<PHISundry> {
  return await get(`/api/CheckTemp/sundry/checktemplist`, PHISundryListZod, {
    year,
    sem,
    sundryId,
    examDate,
    action
  })
}

export async function deleteCheckTemp(
  grade: number,
  year: number,
  sem: number,
  agencies: string,
  sundryId: number,
  examDate: DateTime | null,
  ids: number[]
): Promise<void> {
  await put(`/api/CheckTemp/delete`, {
    grade,
    year,
    sem,
    agencies,
    sundryId,
    examDate,
    ids
  })
}

export async function getSundryExamDateBySundryId(
  year: number,
  sem: number,
  sundryId: number
): Promise<SundryExamDate[]> {
  return await get(`/api/CheckTemp/sundry/checktemplist/${sundryId}`, SundryExamDateArr, {
    year,
    sem
  })
}

export async function updateStudentSundry(
  year: number,
  sem: number,
  id: number,
  grade: number,
  sundryId: number,
  agencies: string,
  sundryResult: number,
  yearClassId: number,
  examDate: string,
  followUpDescription: string,
  remark: string,
): Promise<void> {
  return await put(`/api/CheckTemp/sundry/student/update`, {
    year,
    sem,
    id,
    grade,
    sundryId,
    agencies,
    sundryResult,
    yearClassId,
    examDate,
    followUpDescription,
    remark,
  })
}

export async function deleteStudentSundry(
  year: number,
  sem: number,
  id: number,
  grade: number,
  sundryId: number,
  agencies: string,
  sundryResult: number,
  yearClassId: number,
  examDate: string,
  followUpDescription: string,
  remark: string,
): Promise<void> {
  return await put(`/api/CheckTemp/sundry/student/delete`, {
    year,
    sem,
    id,
    grade,
    sundryId,
    agencies,
    sundryResult,
    yearClassId,
    examDate,
    followUpDescription,
    remark,
  })
}

export async function insertStudentSundry(
  year: number,
  sem: number,
  sundryId: number,
  agencies: string,
  sundryResult: number,
  examDate: string,
  followUpDescription: string,
  remark: string,
  yearClassId: number,
): Promise<void> {
  await post(`/api/CheckTemp/sundry/student/insert`, {
    year,
    sem,
    sundryId,
    agencies,
    sundryResult,
    examDate,
    followUpDescription,
    remark,
    yearClassId,
  })
}
  
  
