import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { MenuItem } from '../model';

import { ApplicationState } from '../redux/States';
import { EnchanceLink } from './EnhanceLink';

type MenuItemWithParent = { parent?: MenuItem } & MenuItem;

const mapState = (state: ApplicationState) => state.auth;
const connector = connect(mapState);
type Prop = ConnectedProps<typeof connector>;

const breadcrumb: FunctionComponent<Prop> = ({ user }) => {
  if (user == undefined) {
    console.log('breadcrumb user is undefined');
    return <React.Fragment>breadcrumb user is undefined</React.Fragment>;
  }

  function searchPath(menu: MenuItem): MenuItem[] {
    if (document.location.pathname === menu.url) {
      return [menu];
    }
    if (menu.children) {
      for (let i = 0; i < menu.children.length; i++) {
        const response = searchPath(menu.children[i]);
        if (response.length)
          return [{ ...menu, children: undefined }, ...response];
      }
    }
    return [];
  }

  const menuCrumb = useMemo(() => {
    const r = searchPath(user.menus[0]);
    r.shift();
    if (!r.length) {
      r.push({ title: '主頁', type: 'item', id: '' });
    }
    return r;
  }, [user]);

  let title = '';

  const content = menuCrumb.map((m, i) => {
    title = m.title;
    return (
      <ListGroup.Item key={i} as="li" bsPrefix=" " className="breadcrumb-item">
        <span>{m.title}</span>
      </ListGroup.Item>
    );
  });

  document.title = title;
  return (
    <React.Fragment>
      <div className="align-items-center px-4">
        <div className="page-header-title mb-2">
          <h5>{title}</h5>
        </div>
        <ListGroup as="ul" bsPrefix=" " className="breadcrumb mb-0">
          <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
            <EnchanceLink to="/">
              <span className="feather icon-home" />
            </EnchanceLink>
          </ListGroup.Item>
          {content}
        </ListGroup>
      </div>
    </React.Fragment>
  );
};

export const Breadcrumb = connector(breadcrumb);
