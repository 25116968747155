import React, { ReactNode } from 'react';
import Chart from 'react-apexcharts';

export interface DonutChartOptions {
  chart: {
    type: string;
  };
  labels: string[];
  fill: {
    type: string;
    gradient: {
      shade: string;
      type: string;
      shadeIntensity: number;
      inverseColors: boolean;
      opacityFrom: number;
      opacityTo: number;
      stops: number[];
    };
  };
  plotOptions: {
    pie: {
      donut: {
        size: string;
        labels: {
          show: boolean;
          name: {
            show: boolean;
            fontSize: string;
          };
          value: {
            show: boolean;
            fontSize: string;
            formatter: (val: number) => string;
          };
          total: {
            show: boolean;
            showAlways: boolean;
            label: string;
            formatter: (w: any) => number;
          };
        };
      };
    };
  };
  responsive: Array<{
    breakpoint: number;
    options: {
      chart: {
        width: number;
      };
      legend: {
        position: string;
      };
    };
  }>;
  legend: {
    formatter: (val: string, opts: any) => string;
  };
}

export interface DonutChartState {
  options: DonutChartOptions;
  series: number[];
}

export const useApexDonutChart = ({
  options,
  series,
}: DonutChartState): {
  element: ReactNode;
} => {
  return {
    element: (
      // @ts-ignore
      <Chart options={options} series={series} type="donut" width="850" />
    ),
  };
};

export default useApexDonutChart;
