import { Reducer } from 'redux';
import { AppThunkAction } from '..';
import Apis, { ApiError, ErrorAction } from '../../apis';
import { ApplicationState } from '../States';

export interface ErrorState {
  errorShow: boolean;
  errorTitle: string;
  errorMessage: string;
  errorAction?: ErrorAction;
}

export enum ErrorActionType {
  ShowError = 'ShowError',
  HideError = 'HideError',
  KickOut = 'UserKickOut',
}

export interface ShowErrorAction {
  type: ErrorActionType.ShowError;
  title: string;
  msg: string;
  action?: ErrorAction;
}

export interface HideErrorAction {
  type: ErrorActionType.HideError | ErrorActionType.KickOut;
}

export type ErrorActions = ShowErrorAction | HideErrorAction;

function handleErrorCode(err: any): {
  title: string;
  msg: string;
  action?: ErrorAction;
} {
  if (err instanceof ApiError) {
    const { title, msg } = err.toTitleAndMsg();
    return { title, msg, action: err.errorAction() };
  } else if (err instanceof Error) {
    const e = new ApiError('unknown-error', err.message);
    const { title, msg } = e.toTitleAndMsg();
    return { title, msg, action: e.errorAction() };
  } else {
    const e = new ApiError('unknown-error');
    const { title, msg } = e.toTitleAndMsg();
    return { title, msg, action: e.errorAction() };
  }
}

export const ErrorDispatches = Object.freeze({
  showError:
    (msg: string): AppThunkAction<ShowErrorAction> =>
    async (dispatch) => {
      return dispatch({ title: '錯誤', type: ErrorActionType.ShowError, msg });
    },
  hideError:
    (): AppThunkAction<HideErrorAction> => async (dispatch, getState) => {
      return dispatch({ type: ErrorActionType.HideError });
    },
  kickUser: (): AppThunkAction<HideErrorAction> => async (dispatch) => {
    return dispatch({ type: ErrorActionType.KickOut });
  },
  catchErrorForModal:
    (err: unknown): AppThunkAction<ErrorActions> =>
    async (dispatch) => {
      const { title, msg, action } = handleErrorCode(err);
      dispatch({
        type: ErrorActionType.ShowError,
        title,
        msg,
        action,
      });
    },
  globalCatch:
    (dispatch: (action: unknown) => void, getState?: () => ApplicationState) =>
    (err: unknown): void => {
      console.log(err, dispatch, getState);
      const { title, msg, action } = handleErrorCode(err);
      dispatch({
        type: ErrorActionType.ShowError,
        title,
        msg,
        action,
      });
    },
});

export const errorReducer: Reducer<ErrorState, ErrorActions> = (
  state,
  incomingAction
) => {
  if (state === undefined) {
    return {
      errorShow: false,
      errorTitle: '',
      errorMessage: '',
    };
  }
  switch (incomingAction.type) {
    case ErrorActionType.ShowError:
      return {
        errorShow: true,
        errorTitle: incomingAction.title,
        errorMessage: incomingAction.msg,
        errorAction: incomingAction.action,
      };
    case ErrorActionType.HideError:
    default:
      return {
        ...state,
        errorShow: false,
      };
  }
};
