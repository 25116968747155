import React, { CSSProperties, ReactElement, useState } from 'react';
import { Table, Button, Modal, Container, Row as BSRow } from 'react-bootstrap';
import { Form } from 'react-final-form';
import {
  HeaderDisplayKeysWithType,
  OnRowRender,
  RowRenderResult,
} from './EditableTable';
import { Decorator } from 'final-form';

type TValue = {
  editMetadata?: boolean;
  [k: string]: unknown;
};

type EditingTransform<T, K> = (v: T) => K;

type RowProps<T extends Record<string, unknown>, K extends T> = {
  editable: boolean;
  deleteable?: boolean;
  scrollable?: boolean;
  headers: HeaderDisplayKeysWithType<T, keyof T>[];
  value: T;
  index: number;
  onValueUpdate: (value: T) => void;
  onValueDelete?: (value: T) => void;
  beforeEdit?: EditingTransform<T, K>;
  beforeDelete?: EditingTransform<T, K>;
  afterEdit?: EditingTransform<K, T>;
  afterDelete?: EditingTransform<K, T>;
  context?: Record<string, unknown>;
  decorators?: Array<Decorator<T, Partial<T>>>;
};
const tableStyle: CSSProperties = {
  display: 'block',
  overflowX: 'auto',
  whiteSpace: 'nowrap'
};
const EditableRow = <T extends Record<string, unknown>, K extends T>({
  editable,
  deleteable,
  scrollable,
  value: v,
  index,
  headers,
  onValueUpdate,
  onValueDelete,
  beforeEdit = (v) => v as K,
  beforeDelete = (v) => v as K,
  afterEdit = (v) => v as T,
  afterDelete = (v) => v as T,
  decorators,
  context = {},
}: RowProps<T, K>) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const row = headers.reduce((elements, h) => {
    const value = h.onRender
      ? h.onRender(v[h.property], index, editing, { context, allValues: v })
      : v[h.property];
    elements.push(
      <td style={h.style} key={`body-${index}-${String(h.property)}`}>
        {value as ReactElement}
      </td>
    );
    return elements;
  }, [] as ReactElement[]);

  const deleteButton = deleteable ? (
    <>
      <Button
        key="cell-edit"
        className="border-0 px-2 py-1 m-0 mr-1"
        variant="outline-danger"
        size="sm"
        onClick={() => {
          setDeleting(true);
        }}
      >
        <span className="feather icon-trash-2" />
      </Button>

      <Modal
        show={deleting}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">刪除</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <BSRow className="mb-3">您確定要刪除嗎?</BSRow>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="danger"
            onClick={() => {
              console.log(v);
              if (onValueDelete) {
                onValueDelete(v);
              }
              setDeleting(false);
            }}
          >
            確認
          </Button>
          <Button
            type="reset"
            variant="secondary"
            onClick={() => {
              setDeleting(false);
            }}
          >
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );

  const editButton = editable ? (
    <>
      <Button
        key="cell-edit"
        className="border-0 px-2 py-1 m-0 mr-1"
        variant="outline-primary"
        size="sm"
        onClick={() => {
          setEditing(true);
        }}
      >
        <span className="feather icon-edit" />
      </Button>
    </>
  ) : (
    <></>
  );


  if (editable) {
    if (editing) {
      return (
        <Form
          initialValues={beforeEdit(v)}
          decorators={decorators}
          onSubmit={(value) => {
            setEditing(false);
            onValueUpdate(afterEdit(value as K));
          }}
          validateOnBlur
          subscription={{ submitting: true, pristine: true }}
          render={({ values, handleSubmit }) => {
            row.push(
              <td key={`body-${row.length}-edit`}>
                <Button
                  key="cell-save"
                  className="border-0 px-2 py-1 m-0 mr-1"
                  variant="outline-primary"
                  size="sm"
                  onClick={handleSubmit}
                >
                  <span className="feather icon-save" />
                </Button>
                <Button
                  key="cell-x"
                  className="border-0 px-2 py-1 m-0 mr-1"
                  variant="outline-primary"
                  size="sm"
                  onClick={() => {
                    setEditing(false);
                  }}
                >
                  <span className="feather icon-x" />
                </Button>
              </td>
            );
            return row;
          }}
        />
      );
    }
  }
  row.push(
    <td key={`body-${row.length}-edit`}>
      {editButton}
      {deleteButton}
    </td>
  );
  return <>{row}</>;
};

type Props<T extends Record<string, unknown>, K extends T> = {
  headers: HeaderDisplayKeysWithType<T, keyof T>[];
  values: T[];
  responsive?: boolean;
  scrollable?: boolean;
  onValueUpdate: (value: T) => void;
  onValueDelete?: (value: T) => void;
  onRowRender?: OnRowRender<T>;
  beforeEdit?: EditingTransform<T, K>;
  beforeDelete?: EditingTransform<T, K>;
  afterEdit?: EditingTransform<K, T>;
  afterDelete?: EditingTransform<K, T>;
  context?: Record<string, unknown>;
  decorators?: Array<Decorator<T, Partial<T>>>;
};

const inlineEditableTable = <T extends TValue, K extends T>({
  headers,
  values,
  responsive,
  scrollable,
  decorators,
  onRowRender,
  onValueUpdate,
  onValueDelete,
  beforeEdit,
  afterEdit,
  beforeDelete,
  afterDelete,
  context = {},
}: Props<T, K>) => {
  const headerElements = headers.map((h, i) => {
    return (
      <td style={h.style} key={`header-${i}`}>
        {h.display}
      </td>
    );
  });

  headerElements.push(
    <td
      key={`header-${headerElements.length}-edit`}
      style={{
        width: '5rem',
      }}
    ></td>
  );

  let bodyElements = values.map((v, i) => {
    const {
      rowStyle,
      rowClassName,
      editable = true,
      deleteable = false,
    } = onRowRender ? onRowRender(v) : ({} as RowRenderResult);
    return (
      <tr
        style={rowStyle}
        className={rowClassName}
        key={`body-${i}-${Math.random().toString(16).slice(-6)}`}
      >
        <EditableRow
          editable={editable}
          deleteable={deleteable}
          headers={headers}
          value={v}
          index={i}
          decorators={decorators}
          onValueUpdate={onValueUpdate}
          onValueDelete={onValueDelete}
          beforeEdit={beforeEdit}
          afterEdit={afterEdit}
          beforeDelete={beforeDelete}
          afterDelete={afterDelete}
          context={{ ...context }}
        />
      </tr>
    );
  });

  if (bodyElements.length === 0) {
    bodyElements = [
      <tr key={'empty-table'}>
        <td colSpan={headerElements.length}>目前資料表中沒有資料</td>
      </tr>,
    ];
  }
  return (
    <Table
      bordered
      striped
      hover
      responsive={responsive}
      className="text-center"
      style={scrollable ? tableStyle : {}}
    >
      <thead>
        <tr>{headerElements}</tr>
      </thead>
      <tbody>{bodyElements}</tbody>
    </Table>
  );
};

export const InlineEditableTable = inlineEditableTable;
