import { record, z } from 'zod';
import { DateTime } from 'luxon';
import { GetAcademicYear } from '../utils/semester';
import {
  CheckStudentBaseData,
  CheckSemester,
  CheckCode,
  CheckNumber,
} from '../utils/checkFormat';
import {
  zodStringOrNumberToString,
  zodStringOrNumberToNumber,
  zodStringToDateTime,
} from '../utils/zod';
import { ClassStudentZod } from './Class';

export const PhiTeeZod = ClassStudentZod.merge(
  z.object({
    /* 身分證	學號	年級班級座號	學期
        未治療齲齒	已治療齲齒	
        上顎恆牙第一大臼齒齲齒經驗	下顎恆牙第一大臼齒齲齒經驗	恆牙臼齒窩溝封填	
        口腔衛生不良	牙結石	牙齦炎	咬合不正	
        口腔黏膜異常	牙周病	乳牙待拔牙	待拔牙	贅生牙	缺牙	阻生牙	
        口腔其他	口腔其他陳述	口檢表	複檢口腔其他陳述	複檢口檢表
    */
    // pid: z.string(),
    // sid: z.string(),
    // grade: z.string(),
    // no: z.string(),
    // seat: z.string(),
    sem: z.string(),
    year: z.number(),
    t01: z.number(),
    t11: z.number(),
    t12: z.number(),
    t13: z.number(),
    t15: z.number(),
    t04: z.number(),
    t05: z.number(),
    t08: z.number(),
    t07: z.number(),
    t09: z.number(),
    t16: z.number(),
    t17: z.number(),
    t03: z.number(),
    t18: z.number(),
    t02: z.number(),
    t19: z.number(),
    t99: z.number(),
    t99state: z.string().nullish(),
    tSheet: z.string().nullish(),
    ret99state: z.string().nullish(),
    retSheet: z.string().nullish(),
    examDate: zodStringToDateTime(),
    yearClassId: z.number(),
  })
);
export type PhiTee = z.infer<typeof PhiTeeZod>;

export const PhiTeeFileZod = z
  .object({
    /* 身分證、學號、年級班級座號、學期、
    散瞳治療、裸視右眼、裸視左眼、戴鏡右眼、戴鏡左眼、
    散瞳、近視右眼、近視左眼、遠視右眼、遠視左眼、散光右眼、散光左眼、
    近視、遠視、散光、弱視、其他、複檢無異狀、備註、醫師建議處置、定期檢查
      */
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    學期: z.string().nullish(),
    未治療齲齒: z.string().nullish(),
    已治療齲齒: z.string().nullish(),
    上顎恆牙第一大臼齒齲齒經驗: z.string().nullish(),
    下顎恆牙第一大臼齒齲齒經驗: z.string().nullish(),
    恆牙臼齒窩溝封填: z.string().nullish(),
    口腔衛生不良: z.string().nullish(),
    牙結石: z.string().nullish(),
    牙齦炎: z.string().nullish(),
    咬合不正: z.string().nullish(),
    口腔黏膜異常: z.string().nullish(),
    牙周病: z.string().nullish(),
    乳牙待拔牙: z.string().nullish(),
    待拔牙: z.string().nullish(),
    贅生牙: z.string().nullish(),
    缺牙: z.string().nullish(),
    阻生牙: z.string().nullish(),
    口腔其他: z.string().nullish(),
    口腔其他陳述: z.string().nullish(),
    口檢表: z.string().nullish(),
    複檢口腔其他陳述: z.string().nullish(),
    複檢口檢表: z.string().nullish(),
  })
  .transform((arg) => {
    let _okUpload = true;
    let _errorMsg = '';

    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const _pid = checkStudentBaseDataResult.pid;
    const _sid = checkStudentBaseDataResult.sid;
    const _grade = checkStudentBaseDataResult.grade;
    const _no = checkStudentBaseDataResult.no;
    const _seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    const checkSemester = CheckSemester(arg.學期);
    const _semester = checkSemester.semester;
    if (!checkSemester.okUpload) {
      _okUpload = checkSemester.okUpload;
      _errorMsg += checkSemester.errorMsg;
    }

    const _code = ['0', '1', '9'];
    const _code2 = ['0', '1', '2', '3', '9'];

    //檢查【未治療齲齒】欄位，必填欄位，t01
    const checkT01 = CheckCode(arg.未治療齲齒 || '0', _code2, '未治療齲齒');
    if (!checkT01.okUpload) {
      _okUpload = checkT01.okUpload;
      _errorMsg += checkT01.errorMsg;
    }

    //檢查【已治療齲齒】欄位，必填欄位，t11
    const checkT11 = CheckCode(arg.已治療齲齒 || '0', _code, '已治療齲齒');
    if(checkT01.code == '9') {
      checkT11.code = '9';
    } else if (checkT01.code == '1' || checkT01.code == '2' || checkT01.code == '3')  {
      checkT11.code = '0';
    }

    //檢查【上顎恆牙第一大臼齒齲齒經驗】欄位，必填欄位，t12
    const checkT12 = CheckCode(
      arg.上顎恆牙第一大臼齒齲齒經驗 || '0',
      _code,
      '上顎恆牙第一大臼齒齲齒經驗'
    );
    if (!checkT12.okUpload) {
      _okUpload = checkT12.okUpload;
      _errorMsg += checkT12.errorMsg;
    }
    //檢查【下顎恆牙第一大臼齒齲齒經驗】欄位，必填欄位，t13
    const checkT13 = CheckCode(
      arg.下顎恆牙第一大臼齒齲齒經驗 || '0',
      _code,
      '下顎恆牙第一大臼齒齲齒經驗'
    );
    if (!checkT13.okUpload) {
      _okUpload = checkT13.okUpload;
      _errorMsg += checkT13.errorMsg;
    }

    //檢查【已治療齲齒】欄位，必填欄位，t15
    const checkT15 = CheckCode(
      arg.恆牙臼齒窩溝封填 || '0',
      _code,
      '恆牙臼齒窩溝封填'
    );
    if (!checkT15.okUpload) {
      _okUpload = checkT15.okUpload;
      _errorMsg += checkT15.errorMsg;
    }

    //檢查【待拔牙】欄位，必填欄位，t03
    const checkT03 = CheckCode(arg.待拔牙 || '0', _code2, '待拔牙');
    if (!checkT03.okUpload) {
      _okUpload = checkT03.okUpload;
      _errorMsg += checkT03.errorMsg;
    }

    //檢查【阻生牙】欄位，必填欄位，t19
    const checkT19 = CheckCode(arg.阻生牙 || '0', _code2, '阻生牙');
    if (!checkT19.okUpload) {
      _okUpload = checkT19.okUpload;
      _errorMsg += checkT19.errorMsg;
    }
    
    //檢查【贅生牙】欄位，必填欄位，t18
    const checkT18 = CheckCode(arg.贅生牙 || '0', _code2, '贅生牙');
    if (!checkT18.okUpload) {
      _okUpload = checkT18.okUpload;
      _errorMsg += checkT18.errorMsg;
    }

    //檢查【缺牙】欄位，必填欄位，t02
    const checkT02 = CheckCode(arg.缺牙 || '0', _code2, '缺牙');
    if (!checkT02.okUpload) {
      _okUpload = checkT02.okUpload;
      _errorMsg += checkT02.errorMsg;
    }   
    
    //檢查【乳牙待拔牙】欄位，必填欄位，t17
    const checkT17 = CheckCode(arg.乳牙待拔牙 || '0', _code2, '乳牙待拔牙');
    if (!checkT17.okUpload) {
      _okUpload = checkT17.okUpload;
      _errorMsg += checkT17.errorMsg;
    }

    //檢查【口檢表】
    const tSheet = arg.口檢表;
    if(tSheet) {
      const tSheetArr = tSheet.match(/(\d+[A-Za-z]+)/g);
      const checkT12Code = ['16D', '16E', '16F', '26D', '26E', '26F'];
      const checkT13Code = ['36D', '36E', '36F', '46D', '46E', '46F'];
   
      if(tSheetArr?.length) {
        if(tSheetArr.some(element => checkT12Code.includes(element))) { // 口檢表有 16D、16E、16F、26D、26E、26F，則「上顎恆牙第一大臼齒齲齒經驗」設為「有」
          checkT12.code = '1';
        }
        if(tSheetArr.some(element => checkT13Code.includes(element))) { // 口檢表有 36D、36E、36F、46D、46E、46F，則「下顎恆牙第一大臼齒齲齒經驗」設為「有」
          checkT13.code = '1';
        }
        if(tSheet.includes('D') || tSheet.includes('E') || tSheet.includes('d') || tSheet.includes('e')) { // 口檢表有D（齲齒）或 E（待拔牙），將「未治療齲齒」設定為 1（初檢異常），「已治療齲齒」設為 0（無）
          checkT01.code = '1';
          checkT11.code = '0';
        } else if(tSheet.includes('F') || tSheet.includes('f')) { // 口檢表有 F（已矯治）時，將「未治療齲齒」設定為 0（無異狀），「已治療齲齒」設為 1（有）
          checkT01.code = '0';
          checkT11.code = '1';
        } 
        if((tSheet.includes('SF') || tSheet.includes('sf')) && (checkT15.code == '0' || checkT15.code == '9')) { // 口檢表有 SF（恆牙臼齒窩溝封填），對應的「恆牙臼齒窩溝封填」判讀欄位聯動修改判讀欄位為 1（有）
          checkT15.code = '1';
        }
        if((tSheet.includes('E') || tSheet.includes('e')) && (checkT03.code == '0' || checkT03.code == '9')) { // 口檢表有 E（待拔牙），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT03.code = '1';
        }
        if((tSheet.includes('G') || tSheet.includes('g')) && (checkT19.code == '0' || checkT19.code == '9')) { // 口檢表有 G （阻生齒），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT19.code = '1';
        }
        if((tSheet.includes('S') || tSheet.includes('s')) && (checkT18.code == '0' || checkT18.code == '9')) { // 口檢表有 S（贅生牙），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT18.code = '1';
        }
        if((tSheet.includes('M') || tSheet.includes('m')) && (checkT02.code == '0' || checkT02.code == '9')) { // 口檢表有 M（缺牙），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT02.code = '1';
        }
        if((tSheet.includes('H') || tSheet.includes('h')) && (checkT17.code == '0' || checkT17.code == '9')) { // 口檢表有 H（乳牙待拔），對應的判讀欄位聯動修改判讀欄位為 1（有）
          checkT17.code = '1';
        }
      }
    }
    const _t12 = checkT12.code;
    const _t13 = checkT13.code;
    const _t01 = checkT01.code;
    const _t11 = checkT11.code;
    const _t15 = checkT15.code;
    const _t03 = checkT03.code;
    const _t19 = checkT19.code;
    const _t18 = checkT18.code;
    const _t02 = checkT02.code;
    const _t17 = checkT17.code;

    //檢查【口腔衛生不良】欄位，必填欄位，t04
    const checkT04 = CheckCode(arg.口腔衛生不良 || '0', _code2, '口腔衛生不良');
    if (!checkT04.okUpload) {
      _okUpload = checkT04.okUpload;
      _errorMsg += checkT04.errorMsg;
    }
    const _t04 = checkT04.code;

    //檢查【牙結石】欄位，必填欄位，t05
    const checkT05 = CheckCode(arg.牙結石 || '0', _code2, '牙結石');
    if (!checkT05.okUpload) {
      _okUpload = checkT05.okUpload;
      _errorMsg += checkT05.errorMsg;
    }
    const _t05 = checkT05.code;

    //檢查【牙齦炎】欄位，必填欄位，t08
    const checkT08 = CheckCode(arg.牙齦炎 || '0', _code2, '牙齦炎');
    if (!checkT08.okUpload) {
      _okUpload = checkT08.okUpload;
      _errorMsg += checkT08.errorMsg;
    }
    const _t08 = checkT08.code;

    //檢查【咬合不正】欄位，必填欄位，t07
    const checkT07 = CheckCode(arg.咬合不正 || '0', _code2, '咬合不正');
    if (!checkT07.okUpload) {
      _okUpload = checkT07.okUpload;
      _errorMsg += checkT07.errorMsg;
    }
    const _t07 = checkT07.code;

    //檢查【口腔黏膜異常】欄位，必填欄位，t09
    const checkT09 = CheckCode(arg.口腔黏膜異常 || '0', _code2, '口腔黏膜異常');
    if (!checkT09.okUpload) {
      _okUpload = checkT09.okUpload;
      _errorMsg += checkT09.errorMsg;
    }
    const _t09 = checkT09.code;

    //檢查【牙周病】欄位，必填欄位，t16
    const checkT16 = CheckCode(arg.牙周病 || '0', _code2, '牙周病');
    if (!checkT16.okUpload) {
      _okUpload = checkT16.okUpload;
      _errorMsg += checkT16.errorMsg;
    }
    const _t16 = checkT16.code;

    const _t99state = arg.口腔其他陳述;
    //檢查【口腔其他】欄位，必填欄位，t99
    const checkT99 = CheckCode(arg.口腔其他 || '0', _code2, '口腔其他');
    if(_t99state && checkT99.code == '0') {
      checkT99.code = '1';
    } else if (!checkT99.okUpload) {
      _okUpload = checkT99.okUpload;
      _errorMsg += checkT99.errorMsg;
    }
    const _t99 = checkT99.code;

    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid: _pid,
      sid: _sid,
      grade: _grade,
      no: _no,
      seat: _seat,
      sem: _semester,
      year: GetAcademicYear,
      t01: Number(_t01), //未治療齲齒
      t11: Number(_t11), //已治療齲齒
      t12: Number(_t12), //上顎恆牙第一大臼齒齲齒經驗
      t13: Number(_t13), //下顎恆牙第一大臼齒齲齒經驗
      t15: Number(_t15), //恆牙臼齒窩溝封填
      t04: Number(_t04), //口腔衛生不良
      t05: Number(_t05), //牙結石
      t08: Number(_t08), //牙齦炎
      t07: Number(_t07), //咬合不正
      t09: Number(_t09), //口腔黏膜異常
      t16: Number(_t16), //牙周病
      t17: Number(_t17), //乳牙待拔牙
      t03: Number(_t03), //待拔牙
      t18: Number(_t18), //贅生牙
      t02: Number(_t02), //缺牙
      t19: Number(_t19), //阻生牙
      t99: Number(_t99), //口腔其他
      t99state: _t99state, //口腔其他陳述
      tSheet: arg.口檢表, //口檢表
      ret99state: arg.複檢口腔其他陳述, //複檢口腔其他陳述
      retSheet: arg.複檢口檢表, //複檢口檢表
      examDate: null as DateTime | null,
      yearClassId: 0,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

export type PhiTeeFile = z.infer<typeof PhiTeeFileZod>;

export const PhiTeeApiZod = z.object({
  /* 身分證	學號	年級班級座號	學期
      未治療齲齒	已治療齲齒	
      上顎恆牙第一大臼齒齲齒經驗	下顎恆牙第一大臼齒齲齒經驗	恆牙臼齒窩溝封填	
      口腔衛生不良	牙結石	牙齦炎	咬合不正	
      口腔黏膜異常	牙周病	乳牙待拔牙	待拔牙	贅生牙	缺牙	阻生牙	
      口腔其他	口腔其他陳述	口檢表	複檢口腔其他陳述	複檢口檢表
  */
  pid: z.string(),
  sid: z.string(),
  grade: z.string(),
  no: z.string(),
  seat: z.string(),
  sem: z.string(),
  year: z.number(),
  t01: z.number(),
  t11: z.number(),
  t12: z.number(),
  t13: z.number(),
  t15: z.number(),
  t04: z.number(),
  t05: z.number(),
  t08: z.number(),
  t07: z.number(),
  t09: z.number(),
  t16: z.number(),
  t17: z.number(),
  t03: z.number(),
  t18: z.number(),
  t02: z.number(),
  t19: z.number(),
  t99: z.number(),
  t99state: z.string().nullish(),
  tSheet: z.record(z.string()).nullish(),
  ret99state: z.string().nullish(),
  retSheet: z.string().nullish(),
  examDate: zodStringToDateTime(),
  yearClassId: z.number(),
});
export type PhiTeeApi = z.infer<typeof PhiTeeApiZod>;
