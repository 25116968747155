import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  Modal,
  Row,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../apis';
import {
  AuthedLayout,
  CheckBoxField,
  DateTimeField,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  TextField,
  DropdownCheckListField,
} from '../components';
import { PhysicalSetting } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { DateTime } from 'luxon';
import { I18N } from '../i18n-raw';
import { getPhysicalGradeByRank } from '../utils/grade';
import createDecorator from 'final-form-calculate';
import { useHistory } from 'react-router';

const transformCheckedBox = (v: PhysicalSetting[keyof PhysicalSetting]) => (
  <FormCheck checked={!!v} readOnly type={'checkbox'} />
);

const modalDisplayHeader: HeaderDisplayKeysWithType<PhysicalSetting>[] = [
  { property: 'year', display: '入學年' },
  { property: 'grade', display: '年級' },
  { property: 'body', display: '身體診察受檢', onRender: transformCheckedBox },
  { property: 'blood', display: '血液受檢', onRender: transformCheckedBox },
  { property: 'xRay', display: 'Ｘ光受檢', onRender: transformCheckedBox },
  { property: 'bodyAdditional', display: '身體診察追加' },
  { property: 'bloodAdditional', display: '血液追加' },
  {
    property: 'bloodPressure',
    display: '血壓判讀',
    onRender: transformCheckedBox,
  },
  { property: 'pulse', display: '脈搏判讀', onRender: transformCheckedBox },
  { property: 'checkUnit', display: '健檢單位' },
  { property: 'doctor', display: '檢查醫師' },
  { property: 'teeDoctor', display: '牙科醫師' },
  {
    property: 'checkDate',
    display: '檢查日期',
    onRender: (v) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
  },
];

const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const dispatches = ErrorDispatches;
const connector = connect(mapState, dispatches);

type Props = ConnectedProps<typeof connector>;

const physicalSettingPage: FunctionComponent<Props> = ({
  user,
  loading,
  catchErrorForModal,
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  const [inputing, setInputing] = useState(false);
  const [editingSetting, setEditingSetting] = useState<PhysicalSetting>(
    {} as PhysicalSetting
  );
  const [settings, setSettings] = useState<PhysicalSetting[]>([]);
  const showCurrentRef = useRef<HTMLInputElement>(null);
  const fromInputRef = useRef<HTMLInputElement>(null);
  const toInputRef = useRef<HTMLInputElement>(null);
  // const [bodyAdditional, setBodyAdditional] = useState<string[]>([]);
  // const [bloodAdditional, setBloodAdditional] = useState<string[]>([]);

  const { year } = user.semesters[user.currentSemester];
  const currentSchool = user.currentSchool;
  const { rank } = user.schools[user.currentSchool];

  useEffect(() => {
    if (!!year && fromInputRef.current && toInputRef.current) {
      fromInputRef.current.value = year - 5 + '';
      toInputRef.current.value = year + '';
    }
  }, [fromInputRef.current, toInputRef.current]);

  useEffect(() => {
    getSettings();
  }, [inputing]);

  async function getSettings() {
    try {
      let from = year;
      let to = year;
      if (!fromInputRef.current || !fromInputRef.current?.value) {
        if (fromInputRef.current) fromInputRef.current.value = year + '';
      }
      if (!toInputRef.current || !toInputRef.current?.value) {
        if (toInputRef.current) toInputRef.current.value = year + '';
      }
      if (showCurrentRef.current?.checked) {
        from = +(fromInputRef.current?.value ?? year);
        to = +(toInputRef.current?.value ?? year);
      }
      const rawSettings = await apis.getPhysicalSettings(from, to);
      const grades = getPhysicalGradeByRank(rank);
      const newSettings = Array.from({ length: to - from + 1 })
        .map((y, i) =>
          grades.map((g): PhysicalSetting => {
            const s = rawSettings.find(
              (s) => s.grade === g && s.year == from + i
            );
            return {
              schoolId: currentSchool,
              year: from + i,
              grade: g,
              body: false,
              blood: false,
              xRay: false,
              bodyAdditional: [],
              bloodAdditional: [],
              bloodPressure: false,
              pulse: false,
              ...s,
            };
          })
        )
        .reduce((pv, cv) => pv.concat(cv), [] as PhysicalSetting[]);
      setSettings(newSettings);
    } catch (e) {
      return catchErrorForModal(e);
    }
  }

  function onEdit(setting: PhysicalSetting) {
    setEditingSetting(setting);
    // setBodyAdditional(setting.bodyAdditional);
    // setBloodAdditional(setting.bloodAdditional);
    setInputing(true);
  }

  function onHide() {
    setEditingSetting({} as PhysicalSetting);
    // setBodyAdditional([]);
    // setBloodAdditional([]);
    setInputing(false);
  }

  function upsertSetting(setting: PhysicalSetting) {
    apis
      .upsertPhysicalSetting(setting)
      .then(() => onHide())
      .catch(catchErrorForModal);
  }

  return (
    <AuthedLayout>
      <Row className="mb-2">
        <Col>
          <FormCheck
            ref={showCurrentRef}
            label="載入全年級設定資料"
            onChange={() => getSettings()}
          />
        </Col>
        顯示：
        <Col sm={1}>
          <FormControl
            min={year - 5}
            max={year}
            ref={fromInputRef}
            disabled={!showCurrentRef.current?.checked}
          />
        </Col>
        年~
        <Col sm={1}>
          <FormControl
            min={year - 5}
            max={year}
            ref={toInputRef}
            disabled={!showCurrentRef.current?.checked}
          />
        </Col>
        年
        <Col sm={1} className="ml-3">
          <Button
            disabled={!showCurrentRef.current?.checked}
            onClick={() => getSettings()}
          >
            查詢
          </Button>
        </Col>
      </Row>
      <Row>
        <EditableTableDiv
          headers={modalDisplayHeader}
          values={settings}
          editable
          onEdit={(v) => onEdit(v)}
        />
      </Row>
      <Modal
        show={inputing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            健康資料設定 - <strong>{editingSetting?.year}</strong>
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={editingSetting}
          onSubmit={upsertSetting}
          decorators={FormDecorators}
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { values, handleSubmit } = prop;
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row className="mb-2">
                      <Col sm={1}>入學年:</Col>
                      <Col sm={3}>
                        <TextField property="year" disabled />
                      </Col>
                      <Col sm={1}>年級:</Col>
                      <Col sm={3}>
                        <TextField property="grade" disabled />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>身體診察受檢:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="body" />
                      </Col>
                      <Col sm={1}>血液受檢:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="blood" />
                      </Col>
                      <Col sm={1}>Ｘ光受檢:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="xRay" />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>身體診察追加:</Col>
                      <Col sm={3}>
                        <DropdownCheckListField
                          property="bodyAdditional"
                          content={I18N.PhysicalBodyAdditional}
                        />
                      </Col>
                      <Col sm={1}>血液追加:</Col>
                      <Col sm={3}>
                        <DropdownCheckListField
                          property="bloodAdditional"
                          content={I18N.PhysicalBloodAdditional}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>血壓判讀:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="bloodPressure" />
                      </Col>
                      <Col sm={1}>脈搏判讀:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="pulse" />
                      </Col>
                      <Col sm={1}>健檢單位:</Col>
                      <Col sm={3}>
                        <TextField property="checkUnit" />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>檢查醫師:</Col>
                      <Col sm={3}>
                        <TextField property="doctor" />
                      </Col>
                      <Col sm={1}>牙科醫師:</Col>
                      <Col sm={3}>
                        <TextField property="teeDoctor" />
                      </Col>
                      <Col sm={1}>檢查日期:</Col>
                      <Col sm={3}>
                        <DateTimeField property="checkDate" />
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHide}>
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        />
      </Modal>
    </AuthedLayout>
  );
};

export const PhysicalSettingPage = connector(physicalSettingPage);

const FormDecorators = [
  createDecorator<PhysicalSetting>(
    {
      field: /body[Additional]?/,
      updates: {
        body: (value: boolean, allValues: PhysicalSetting | undefined) => {
          return (
            !!allValues &&
            (allValues?.body || !!allValues?.bodyAdditional?.length || false)
          );
        },
      },
    },
    {
      field: /blood[Additional]?/,
      updates: {
        blood: (value: boolean, allValues: PhysicalSetting | undefined) => {
          return (
            !!allValues &&
            (allValues?.blood || !!allValues?.bloodAdditional?.length || false)
          );
        },
      },
    }
  ),
];
