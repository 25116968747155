import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import ExcelJS, { Style } from 'exceljs';
import { YearSem } from '../model';
import html2canvas from 'html2canvas';
import { downloadBlob } from '../utils/element';
import { WorkSheet } from 'xlsx-js-style';
import XLSX from 'xlsx-js-style';
import { Row, Cell } from '../utils';
type Argument = {
    chartdata?: number[] | string[];
    chartRefs: { ref: string, sheetname: string }[],
    titles: string[]
    tablesheets?: Row[],
    tablesheetname: string
};

export const useDownloadSheetChart = ({
    chartdata,
    chartRefs,
    titles,
    tablesheets,
    tablesheetname
}: Argument): {
    element: ReactNode;
} => {
    const workbook = new ExcelJS.Workbook();
    const exportToExcel = async () => {

        if (tablesheets) {
            const worksheet = workbook.addWorksheet(tablesheetname);
            const maxColumns = 16384; // Excel 的最大列數限制

            tablesheets.forEach((row, rowIndex) => {
                const newRow = worksheet.getRow(rowIndex + 1);
                let currentColIndex = 1; // 開始的列索引
                row.cells.forEach((cell, colIndex) => {
                    // 動態計算目前的列索引，考慮前面合併的儲存格數量
                    while (worksheet.getCell(rowIndex + 1, currentColIndex).isMerged) {
                        currentColIndex++;
                    }

                    const newCell = newRow.getCell(currentColIndex);
                    newCell.value = cell.value;
                    if (cell.style) {
                        const styleObject: Partial<ExcelJS.Style> = {}; // Define style object based on ExcelJS's Style interface
                        if (cell.style.alignment) {
                            styleObject.alignment = cell.style.alignment as Partial<ExcelJS.Alignment>;
                        }
                        if (cell.style.border) {
                            styleObject.border = cell.style.border;
                        }
                        if (cell.style.fill) {
                            styleObject.fill = cell.style.fill as ExcelJS.Fill;
                        }
                        if (cell.style.font) {
                            styleObject.font = cell.style.font;
                        }
                        if (cell.style.numFmt) {
                            styleObject.numFmt = cell.style.numFmt;
                        }

                        newCell.style = styleObject as ExcelJS.Style;
                    }
                    // 處理合併儲存格
                    if (cell.merge) {
                        const { row: mergeRows = 1, column: mergeCols = 1 } = cell.merge;
                        const mergeStartRow = rowIndex + 1;
                        const mergeStartCol = currentColIndex;
                        const mergeEndRow = mergeStartRow + mergeRows - 1;
                        const mergeEndCol = mergeStartCol + mergeCols - 1;

                        worksheet.mergeCells(mergeStartRow, mergeStartCol, mergeEndRow, mergeEndCol);

                        // 更新目前列索引，考慮合併儲存格後的偏移量
                        currentColIndex += mergeCols;
                    }
                });
            });
        }

        for (let index = 0; index < chartRefs.length; index++) {
            const chartId = chartRefs[index].ref;
            const chartInstance = ApexCharts.getChartByID(chartId);
            if (chartInstance) {
                const dataURIResult = await chartInstance.dataURI();
                // Get data URI from ApexCharts
                const imgURI = 'imgURI' in dataURIResult ? dataURIResult.imgURI : URL.createObjectURL(dataURIResult.blob);
                const worksheet = workbook.addWorksheet(chartRefs[index].sheetname);
                worksheet.getCell('A1').value = titles[index] ?? titles[0];

                const imageId = workbook.addImage({
                    base64: imgURI.split(',')[1], // Remove the data URI prefix
                    extension: 'png',
                });

                worksheet.addImage(imageId, {
                    tl: { col: 0, row: 1 },
                    ext: { width: 1000, height: 650 }, // Adjust dimensions as needed
                });
            }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadBlob(blob, `${titles[0]}.xlsx`);
    };
    return {
        element:
            (
                <Button
                    disabled={!chartdata?.length}
                    className="text-dark"
                    variant="success"
                    onClick={exportToExcel}
                >
                    Excel 下載
                </Button>
            )
    }
};


export default useDownloadSheetChart;