import { DateTime } from 'luxon';
import React, {
  FunctionComponent,
  useState,
  ReactNode,
  useEffect,
  ChangeEvent,
} from 'react';
import { Modal, Button, Table, Row, Col } from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  TextField,
  OverflowWithTooltip,
} from '../../components';
import { PHIDisease, Semester, Student, YearSemClasses } from '../../model';
import { InputDropdownGroup } from '../../components';
import { toast } from 'react-toastify';
import { I18N } from '../../i18n-raw';

type Props = {
  editable: boolean;
  deleteable: boolean;
  diseases: PHIDisease[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};
const transformTextInput = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? <TextField property={property} /> : wrapOverflow(v as string));

const inlineDiseaseTableHeader: HeaderDisplayKeysWithType<PHIDisease>[] = [
  { property: 'studentId', display: '統編' },
  { property: 'diseaseId', display: '診斷代號' },
  { property: 'diseaseName', display: '疾病名稱' },
  {
    property: 'description',
    display: '陳述',
    onRender: transformTextInput.bind(null, 'description'),
  },
  {
    property: 'treatment',
    display: '處置',
    onRender: transformTextInput.bind(null, 'treatment'),
  },
];

function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}
export const DiseaseSection: FunctionComponent<Props> = ({
  editable,
  deleteable,
  diseases,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const currentSemester = semesters.find((s) => s.isNow);
  const [inputing, setInputing] = useState(false);
  const [diseaseItems, setdiseaseItems] = useState<object>();
  const [diseaseDropDownItems, setdiseaseDropDownItems] =
    useState<Record<number, string>>();
  const [diseaseDropDown, setDiseaseDropDown] = useState<number>();
  const [isStudentDiseaseValid, setIsStudentDiseaseValid] = useState<boolean>(false);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [treatment, setTreatment] = useState<string>('');
  const [year, setYear] = useState<number>();
  const [sem, setSem] = useState<number>();
  const wh = diseases.find(
    (s) => s.sem === currentSemester?.sem && s.year === currentSemester?.year
  );

  useEffect(() => {
    // 目前學期、學年
    const isNow = semesters.filter((y) => y.isNow == true)[0];
    setYear(isNow.year);
    setSem(isNow.sem);
    // 個人疾病下拉選單
    setdiseaseDropDownItems(I18N.StudentDiseaseType);
  }, [semesters]);

  useEffect(() => {
    setInsertBtn(false);
  }, [diseaseDropDown]);

  useEffect(() => {
    // 檢查疾病代號
    setIsStudentDiseaseValid(false);
    if (diseaseDropDown) {
      if(I18N.StudentDiseaseType[diseaseDropDown]) {
        setIsStudentDiseaseValid(true);
        apis
        .GetDiseaseDropDown()
        .then((s) => {
          if (s.length) {
            s.forEach((i) => {
              if(i.id == diseaseDropDown) {
                setTreatment(i.treatment);
              }
            });
          }
        })
      .catch(onApiError);
      }
    }
  }, [diseaseDropDown]);

  yearSems.filter(
    (s) => !diseases.some((wh) => wh.sem === s.sem && wh.year === s.year)
  );

  diseases.sort((a, b) => a.sem - b.sem + (a.year - b.year) * 100);

  function onValueUpdate(value: PHIDisease) {
    apis
      .updateStudentDisease(
        value?.id,
        value?.diseaseId,
        value?.diseaseName,
        value.description,
        value.treatment,
        value.enterDate ? value.enterDate.toString() : '',
        value.yearClassId
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function onValueDelete(value: PHIDisease) {
    apis
      .deleteStudentDisease(
        value?.id,
        value?.diseaseId,
        value?.diseaseName,
        value.description,
        value.treatment,
        value.enterDate ? value.enterDate.toString() : '',
        value.yearClassId
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function insertDiseaseCheck() {
    // 檢查診斷代號
    if (isStudentDiseaseValid) {
      insertDisease();
    } else {
      toast.error('診斷代號錯誤!', {});
    }
  }

  function insertDisease() {
    if (diseaseDropDown) {
      // 送出api
      if (year && sem) {
        toast
          .promise(
            apis.insertPhiDisease(
              year,
              sem,
              '',
              0,
              0,
              0,
              student.pid,
              diseaseDropDown,
              '', // 陳述
              treatment,
              new Date().toLocaleString('sv').slice(0, 10),
              1
            ),
            {
              pending: '資料新增中......',
              success: '新增成功！',
            }
          )
          .then(onValueUpdated)
          .catch(onApiError);
      }
    }
  }

  return (
    <>
      <div>
        個人疾病史
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            個人疾病史 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <InlineEditableTable
            values={diseases}
            headers={inlineDiseaseTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable:
                editable &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
              deleteable:
                deleteable &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
            })}
          />
          <Row className='px-2'>
            疾病代號：
            <Col sm={3}>
              <InputDropdownGroup
                className={'p-1 text-center mr-2'}
                name="diseaseId"
                type="number"
                options={diseaseDropDownItems || {}}
                defaultShow="無異常"
                value={diseaseDropDown || ''}
                onChange={(
                  e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                  >
                ) => {
                  setDiseaseDropDown(parseInt(e.target.value));
                }}
                onBlur={() => {}}
                onFocus={() => {}}
              />
            </Col>
            <Button disabled={insertBtn} onClick={insertDiseaseCheck}>
              新增
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
