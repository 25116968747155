import { CheckField, ClassStudent, ClassStudentResponse } from '../model';
import { Nullable } from '../types';

export function takeClassStudent(
  arr: Nullable<ClassStudent>[]
): Nullable<ClassStudent>[] {
  return arr.map(({ pid, sid, grade, no, seat }) => ({
    pid,
    sid,
    grade,
    no,
    seat,
  }));
}

export function isAllHavePid(arr: ClassStudent[]): boolean {
  return !arr.some(({ pid }) => !pid);
}

export function isAllHaveSid(arr: ClassStudent[]): boolean {
  return !arr.some(({ sid }) => !sid);
}

export function isAllHaveGradeNoSeat(arr: ClassStudent[]): boolean {
  return !arr.some(({ grade, no, seat }) => !grade || !no || !seat);
}

export function CheckShouldUseIdentify<T extends ClassStudent>(
  students: T[]
): [CheckField, (f: ClassStudentResponse, t: T) => boolean] {
  if (isAllHavePid(students)) {
    return [CheckField.Pid, (f, t) => f.pid === t.pid];
  }
  if (isAllHaveSid(students)) {
    return [CheckField.Sid, (f, t) => f.sid === t.sid];
  }
  if (isAllHaveGradeNoSeat(students)) {
    return [
      CheckField.GradeNoSeat,
      (f, t) => f.grade === t.grade && f.no === t.no && f.seat === t.seat,
    ];
  }
  return [CheckField.None, () => false];
}

export function fieldIssueWording(fieldIssue: CheckField): string {
  switch (fieldIssue) {
    case CheckField.Pid:
      return '身份證不正確';
    case CheckField.Sid:
      return '學號不正確';
    case CheckField.GradeNoSeat:
      return '年級班級座號不正確';
    default:
      throw new Error('check field is invalid');
  }
}

type RemarkObj = ClassStudent & { remark: string; [K: string]: unknown };
// type ExistsObj = { exists: boolean; [K: string]: unknown };
export function assignExists<T extends RemarkObj>(
  matchFunc: (from: ClassStudentResponse, to: T) => boolean,
  datas: T[],
  exists: ClassStudentResponse[]
): T[] {
  datas.forEach((d) => {
    const e = exists.find((e) => matchFunc(e, d));
    if (!e?.exists) {
      if(d.remark) {
        d.remark += `，該學生不存在`;
      } else {
        d.remark += `該學生不存在`;
      }
    } else {
      d.remark += e.fieldIssues.map((fi) => fieldIssueWording(fi)).join('，');
    }
  });
  return datas;
}

export function checkOkToUpload<T extends RemarkObj>(
  okUpload: boolean,
  data: T[]
): boolean {
  // console.log('checkOkToUpload: ',okUpload);
  if (okUpload) {
    const a = data.every(function (item) {
      return item.remark === '' && item.okUpload;
    });
    // console.log(a);
    return a;
  } else {
    return false;
  }
}

export function checkOkToUpdate<T extends RemarkObj>(
  okUpload: boolean,
  data: T[]
): boolean {
  if (okUpload) {
    const a = data.every(function (item) {
      return item.remark === '' && item.okUpload;
    });
    return a;
  } else {
    return false;
  }
}
