import React, { FunctionComponent } from 'react';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../redux/States';
import { AuthDispatches, ErrorDispatches } from '../../redux/Dispatches';

import avatar1 from '../../assets/images/user/avatar-1.jpg';
import { EnchanceLink } from '../EnhanceLink';

const mapState = (state: ApplicationState) => state.auth;
const mapDispatch = { ...AuthDispatches, ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const navRight: FunctionComponent<Props> = ({
  user,
  signOut,
  changeShcool,
  catchErrorForModal,
}) => {
  if (!user) {
    return <React.Fragment></React.Fragment>;
  }
  const { schools, currentSchool, semesters, currentSemester } = user;
  const { year, sem } = semesters[currentSemester] || {};
  return (
    <React.Fragment>
      <span className="ml-auto">
        {year}學年度 第{sem}學期
      </span>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav" id="navbar-right">
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown
            onSelect={(e) => {
              if (e !== null) changeShcool(e).catch(catchErrorForModal);
            }}
          >
            <Dropdown.Toggle variant="link">
              {schools[currentSchool]?.name ?? ''}
            </Dropdown.Toggle>
            <Dropdown.Menu as="ul">
              {Object.entries(user.schools).map(([id, { name }]) => (
                <Dropdown.Item
                  as="li"
                  key={`school-dropdown-${id}`}
                  eventKey={id}
                >
                  <EnchanceLink to="" className="dropdown-item">
                    <i className="feather icon-settings" /> {name}
                  </EnchanceLink>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown className="drp-user">
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="profile-notification">
              <div className="pro-head">
                <img src={avatar1} className="img-radius" alt="User Profile" />
                <span>{user.name}</span>
              </div>
              <ListGroup
                as="ul"
                bsPrefix=" "
                variant="flush"
                className="pro-body"
              >
                <ListGroup.Item as="li" bsPrefix=" ">
                  <EnchanceLink
                    to=""
                    className="dropdown-item"
                    title="Logout"
                    onClick={() => {
                      signOut();
                    }}
                  >
                    <i className="feather icon-log-out" /> 登出
                  </EnchanceLink>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export const NavRight = connector(navRight);
