import { I18N } from '../i18n-raw';
import { StudentInjury } from '../model';

export function serializeInjury(injury: StudentInjury): string {
  const ps = StudentInjuryBoolProperties.filter((p) => injury[p]).map(
    (p) => I18N.Injury[p]
  );

  if (injury.observationMins) {
    ps.push(`觀察${injury.observationMins}分`);
  }

  if (injury.temperature) {
    ps.push(`體溫${injury.temperature}`);
  }

  if (injury.memos) {
    ps.push(injury.memos);
  }

  return ps.join(',');
}

const StudentInjuryBoolProperties = [
  'head',
  'neck',
  'shoulder',
  'chest',
  'belly',
  'back',
  'eye',
  'face',
  'mouth',
  'nose',
  'arm',
  'waist',
  'leg',
  'buttock',
  'perineum',
  'friction',
  'slash',
  'press',
  'strick',
  'twist',
  'burn',
  'sting',
  'fracture',
  'old',
  'aOther',
  'fever',
  'dizzy',
  'puke',
  'headache',
  'toothache',
  'stomachache',
  'bellyache',
  'diarrhoea',
  'menses',
  'pant',
  'noseBlood',
  'rash',
  'eyeache',
  'hOther',
  'woundCare',
  'ice',
  'hot',
  'rest',
  'noteParent',
  'backHome',
  'hospital',
  'education',
  'mOther',
] as const;
