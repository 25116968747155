import React, { ReactNode, useEffect, useState } from 'react';
import { Class, YearSem } from '../model';
import apis from '../apis';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { listToObject } from '../utils';

type Argument = {
    yearSem?: YearSem;
    catchErrorForModal: (ex: unknown) => Promise<void>;
};

export const useInspectionType = ({
    yearSem,
    catchErrorForModal,
}: Argument): {
    inspectiontype?: string;
    inspectionTypeDP: ReactNode;
} => {
    const [choosetype, settype] = useState<string>('');
    return {
        inspectiontype: choosetype,
        inspectionTypeDP: (
            <>
                <DropdownButton
                    title={
                        yearSem
                            ? (choosetype) || '請選擇檢查資料'
                            : '請先選擇學年'
                    }
                    onSelect={(k: string | null) => {
                        if (k == null) return;
                        settype(k);
                    }}
                >
                    <Dropdown.Item eventKey="初檢">初檢</Dropdown.Item>
                    <Dropdown.Item eventKey="複檢">複檢</Dropdown.Item>
                </DropdownButton>
            </>
        ),
    };
};
