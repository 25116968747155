import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Modal, Button, InputGroup, Form, Col, FormCheck } from 'react-bootstrap';
import apis from '../../apis';
import {
  PHIInsurance,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { InputDropdownGroupField } from '../../components';

import { toast } from 'react-toastify';

type Props = {
  editable: boolean;
  deleteable: boolean;
  insurance: PHIInsurance[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};
const transformInputDropdownField = (
  property: string,
  content: Record<number, string>,
  v: unknown,
  i: number,
  e?: boolean
) =>
  e ? (
    <InputDropdownGroupField
      type="number"
      content={content}
      property={property}
    />
  ) : (
    (v as string)
  );
const StringMap1 = {
  1: '輕度',
  2: '中度',
  3: '極重度',
};

export const InsuranceSection: FunctionComponent<Props> = ({
  insurance,
  student,
  semesters,
  onApiError,
  onValueUpdated,
}) => {
  const currentSemester = semesters.find((s) => s.isNow);
  const [inputing, setInputing] = useState(false);
  const [year, setYear] = useState<number>();
  const [sem, setSem] = useState<number>();
  const [states, setStates] = useState<string>();
  const otherInsuranceInputRef = useRef<HTMLInputElement>(null);
  const nationalHealthInsurance = useRef<HTMLInputElement>(null);
  const studentInsurance = useRef<HTMLInputElement>(null);
  const otherInsurance = useRef<HTMLInputElement>(null);
  const [isNationalInsuranceChecked, setIsNationalInsuranceChecked] = useState(false);
  const [isGroupInsuranceChecked, setIsGroupInsuranceChecked] = useState(false);
  const [isOtherInsuranceChecked, setIsOtherInsuranceChecked] = useState(false);

  useEffect(() => {
    // 目前學期、學年
    const isNow = semesters.filter((y) => y.isNow == true)[0];
    setYear(isNow.year);
    setSem(isNow.sem);
    setIsNationalInsuranceChecked(false);
    setIsGroupInsuranceChecked(false);
    setIsOtherInsuranceChecked(false);
    setStates("");
    // 代入checkbox
    insurance.forEach((v) => {
      if(v.insurance === 1) setIsNationalInsuranceChecked(true);
      if(v.insurance === 2) setIsGroupInsuranceChecked(true);
      if(v.insurance === 9) {
        setIsOtherInsuranceChecked(true);
        setStates(v.states || "");
      }
    })
  }, [semesters]);

  useEffect(() => {
    if(otherInsuranceInputRef.current) {
      otherInsuranceInputRef.current.value = states ?? '';
    }
  }, [states]);

  function onValueUpdate(value: PHIInsurance) {}

  function onValueDelete(value: PHIInsurance) {}

  function insertStudentInsurance() {
    const data = [];
    if(isNationalInsuranceChecked) {
      data.push({
        studentId: student.pid,
        insurance: 1,
        states: ""
      });
    }
    if(isGroupInsuranceChecked) {
      data.push({
        studentId: student.pid,
        insurance: 2,
        states: ""
      });
    }
    if(isOtherInsuranceChecked) {
      data.push({
        studentId: student.pid,
        insurance: 9,
        states: states || ""
      });
    }
    toast
      .promise(
        apis.insertStudentInsurances(
          student.pid,
          data
        ),
        {
          pending: '資料新增中......',
          success: '新增成功！',
        }
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  return (
    <>
      <div>
        保險
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            保險 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <div className="d-flex align-items-center">
            <Col sm={2}>
              <FormCheck
                ref={nationalHealthInsurance}
                label="全民健保"
                checked={isNationalInsuranceChecked}
                onChange={(e) => {
                  if(e.target) {
                    setIsNationalInsuranceChecked(e.target.checked);
                  }
                }}
              />
            </Col>
            <Col sm={2}>  
              <FormCheck
                ref={studentInsurance}
                label="學生團保"
                checked={isGroupInsuranceChecked}
                onChange={(e) => {
                  if(e.target) {
                    setIsGroupInsuranceChecked(e.target.checked);
                  }
                }}
              />
            </Col>
            <Col sm={1} className='pr-0'> 
              <FormCheck
                ref={otherInsurance}
                label="其他"
                checked={isOtherInsuranceChecked}
                onChange={(e) => {
                  if(e.target) {
                    setIsOtherInsuranceChecked(e.target.checked);
                  }
                }}
              />
             </Col>
            <Col sm={2} className='pl-0'> 
              <InputGroup>
                <Form.Control
                  className="mr-2"
                  placeholder=""
                  aria-label="other"
                  aria-describedby="basic-addon1"
                  ref={otherInsuranceInputRef}
                  value={states ?? ''}
                  onChange={(e) => {
                    if(e.target) {
                      setStates(e.target.value);
                    }
                  }}
                />
              </InputGroup>
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={insertStudentInsurance}>
            確定
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
