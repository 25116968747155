import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table, Col } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { InjuryGradeClassStatistic } from '../../../model';
import { useMonth, useSemGrade } from '../../../hook';
import apis from '../../../apis';


const HDID = {
    // Total: -9,
    UnderWeight: -1,
    Healty: 0,
    OverWeight: 1,
    Obesity: 2,
};

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const injuryAccStCount: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { selectedmonth, element: element } = useMonth({
        yearSem,
    });
    const [datas, setDatas] = useState<InjuryGradeClassStatistic[]>([]);

    useEffect(() => {
        if (yearSem && selectedmonth) {
            apis.getInjuryAccStCount(yearSem.year, yearSem.sem, selectedmonth?.id).then((s) => {
                setDatas(s);
            });
        }
    }, [selectedmonth, yearSem?.year, yearSem?.sem]);

    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, InjuryGradeClassStatistic[]>);

    let schoolcount = 0;
    let schoolfemalecount = 0;
    let schoolmalecount = 0;

    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const classInjury = {
                grade: 0,
                classNo: 0,
                totalCount: 0,
                femaleCount: 0,
                maleCount: 0,
            };
            const rows = gs.flatMap((g, idx) => {
                return TeeToRows(
                    g.grade,
                    g.classNo ?? 0,
                    g.male?.count ?? 0,
                    g.female?.count ??0 ,
                    g.total?.count ?? 0,
                    idx === 0 ? { row: (gs.length + 1) } : undefined
                );
            });
            gs.forEach(i => {
                classInjury.totalCount += i.total?.count ?? 0,
                classInjury.femaleCount += i.female?.count ?? 0,
                classInjury.maleCount += i.male?.count ?? 0,
                schoolcount += i.total?.count ?? 0
                schoolfemalecount += i.female?.count ?? 0
                schoolmalecount += i.male?.count ?? 0
            });
            rows.push(...TeeToRows('', '小計', classInjury.maleCount, classInjury.femaleCount, classInjury.totalCount));
            if (idx === allArray.length - 1) {
                const lastRow = rows[rows.length - 1];

                lastRow.cells = lastRow?.cells.map((c) => {
                    const { style: { border = {} } = {} } = c;
                    border.bottom = borderStyle;
                    c.style = { ...c.style, border };
                    return c;
                });
            }

            return rows;
        }
    );
    const content: Row[] = [
        {
            cells: [
                {
                    value: '年班/性別',
                    merge: { column: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '男',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '女',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '小計',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
        ...TeeToRows('全校合計', '', schoolmalecount, schoolfemalecount, schoolcount, { column: 2, row: 2 }),
    ];

    // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <Col>
                    <BSRow>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                        <Col xs={3} className="mr-3">
                            {element}
                        </Col>
                    </BSRow>
                </Col>
                <div>
                    <Button
                        disabled={!datas.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            const workSheet = generateSheet(
                                [
                                    {
                                        cells: [
                                            {
                                                value: `${yearSem?.year}學年第${yearSem?.sem}學期_傷病人數統計(分月)`,
                                                style: { alignment: { horizontal: 'left' } },
                                            },
                                        ],
                                    },
                                    ...content,
                                    {
                                        cells: [
                                            {
                                                value:
                                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                                style: { alignment: { horizontal: 'left' } },
                                            },
                                        ],
                                    },
                                ],
                                {
                                    alignment: cellAlignStyle,
                                }
                            );
                            downloadSheets(`${yearSem?.year}學年第${yearSem?.sem}學期_傷病人數統計(分月)`, { sheet: workSheet, name: 'st' });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function TeeToRows(
    grade: number | string,
    classNo: number | string,
    maleCount: number,
    femaleCount: number,
    totalCount: number,
    gradeMerge?: { row?: number; column?: number }
): Row[] {
    const gradeNo = [
        {
            value: gradeMerge ? grade : undefined,
            merge: gradeMerge,
            style: { border: fullBorderStyle },
        },
    ];
    if (!gradeMerge?.column) {
        gradeNo.push({
            value: classNo,
            merge: { row: 1 },
            style: { border: fullBorderStyle },
        });
    }
    return [
        {
            cells: [
                ...gradeNo,
                {
                    value: maleCount ?? 0,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: femaleCount ?? 0,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: totalCount ?? 0,
                    style: { border: { left: borderStyle } },
                },
            ],
        },

    ];
}

export const InjuryAccStCount = connector(injuryAccStCount);
