import React, { Fragment, FunctionComponent } from 'react';
import { Card, Row, Spinner } from 'react-bootstrap';
import { ErrorModal } from '../components';

const loadingPage: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless text-center">
            <Card.Body>
              <Card.Title>Loading...</Card.Title>
              <Row className="justify-content-md-center">
                <Spinner animation="border" variant="info" />
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
      <ErrorModal />
    </Fragment>
  );
};

export const LoadingPage = loadingPage;
