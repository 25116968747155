import React, { FunctionComponent, ReactElement } from 'react';
import { ListGroup } from 'react-bootstrap';
import { MenuItem } from '../../model';
import { SideMenuCollapse } from './SideMenuCollapse';
import { SideMenuItem } from './SideMenuItem';

type Props = { group: MenuItem };

export const SideMenuGroup: FunctionComponent<Props> = ({ group }) => {
  let navItems: ReactElement[] = [];

  if (group.children) {
    const groups = group.children;
    navItems = Object.values(groups).reduce((elements, item, i) => {
      switch (item.type) {
        case 'collapse':
          item.icon = 'feather icon-menu';
          if (item.children?.some((s) => s.type === 'notice')) {
            elements.push(<SideMenuItem key={`${item.id}.${i}`} item={item} />);
          } else {
            elements.push(
              <SideMenuCollapse key={item.id} collapse={item} type="main" />
            );
          }
          break;
        case 'item':
          item.icon = 'feather icon-menu';
          elements.push(<SideMenuItem key={`${item.id}.${i}`} item={item} />);
          break;
        default:
      }
      return elements;
    }, navItems);
  }

  return (
    <React.Fragment>
      <ListGroup.Item
        as="li"
        bsPrefix=" "
        key={group.id}
        className="nav-item pcoded-menu-caption"
      >
        <label>{group.title}</label>
      </ListGroup.Item>
      {navItems}
    </React.Fragment>
  );
};
