import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';
import { CompareYearClassSightRate } from '../../../model';

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const bottomBorderStyle = {
  bottom: borderStyle,
};
const topBottomBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
};
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;
const cellAlignStyle = {
  horizontal: 'center',
  vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const sightDeteriorateRate: FunctionComponent<Props> = ({ catchErrorForModal }) => {
  const { yearSem, element } = useSemGrade();
  const [datas, setDatas] = useState<CompareYearClassSightRate[]>([]);

  useEffect(() => {
    if (yearSem) {
      apis.getSightDeteriorateRate(yearSem.year, yearSem.sem).then((s) => {
        console.log(s)
        setDatas(s)
      });
    }
  }, [yearSem?.year, yearSem?.sem]);


  let schoolYearfailtP = 0;
  const contentRows: Row[] = datas.flatMap(
    (gs, idx, allArray) => {
      gs.lastYearFailtP = percent(Number(gs.lastYearFailt), Number(gs.lastYearCount));
      gs.thisYearFailtP = percent(Number(gs.thisYearFailt), Number(gs.thisYearCount));
      gs.failtGAP = gs.thisYearFailtP - gs.lastYearFailtP;
      schoolYearfailtP = (schoolYearfailtP + Number(gs.failtGAP)) / (idx + 1);
      return sightToRow(
        gs.thisGrade,
        gs.thisYearCount,
        gs.thisYearFailt,
        gs.thisYearFailtP,
        gs.lastGrade,
        gs.lastYearCount,
        gs.lastYearFailt,
        gs.lastYearFailtP,
        gs.failtGAP
      )
    }
  );

  const content: Row[] = [
    {
      cells: [
        {
          value: '本學年度',
          merge: { column: 4 },
          style: { border: { top: borderStyle, left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '上學年度',
          merge: { column: 4 },
          style: { border: { top: borderStyle, left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '',
          merge: { column: 1 },
          style: { border: { top: borderStyle, left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        }
      ]
    },
    {
      cells: [
        {
          value: '年級',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '視力受檢人數A',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '裸眼視力不良人數B',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '裸眼視力不良率C',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '年級',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '視力受檢人數A',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '裸眼視力不良人數B',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '裸眼視力不良率C',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: '裸視篩檢視力不良惡化率D',
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
      ],
    },
    ...contentRows,
    {
      cells: [
        {
          value: '平均',
          merge: { column: 8 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
        {
          value: schoolYearfailtP + "%",
          merge: { column: 1 },
          style: { border: { left: borderStyle, right: borderStyle }, alignment: { horizontal: 'left' } },
        },
      ],
    },
  ];

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        <span className="mr-3">{element}</span>
        <Button
          disabled={!datas.length}
          onClick={() => {
            const workSheet = generateSheet(
              [
                {
                  cells: [
                    {
                      value: `${yearSem?.year}學年第${yearSem?.sem}學期_裸視篩檢視力不良惡化率`,
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
                ...content,
                {
                  cells: [
                    {
                      value:
                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
              ],
              {
                alignment: cellAlignStyle,
              }
            );
            downloadSheets(`${yearSem?.year}學年第${yearSem?.sem}學期_裸視篩檢視力不良惡化率`, { sheet: workSheet, name: 'SightMatrix' });
          }}
        >
          Excel 下載
        </Button>
      </BSRow>
      <hr />
      <BSRow>
        <Table
          striped
          bordered
          className="text-center"
          style={{ tableLayout: 'fixed' }}
        >
          <colgroup>
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
          </colgroup>
          <tbody>
            {content.map((r, i) => {
              const cells = r.cells.map((c, j) =>
                c.value != undefined ? (
                  <td
                    colSpan={c.merge?.column}
                    rowSpan={c.merge?.row}
                    key={`${i}-${j}`}
                  >
                    {c.value}
                  </td>
                ) : (
                  <Fragment key={`${i}-${j}`}></Fragment>
                )
              );
              return <tr key={i}>{cells}</tr>;
            })}
          </tbody>
        </Table>
      </BSRow>
    </AuthedLayout>
  );

};

function sightToRow(
  thisgrade: number,
  thisyearcount: number,
  thisyearfailt: number,
  thisyearfailtP: number,
  lastgrade: number,
  lastyearcount: number,
  lastyearfailt: number,
  lastyearfailtP: number,
  failtGAP: number
): Row[] {
  return [
    {
      cells: [
        {
          value: thisgrade,
          style: { border: bottomBorderStyle },
        }, {
          value: thisyearcount,
          style: { border: bottomBorderStyle },
        }, {
          value: thisyearfailt,
          style: { border: bottomBorderStyle },
        },
        {
          value: thisyearfailtP + "%",
          style: { border: bottomBorderStyle },
        }, {
          value: lastgrade,
          style: { border: bottomBorderStyle },
        }, {
          value: lastyearcount,
          style: { border: bottomBorderStyle },
        }, {
          value: lastyearfailt,
          style: { border: bottomBorderStyle },
        },
        {
          value: lastyearfailtP + "%",
          style: { border: bottomBorderStyle },
        },
        {
          value: failtGAP + "%",
          style: { border: bottomBorderStyle },
        }
      ]
    }
  ];
}
function percent(value: number, denominator: number): number {
  if (!denominator) return 0.00;
  return Number(((value / denominator) * 100).toFixed(2));
}


export const SightDeteriorateRate = connector(sightDeteriorateRate);