import { MutableRefObject, useEffect } from 'react';

type Callback = () => void;

export const useOutsideClick = (
  ref: MutableRefObject<any>,
  callback: Callback
) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
