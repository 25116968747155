import React, { FunctionComponent, useState } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ErrorModal } from '../components';
import { AuthDispatches, ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';

const mapState = (state: ApplicationState) => ({
  ...state.auth,
  ...state.error,
});
const mapDispatch = { ...AuthDispatches, ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;
const loginPage: FunctionComponent<Props> = ({
  isAuth,
  signIn,
  catchErrorForModal,
}) => {
  const qs = new URLSearchParams(window.location.search);
  const lastPath = qs.get('lastPath');
  if (lastPath && isAuth) {
    return <Redirect to={lastPath} />;
  }
  const [account, setAccount] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  document.title = '學生健康資訊系統';
  return (
    <React.Fragment>
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless text-center">
            <Card.Body>
              <Card.Title>學生健康資訊系統</Card.Title>
              <Form.Group controlId="formAccount">
                <Form.Label>帳號</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="請輸入帳號"
                  value={account}
                  autoComplete='off'
                  onChange={(e) => setAccount(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label>密碼</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="請輸入密碼"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={() =>
                  signIn(account, password).catch(catchErrorForModal)
                }
              >
                登入
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
      <ErrorModal />
    </React.Fragment>
  );
};

export const LoginPage = connector(loginPage);
