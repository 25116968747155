import React, {
  FunctionComponent,
  ReactNode,
  RefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ExaminedResultEnum,
  LeftRightEnum,
  PHIPhysical,
  Semester,
  StrabismusKindEnum,
  Student,
  TeeInspection,
  YearSemClasses,
} from '../../model';
import {
  DateTimeField,
  InputDropdownGroupField,
  TeeSheetWithButton,
  TextField,
} from '../../components';
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { DateTime } from 'luxon';
import apis, { ApiError, E_GRADE_INVALID } from '../../apis';
import { I18N } from '../../i18n-raw';
import {
  ExaminedResultToTeeEnum,
  ExaminedResultToTeeFollowUpEnum,
  TeeFollowUpExaminationEnum,
  TeeInitialExaminationEnum,
  TeeToExaminedEnum,
} from '../../utils/tee';

type Props = {
  physicals: PHIPhysical[];
  editable: boolean;
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  grade: number;
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};

export const PhysicalSection: FunctionComponent<Props> = ({
  editable,
  physicals,
  student,
  semesters,
  yearSems,
  grade,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const pageData = useMemo(() => {
    const p = physicals.find((s) => s.grade === grade && s.sem === 1);
    if (p) return PHIPhysicalToPageData(p);
    return undefined;
  }, [physicals, grade]);
  function onValueUpdate(value: PageData, examined: boolean) {
    const physical = PageDataToPHIPhysical({
      ...generatePhysical(grade, student.sex),
      ...value,
    });
    const validGrade = yearSems.find((s) => s.grade === grade && s.sem === 1);
    if (validGrade) {
      const promise = examined
        ? apis.InsertStudentPhysical(student.pid, {
            ...physical,
            grade: validGrade.grade,
            sem: 1,
            year: validGrade.year,
          })
        : apis.deleteStudentPhysical(student.pid, validGrade.year, 1);

      promise.catch(onApiError).then(() => {
        onValueUpdated();
      });
    } else {
      onApiError(new ApiError(E_GRADE_INVALID));
    }
    setInputing(false);
  }

  return (
    <>
      <div className="sheet-title">
        身體診察 {grade ?? ''}年級
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Table bordered className="student-phi">
        <colgroup>
          <col width={'20%'} />
          <col width={'80%'} />
        </colgroup>
        <tbody>
          <tr>
            <td>學期/日期</td>
            <td>
              {pageData
                ? pageData.examDate?.toFormat('yyyy/MM/dd') ?? '無檢查日期'
                : '尚未受檢'}
            </td>
          </tr>
          {pageData ? generatePhysicalSummarize(pageData) : <></>}
        </tbody>
        {inputing ? (
          <PhysicalModel
            inputing={inputing}
            physical={pageData}
            grade={grade}
            student={student}
            onClose={() => setInputing(false)}
            onValueUpdate={onValueUpdate}
          />
        ) : (
          <></>
        )}
      </Table>
    </>
  );
};

type ModelProps = {
  inputing: boolean;
  physical?: PageData;
  student: Student;
  grade: number;
  onClose: () => void;
  onValueUpdate: (v: PageData, examined: boolean) => void;
};

function PhysicalModel({
  inputing,
  physical,
  student,
  grade,
  onClose,
  onValueUpdate,
}: ModelProps): JSX.Element {
  const [examined, setExamined] = useState(!!physical);

  const dbpPulseRef = useRef<HTMLTableCellElement>(null);
  const eyeRef = useRef<HTMLTableCellElement>(null);
  const entRef = useRef<HTMLTableCellElement>(null);
  const neckRef = useRef<HTMLTableCellElement>(null);
  const chestRef = useRef<HTMLTableCellElement>(null);
  const abdomenRef = useRef<HTMLTableCellElement>(null);
  const spineRef = useRef<HTMLTableCellElement>(null);
  const urogenitalRef = useRef<HTMLTableCellElement>(null);
  const skinRef = useRef<HTMLTableCellElement>(null);
  const oralRef = useRef<HTMLTableCellElement>(null);
  const reOralRef = useRef<HTMLTableCellElement>(null);

  return (
    <Modal
      show={inputing}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      dialogClassName="modal-dialog-full"
      centered
    >
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          身體診察 -{' '}
          <strong>
            {student.seat} {student.name}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <Form
          initialValues={physical ?? PHIPhysicalToPageData(NoExaminedPhysical)}
          onSubmit={(v: PageData) => {
            onValueUpdate(v, examined);
          }}
          validateOnBlur
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, form }) => {
            return (
              <Table striped bordered hover className="text-break">
                <colgroup>
                  <col width={'7%'} />
                  <col width={'90%'} />
                </colgroup>
                <thead>
                  <tr>
                    <td className="align-middle">項目</td>
                    <td className="align-middle">
                      <Row noGutters className="align-items-center">
                        <Col xs={12} md={2}>
                          身分證： {student.pid}
                        </Col>
                        <Col xs={12} md={2}>
                          檢查年級： {grade}
                        </Col>
                        <Col xs={12} md={2}>
                          是否受檢：{' '}
                          <DropdownButton
                            className="d-inline mr-2"
                            title={examined ? '是' : '否'}
                            onSelect={(k: string | null) => {
                              setExamined((lastExamine) => {
                                const nextExamine = k == '0' ? false : true;
                                if (!lastExamine && nextExamine) {
                                  form.reset(
                                    PHIPhysicalToPageData(
                                      generatePhysical(grade, student.sex)
                                    )
                                  );
                                } else if (!nextExamine) {
                                  form.reset(
                                    PHIPhysicalToPageData(NoExaminedPhysical)
                                  );
                                }
                                return nextExamine;
                              });
                            }}
                          >
                            <Dropdown.Item eventKey={0}>否</Dropdown.Item>
                            <Dropdown.Item eventKey={1}>是</Dropdown.Item>
                          </DropdownButton>
                        </Col>
                        <Col xs={16} md={6}>
                          若是否受檢選擇&quot;否&quot;，則存檔後系統會自動將下列各項目值設定為
                          9（未受檢）
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="align-middle">
                      血壓
                      <br />
                      腰圍
                    </th>
                    <td className="align-middle" ref={dbpPulseRef}>
                      <Row noGutters className="align-items-center">
                        <div className="d-flex align-items-center px-2 my-1">
                          <span className="mx-1">收縮壓：</span>
                          <TextField
                            property="sbp"
                            className="wid-120"
                            disabled={!examined}
                          />
                        </div>
                        <div className="d-flex align-items-center px-2 my-1">
                          <span className="mx-1">舒張壓：</span>
                          <TextField
                            property="dbp"
                            className="wid-120"
                            disabled={!examined}
                          />
                          <InputDropdownGroupField
                            property="bpResult"
                            content={I18N.ExaminationResult}
                            type="number"
                            containerRef={dbpPulseRef}
                            className="wid-40"
                            disabled={!examined}
                            needGroup={false}
                          />
                          <span className="mx-1">血壓判讀</span>
                        </div>
                        <div className="d-flex align-items-center px-2 my-1">
                          <span className="mx-1">腰圍：</span>
                          <TextField
                            property="waist"
                            className="wid-120"
                            disabled={!examined}
                          />
                        </div>
                        <div className="d-flex align-items-center px-2 my-1">
                          <span className="mx-1">脈博：</span>
                          <TextField
                            property="pulse"
                            className="wid-120"
                            disabled={!examined}
                          />
                          <InputDropdownGroupField
                            property="pulseResult"
                            content={I18N.ExaminationResult}
                            type="number"
                            containerRef={dbpPulseRef}
                            className="wid-40"
                            disabled={!examined}
                            needGroup={false}
                          />
                          <span className="mx-1">脈搏判讀</span>
                        </div>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      眼
                    </th>
                    <td className="align-middle" ref={eyeRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '辨色力異常',
                          'colorBlind',
                          eyeRef,
                          !examined
                        )}
                        {generateExaminedAndSubValue(
                          '斜視',
                          'strabismus',
                          eyeRef,
                          'strabismusType',
                          I18N.StrabismusResult,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '睫毛倒插',
                          'trichiasis',
                          eyeRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '眼球震顫',
                          'nystagmus',
                          eyeRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '眼瞼下垂',
                          'blepharoptosis',
                          eyeRef,
                          !examined
                        )}
                        {generate99state('e99', eyeRef, 'e99State', !examined)}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      耳鼻喉
                    </th>
                    <td className="align-middle" ref={entRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedAndSubValue(
                          '聽力異常',
                          'hearing',
                          entRef,
                          'hearingLR',
                          I18N.LeftRightResult,
                          !examined,
                          'wid-70'
                        )}
                        {generateExaminedAndSubValue(
                          '耳膜破損',
                          'eardrum',
                          entRef,
                          'eardrumLR',
                          I18N.LeftRightResult,
                          !examined,
                          'wid-70'
                        )}
                        {generateExaminedAndSubValue(
                          '耳道畸型',
                          'earMisshapen',
                          entRef,
                          'earMisshapenLR',
                          I18N.LeftRightResult,
                          !examined,
                          'wid-70'
                        )}
                        {generateExaminedOnly(
                          '唇顎裂',
                          'clp',
                          entRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '構音異常',
                          'articulationDisorders',
                          entRef,
                          !examined
                        )}
                        {generateExaminedAndSubValue(
                          '耳前瘻管',
                          'preauricularFistula',
                          entRef,
                          'preauricularFistulaLR',
                          I18N.LeftRightResult,
                          !examined,
                          'wid-70'
                        )}
                        {generateExaminedAndSubValue(
                          '耵聹栓塞',
                          'cerumen',
                          entRef,
                          'cerumenLR',
                          I18N.LeftRightResult,
                          !examined,
                          'wid-70'
                        )}
                        {generateExaminedOnly(
                          '慢性鼻炎',
                          'rhinitis',
                          entRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '過敏性鼻炎',
                          'allergicRhinitis',
                          entRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '扁桃腺腫大',
                          'tonsillitis',
                          entRef,
                          !examined
                        )}
                        {generate99state('o99', entRef, 'o99State', !examined)}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      頭頸
                    </th>
                    <td className="align-middle" ref={neckRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '斜頸',
                          'torticollis',
                          neckRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '異常腫塊',
                          'mass',
                          neckRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '甲狀腺腫',
                          'goiter',
                          neckRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '淋巴腺腫大',
                          'lymphadenectasis',
                          neckRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '其他異常腫塊',
                          'mass99',
                          neckRef,
                          !examined
                        )}
                        {generate99state('n99', neckRef, 'n99State', !examined)}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      胸部
                    </th>
                    <td className="align-middle" ref={chestRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '心肺疾病',
                          'cardiopulmonary',
                          chestRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '心雜音',
                          'heartMurmur',
                          chestRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '心律不整',
                          'arrhythmia',
                          chestRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '呼吸聲異常',
                          'wheeze',
                          chestRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '其他心肺疾病',
                          'cardiopulmonary99',
                          chestRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '胸廓異常',
                          'thorax',
                          chestRef,
                          !examined
                        )}
                        {generate99state(
                          'c99',
                          chestRef,
                          'c99State',
                          !examined
                        )}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      腹部
                    </th>
                    <td className="align-middle" ref={abdomenRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '腹部異常腫大',
                          'abdomen',
                          abdomenRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '疝氣',
                          'hernia',
                          abdomenRef,
                          !examined
                        )}
                        {generate99state(
                          'a99',
                          abdomenRef,
                          'a99State',
                          !examined
                        )}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      脊柱
                      <br />
                      四肢
                    </th>
                    <td className="align-middle" ref={spineRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '脊柱側彎',
                          'scoliosis',
                          spineRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '肢體畸形',
                          'dysmelia',
                          spineRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '多併指（趾）',
                          'polydactyly',
                          spineRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '關節變形',
                          'dysarthrosis',
                          spineRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '其他肢體畸形',
                          'dysmelia99',
                          spineRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '蹲踞困難',
                          'squatting',
                          spineRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '水腫',
                          'edema',
                          spineRef,
                          !examined
                        )}
                        {generate99state(
                          'l99',
                          spineRef,
                          'l99State',
                          !examined
                        )}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      泌尿
                      <br />
                      生殖
                    </th>
                    <td className="align-middle" ref={urogenitalRef}>
                      <Row
                        noGutters
                        className={`align-items-center ${
                          student.sex === '2' ? 'd-none' : ''
                        }`}
                      >
                        {generateExaminedOnly(
                          '隱睪',
                          'cryptorchidism',
                          urogenitalRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '陰囊腫大',
                          'scrotalSwelling',
                          urogenitalRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '包皮異常',
                          'prepuce',
                          urogenitalRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '精索靜脈曲張',
                          'varicocele',
                          urogenitalRef,
                          !examined
                        )}
                        {generate99state(
                          'u99',
                          urogenitalRef,
                          'u99State',
                          !examined
                        )}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      皮膚
                    </th>
                    <td className="align-middle" ref={skinRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '癬',
                          'epidermophytosis',
                          skinRef,
                          !examined
                        )}
                        {generateExaminedOnly('疣', 'wart', skinRef, !examined)}
                        {generateExaminedOnly(
                          '紫斑',
                          'purpura',
                          skinRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '疥瘡',
                          'scabies',
                          skinRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '溼疹',
                          'eczema',
                          skinRef,
                          !examined
                        )}
                        {generateExaminedOnly(
                          '異位性皮膚炎',
                          'atopicDermatitis',
                          skinRef,
                          !examined
                        )}
                        {generate99state('d99', skinRef, 'd99State', !examined)}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      口腔
                      <br />
                      初檢
                    </th>
                    <td className="align-middle" ref={oralRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '未治療齲齒',
                          't01',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '已治療齲齒',
                          't11',
                          oralRef,
                          !examined,
                          I18N.FirstExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '上顎恆牙第一大臼齒齲齒經驗',
                          't12',
                          oralRef,
                          !examined,
                          I18N.FirstExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '下顎恆牙第一大臼齒齲齒經驗',
                          't13',
                          oralRef,
                          !examined,
                          I18N.FirstExaminationOnlyResult
                        )}

                        {generateExaminedOnly(
                          '恆牙臼齒窩溝封填',
                          't15',
                          oralRef,
                          !examined,
                          I18N.FirstExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '口腔衛生不良',
                          't04',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '牙結石',
                          't05',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '牙齦炎',
                          't08',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '咬合不正',
                          't07',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '口腔黏膜異常',
                          't09',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}

                        {generateExaminedOnly(
                          '牙周病',
                          't16',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '乳牙待拔牙',
                          't17',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '待拔牙',
                          't03',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '贅生牙',
                          't18',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '缺牙',
                          't02',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}
                        {generateExaminedOnly(
                          '阻生牙',
                          't19',
                          oralRef,
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}

                        {generate99state(
                          't99',
                          oralRef,
                          't99State',
                          !examined,
                          I18N.SingleExaminationOnlyResult
                        )}

                        <div className="d-flex align-items-center px-2 my-1">
                          <div className="mr-1">口檢表</div>
                          <div className="wid-300">
                            <TeeSheetWithButton disabled={!examined} />
                          </div>
                        </div>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      口腔
                      <br />
                      複檢
                    </th>
                    <td className="align-middle" ref={reOralRef}>
                      <Row noGutters className="align-items-center">
                        {generateExaminedOnly(
                          '未治療齲齒',
                          'ret01',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t01 != TeeInitialExaminationEnum.NoExamined &&
                              v.t01 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '口腔衛生不良',
                          'ret04',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t04 != TeeInitialExaminationEnum.NoExamined &&
                              v.t04 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '牙結石',
                          'ret05',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t05 != TeeInitialExaminationEnum.NoExamined &&
                              v.t05 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '牙齦炎',
                          'ret08',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t08 != TeeInitialExaminationEnum.NoExamined &&
                              v.t08 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '咬合不正',
                          'ret07',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t07 != TeeInitialExaminationEnum.NoExamined &&
                              v.t07 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '口腔黏膜異常',
                          'ret09',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t09 != TeeInitialExaminationEnum.NoExamined &&
                              v.t09 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}

                        {generateExaminedOnly(
                          '牙周病',
                          'ret16',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t16 != TeeInitialExaminationEnum.NoExamined &&
                              v.t16 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '乳牙待拔牙',
                          'ret17',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t17 != TeeInitialExaminationEnum.NoExamined &&
                              v.t17 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '待拔牙',
                          'ret03',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t03 != TeeInitialExaminationEnum.NoExamined &&
                              v.t03 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '贅生牙',
                          'ret18',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t18 != TeeInitialExaminationEnum.NoExamined &&
                              v.t18 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '缺牙',
                          'ret02',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t02 != TeeInitialExaminationEnum.NoExamined &&
                              v.t02 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generateExaminedOnly(
                          '阻生牙',
                          'ret19',
                          reOralRef,
                          (v) =>
                            !(
                              examined &&
                              v.t19 != TeeInitialExaminationEnum.NoExamined &&
                              v.t19 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}
                        {generate99state(
                          'reT99',
                          reOralRef,
                          'reT99State',
                          (v) =>
                            !(
                              examined &&
                              v.t99 != TeeInitialExaminationEnum.NoExamined &&
                              v.t99 != TeeInitialExaminationEnum.NoProblem
                            ),
                          I18N.FollowUpExaminationResult
                        )}

                        <div className="d-flex align-items-center px-2 my-1 ">
                          <div className="mr-1">複檢口檢表</div>
                          <div className="wid-300">
                            <TeeSheetWithButton
                              property="reTSheet"
                              disabled={!examined}
                            />
                          </div>
                        </div>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="align-middle">
                      檢查
                      <br />
                      單位
                    </th>
                    <td className="align-middle">
                      <Row noGutters className="align-items-center">
                        <div className="d-flex align-items-center px-2 my-1">
                          檢查單位：
                          <TextField
                            property="checkUnit"
                            disabled={!examined}
                            className="ml-1 wid-200"
                          />
                        </div>
                        <div className="d-flex align-items-center px-2 my-1">
                          醫師：
                          <TextField
                            property="doctor"
                            disabled={!examined}
                            className="ml-1 wid-200"
                          />
                        </div>
                        <div className="d-flex align-items-center px-2 my-1">
                          牙醫師：
                          <TextField
                            property="teeDoctor"
                            disabled={!examined}
                            className="ml-1 wid-200"
                          />
                        </div>
                        <div className="d-flex align-items-center px-2 my-1">
                          檢查日期：
                          <DateTimeField
                            property="examDate"
                            disabled={!examined}
                            className="ml-1 wid-200"
                            end={DateTime.now()}
                          />
                        </div>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="align-middle">
                      <Button className="mx-2" onClick={() => handleSubmit()}>
                        <span className="feather icon-check mr-1" />
                        儲存
                      </Button>
                      <Button
                        className="mx-2"
                        variant="danger"
                        onClick={onClose}
                      >
                        <span className="feather icon-x  mr-1" />
                        取消
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            );
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

function generateExaminedOnly(
  display: string,
  property: string,
  dropdownRef: RefObject<HTMLTableCellElement>,
  disabled: boolean | ((v: PageData) => boolean),
  content: Record<number, string> = I18N.ExaminationResult
): ReactNode {
  return (
    <div className="d-flex align-items-center px-2 my-1">
      <InputDropdownGroupField
        className={`wid-40 ml-1`}
        property={property}
        content={content}
        disabled={disabled}
        type="number"
        containerRef={dropdownRef}
        maxLength={1}
        needGroup={false}
      />
      <span className="mx-1">{display}</span>
    </div>
  );
}
function generateExaminedAndSubValue(
  display: string,
  property: string,
  dropdownRef: RefObject<HTMLTableCellElement>,
  subProperty: string,
  subValues: Record<number, string>,
  disabled: boolean,
  subInputClassName: string = 'wid-120'
): ReactNode {
  return (
    <div className="d-flex align-items-center px-2 my-1">
      <InputDropdownGroupField
        className={`wid-40 ml-1`}
        property={property}
        content={I18N.ExaminationResult}
        type="number"
        disabled={disabled}
        containerRef={dropdownRef}
        maxLength={1}
        needGroup={false}
      />
      <span className="mx-1">{display}</span>
      <InputDropdownGroupField
        resultClassName={`${subInputClassName} mr-1`}
        className="wid-40"
        property={subProperty}
        content={subValues}
        type="number"
        disabled={disabled}
        resultShow="both"
        containerRef={dropdownRef}
        needGroup={false}
      />
    </div>
  );
}
function generate99state(
  property: string,
  dropdownRef: RefObject<HTMLTableCellElement>,
  subProperty: string,
  disabled: boolean | ((v: PageData) => boolean),
  content: Record<number, string> = I18N.ExaminationResult
): ReactNode {
  return (
    <div className="d-flex align-items-center px-2 my-1">
      <InputDropdownGroupField
        className={`wid-40 mx-1`}
        property={property}
        content={content}
        type="number"
        containerRef={dropdownRef}
        needGroup={false}
        disabled={disabled}
      />
      <div className="">其他</div>
      <TextField
        className="wid-200 mx-1"
        property={subProperty}
        disabled={disabled}
      />
    </div>
  );
}

export function invalidStatus(data: ExaminedResultEnum | TeeInspection): boolean {
  return data === 1 || data === 2 || data === 3;
}

function generatePhysicalSummarize(pageData: PageData): ReactNode {
  const nodes: ReactNode[] = [];
  if (
    invalidStatus(pageData.colorBlind) ||
    invalidStatus(pageData.strabismus) ||
    invalidStatus(pageData.trichiasis) ||
    invalidStatus(pageData.nystagmus) ||
    invalidStatus(pageData.blepharoptosis) ||
    invalidStatus(pageData.e99)
  ) {
    nodes.push(
      <tr key={'eye'}>
        <td>眼</td>
        <td>
          {generateUnhealthy(pageData.colorBlind, 'colorBlind')}
          {generateUnhealthy(
            pageData.strabismus,
            'strabismus',
            I18N.StrabismusResult[pageData.strabismusType]
          )}
          {generateUnhealthy(pageData.trichiasis, 'trichiasis')}
          {generateUnhealthy(pageData.nystagmus, 'nystagmus')}
          {generateUnhealthy(pageData.blepharoptosis, 'blepharoptosis')}
          {generateUnhealthy(pageData.e99, 'e99', pageData.e99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(pageData.hearing) ||
    invalidStatus(pageData.eardrum) ||
    invalidStatus(pageData.earMisshapen) ||
    invalidStatus(pageData.clp) ||
    invalidStatus(pageData.articulationDisorders) ||
    invalidStatus(pageData.preauricularFistula) ||
    invalidStatus(pageData.cerumen) ||
    invalidStatus(pageData.rhinitis) ||
    invalidStatus(pageData.allergicRhinitis) ||
    invalidStatus(pageData.tonsillitis) ||
    invalidStatus(pageData.o99)
  ) {
    nodes.push(
      <tr key={'ent'}>
        <td>耳鼻喉</td>
        <td>
          {generateUnhealthy(
            pageData.hearing,
            'hearing',
            I18N.LeftRightResult[pageData.hearingLR]
          )}
          {generateUnhealthy(
            pageData.eardrum,
            'eardrum',
            I18N.LeftRightResult[pageData.eardrumLR]
          )}
          {generateUnhealthy(
            pageData.earMisshapen,
            'earMisshapen',
            I18N.LeftRightResult[pageData.earMisshapenLR]
          )}
          {generateUnhealthy(pageData.clp, 'clp')}
          {generateUnhealthy(
            pageData.articulationDisorders,
            'articulationDisorders'
          )}
          {generateUnhealthy(
            pageData.preauricularFistula,
            'preauricularFistula',
            I18N.LeftRightResult[pageData.preauricularFistulaLR]
          )}
          {generateUnhealthy(
            pageData.cerumen,
            'cerumen',
            I18N.LeftRightResult[pageData.cerumenLR]
          )}
          {generateUnhealthy(pageData.rhinitis, 'rhinitis')}
          {generateUnhealthy(pageData.allergicRhinitis, 'allergicRhinitis')}
          {generateUnhealthy(pageData.tonsillitis, 'tonsillitis')}
          {generateUnhealthy(pageData.o99, 'o99', pageData.o99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(pageData.torticollis) ||
    invalidStatus(pageData.mass) ||
    invalidStatus(pageData.goiter) ||
    invalidStatus(pageData.lymphadenectasis) ||
    invalidStatus(pageData.mass99) ||
    invalidStatus(pageData.n99)
  ) {
    nodes.push(
      <tr key={'neck'}>
        <td>頭頸</td>
        <td>
          {generateUnhealthy(pageData.torticollis, 'torticollis')}
          {generateUnhealthy(pageData.mass, 'mass')}
          {generateUnhealthy(pageData.goiter, 'goiter')}
          {generateUnhealthy(pageData.lymphadenectasis, 'lymphadenectasis')}
          {generateUnhealthy(pageData.mass99, 'mass99')}
          {generateUnhealthy(pageData.n99, 'n99', pageData.n99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(pageData.cardiopulmonary) ||
    invalidStatus(pageData.heartMurmur) ||
    invalidStatus(pageData.arrhythmia) ||
    invalidStatus(pageData.wheeze) ||
    invalidStatus(pageData.cardiopulmonary99) ||
    invalidStatus(pageData.thorax) ||
    invalidStatus(pageData.c99)
  ) {
    nodes.push(
      <tr key={'chest'}>
        <td>胸部</td>
        <td>
          {generateUnhealthy(pageData.cardiopulmonary, 'cardiopulmonary')}
          {generateUnhealthy(pageData.heartMurmur, 'heartMurmur')}
          {generateUnhealthy(pageData.arrhythmia, 'arrhythmia')}
          {generateUnhealthy(pageData.wheeze, 'wheeze')}
          {generateUnhealthy(pageData.cardiopulmonary99, 'cardiopulmonary99')}
          {generateUnhealthy(pageData.thorax, 'thorax')}
          {generateUnhealthy(pageData.c99, 'c99', pageData.c99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(pageData.abdomen) ||
    invalidStatus(pageData.hernia) ||
    invalidStatus(pageData.a99)
  ) {
    nodes.push(
      <tr key={'abdomen'}>
        <td>腹部</td>
        <td>
          {generateUnhealthy(pageData.abdomen, 'abdomen')}
          {generateUnhealthy(pageData.hernia, 'hernia')}
          {generateUnhealthy(pageData.a99, 'a99', pageData.a99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(pageData.scoliosis) ||
    invalidStatus(pageData.dysmelia) ||
    invalidStatus(pageData.polydactyly) ||
    invalidStatus(pageData.dysarthrosis) ||
    invalidStatus(pageData.dysmelia99) ||
    invalidStatus(pageData.squatting) ||
    invalidStatus(pageData.edema) ||
    invalidStatus(pageData.l99)
  ) {
    nodes.push(
      <tr key={'spine'}>
        <td>脊柱四肢</td>
        <td>
          {generateUnhealthy(pageData.scoliosis, 'scoliosis')}
          {generateUnhealthy(pageData.dysmelia, 'dysmelia')}
          {generateUnhealthy(pageData.polydactyly, 'polydactyly')}
          {generateUnhealthy(pageData.dysarthrosis, 'dysarthrosis')}
          {generateUnhealthy(pageData.dysmelia99, 'dysmelia99')}
          {generateUnhealthy(pageData.squatting, 'squatting')}
          {generateUnhealthy(pageData.edema, 'edema')}
          {generateUnhealthy(pageData.l99, 'l99', pageData.l99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(pageData.cryptorchidism) ||
    invalidStatus(pageData.scrotalSwelling) ||
    invalidStatus(pageData.prepuce) ||
    invalidStatus(pageData.varicocele) ||
    invalidStatus(pageData.u99)
  ) {
    nodes.push(
      <tr key={'urogenital'}>
        <td>泌尿生殖</td>
        <td>
          {generateUnhealthy(pageData.cryptorchidism, 'cryptorchidism')}
          {generateUnhealthy(pageData.scrotalSwelling, 'scrotalSwelling')}
          {generateUnhealthy(pageData.prepuce, 'prepuce')}
          {generateUnhealthy(pageData.varicocele, 'varicocele')}
          {generateUnhealthy(pageData.u99, 'u99', pageData.u99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(pageData.epidermophytosis) ||
    invalidStatus(pageData.wart) ||
    invalidStatus(pageData.purpura) ||
    invalidStatus(pageData.scabies) ||
    invalidStatus(pageData.eczema) ||
    invalidStatus(pageData.atopicDermatitis) ||
    invalidStatus(pageData.d99)
  ) {
    nodes.push(
      <tr key={'skin'}>
        <td>皮膚</td>
        <td>
          {generateUnhealthy(pageData.epidermophytosis, 'epidermophytosis')}
          {generateUnhealthy(pageData.wart, 'wart')}
          {generateUnhealthy(pageData.purpura, 'purpura')}
          {generateUnhealthy(pageData.scabies, 'scabies')}
          {generateUnhealthy(pageData.eczema, 'eczema')}
          {generateUnhealthy(pageData.atopicDermatitis, 'atopicDermatitis')}
          {generateUnhealthy(pageData.d99, 'd99', pageData.d99State)}
        </td>
      </tr>
    );
  }

  if (
    invalidStatus(TeeToExaminedEnum(pageData.t01, pageData.ret01)) ||
    invalidStatus(pageData.t11) ||
    invalidStatus(pageData.t12) ||
    invalidStatus(pageData.t13) ||
    invalidStatus(pageData.t15) ||
    invalidStatus(TeeToExaminedEnum(pageData.t04, pageData.ret04)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t05, pageData.ret05)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t08, pageData.ret08)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t07, pageData.ret07)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t09, pageData.ret09)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t16, pageData.ret16)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t17, pageData.ret17)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t03, pageData.ret03)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t18, pageData.ret18)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t02, pageData.ret02)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t19, pageData.ret19)) ||
    invalidStatus(TeeToExaminedEnum(pageData.t99, pageData.ret99))
  ) {
    nodes.push(
      <tr key={'oral'}>
        <td>口腔</td>
        <td>
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t01, pageData.ret01),
            't01'
          )}
          {generateUnhealthy(pageData.t11, 't11')}
          {generateUnhealthy(pageData.t12, 't12')}
          {generateUnhealthy(pageData.t13, 't13')}
          {generateUnhealthy(pageData.t15, 't15')}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t04, pageData.ret04),
            't04'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t05, pageData.ret05),
            't05'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t08, pageData.ret08),
            't08'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t07, pageData.ret07),
            't07'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t09, pageData.ret09),
            't09'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t16, pageData.ret16),
            't16'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t17, pageData.ret17),
            't17'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t03, pageData.ret03),
            't03'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t18, pageData.ret18),
            't18'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t02, pageData.ret02),
            't02'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t19, pageData.ret19),
            't19'
          )}
          {generateUnhealthy(
            TeeToExaminedEnum(pageData.t99, pageData.ret99),
            't99',
            pageData.t99state
          )}
        </td>
      </tr>
    );
  }

  return nodes;
}

function generateUnhealthy(
  data: ExaminedResultEnum | TeeInspection,
  i18nKey: string,
  subType: string | null | undefined = ''
): ReactNode {
  return invalidStatus(data) ? (
    <div>
      <span className="text-danger">*{I18N.Physical[i18nKey]}</span>:{data}
      {subType ? `-${subType}` : ''}
    </div>
  ) : (
    ''
  );
}

function generatePhysical(grade: number, sex: string): PHIPhysical {
  const defaultPhysical = generateDefaultPhysical(grade);
  return {
    ...defaultPhysical,
    ...(sex === '2'
      ? {
          cryptorchidism: ExaminedResultEnum.NoExamined,
          prepuce: ExaminedResultEnum.NoExamined,
          varicocele: ExaminedResultEnum.NoExamined,
          u99: ExaminedResultEnum.NoExamined,
          scrotalSwelling: ExaminedResultEnum.NoExamined,
        }
      : {}),
  };
}

function generateDefaultPhysical(grade: number): PHIPhysical {
  switch (grade) {
    case 1:
      return {
        ...DefaultPhysical,
        // eye
        trichiasis: ExaminedResultEnum.NoProblem,

        // Ear, Nose, and Throat
        eardrum: ExaminedResultEnum.NoProblem,
        earMisshapen: ExaminedResultEnum.NoProblem,
        cerumen: ExaminedResultEnum.NoProblem,
        tonsillitis: ExaminedResultEnum.NoProblem,

        cryptorchidism: ExaminedResultEnum.NoProblem,
        t15: TeeInspection.None,
      };
    case 4:
      return {
        ...DefaultPhysical,
        trichiasis: ExaminedResultEnum.NoProblem,

        // Ear, Nose, and Throat
        eardrum: ExaminedResultEnum.NoProblem,
        earMisshapen: ExaminedResultEnum.NoProblem,
        cerumen: ExaminedResultEnum.NoProblem,
        tonsillitis: ExaminedResultEnum.NoProblem,
        t15: TeeInspection.None,
      };
    case 7:
      return {
        ...DefaultPhysical,
        t09: ExaminedResultEnum.NoProblem,
      };
    case 10:
    case 13:
      return {
        ...DefaultPhysical,
        bpResult: ExaminedResultEnum.NoProblem,
        pulseResult: ExaminedResultEnum.NoProblem,
        t09: ExaminedResultEnum.NoProblem,
      };
    default:
      return {
        ...DefaultPhysical,
        colorBlind: ExaminedResultEnum.NoExamined,
        e99: ExaminedResultEnum.NoExamined,

        hearing: ExaminedResultEnum.NoExamined,
        o99: ExaminedResultEnum.NoExamined,

        torticollis: ExaminedResultEnum.NoExamined,
        mass: ExaminedResultEnum.NoExamined,
        goiter: ExaminedResultEnum.NoExamined,
        lymphadenectasis: ExaminedResultEnum.NoExamined,
        mass99: ExaminedResultEnum.NoExamined,
        n99: ExaminedResultEnum.NoExamined,

        cardiopulmonary: ExaminedResultEnum.NoExamined,
        heartMurmur: ExaminedResultEnum.NoExamined,
        arrhythmia: ExaminedResultEnum.NoExamined,
        wheeze: ExaminedResultEnum.NoExamined,
        cardiopulmonary99: ExaminedResultEnum.NoExamined,
        thorax: ExaminedResultEnum.NoExamined,
        c99: ExaminedResultEnum.NoExamined,

        abdomen: ExaminedResultEnum.NoExamined,
        a99: ExaminedResultEnum.NoExamined,

        scoliosis: ExaminedResultEnum.NoExamined,
        dysmelia: ExaminedResultEnum.NoExamined,
        polydactyly: ExaminedResultEnum.NoExamined,
        dysarthrosis: ExaminedResultEnum.NoExamined,
        dysmelia99: ExaminedResultEnum.NoExamined,
        squatting: ExaminedResultEnum.NoExamined,
        l99: ExaminedResultEnum.NoExamined,

        prepuce: ExaminedResultEnum.NoExamined,
        varicocele: ExaminedResultEnum.NoExamined,
        u99: ExaminedResultEnum.NoExamined,

        epidermophytosis: ExaminedResultEnum.NoExamined,
        scabies: ExaminedResultEnum.NoExamined,
        wart: ExaminedResultEnum.NoExamined,
        atopicDermatitis: ExaminedResultEnum.NoExamined,
        eczema: ExaminedResultEnum.NoExamined,
        d99: ExaminedResultEnum.NoExamined,

        t01: ExaminedResultEnum.NoExamined,
        t04: ExaminedResultEnum.NoExamined,
        t05: ExaminedResultEnum.NoExamined,
        t08: ExaminedResultEnum.NoExamined,
        t07: ExaminedResultEnum.NoExamined,
        t99: ExaminedResultEnum.NoExamined,
      };
  }
}
 export function PHIPhysicalToPageData(physical: PHIPhysical): PageData {
  return {
    ...physical,
    t01: ExaminedResultToTeeEnum(physical.t01),
    ret01: ExaminedResultToTeeFollowUpEnum(physical.t01),
    t02: ExaminedResultToTeeEnum(physical.t02),
    ret02: ExaminedResultToTeeFollowUpEnum(physical.t02),
    t03: ExaminedResultToTeeEnum(physical.t03),
    ret03: ExaminedResultToTeeFollowUpEnum(physical.t03),
    t04: ExaminedResultToTeeEnum(physical.t04),
    ret04: ExaminedResultToTeeFollowUpEnum(physical.t04),
    t05: ExaminedResultToTeeEnum(physical.t05),
    ret05: ExaminedResultToTeeFollowUpEnum(physical.t05),
    t07: ExaminedResultToTeeEnum(physical.t07),
    ret07: ExaminedResultToTeeFollowUpEnum(physical.t07),
    t08: ExaminedResultToTeeEnum(physical.t08),
    ret08: ExaminedResultToTeeFollowUpEnum(physical.t08),
    t99: ExaminedResultToTeeEnum(physical.t99),
    ret99: ExaminedResultToTeeFollowUpEnum(physical.t99),
    t09: ExaminedResultToTeeEnum(physical.t09),
    ret09: ExaminedResultToTeeFollowUpEnum(physical.t09),
    t16: ExaminedResultToTeeEnum(physical.t16),
    ret16: ExaminedResultToTeeFollowUpEnum(physical.t16),
    t17: ExaminedResultToTeeEnum(physical.t17),
    ret17: ExaminedResultToTeeFollowUpEnum(physical.t17),
    t18: ExaminedResultToTeeEnum(physical.t18),
    ret18: ExaminedResultToTeeFollowUpEnum(physical.t18),
    t19: ExaminedResultToTeeEnum(physical.t19),
    ret19: ExaminedResultToTeeFollowUpEnum(physical.t19),
  };
}

function PageDataToPHIPhysical({
  t01,
  ret01,
  t02,
  ret02,
  t03,
  ret03,
  t04,
  ret04,
  t05,
  ret05,
  t07,
  ret07,
  t08,
  ret08,
  t99,
  ret99,
  t09,
  ret09,
  t16,
  ret16,
  t17,
  ret17,
  t18,
  ret18,
  t19,
  ret19,
  bpResult,
  waistResult,
  pulseResult,
  colorBlind,
  e99,
  hearing,
  hearingLR,
  earMisshapen,
  earMisshapenLR,
  eardrum,
  eardrumLR,
  cerumen,
  cerumenLR,
  tonsillitis,
  o99,
  torticollis,
  mass,
  goiter,
  lymphadenectasis,
  mass99,
  n99,
  cardiopulmonary,
  heartMurmur,
  arrhythmia,
  wheeze,
  cardiopulmonary99,
  thorax,
  c99,
  abdomen,
  a99,
  scoliosis,
  dysmelia,
  polydactyly,
  dysarthrosis,
  dysmelia99,
  squatting,
  l99,
  cryptorchidism,
  prepuce,
  varicocele,
  u99,
  epidermophytosis,
  scabies,
  wart,
  atopicDermatitis,
  eczema,
  d99,
  t06,
  t11,
  t12,
  t13,
  t15,
  strabismus,
  strabismusType,
  trichiasis,
  nystagmus,
  blepharoptosis,
  clp,
  articulationDisorders,
  preauricularFistula,
  preauricularFistulaLR,
  rhinitis,
  allergicRhinitis,
  hernia,
  edema,
  scrotalSwelling,
  purpura,
  ...physical
}: PageData): PHIPhysical {
  return {
    ...physical,
    t01: TeeToExaminedEnum(t01, ret01),
    t02: TeeToExaminedEnum(t02, ret02),
    t03: TeeToExaminedEnum(t03, ret03),
    t04: TeeToExaminedEnum(t04, ret04),
    t05: TeeToExaminedEnum(t05, ret05),
    t07: TeeToExaminedEnum(t07, ret07),
    t08: TeeToExaminedEnum(t08, ret08),
    t99: TeeToExaminedEnum(t99, ret99),
    t09: TeeToExaminedEnum(t09, ret09),
    t16: TeeToExaminedEnum(t16, ret16),
    t17: TeeToExaminedEnum(t17, ret17),
    t18: TeeToExaminedEnum(t18, ret18),
    t19: TeeToExaminedEnum(t19, ret19),
    bpResult: +bpResult,
    waistResult: +waistResult,
    pulseResult: +pulseResult,
    colorBlind: +colorBlind,
    e99: +e99,
    hearing: +hearing,
    hearingLR: +hearingLR,
    earMisshapen: +earMisshapen,
    earMisshapenLR: +earMisshapenLR,
    eardrum: +eardrum,
    eardrumLR: +eardrumLR,
    cerumen: +cerumen,
    cerumenLR: +cerumenLR,
    tonsillitis: +tonsillitis,
    o99: +o99,
    torticollis: +torticollis,
    mass: +mass,
    goiter: +goiter,
    lymphadenectasis: +lymphadenectasis,
    mass99: +mass99,
    n99: +n99,
    cardiopulmonary: +cardiopulmonary,
    heartMurmur: +heartMurmur,
    arrhythmia: +arrhythmia,
    wheeze: +wheeze,
    cardiopulmonary99: +cardiopulmonary99,
    thorax: +thorax,
    c99: +c99,
    abdomen: +abdomen,
    a99: +a99,
    scoliosis: +scoliosis,
    dysmelia: +dysmelia,
    polydactyly: +polydactyly,
    dysarthrosis: +dysarthrosis,
    dysmelia99: +dysmelia99,
    squatting: +squatting,
    l99: +l99,
    cryptorchidism: +cryptorchidism,
    prepuce: +prepuce,
    varicocele: +varicocele,
    u99: +u99,
    epidermophytosis: +epidermophytosis,
    scabies: +scabies,
    wart: +wart,
    atopicDermatitis: +atopicDermatitis,
    eczema: +eczema,
    d99: +d99,
    t06: +t06,
    t11: +t11,
    t12: +t12,
    t13: +t13,
    t15: +t15,
    strabismus: +strabismus,
    strabismusType: +strabismusType,
    trichiasis: +trichiasis,
    nystagmus: +nystagmus,
    blepharoptosis: +blepharoptosis,
    clp: +clp,
    articulationDisorders: +articulationDisorders,
    preauricularFistula: +preauricularFistula,
    preauricularFistulaLR: +preauricularFistulaLR,
    rhinitis: +rhinitis,
    allergicRhinitis: +allergicRhinitis,
    hernia: +hernia,
    edema: +edema,
    scrotalSwelling: +scrotalSwelling,
    purpura: +purpura,
  };
}

const DefaultPhysical: PHIPhysical = {
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  id: 0,
  bpResult: ExaminedResultEnum.NoExamined,
  waistResult: ExaminedResultEnum.NoExamined,
  pulseResult: ExaminedResultEnum.NoExamined,
  colorBlind: ExaminedResultEnum.NoProblem,
  e99: ExaminedResultEnum.NoProblem,
  hearing: ExaminedResultEnum.NoProblem,
  hearingLR: LeftRightEnum.None,
  earMisshapen: ExaminedResultEnum.NoExamined,
  earMisshapenLR: LeftRightEnum.None,
  eardrum: ExaminedResultEnum.NoExamined,
  eardrumLR: LeftRightEnum.None,
  cerumen: ExaminedResultEnum.NoExamined,
  cerumenLR: LeftRightEnum.None,
  tonsillitis: ExaminedResultEnum.NoExamined,
  o99: ExaminedResultEnum.NoProblem,
  torticollis: ExaminedResultEnum.NoProblem,
  mass: ExaminedResultEnum.NoProblem,
  goiter: ExaminedResultEnum.NoProblem,
  lymphadenectasis: ExaminedResultEnum.NoProblem,
  mass99: ExaminedResultEnum.NoProblem,
  n99: ExaminedResultEnum.NoProblem,
  cardiopulmonary: ExaminedResultEnum.NoProblem,
  heartMurmur: ExaminedResultEnum.NoProblem,
  arrhythmia: ExaminedResultEnum.NoProblem,
  wheeze: ExaminedResultEnum.NoProblem,
  cardiopulmonary99: ExaminedResultEnum.NoProblem,
  thorax: ExaminedResultEnum.NoProblem,
  c99: ExaminedResultEnum.NoProblem,
  abdomen: ExaminedResultEnum.NoProblem,
  a99: ExaminedResultEnum.NoProblem,
  scoliosis: ExaminedResultEnum.NoProblem,
  dysmelia: ExaminedResultEnum.NoProblem,
  polydactyly: ExaminedResultEnum.NoProblem,
  dysarthrosis: ExaminedResultEnum.NoProblem,
  dysmelia99: ExaminedResultEnum.NoProblem,
  squatting: ExaminedResultEnum.NoProblem,
  l99: ExaminedResultEnum.NoProblem,
  cryptorchidism: ExaminedResultEnum.NoExamined,
  prepuce: ExaminedResultEnum.NoProblem,
  varicocele: ExaminedResultEnum.NoProblem,
  u99: ExaminedResultEnum.NoProblem,
  epidermophytosis: ExaminedResultEnum.NoProblem,
  scabies: ExaminedResultEnum.NoProblem,
  wart: ExaminedResultEnum.NoProblem,
  atopicDermatitis: ExaminedResultEnum.NoProblem,
  eczema: ExaminedResultEnum.NoProblem,
  d99: ExaminedResultEnum.NoProblem,
  t01: ExaminedResultEnum.NoProblem,
  t02: ExaminedResultEnum.NoProblem,
  t03: ExaminedResultEnum.NoProblem,
  t04: ExaminedResultEnum.NoProblem,
  t05: ExaminedResultEnum.NoProblem,
  t06: ExaminedResultEnum.NoExamined,
  t07: ExaminedResultEnum.NoProblem,
  t08: ExaminedResultEnum.NoProblem,
  t99: ExaminedResultEnum.NoProblem,
  t09: ExaminedResultEnum.NoExamined,
  t11: TeeInspection.None,
  t12: TeeInspection.None,
  t13: TeeInspection.None,
  t15: TeeInspection.NoInspection,
  t16: ExaminedResultEnum.NoProblem,
  t17: ExaminedResultEnum.NoProblem,
  t18: ExaminedResultEnum.NoProblem,
  t19: ExaminedResultEnum.NoProblem,
  strabismus: ExaminedResultEnum.NoProblem,
  strabismusType: StrabismusKindEnum.None,
  trichiasis: ExaminedResultEnum.NoExamined,
  nystagmus: ExaminedResultEnum.NoExamined,
  blepharoptosis: ExaminedResultEnum.NoExamined,
  clp: ExaminedResultEnum.NoExamined,
  articulationDisorders: ExaminedResultEnum.NoExamined,
  preauricularFistula: ExaminedResultEnum.NoExamined,
  preauricularFistulaLR: ExaminedResultEnum.NoExamined,
  rhinitis: ExaminedResultEnum.NoExamined,
  allergicRhinitis: ExaminedResultEnum.NoExamined,
  hernia: ExaminedResultEnum.NoExamined,
  edema: ExaminedResultEnum.NoExamined,
  scrotalSwelling: ExaminedResultEnum.NoExamined,
  purpura: ExaminedResultEnum.NoExamined,
};

const NoExaminedPhysical: PHIPhysical = {
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  id: 0,
  bpResult: ExaminedResultEnum.NoExamined,
  waistResult: ExaminedResultEnum.NoExamined,
  pulseResult: ExaminedResultEnum.NoExamined,
  colorBlind: ExaminedResultEnum.NoExamined,
  e99: ExaminedResultEnum.NoExamined,
  hearing: ExaminedResultEnum.NoExamined,
  hearingLR: LeftRightEnum.None,
  earMisshapen: ExaminedResultEnum.NoExamined,
  earMisshapenLR: LeftRightEnum.None,
  eardrum: ExaminedResultEnum.NoExamined,
  eardrumLR: LeftRightEnum.None,
  cerumen: ExaminedResultEnum.NoExamined,
  cerumenLR: LeftRightEnum.None,
  tonsillitis: ExaminedResultEnum.NoExamined,
  o99: ExaminedResultEnum.NoExamined,
  torticollis: ExaminedResultEnum.NoExamined,
  mass: ExaminedResultEnum.NoExamined,
  goiter: ExaminedResultEnum.NoExamined,
  lymphadenectasis: ExaminedResultEnum.NoExamined,
  mass99: ExaminedResultEnum.NoExamined,
  n99: ExaminedResultEnum.NoExamined,
  cardiopulmonary: ExaminedResultEnum.NoExamined,
  heartMurmur: ExaminedResultEnum.NoExamined,
  arrhythmia: ExaminedResultEnum.NoExamined,
  wheeze: ExaminedResultEnum.NoExamined,
  cardiopulmonary99: ExaminedResultEnum.NoExamined,
  thorax: ExaminedResultEnum.NoExamined,
  c99: ExaminedResultEnum.NoExamined,
  abdomen: ExaminedResultEnum.NoExamined,
  a99: ExaminedResultEnum.NoExamined,
  scoliosis: ExaminedResultEnum.NoExamined,
  dysmelia: ExaminedResultEnum.NoExamined,
  polydactyly: ExaminedResultEnum.NoExamined,
  dysarthrosis: ExaminedResultEnum.NoExamined,
  dysmelia99: ExaminedResultEnum.NoExamined,
  squatting: ExaminedResultEnum.NoExamined,
  l99: ExaminedResultEnum.NoExamined,
  cryptorchidism: ExaminedResultEnum.NoExamined,
  prepuce: ExaminedResultEnum.NoExamined,
  varicocele: ExaminedResultEnum.NoExamined,
  u99: ExaminedResultEnum.NoExamined,
  epidermophytosis: ExaminedResultEnum.NoExamined,
  scabies: ExaminedResultEnum.NoExamined,
  wart: ExaminedResultEnum.NoExamined,
  atopicDermatitis: ExaminedResultEnum.NoExamined,
  eczema: ExaminedResultEnum.NoExamined,
  d99: ExaminedResultEnum.NoExamined,
  t01: ExaminedResultEnum.NoExamined,
  t02: ExaminedResultEnum.NoExamined,
  t03: ExaminedResultEnum.NoExamined,
  t04: ExaminedResultEnum.NoExamined,
  t05: ExaminedResultEnum.NoExamined,
  t06: ExaminedResultEnum.NoExamined,
  t07: ExaminedResultEnum.NoExamined,
  t08: ExaminedResultEnum.NoExamined,
  t99: ExaminedResultEnum.NoExamined,
  t09: ExaminedResultEnum.NoExamined,
  t11: TeeInspection.None,
  t12: TeeInspection.None,
  t13: TeeInspection.None,
  t15: TeeInspection.None,
  t16: ExaminedResultEnum.NoExamined,
  t17: ExaminedResultEnum.NoExamined,
  t18: ExaminedResultEnum.NoExamined,
  t19: ExaminedResultEnum.NoExamined,
  strabismus: ExaminedResultEnum.NoExamined,
  strabismusType: StrabismusKindEnum.None,
  trichiasis: ExaminedResultEnum.NoExamined,
  nystagmus: ExaminedResultEnum.NoExamined,
  blepharoptosis: ExaminedResultEnum.NoExamined,
  clp: ExaminedResultEnum.NoExamined,
  articulationDisorders: ExaminedResultEnum.NoExamined,
  preauricularFistula: ExaminedResultEnum.NoExamined,
  preauricularFistulaLR: ExaminedResultEnum.NoExamined,
  rhinitis: ExaminedResultEnum.NoExamined,
  allergicRhinitis: ExaminedResultEnum.NoExamined,
  hernia: ExaminedResultEnum.NoExamined,
  edema: ExaminedResultEnum.NoExamined,
  scrotalSwelling: ExaminedResultEnum.NoExamined,
  purpura: ExaminedResultEnum.NoExamined,
};

 export type PageData = {
  id: number;
  year: number;
  sem: number;
  grade: number;
  sbp?: number | null;
  dbp?: number | null;
  bpResult: ExaminedResultEnum;
  waistline?: number | null;
  waistResult: ExaminedResultEnum;
  pulse?: number | null;
  pulseResult: ExaminedResultEnum;
  colorBlind: ExaminedResultEnum;
  e99: ExaminedResultEnum;
  e99State?: string | null;
  hearing: ExaminedResultEnum;
  hearingLR: LeftRightEnum;
  earMisshapen: ExaminedResultEnum;
  earMisshapenLR: LeftRightEnum;
  eardrum: ExaminedResultEnum;
  eardrumLR: LeftRightEnum;
  cerumen: ExaminedResultEnum;
  cerumenLR: LeftRightEnum;
  tonsillitis: ExaminedResultEnum;
  o99: ExaminedResultEnum;
  o99State?: string | null;
  torticollis: ExaminedResultEnum;
  mass: ExaminedResultEnum;
  goiter: ExaminedResultEnum;
  lymphadenectasis: ExaminedResultEnum;
  mass99: ExaminedResultEnum;
  n99: ExaminedResultEnum;
  n99State?: string | null;
  cardiopulmonary: ExaminedResultEnum;
  heartMurmur: ExaminedResultEnum;
  arrhythmia: ExaminedResultEnum;
  wheeze: ExaminedResultEnum;
  cardiopulmonary99: ExaminedResultEnum;
  thorax: ExaminedResultEnum;
  c99: ExaminedResultEnum;
  c99State?: string | null;
  abdomen: ExaminedResultEnum;
  a99: ExaminedResultEnum;
  a99State?: string | null;
  scoliosis: ExaminedResultEnum;
  dysmelia: ExaminedResultEnum;
  polydactyly: ExaminedResultEnum;
  dysarthrosis: ExaminedResultEnum;
  dysmelia99: ExaminedResultEnum;
  squatting: ExaminedResultEnum;
  l99: ExaminedResultEnum;
  l99State?: string | null;
  cryptorchidism: ExaminedResultEnum;
  prepuce: ExaminedResultEnum;
  varicocele: ExaminedResultEnum;
  u99: ExaminedResultEnum;
  u99State?: string | null;
  epidermophytosis: ExaminedResultEnum;
  scabies: ExaminedResultEnum;
  wart: ExaminedResultEnum;
  atopicDermatitis: ExaminedResultEnum;
  eczema: ExaminedResultEnum;
  d99: ExaminedResultEnum;
  d99State?: string | null;
  t01: TeeInitialExaminationEnum;
  ret01?: TeeFollowUpExaminationEnum | null;
  t02: TeeInitialExaminationEnum;
  ret02?: TeeFollowUpExaminationEnum | null;
  t03: TeeInitialExaminationEnum;
  ret03?: TeeFollowUpExaminationEnum | null;
  t04: TeeInitialExaminationEnum;
  ret04?: TeeFollowUpExaminationEnum | null;
  t06: number;
  t05: TeeInitialExaminationEnum;
  ret05?: TeeFollowUpExaminationEnum | null;
  t07: TeeInitialExaminationEnum;
  ret07?: TeeFollowUpExaminationEnum | null;
  t08: TeeInitialExaminationEnum;
  ret08?: TeeFollowUpExaminationEnum | null;
  t99: TeeInitialExaminationEnum;
  ret99?: TeeFollowUpExaminationEnum | null;
  t99state?: string | null;
  tSheet?: Record<string, string> | null;
  t09: TeeInitialExaminationEnum;
  ret09?: TeeFollowUpExaminationEnum | null;
  t11: TeeInspection;
  t12: TeeInspection;
  t13: TeeInspection;
  t15: TeeInspection;
  t16: TeeInitialExaminationEnum;
  ret16?: TeeFollowUpExaminationEnum | null;
  t17: TeeInitialExaminationEnum;
  ret17?: TeeFollowUpExaminationEnum | null;
  t18: TeeInitialExaminationEnum;
  ret18?: TeeFollowUpExaminationEnum | null;
  t19: TeeInitialExaminationEnum;
  ret19?: TeeFollowUpExaminationEnum | null;
  reT99state?: string | null;
  reTSheet?: Record<string, string> | null;
  strabismus: ExaminedResultEnum;
  strabismusType: StrabismusKindEnum;
  trichiasis: ExaminedResultEnum;
  nystagmus: ExaminedResultEnum;
  blepharoptosis: ExaminedResultEnum;
  clp: ExaminedResultEnum;
  articulationDisorders: ExaminedResultEnum;
  preauricularFistula: ExaminedResultEnum;
  preauricularFistulaLR: LeftRightEnum;
  rhinitis: ExaminedResultEnum;
  allergicRhinitis: ExaminedResultEnum;
  hernia: ExaminedResultEnum;
  edema: ExaminedResultEnum;
  scrotalSwelling: ExaminedResultEnum;
  purpura: ExaminedResultEnum;
  examDate?: DateTime | null;
  yearClassId: number;
};
