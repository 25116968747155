import React, {
  FunctionComponent,
  useState,
  useRef,
  ReactNode,
  useEffect,
  ChangeEvent,
} from 'react';
import { Modal, Button, InputGroup, Form, Col, DropdownButton, Dropdown} from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  TextField,
  OverflowWithTooltip,
} from '../../components';
import {
  PHISightSolid,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { toast } from 'react-toastify';

type Props = {
  editable: boolean;
  deleteable: boolean;
  solid: PHISightSolid[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};
const transformTextInput = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? <TextField property={property} /> : wrapOverflow(v as string));
const sightSolidKind = [
  {
    Id: 0,
    Name: '斜視',
  },
  {
    Id: 1,
    Name: '弱視',
  },
  {
    Id: 2,
    Name: '斜、弱視',
  },
  {
    Id: 3,
    Name: '屈光不正',
  },
  {
    Id: 4,
    Name: '無確定診斷',
  },
  {
    Id: 5,
    Name: '診治正常',
  },
  {
    Id: 6,
    Name: '其他眼疾',
  },
  {
    Id: 7,
    Name: '未就醫',
  }
];
function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}
const inlineTableHeader: HeaderDisplayKeysWithType<PHISightSolid>[] =
  [
    { 
      property: 'studentId',
      display: '統編',
    },
    { 
      property: 'sightSolid', 
      display: '診斷',
      onRender: (value: unknown) => {
        if (value) {
          return typeof value == 'number'
            ? sightSolidKind[value].Name
            : '無診斷';
        }
      },
    },
    { 
      property: 'states',
      display: '其他診斷',
      onRender: transformTextInput.bind(null, 'states'),
    },
    { 
      property: 'hospital',
      display: '醫院',
      onRender: transformTextInput.bind(null, 'hospital'),
    }
  ];

export const SolidSection: FunctionComponent<Props> = ({
  editable,
  deleteable,
  solid,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const currentSemester = semesters.find((s) => s.isNow);
  const [inputing, setInputing] = useState(false);
  const [sightSolidId, setSightSolidId] = useState<number>(-1);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [year, setYear] = useState<number>();
  const [sem, setSem] = useState<number>();
  const solidStatesInputRef = useRef<HTMLInputElement>(null);
  const solidHospitalInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // 目前學期、學年
    const isNow = semesters.filter((y) => y.isNow == true)[0];
    setYear(isNow.year);
    setSem(isNow.sem);
  }, [semesters]);

  useEffect(() => {
    setInsertBtn(true);
    if(sightSolidId > -1) {
      setInsertBtn(false);
    }
  }, [sightSolidId]);


  function onValueUpdate(value: PHISightSolid) {
    apis
      .updateStudentSightSolid(
        value?.id,
        value?.sightSolid || 0,
        value?.states || "",
        value?.hospital || "",
        value.studentId,
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function onValueDelete(value: PHISightSolid) {
    apis
      .deleteStudentSightSolid(
        value?.id,
        value?.sightSolid || 0,
        value?.states || "",
        value?.hospital || "",
        value.studentId,
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function insertSightSolid() {
    if(year && sem) {
      toast
        .promise(
          apis.insertStudentSightSolid(
            student.pid,
            sightSolidId,
            solidStatesInputRef.current?.value || "",
            solidHospitalInputRef.current?.value || ""
          ),
          {
            pending: '資料新增中......',
            success: '新增成功！',
          }
        )
        .then(() =>{
          onValueUpdated();
          setInsertBtn(true);
        })
        .catch(onApiError);
    }
  }

  return (
    <>
      <div>
        立體感
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            立體感 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <Col sm={12}>一個人最多一筆資料</Col>
          <InlineEditableTable
            values={solid}
            headers={inlineTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable: editable,
              deleteable: deleteable 
            })}
          />
          {solid.length ? 
            "" :
            (<div className="d-flex align-items-center">
              診斷：
              <Col sm={2}>
                <DropdownButton
                  title={sightSolidId > -1 ? sightSolidKind[sightSolidId].Name : "診斷"}
                  onSelect={(c) => {
                    if (c) {
                      setSightSolidId(sightSolidKind[parseInt(c) - 1].Id);
                    }
                  }}
                >
                  <Dropdown.Item key={`sightSolid-${1}`} eventKey={1}>
                    斜視
                  </Dropdown.Item>
                  <Dropdown.Item key={`sightSolid-${2}`} eventKey={2}>
                    弱視
                  </Dropdown.Item>
                  <Dropdown.Item key={`sightSolid-${3}`} eventKey={3}>
                    斜、弱視
                  </Dropdown.Item>
                  <Dropdown.Item key={`sightSolid-${4}`} eventKey={4}>
                    屈光不正
                  </Dropdown.Item>
                  <Dropdown.Item key={`sightSolid-${5}`} eventKey={5}>
                    無確定診斷
                  </Dropdown.Item>
                  <Dropdown.Item key={`sightSolid-${6}`} eventKey={6}>
                    診治正常
                  </Dropdown.Item>
                  <Dropdown.Item key={`sightSolid-${7}`} eventKey={7}>
                    其他眼疾
                  </Dropdown.Item>
                  <Dropdown.Item key={`sightSolid-${8}`} eventKey={8}>
                    未就醫
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
              其他診斷：
              <Col sm={3}>
                <InputGroup>
                  <Form.Control
                    className="mr-2"
                    placeholder="其他診斷"
                    aria-label="sighSolidStates"
                    aria-describedby="basic-addon1"
                    ref={solidStatesInputRef}
                  />
                </InputGroup>
              </Col>
              醫院：
              <Col sm={2}>
                <InputGroup>
                  <Form.Control
                    className="mr-2"
                    placeholder="醫院"
                    aria-label="hospital"
                    aria-describedby="basic-addon1"
                    ref={solidHospitalInputRef}
                  />
                </InputGroup>
              </Col>
              <Button disabled={insertBtn} onClick={insertSightSolid}>
                新增
              </Button>
            </div>)
          }
        </Modal.Body>
      </Modal>
    </>
  );
};
