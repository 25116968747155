import { z } from 'zod';
import { ClassHDIDStatistic, ClassHDIDStatisticZod, ClassSightDiagrate2Statistic, ClassSightDiagrate2StatisticZod, ClassSightRate, ClassSightRateZod, CompareYearClassSightRate, CompareYearClassSightRateZod, ClassWHAvgStatistic, ClassWHAvgStatisticZod, GradeWHAvgStatistic, GradeHDIDStatisticZod, StudentGradeNoSeatZod, SightZod, PHIWHZod, TeeGradeClassStatisticZod, TeeGradeClassStatistic, StudentDiseaseZod, StudentDiseaseBodyMindZod, StudentInjuryZod, InjuryGradeClassStatisticZod, InjuryGradeClassStatistic, InjuryDateZod, InjuryDate, StudentPHIWHZod, StudentWHZod, MenuItem, menuItemZod, WHAndPreWHNoDiagsZod, CleanTeethStudentStatisticZod, CleanTeethStudentStatistic, FluorStudentStatisticZod, FluorStudentStatistic, StudentGradeNoSeat, SightUnCorrectChartZod, SightUnCorrectChartStatistic, WHChartStatistic, WHChartZod, WHBmiChartZod, WHBmiChartStatistic, SightChartStatistic, SightChartZod, DiseaseChartZod, DiseaseChartStatistic, CheckYearGradeZod, UnpivotedBloodCheckZod, UnpivotedBloodCheck, CheckTotalZod, StudentPHIAll, StudentPHIAllZod, WHSightNoDiagsZod, WHSightNoDiags, StudentCountStatistic, StudentCountZod } from '../model';
import { get } from './BaseApi';
import { DateTime } from 'luxon';

const ClassHDIDStatisticZodArray = z.array(ClassHDIDStatisticZod);
const ClassSightDiagrate2StatisticArr = z.array(ClassSightDiagrate2StatisticZod);
const ClassSightRateStatisticArr = z.array(ClassSightRateZod);
const CompareYearClassSightRateArr = z.array(CompareYearClassSightRateZod);
const ClassWHAvgStatisticArr = z.array(ClassWHAvgStatisticZod);
const GradeWHAvgStatisticArr = z.array(GradeHDIDStatisticZod);
const TeeGradeClassStatisticArr = z.array(TeeGradeClassStatisticZod);
const InjuryGradeClassStatisticArr = z.array(InjuryGradeClassStatisticZod);
const CleanTeethStudentStatisticArr = z.array(CleanTeethStudentStatisticZod);
const FluorStudentStatisticArr = z.array(FluorStudentStatisticZod);
const InjuryDateArr = z.array(InjuryDateZod);
const StudentGradeNoSeatArr = z.array(StudentGradeNoSeatZod);
const SightUnCorrectChartArr = z.array(SightUnCorrectChartZod);
const WHChartArr = z.array(WHChartZod);
const WHBmiChartArr = z.array(WHBmiChartZod);
const DiseaseChartArr = z.array(DiseaseChartZod);
const UnpivotedBloodCheckArr = z.array(UnpivotedBloodCheckZod);
const StudentPHIAllArr = z.array(StudentPHIAllZod);
const StudentCountArr = z.array(StudentCountZod);

const WHDiagsZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      birth: z.string().transform((s) => DateTime.fromISO(s)),
      wh: PHIWHZod.nullish(),
    })
  )
);
const NoticeMenuZod = z.array(menuItemZod);
type NoticeMenu = z.infer<typeof NoticeMenuZod>
export async function GetNoticeMenu(): Promise<NoticeMenu> {
  return get(`/api/statistic/notice/menu`, NoticeMenuZod);
}

export async function getWHClassStatistic(
  year?: number,
  sem?: number
): Promise<ClassHDIDStatistic[]> {
  return await get('/api/statistic/wh/class', ClassHDIDStatisticZodArray, {
    year,
    sem,
  });
}

export async function getWHGradeStatistic(
  year?: number,
  sem?: number
): Promise<GradeWHAvgStatistic[]> {
  return await get('/api/statistic/wh/grade', GradeWHAvgStatisticArr, {
    year,
    sem,
  });
}

export async function getWHClassAvg(
  year?: number,
  sem?: number
): Promise<ClassWHAvgStatistic[]> {
  return await get('/api/statistic/wh/class/avg', ClassWHAvgStatisticArr, {
    year,
    sem,
  });
}

export async function getWHClassSight(
  year?: number,
  sem?: number,
  classId?: string
): Promise<WHSightNoDiags> {
  return await get('/api/statistic/wh/class/sight', WHSightNoDiagsZod, {
    year,
    sem,
    classId
  });
}

export async function getWHClassBmi(
  year?: number,
  sem?: number,
): Promise<WHSightNoDiags> {
  return await get('/api/statistic/wh/class/bmi', WHSightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getWHClassStudentBmi(
  year: number,
  sem: number,
  classId: string,
  isNotHealthy: boolean,
): Promise<WHSightNoDiags> {
  return await get('/api/statistic/wh/class/student/bmi', WHSightNoDiagsZod, {
    year,
    sem,
    classId,
    isNotHealthy,
  });
}

export async function getHeightChart(
  year?: number,
  sem?: number
): Promise<WHChartStatistic[]> {
  return await get('/api/statistic/wh/getheightchart', WHChartArr, {
    year,
    sem,
  });
}

export async function getWeightChart(
  year?: number,
  sem?: number
): Promise<WHChartStatistic[]> {
  return await get('/api/statistic/wh/getweightchart', WHChartArr, {
    year,
    sem,
  });
}

export async function getWHBmiChart(
  year?: number,
  sem?: number
): Promise<WHBmiChartStatistic[]> {
  return await get('/api/statistic/wh/getwhbmichart', WHBmiChartArr, {
    year,
    sem,
  });
}

export async function getSightDiagrate2ClassStatistic(
  year?: number,
  sem?: number
): Promise<ClassSightDiagrate2Statistic[]> {
  return await get('/api/statistic/sight/diagrate2/class', ClassSightDiagrate2StatisticArr, {
    year,
    sem,
  });
}

export async function getSightDilatingNearRate(
  year: number,
  sem: number
): Promise<ClassSightRate[]> {
  return await get('/api/statistic/sight/getsightdilatingnearate', ClassSightRateStatisticArr, {
    year,
    sem
  });
}

export async function getSightLongDilationRate(
  year: number,
  sem: number
): Promise<ClassSightRate[]> {
  return await get('/api/statistic/sight/getsightlongdilationrate', ClassSightRateStatisticArr, {
    year,
    sem
  });
}

export async function getSightShortDilationRate(
  year: number,
  sem: number
): Promise<ClassSightRate[]> {
  return await get('/api/statistic/sight/getsightshortdilationrate', ClassSightRateStatisticArr, {
    year,
    sem
  });
}

export async function getSightDeteriorateRate(
  year: number,
  sem: number
): Promise<CompareYearClassSightRate[]> {
  return await get('/api/statistic/sight/getsightdeterioraterate', CompareYearClassSightRateArr, {
    year,
    sem
  });
}

export async function getSightUnCorrectChart(
  year?: number,
  sem?: number
): Promise<SightUnCorrectChartStatistic[]> {
  return await get('/api/statistic/sight/getsightuncorrectchart', SightUnCorrectChartArr, {
    year,
    sem,
  });
}

export async function getSightChart(
  year?: number,
  sem?: number
): Promise<SightChartStatistic> {
  return await get('/api/statistic/sight/getsightchart', SightChartZod, {
    year,
    sem,
  });
}


export async function getTeethTeeStaticSexNeatInit(
  year: number,
  sem: number
): Promise<TeeGradeClassStatistic[]> {
  return await get('/api/statistic/teeth/getteethteestaticsexneatinit', TeeGradeClassStatisticArr, {
    year,
    sem
  });
}

export async function getTeethTeeClassStaticSexNeatInit(
  year: number,
  sem: number
): Promise<TeeGradeClassStatistic[]> {
  return await get('/api/statistic/teeth/getteethteeclassstaticsexneatinit', TeeGradeClassStatisticArr, {
    year,
    sem
  });
}

export async function getTeethTeeStaticSexNeat(
  year: number,
  sem: number
): Promise<TeeGradeClassStatistic[]> {
  return await get('/api/statistic/teeth/getteethteestaticsexneat', TeeGradeClassStatisticArr, {
    year,
    sem
  });
}

export async function getTeethTeeClassStaticSexNeat(
  year: number,
  sem: number
): Promise<TeeGradeClassStatistic[]> {
  return await get('/api/statistic/teeth/getteethteeclassstaticsexneat', TeeGradeClassStatisticArr, {
    year,
    sem
  });
}

export async function getTeethTeeStaticSexAll(
  year: number,
  sem: number
): Promise<TeeGradeClassStatistic[]> {
  return await get('/api/statistic/teeth/getteethteestaticsexall', TeeGradeClassStatisticArr, {
    year,
    sem
  });
}

export async function getTeethTeeClassStatic(
  year: number,
  sem: number
): Promise<TeeGradeClassStatistic[]> {
  return await get('/api/statistic/teeth/getteethteeclassstatic', TeeGradeClassStatisticArr, {
    year,
    sem
  });
}

export async function getTeethTeeGradeCleanStatic(
  year: number,
  sem: number
): Promise<CleanTeethStudentStatistic[]> {
  return await get('/api/statistic/teeth/getteethteegradecleanstatic', CleanTeethStudentStatisticArr, {
    year,
    sem
  });
}
export async function getTeethTeeClassCleanStatic(
  year: number,
  sem: number
): Promise<CleanTeethStudentStatistic[]> {
  return await get('/api/statistic/teeth/getteethteeclasscleanstatic', CleanTeethStudentStatisticArr, {
    year,
    sem
  });
}

export async function getFluorGradeStatic(
  year: number,
  sem: number
): Promise<FluorStudentStatistic[]> {
  return await get('/api/statistic/teeth/getfluorgradestatic', FluorStudentStatisticArr, {
    year,
    sem
  });
}

export async function getFluorClassStatic(
  year: number,
  sem: number
): Promise<FluorStudentStatistic[]> {
  return await get('/api/statistic/teeth/getfluorclassstatic', FluorStudentStatisticArr, {
    year,
    sem
  });
}

export async function getTeethUnCheck(
  year: number,
  sem: number
): Promise<StudentGradeNoSeat[]> {
  return await get('/api/statistic/teeth/getteethuncheck', StudentGradeNoSeatArr, {
    year,
    sem
  });
}

export async function getTeethTeeStaticSex(
  year: number,
  sem: number
): Promise<TeeGradeClassStatistic[]> {
  return await get('/api/statistic/teeth/getteethteestaticsex', TeeGradeClassStatisticArr, {
    year,
    sem
  });
}

const StudentDiseaseListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      studentDisease: StudentDiseaseZod,
    })
  )
);
type StudentDiseaseList = z.infer<typeof StudentDiseaseListZod>;

export async function getClassDisease(
  year: number,
  sem: number,
  classId?: string
): Promise<StudentDiseaseList> {
  return await get('/api/statistic/disease/class', StudentDiseaseListZod, {
    year,
    sem,
    classId,
  })
}

export async function getClassDiseaseByDiseaseId(
  year: number,
  sem: number,
  diseaseId?: number
): Promise<StudentDiseaseList> {
  return await get('/api/statistic/disease/classdisease', StudentDiseaseListZod, {
    year,
    sem,
    diseaseId,
  })
}

export async function getGradeDisease(
  year: number,
  sem: number,
): Promise<StudentDiseaseList> {
  return await get('/api/statistic/disease/class', StudentDiseaseListZod, {
    year,
    sem
  })
}

const StudentDiseaseBodyMindListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      studentDiseaseBodyMind: StudentDiseaseBodyMindZod,
    })
  )
);

type StudentDiseaseBodyMindList = z.infer<typeof StudentDiseaseBodyMindListZod>;
export async function getClassDiseaseBodyMind(
  year: number,
  sem: number,
  classId?: string
): Promise<StudentDiseaseBodyMindList> {
  return await get('/api/statistic/disease/getclassdiseasebodymind', StudentDiseaseBodyMindListZod, {
    year,
    sem,
    classId,
  })
}

export async function getDiseaseStaticChart(
  year: number,
  sem: number,
): Promise<DiseaseChartStatistic[]> {
  return await get('/api/statistic/disease/getdiseasestaticchart', DiseaseChartArr, {
    year,
    sem
  })
}

export async function getInjuryAccList(
  year: number,
  sem: number,
  monthId?: number
): Promise<StudentInjuryList> {
  return await get('/api/statistic/injury/acclist', StudentInjuryListZod, {
    year,
    sem,
    monthId,
  })
}

const StudentInjuryListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      studentInjury: StudentInjuryZod,
    })
  )
);
type StudentInjuryList = z.infer<typeof StudentInjuryListZod>;

export async function getInjuryAccDateList(
  year: number,
  sem: number,
): Promise<InjuryDate[]> {
  return await get('/api/statistic/injury/acclistdate', InjuryDateArr, {
    year,
    sem,
  })
}

export async function getInjuryClassAccList(
  year: number,
  sem: number,
  classId?: string
): Promise<StudentInjuryList> {
  return await get('/api/statistic/injury/classacclist', StudentInjuryListZod, {
    year,
    sem,
    classId,
  })
}

export async function getInjuryAccStCount(
  year: number,
  sem: number,
  monthId?: number
): Promise<InjuryGradeClassStatistic[]> {
  return await get('/api/statistic/injury/accstcount', InjuryGradeClassStatisticArr, {
    year,
    sem,
    monthId,
  })
}

export async function getInjuryAccListSortByDate(
  year: number,
  sem: number,
  selectDate?: string
): Promise<StudentInjuryList> {
  return await get('/api/statistic/injury/acclistsortbydate', StudentInjuryListZod, {
    year,
    sem,
    selectDate,
  })
}

export async function getInjuryAccListSortByClass(
  year: number,
  sem: number,
  selectDate?: string
): Promise<StudentInjuryList> {
  return await get('/api/statistic/injury/acclistsortbyclass', StudentInjuryListZod, {
    year,
    sem,
    selectDate,
  })
}

export async function getInjuryAccStaticChart(
  year: number,
): Promise<StudentInjuryList> {
  return await get('/api/statistic/injury/accstaticchart', StudentInjuryListZod, {
    year
  })
}

export async function getInjuryAccStaticMonthChart(
  year: number,
  monthId?: number
): Promise<StudentInjuryList> {
  return await get('/api/statistic/injury/accstaticmonthchart', StudentInjuryListZod, {
    year,
    monthId
  })
}

const studentPhiWHsZod = z.array(StudentWHZod);
export async function getClassStudentUnCheck(
  year: number,
  sem: number
): Promise<z.infer<typeof studentPhiWHsZod>> {
  return get(`/api/statistic/wh/getclassstudentwhuncheck`, studentPhiWHsZod, {
    year,
    sem,
  });
}

export async function getClassStudentCantCheck(
  year: number,
  sem: number
): Promise<z.infer<typeof studentPhiWHsZod>> {
  return get(`/api/statistic/wh/getclassstudentwhcantcheck`, studentPhiWHsZod, {
    year,
    sem,
  });
}

const WHAndPreWHNoDiagsListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      wh: WHAndPreWHNoDiagsZod,
    })
  )
);
type WHAndPreWHNoDiagsList = z.infer<typeof WHAndPreWHNoDiagsListZod>;

export async function getIncreasedHeight(
  year: number,
  sem: number,
  interval: number,
  increaseHeightId?: number
): Promise<WHAndPreWHNoDiagsList> {
  return await get('/api/statistic/wh/getincreasedheight', WHAndPreWHNoDiagsListZod, {
    year,
    sem,
    interval,
    increaseHeightId
  });
}

const CheckYearGradeListZod = z.object({
  isNow: z.array(CheckYearGradeZod),
  graduate: z.array(CheckYearGradeZod),
});
type CheckYearGradeList = z.infer<typeof CheckYearGradeListZod>;
export async function getCheckYearGrede(
  year: number,

): Promise<CheckYearGradeList> {
  return await get('/api/statistic/check/getcheckyeargrede', CheckYearGradeListZod, {
    year,
  });
}

export async function getBloodStatistic(
  year: number,
  gradeid: number
): Promise<UnpivotedBloodCheck[]> {
  return await get('/api/statistic/check/blood/getbloodstatistic', UnpivotedBloodCheckArr, {
    year,
    gradeid
  });
}

export async function getXRayStatistic(
  year: number,
  gradeid: number
): Promise<UnpivotedBloodCheck[]> {
  return await get('/api/statistic/check/xray/getxraystatistic', UnpivotedBloodCheckArr, {
    year,
    gradeid
  });
}

const CheckStasticZod = z.object({
  checkTotal: CheckTotalZod,
  checkData: UnpivotedBloodCheckArr
})
type CheckStastic = z.infer<typeof CheckStasticZod>;
export async function getCheckStatistic(
  year: number,
  gradeid: number
): Promise<CheckStastic> {
  return await get('/api/statistic/check/getcheckstatistic', CheckStasticZod, {
    year,
    gradeid
  });
}

export async function getCheckAllQuery(
  year: number,
  gradeid: number
): Promise<StudentPHIAll[]> {
  return await get('/api/statistic/check/getcheckallquery', StudentPHIAllArr, {
    year,
    gradeid
  });
}

export async function getStudentCount(
  year: number,
  sem: number
): Promise<StudentCountStatistic[]> {
  return await get('/api/statistic/other/getstcount', StudentCountArr, {
    year,
    sem
  });
}

