import React, { CSSProperties, ReactNode } from 'react';
import { Table, Button, Form } from 'react-bootstrap';

export type EditableProperties = {
  editable?: boolean;
  deleteable?: boolean;
  selected?: boolean;
};

type TValue = {
  [k: string]: unknown;
} & EditableProperties;

export type HeaderDisplayKeys = {
  display: ReactNode;
  style?: CSSProperties;
  order?: number;
};

export type TableRowContext = {
  context: Record<string, unknown>;
  allValues: any;
};

export type HeaderDisplayKeysWithType<
  T extends TValue,
  K extends keyof T = keyof T
> = {
  property: K;
  onRender?: (
    value: any,
    idx: number,
    editing: boolean,
    context: TableRowContext
  ) => string | number | null | ReactNode;
} & HeaderDisplayKeys;

export type RowRenderResult = {
  rowClassName?: string;
  rowStyle?: CSSProperties;
  editable?: boolean;
  deleteable?: boolean;
  scrollable?: boolean;
};

export type OnRowRender<T extends object> = (value: T) => RowRenderResult;

type SelectOn = {
  index?: number;
  checked: boolean;
};

type Props<T extends TValue> = {
  editable?: boolean;
  deleteable?: boolean;
  headers: HeaderDisplayKeysWithType<T, keyof T>[];
  values: T[];
  responsive?: boolean;
  fixCol?: number;
  fixHeader?: boolean;
  onEdit?: (value: T) => void;
  onDelete?: (value: T) => void;
  onRowRender?: OnRowRender<T>;
  onSelected?: (selectOn: SelectOn) => void;
  tableId?: string;
  context?: Record<string, unknown>;
  scrollable?: boolean
};
const tableStyle: CSSProperties = {
  display: 'block',
  overflowX: 'auto',
  whiteSpace: 'nowrap'
};
const editableTable = <T extends TValue>({
  headers,
  values,
  editable,
  deleteable,
  fixCol,
  onEdit,
  onDelete,
  onRowRender,
  onSelected,
  tableId,
  context = {},
  scrollable
}: Props<T>) => {
  const headerElements = headers.map((h, i) => {
    const fixColCss = {
      backgroundColor: '#ccc',
      position: 'sticky',
      left: 0,
    };
    if (fixCol && i < fixCol) {
      return (
        <th
          className={`sticky${scrollable ? ' font-weight-normal' : ''}`}
          style={Object.assign({}, h.style, fixColCss)}
          key={`header-${i}`}
        >
          {h.display}
        </th>
      );
    } else {
      return (
        <th className={`sticky${scrollable ? ' font-weight-normal' : ''}`} style={h.style} key={`header-${i}`}>
          {h.display}
        </th>
      );
    }
  });

  if (onSelected) {
    headerElements.unshift(
      <th
        className="sticky"
        key={`header-${headerElements.length}-select`}
        style={{ maxWidth: '80px' }}
      >
        <Form.Check
          type="checkbox"
          onChange={(e) => {
            const checked = e.target.checked;
            onSelected({ checked });
          }}
        />
      </th>
    );
  }

  if (editable || deleteable) {
    headerElements.push(
      <th className="sticky" key={`header-${headerElements.length}-edit`}></th>
    );
  }

  let bodyElements = values.map((v, i) => {
    const fixColCss = { backgroundColor: '#eee', position: 'sticky', left: 0 };
    const row = headers.reduce((elements, h, colIdx) => {
      const value = h.onRender
        ? h.onRender(v[h.property], i, false, { context, allValues: v })
        : v[h.property];
      if (fixCol && colIdx < fixCol) {
        elements.push(
          <td
            style={Object.assign({}, h.style, fixColCss)}
            key={`body-${i}-${Math.random().toString(16).slice(-6)}}`}
          >
            {value}
          </td>
        );
      } else {
        elements.push(
          <td style={h.style} key={`body-${i}-${String(h.property)}`}>
            {value}
          </td>
        );
      }
      return elements;
    }, [] as ReactNode[]);
    const modifyCell: ReactNode[] = [];
    if (editable) {
      modifyCell.push(
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1 m-0 mr-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            if (onEdit && editable) {
              onEdit(v);
            }
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      );
    }
    if (deleteable) {
      modifyCell.push(
        <Button
          key="cell-delete"
          className="border-0 px-2 py-1 m-0"
          variant="outline-danger"
          size="sm"
          onClick={() => {
            if (onDelete && deleteable) {
              onDelete(v);
            }
          }}
        >
          <span className="feather icon-trash-2" />
        </Button>
      );
    }

    if (onSelected) {
      row.unshift(
        <td key={`body-${row.length}-select`}>
          <Form.Check
            type="checkbox"
            checked={!!v.selected}
            onChange={() => {
              onSelected({ index: i, checked: !v.selected });
            }}
          />
        </td>
      );
    }
    if (editable || deleteable) {
      row.push(<td key={`body-${row.length}-edit`}>{modifyCell}</td>);
    }

    const { rowStyle, rowClassName } = onRowRender
      ? onRowRender(v)
      : ({} as RowRenderResult);
    return (
      <tr style={rowStyle} className={rowClassName} key={`body-${i}`}>
        {row}
      </tr>
    );
  });

  ////
  if (bodyElements.length === 0) {
    bodyElements = [
      <tr key={'empty-table'}>
        <td colSpan={headers.length}>目前資料表中沒有資料</td>
      </tr>,
    ];
  }

  return (
    <Table id={tableId} bordered striped hover className="text-center" style={scrollable ? tableStyle : {}}>
      <thead>
        <tr>{headerElements}</tr>
      </thead>
      <tbody>{bodyElements}</tbody>
    </Table>
  );
};

export const EditableTable = editableTable;
