import { DateTime } from 'luxon';
import { download } from './BaseApi';
import { Student, StudentPHIAll } from '../model';

export async function downloadSolidPDF(
  classGrade: number,
  pids: string[],
  year: number,
  sem: number
): Promise<Blob> {
  return await download('/api/pdf/solid', {
    classGrade,
    pids,
    year,
    sem,
  });
}

export async function downloadSightPDF(
  classId: string,
  pids: string[],
  year: number,
  sem: number,
  isPrintCurrentSem: boolean,
  isPrintHistories: boolean,
  deadline?: DateTime
): Promise<Blob> {
  return await download('/api/pdf/sight', {
    classId,
    pids,
    year,
    sem,
    isPrintCurrentSem,
    deadline: deadline?.toISO(),
    isPrintHistories,
  });
}

export async function downloadTeePDF(
  classId: string,
  pids: string[],
  year: number,
  sem: number,
  deadline?: DateTime
): Promise<Blob> {
  return await download('/api/pdf/tee', {
    classId,
    pids,
    year,
    sem,
    deadline: deadline?.toISO(),
  });
}

export async function downloadWHSightPDF(
  classId: string,
  pids: string[],
  year: number,
  sem: number,
  needReceipt: boolean,
  needPrintCurrentSight: boolean,
  needSeparateReceipt: boolean,
  receiptDeadline?: DateTime
): Promise<Blob> {
  return await download('/api/pdf/whsight', {
    classId,
    pids,
    year,
    sem,
    needReceipt,
    needPrintCurrentSight,
    needSeparateReceipt,
    receiptDeadline: receiptDeadline?.toISO(),
  });
}

export async function downloadWHClassListPDF(
  classId: string,
  pids: string[],
  year: number,
  sem: number,
  isPrintFitWeight: boolean,
  isPrintHistories: boolean,
  deadline?: DateTime,
): Promise<Blob> {
  return await download('/api/pdf/whclasslist', {
    classId,
    pids,
    year,
    sem,
    deadline: deadline?.toISO(),
    isPrintFitWeight,
    isPrintHistories
  });
}

export async function downloadWH85210ClassListPDF(
  classId: string,
  pids: string[],
  year: number,
  sem: number,
  exportFitWeight: boolean,
  eportFitHeight: boolean,
  deadline?: DateTime,
): Promise<Blob> {
  return await download('/api/pdf/wh85210', {
    classId,
    pids,
    year,
    sem,
    receiptDeadLine: deadline?.toISO(),
    exportFitWeight,
    eportFitHeight
  });
}

export async function downloadCheckAgreePDF(
  classId: string,
  pids: string[],
  year: number,
  sem: number,
  isJunior: boolean,
  checkUnit?: string,
  checkDate?: DateTime,
  replyDate?: DateTime,
  studentReplyDate?: DateTime,
): Promise<Blob> {
  return await download('/api/pdf/check-agree', {
    classId,
    pids,
    year,
    sem,
    isJunior,
    checkUnit,
    checkDate,
    replyDate,
    studentReplyDate
  });
}

export async function downloadWHHistoryClassListPDF(
  classId: string,
  pids: string[],
  year: number,
  sem: number,
  exportFitWeight: boolean,
  eportFitHeight: boolean,
  deadline?: DateTime,
): Promise<Blob> {
  return await download('/api/pdf/whhistory', {
    classId,
    pids,
    year,
    sem,
    receiptDeadLine: deadline?.toISO(),
    exportFitWeight,
    eportFitHeight
  });
}

export async function downloadWHHealthCard(
  studentPHI: StudentPHIAll,
  student: Student,
  pids: string[],
): Promise<Blob> {
  return await download('/api/pdf/whhealthcard', {
    studentPHI,
    student,
    pids,
  });
}
