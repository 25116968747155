import { z } from 'zod';
import { 
  SeriousDiseaseKindZod,
  StudentGradeNoSeatZod,
  BodyMindNZod,
} from '../model';
import { get, put } from './BaseApi';

const SeriousDiseaseKindItem = z.array(SeriousDiseaseKindZod);
const BodyMindListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      bodyMind: BodyMindNZod,
    })
  )
);

type SeriousDiseaseKinds = z.infer<typeof SeriousDiseaseKindItem>;
type BodyMindList= z.infer<typeof BodyMindListZod>;

export async function insertBodyMinds(
  year: number,
  sem: number,
  sid: string,
  seat: number,
  no: number,
  grade: number,
  pid: string,
  bodyMindId: number,
  degree: number,
  checkField: number,
): Promise<void> {
  await put(`/api/bodymindbookn/insert`, {
    year,
    sem,
    sid,
    seat,
    no,
    grade,
    pid,
    bodyMindId,
    degree,
    checkField
  })
}

export async function deleteStudentDeleteBodyMinds(
  id: number,
  studentId: string,
  bodyMindId: number,
  bodyMindName: string,
  degree: number,
): Promise<void> {
  await put(`/api/bodymindbookn/student/delete`, {
    id,
    studentId,
    bodyMindId,
    bodyMindName,
    degree
  })
}

export async function updateStudentBodyMindN(
  id: number,
  studentId: string,
  bodyMindId: number,
  bodyMindName: string,
  degree: number,
): Promise<void> {
  await put(`/api/bodymindbookn/student/update`, {
    id,
    studentId,
    bodyMindId,
    bodyMindName,
    degree
  })
}

export async function getBodyMindKindsDropDown(
): Promise<SeriousDiseaseKinds> {
  return await get(`/api/bodymindbookn/kind`, SeriousDiseaseKindItem, {})
}

export async function getBodyMindBookNAll(
  year: number,
  sem: number,
): Promise<BodyMindList> {
  return await get(`/api/bodymindbookn/student/all`, BodyMindListZod, {
    year,
    sem
  })
}

export async function getBodyMindInSchoolList(
  year: number,
  sem: number,
): Promise<BodyMindList> {
  return await get(`/api/bodymindbookn/student/inschool`, BodyMindListZod, {
    year,
    sem
  })
}
  
  
