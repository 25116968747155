import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { TeeGradeClassStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';


const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const teeStaticSexAll: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element } = useSemGrade();
    const [datas, setDatas] = useState<TeeGradeClassStatistic[]>([]);

    useEffect(() => {
        if (yearSem) {
            apis.getTeethTeeStaticSexAll(yearSem.year, yearSem.sem).then((s) => {
                console.log(s)
                setDatas(s);
            });
        }
    }, [yearSem?.year, yearSem?.sem]);

    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, TeeGradeClassStatistic[]>);

    let schoolcount = 0;
    let schoolcountT01 = 0;
    let schoolcountT11 = 0;
    let schoolcountT12 = 0;
    let schoolcountT13 = 0;
    let schoolcountT15 = 0;
    let countRow = 0;
    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const rows = gs.flatMap((g, idx) => {
                // 計算資料筆數
                countRow++;
                schoolcount += g.total?.count ?? 0;
                schoolcountT01 += g.total?.countT01 ?? 0;
                schoolcountT11 += g.total?.countT11 ?? 0;
                schoolcountT12 += g.total?.countT12 ?? 0;
                schoolcountT13 += g.total?.countT13 ?? 0;
                schoolcountT15 += g.total?.countT15 ?? 0;
                return TeeToRows(
                    g.grade,
                    g.male?.count ?? 0,
                    g.male?.countT01 ?? 0,
                    g.male?.countT11 ?? 0,
                    g.male?.countT12 ?? 0,
                    g.male?.countT13 ?? 0,
                    g.male?.countT15 ?? 0,
                    g.female?.count ?? 0,
                    g.female?.countT01 ?? 0,
                    g.female?.countT11 ?? 0,
                    g.female?.countT12 ?? 0,
                    g.female?.countT13 ?? 0,
                    g.female?.countT15 ?? 0,
                    g.total?.count ?? 0,
                    g.total?.countT01 ?? 0,
                    g.total?.countT11 ?? 0,
                    g.total?.countT12 ?? 0,
                    g.total?.countT13 ?? 0,
                    g.total?.countT15 ?? 0,
                );
            });

            if (datas.length == countRow) {
                rows.push(...TeeToRows(
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    schoolcount ?? 0,
                    schoolcountT01 ?? 0,
                    schoolcountT11 ?? 0,
                    schoolcountT12 ?? 0,
                    schoolcountT13 ?? 0,
                    schoolcountT15 ?? 0,
                ));
            }
            return rows;
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級　/ 　性別 ',
                    merge: { column: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '人數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '未治療齲齒人數A',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '未治療齲齒比率％',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '已治療齲齒人數B',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '已治療齲齒比率％',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '上顎恆牙第一大臼齒齲齒經驗C',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '上顎恆牙第一大臼齒齲齒經驗％',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '下顎恆牙第一大臼齒齲齒經驗D',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '下顎恆牙第一大臼齒齲齒經驗％',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '恆牙臼齒窩溝封填E',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '恆牙臼齒窩溝封填％',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
    ];

    // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <span className="mr-3">{element}</span>
                <Button
                    disabled={!datas.length}
                    onClick={() => {
                        const workSheet = generateSheet(
                            [
                                {
                                    cells: [
                                        {
                                            value: `${yearSem?.year}學年第${yearSem?.sem}學期_口腔檢查結果統計`,
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            value: '一、A：未治療齲齒人數（未治療齲齒資料代號為 1、3）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '二、B：已治療齲齒人數（已治療齲齒資料代號為 1）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '三、C：上顎恆牙第一大臼齒齲齒經驗（上顎恆牙第一大臼齒齲齒經驗資料代號為 1）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '四、D：下顎恆牙第一大臼齒齲齒經驗（下顎恆牙第一大臼齒齲齒經驗資料代號為 1）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '五、E：恆牙臼齒窩溝封填（恆牙臼齒窩溝封填資料代號為 1）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                ...content,
                                {
                                    cells: [
                                        {
                                            value:
                                                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets('口腔檢查結果統計', { sheet: workSheet, name: 'st' });
                    }}
                >
                    Excel 下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function TeeToRows(
    grade: number,
    maleCount: number,
    maleCountT01: number,
    maleCountT11: number,
    maleCountT12: number,
    maleCountT13: number,
    maleCountT15: number,
    femaleCount: number,
    femaleCountT01: number,
    femaleCountT11: number,
    femaleCountT12: number,
    femaleCountT13: number,
    femaleCountT15: number,
    totalCount: number,
    totalCountT01: number,
    totalCountT11: number,
    totalCountT12: number,
    totalCountT13: number,
    totalCountT15: number,
): Row[] {
    let returnhtml;
    const gradeNo = [
        {
            value: grade,
            merge: { row: 3 },
            style: { border: fullBorderStyle },
        }
    ];
    if (grade != 0) {
        returnhtml = [
            {
                cells: [
                    ...gradeNo,
                    {
                        value: "男",
                        merge: { row: 1 }
                    },
                    {
                        value: maleCount,
                    },
                    {
                        value: maleCountT01,
                    },
                    {
                        value: percent(maleCountT01, maleCount),
                    },
                    {
                        value: maleCountT11,
                    },
                    {
                        value: percent(maleCountT11, maleCount),
                    },
                    {
                        value: maleCountT12,
                    },
                    {
                        value: percent(maleCountT12, maleCount),
                    },
                    {
                        value: maleCountT13,
                    },
                    {
                        value: percent(maleCountT13, maleCount),
                    },
                    {
                        value: maleCountT15,
                    },
                    {
                        value: percent(maleCountT15, maleCount),
                    },
                ],

            },
            {
                cells: [
                    {
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: "女",
                        merge: { row: 1 }
                    },
                    {
                        value: femaleCount,
                    },
                    {
                        value: femaleCountT01,
                    },
                    {
                        value: percent(femaleCountT01, femaleCount),
                    },
                    {
                        value: femaleCountT11,
                    },
                    {
                        value: percent(femaleCountT11, femaleCount),
                    },
                    {
                        value: femaleCountT12,
                    },
                    {
                        value: percent(femaleCountT12, femaleCount),
                    },
                    {
                        value: femaleCountT13,
                    },
                    {
                        value: percent(femaleCountT13, femaleCount),
                    },
                    {
                        value: femaleCountT15,
                    },
                    {
                        value: percent(femaleCountT15, femaleCount),
                    },
                ],

            },
            {
                cells: [
                    {
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: "小計",
                        merge: { row: 1 }
                    },
                    {
                        value: totalCount,
                    },
                    {
                        value: totalCountT01,
                    },
                    {
                        value: percent(totalCountT01, totalCount)
                    },
                    {
                        value: totalCountT11,
                    },
                    {
                        value: percent(totalCountT11, totalCount)
                    },
                    {
                        value: totalCountT12,
                    },
                    {
                        value: percent(totalCountT12, totalCount)
                    },
                    {
                        value: totalCountT13,
                    },
                    {
                        value: percent(totalCountT13, totalCount)
                    },
                    {
                        value: totalCountT15,
                    },
                    {
                        value: percent(totalCountT15, totalCount)
                    },
                ],

            },
        ]
    } else {
        returnhtml = [
            {
                cells: [
                    {
                        value: "總計",
                        merge: { column: 2 }
                    },
                    {
                        value: totalCount,
                    },
                    {
                        value: totalCountT01,
                    },
                    {
                        value: percent(totalCountT01, totalCount)
                    },
                    {
                        value: totalCountT11,
                    },
                    {
                        value: percent(totalCountT11, totalCount)
                    },
                    {
                        value: totalCountT12,
                    },
                    {
                        value: percent(totalCountT12, totalCount)
                    },
                    {
                        value: totalCountT13,
                    },
                    {
                        value: percent(totalCountT13, totalCount)
                    },
                    {
                        value: totalCountT15,
                    },
                    {
                        value: percent(totalCountT15, totalCount)
                    },
                ],

            },

        ]
    }
    return returnhtml;
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}
export const TeeStaticSexAll = connector(teeStaticSexAll);
