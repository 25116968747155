import { z } from 'zod';
import { CheckStudentBaseData } from '../utils/checkFormat';

export const StudentDiseaseUploadZod = z.object({
  pid: z.string().nullish(),
  sid: z.number().nullish(),
  grade: z.number().nullish(),
  no: z.number().nullish(),
  seat: z.number().nullish(),
  diseaseId: z.number(),
  description: z.string().nullish(),
  treatment: z.string().nullish(),
});

export const StudentDiseaseFileZod = z
  .object({
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    疾病代號: z.string().nullish(),
    陳述: z.string().nullish(),
    處置: z.string().nullish(),
  })
  .transform((arg) => {
    let _errorMsg = '';
    let _okUpload = true;

    //檢查【身分證】、【學號】、【年級班級座號】格式
    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const pid = checkStudentBaseDataResult.pid;
    const sid = checkStudentBaseDataResult.sid;
    const grade = checkStudentBaseDataResult.grade;
    const no = checkStudentBaseDataResult.no;
    const seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    const diseaseIds = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 99,
    ];

    let diseaseId = 0;
    if (arg.疾病代號 === null || arg.疾病代號 === undefined) {
      diseaseId = 0;
      _okUpload = false;
      _errorMsg += '請填入【疾病代號】，';
    } else {
      diseaseId = +arg.疾病代號;
      if (
        Number.isNaN(diseaseId) ||
        diseaseIds.findIndex((d) => d === diseaseId) === -1
      ) {
        _okUpload = false;
        _errorMsg += '請確認【疾病代號】格式，';
      }
    }

    return {
      pid,
      sid,
      grade,
      no,
      seat,
      diseaseId,
      description: arg.陳述,
      treatment: arg.處置,
      yearClassId: 0,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

export type StudentDiseaseUpload = z.infer<typeof StudentDiseaseUploadZod>;
export type StudentDiseaseFile = z.infer<typeof StudentDiseaseFileZod>;
