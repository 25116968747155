import React, { ReactElement, FunctionComponent, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import { SideMenuItem } from './SideMenuItem';

import { MenuItem } from '../../model';
import { ApplicationState } from '../../redux/States';
import { StyleDispatches } from '../../redux/Dispatches';
import { SideMenuBadge } from './SideMenuBadge';
import { SideMenuIcon } from './SideMenuIcon';

type OwnProps = { collapse: MenuItem; type: 'main' | 'sub' };
const mapState = (state: ApplicationState, ownProps: OwnProps) => ({
  ...state.style,
  ...ownProps,
});
const mapDispatch = StyleDispatches;
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const sideMenuCollapse: FunctionComponent<Props> = ({
  collapse,
  type,
  menuItemDepth = [],
  toggleMenuItem,
}) => {
  useEffect(() => {
    const isThisCollapse =
      collapse.url && document.location.pathname.startsWith(`${collapse.url}/`);
    if (isThisCollapse) {
      console.log(collapse.url, document.location.pathname, type);
      toggleMenuItem({ ...collapse, showType: type });
    }
  }, [collapse, type]);

  let navItems: ReactElement[] = [];
  if (collapse.children) {
    const collapses = collapse.children;
    navItems = Object.values(collapses).reduce((elements, item) => {
      switch (item.type) {
        case 'collapse':
          elements.push(
            <SideMenuCollapse key={item.id} collapse={item} type="sub" />
          );
          break;
        case 'item':
          elements.push(<SideMenuItem key={item.id} item={item} />);
          break;
        default:
          break;
      }
      return elements;
    }, navItems);
  }

  const itemTitle = <span className="pcoded-mtext">{collapse.title}</span>;

  const navLinkClass = ['nav-link'];
  const navItemClass = ['nav-item', 'pcoded-hasmenu'];
  const openIndex = menuItemDepth.findIndex((item) => item.id === collapse.id);
  if (openIndex > -1) {
    navItemClass.push('active');
    navLinkClass.push('active');
    navItemClass.push('pcoded-trigger');
  }

  const isThisCollapse =
    collapse.url && document.location.pathname.startsWith(`${collapse.url}/`);
  if (isThisCollapse) {
    navItemClass.push('active');
  }

  return (
    <React.Fragment>
      <ListGroup.Item as="li" bsPrefix=" " className={navItemClass.join(' ')}>
        <summary
          className={navLinkClass.join(' ')}
          onClick={() => toggleMenuItem({ ...collapse, showType: type })}
        >
          <SideMenuIcon items={collapse} />
          {itemTitle}
          <SideMenuBadge items={collapse} />
        </summary>
        <ListGroup
          variant="flush"
          bsPrefix=" "
          as="ul"
          className="pcoded-submenu"
          onClick={() => toggleMenuItem({ ...collapse, showType: type })}
        >
          {navItems}
        </ListGroup>
      </ListGroup.Item>
    </React.Fragment>
  );
};

export const SideMenuCollapse = connector(sideMenuCollapse);
