import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import ExcelJS from 'exceljs';
import { YearSem } from '../model';
import html2canvas from 'html2canvas';
import { downloadBlob } from '../utils/element';

type Argument = {
    chartdata?: number[] | string[];
    yearSem?: YearSem;
    chartRef: React.MutableRefObject<null>,
    title: string
};

export const useDownloadChart = ({
    chartdata,
    yearSem,
    chartRef,
    title
}: Argument): {
    element: ReactNode;
} => {
    const exportToExcel = async () => {
        if (chartRef.current) {
            const canvas = await html2canvas(chartRef.current);
            const imgData = canvas.toDataURL('image/jpeg');

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('統計圖');

            worksheet.getCell('A1').value = title;

            // Add image to workbook
            const imageId = workbook.addImage({
                base64: imgData,
                extension: 'jpeg',
            });

            // Position the image in the worksheet
            worksheet.addImage(imageId, {
                tl: { col: 0, row: 1 },
                ext: { width: canvas.width, height: canvas.height },
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            downloadBlob(blob, `${title}.xlsx`);
        }
    };
    return {
        element:
            (
                <Button
                    disabled={!chartdata?.length}
                    className="text-dark"
                    variant="success"
                    onClick={exportToExcel}
                >
                    Excel 下載
                </Button>
            )
    }
};


export default useDownloadChart;