import React, {
  FunctionComponent,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import apis from '../../apis';
import {
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { toast } from 'react-toastify';

type Props = {
  remark: string;
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};

export const RemarkSection: FunctionComponent<Props> = ({
  remark,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const [remarkValue, setRemarkValue] = useState<string>();
    useState<number>();
  const [year, setYear] = useState<number>();
  const [sem, setSem] = useState<number>();

  useEffect(() => {
    // 目前學期、學年
    const isNow = semesters.filter((y) => y.isNow == true)[0];
    setYear(isNow.year);
    setSem(isNow.sem);
    setRemarkValue(remark ?? "");
  }, [semesters]);

  function updateStudentRemark() {
    if (year && sem) {
      toast
        .promise(
          apis.SetStudentRemark(
            student.pid,
            remarkValue ?? "",
            year,
            sem
          ),
          {
            pending: '資料更新中......',
            success: '更新成功！',
          }
        )
        .then(() => {
          onValueUpdated();
          setInputing(false);
        })
        .catch(onApiError);
    }
  }

  return (
    <>
      <div>
        學生備註 :
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            學生備註 -{' '}
            <strong>
              {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <Form.Control 
            as="textarea" 
            rows={3} 
            value={remarkValue}
            onChange={(e) => {
              setRemarkValue(e.target.value ?? "");
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={updateStudentRemark}>
            確定
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
