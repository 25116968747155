import React, { FunctionComponent } from 'react';
import { FormControl } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { Nullable } from '../../types';

type Props = {
  property: string;
  disabled?: boolean;
} & Nullable<HTMLInputElement>;

export const CheckBoxField: FunctionComponent<Props> = ({
  property,
  disabled = false,
  onchange,
}) => {
  return (
    <Field name={property} type="checkbox">
      {({ input, meta }) => (
        <>
          <FormControl {...input} disabled={disabled} />
          {meta.error &&
            (meta.modified || meta.submitFailed) &&
            !meta.active && <span className="text-danger">{meta.error}</span>}
        </>
      )}
    </Field>
  );
};
