import { DateTime } from 'luxon';
import z from 'zod';
import { sexValueToWord, sexWordToValue } from '../utils/transform';
import {
  zodStringOrNumberToNumber,
  zodStringOrNumberToString,
} from '../utils/zod';

export const GradeZod = z.object({
  grade: z.number().int(),
  name: z.string(),
});

export const StudentClassGradeZod = z.object({
  pid: z.string(),
  sid: z.string(),
  name: z.string(),
  seat: z.number().int(),
  classNo: z.number().int(),
  classGrade: z.number().int(),
  birth: z.string().transform((s) => DateTime.fromISO(s)),
  sex: z.string(),
  newClassNo: z.number().int().nullish(),
  newSeat: z.number().int().nullish(),
});

export const StudentSwitchClassZod = z.object({
  pid: z.string(),
  oldClassNo: z.number().int(),
  oldSeat: z.number().int(),
  newClassNo: z.number().int(),
  newSeat: z.number().int(),
});

export const StudentSwitchClassExcelZod = z.object({
  身份證號: z.string(),
  姓名: z.string(),
  學號: z.string(),
  性別: zodStringOrNumberToString('性別只接受 ‘1’、‘2’、‘男’、‘女’')
    .refine(
      (s) => ['1', '2', '男', '女'].some((v) => v === s),
      '性別只接受 ‘1’、‘2’、‘男’、‘女’'
    )
    .transform((v) => sexWordToValue(v, true)),
  生日: z.string().nullish(),
  班級: zodStringOrNumberToNumber('班級格式錯誤'),
  座號: zodStringOrNumberToNumber('座號格式錯誤'),
  新班級: zodStringOrNumberToNumber('新班級格式錯誤').nullish(),
  新座號: zodStringOrNumberToNumber('新座號格式錯誤').nullish(),
});

export const SwitchClassResponseZod = z.object({
  notExistsStudents: z.array(z.string()),
  noSeatNotSameStudents: z.array(z.string()),
});

export const transformClassGradeToExcelClass = (
  s: StudentClassGrade
): StudentSwitchClassExcel => ({
  身份證號: s.pid,
  學號: s.sid,
  生日: s.birth.toFormat('yyyy/MM/dd'),
  姓名: s.name,
  性別: sexValueToWord(s.sex, true),
  班級: s.classNo,
  座號: s.seat,
  新班級: s.newClassNo,
  新座號: s.newSeat,
});

export const transformExcelClassToClassGrade = (
  s: StudentSwitchClassExcel
): StudentClassGrade => ({
  pid: s.身份證號,
  sid: s.學號,
  birth: s.生日
    ? DateTime.fromFormat(s.生日, 'yyyy/MM/dd')
    : DateTime.invalid('no datetime'),
  name: s.姓名,
  sex: sexWordToValue(s.性別, true),
  classGrade: 0,
  classNo: s.班級,
  seat: s.座號,
  newClassNo: s.新班級,
  newSeat: s.新座號,
});

export type Grade = z.infer<typeof GradeZod>;
export type StudentClassGrade = z.infer<typeof StudentClassGradeZod>;
export type StudentSwitchClass = z.infer<typeof StudentSwitchClassZod>;
export type SwitchClassResponse = z.infer<typeof SwitchClassResponseZod>;
export type StudentSwitchClassExcel = z.infer<
  typeof StudentSwitchClassExcelZod
>;
