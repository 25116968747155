import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { ApplicationState } from '../../redux/States';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTable,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../components';
import { CheckField } from '../../model';
import { Button, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import {
  CheckShouldUseIdentify,
  assignExists,
  checkOkToUpload,
  takeClassStudent,
} from '../../utils';
import { z } from 'zod';
import apis from '../../apis';
import { toast } from 'react-toastify';
import { PhiXRay, PhiXRayFile, PhiXRayFileZod } from '../../model';
import { useActiveSems } from '../../hook';

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = { ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const inputFileParser = z.array(PhiXRayFileZod);
const tableHeader: HeaderDisplayKeysWithType<PhiXRayFile>[] = [
  { property: 'remark', display: '備註' },
  {
    display: '身份證字號',
    property: 'pid',
    style: { minWidth: '130px', width: '15%' },
  },
  {
    display: '學號',
    property: 'sid',
    style: { minWidth: '100px', width: '10%' },
  },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '胸部X光檢查', property: 'xRay' },
  { display: '肺結核病徵', property: 'phthisis' },
  { display: '肺結核鈣化', property: 'calcify' },
  { display: '胸廓異常', property: 'c03' },
  { display: '肋膜腔積水', property: 'water' },
  { display: '脊柱側彎', property: 'c02' },
  { display: '心臟肥大', property: 'bigHeart' },
  { display: '支氣管擴張', property: 'brochus' },
  { display: '其他', property: 'x99' },
  { display: '其他陳述', property: 'x99State' },
];
function filterOutNonPhiXRayField({
  remark,
  okUpload,
  ...phiXRay
}: PhiXRayFile): PhiXRay {
  return { ...phiXRay };
}

const importPhiXRay: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案'); // 如 :  const [fileName, setFileName] = ''; 可?
  const [fileData, setFileData] = useState([] as PhiXRayFile[]);
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { yearSemElement, selectedSemester } = useActiveSems(
    semesters,
    currentSemester
  );
  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        下載匯入格式：
        <a href="/Doc/胸部X光11.xls" style={{ textDecoration: 'underline' }}>
          胸部X光匯入格式
        </a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const ss = await inputFileParser.parseAsync(
                    jsonData.map((j: any) => ({ ...j, ...selectedSemester }))
                  );

                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    console.log(cf, ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級做號"'
                      );
                      return;
                    }
                    await apis
                      .checkClassesStudentExists(
                        takeClassStudent(ss),
                        cf,
                        selectedSemester
                      )
                      .then((result) => {
                        // console.log('importPhiWH.ts #104',result)
                        // setFileData (merge result&ss)
                        setFileData(assignExists(matchFunc, ss, result));
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, ss));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            } finally {
              e.target.value = '';
            }
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0 && checkField != CheckField.None)
              toast
                .promise(
                  apis.insertPhiXRay(
                    fileData.map(filterOutNonPhiXRayField),
                    checkField,
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      <Row>
        <EditableTable
          headers={tableHeader}
          values={fileData}
          scrollable={true}
          onRowRender={({ remark }) => ({
            rowClassName: remark ? 'bg-danger text-white' : '',
          })}
        />
      </Row>
    </AuthedLayout>
  );
};

export const ImportPhiXRayPage = connector(importPhiXRay);
