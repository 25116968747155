import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  Modal,
  Row,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Form } from 'react-final-form';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../apis';
import {
  AuthedLayout,
  CheckBoxField,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  TextField,
  DropdownCheckListField,
  DateTimeField,
} from '../components';
import { LabSem, SchoolRank } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { DateTime } from 'luxon';
import { I18N } from '../i18n-raw';
import { getPhysicalGradeByRank } from '../utils/grade';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

interface Grades {
  [key: string]: string;
}interface LabKinds {
  [key: string]: string;
}
const grades: Grades = {"1": "一年", "4": "四年"};
const labKinds: LabKinds = {"1": "寄生蟲", "2": "尿液"};
const modalDisplayHeader: HeaderDisplayKeysWithType<LabSem>[] = [
  { property: 'year', display: '學年' },
  { property: 'gradeID', display: '檢查年級' },
  { 
    property: 'schoolId',
    display: '受檢設定',
    onRender: (value: unknown, idx, editing, context) => (
      <button onClick={() => onUpdateLabSemResult(context.allValues.labKind, context.allValues.year, context.allValues.gradeID)}>有受檢且無異狀</button>
    ),
  },
  { 
    property: 'labKind', 
    display: '檢查種類',
    onRender(value: unknown, idx, editing, context) {
      if(value) {
        return typeof(value) == 'number' ? labKinds[value] : '無檢查項目';
      }
    },
  },
  { 
    property: 'checkDate', 
    display: '初查日期',
    onRender: (v: unknown) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
   },
  { 
    property: 'checkDate2',
    display: '複查日期',
    onRender: (v: unknown) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
   },
];

const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const dispatches = ErrorDispatches;
const connector = connect(mapState, dispatches);

type Props = ConnectedProps<typeof connector>;

const labSemPage: FunctionComponent<Props> = ({
  user,
  loading,
  catchErrorForModal,
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  const [editing, setEditing] = useState(false);
  const [editingStudent, setEditingStudent] = useState<LabSem>();
  const showCurrentRef = useRef<HTMLInputElement>(null);
  const fromInputRef = useRef<HTMLInputElement>(null);
  const toInputRef = useRef<HTMLInputElement>(null);
  const [grade, setGrade] = useState<string>("");
  const [gradeName, setGradeName] = useState<string>("");
  const [labKind, setLabKind] = useState<number>();
  const [labKindName, setLabKindName] = useState<string>("");
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const { year } = user.semesters[user.currentSemester];
  const currentSchool = user.currentSchool;
  const { rank } = user.schools[user.currentSchool];
  const physicalGradeRange = getPhysicalGradeByRank(
    user.schools[user.currentSchool].rank ?? SchoolRank.Junior
  );
  const [checkDate, setCheckDate] = useState(new Date()); // 初查日期
  const [checkDate2, setCheckDate2] = useState(new Date()); // 複查日期
  const [labSemList, setLabSemList] = useState([] as LabSem[]);
  const showLabSemNow = useRef<HTMLInputElement>(null);
  const checkDateInputRef = useRef<HTMLInputElement>(null);
  const checkDate2InputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getLabSemList();
  }, [year]);

  useEffect(() => {
    setBtnDisabled(true);
    if(grade) {
      if(labKind) {
        if(checkDate) {
          if(checkDate2) {
            setBtnDisabled(false);
          }
        }
      }
    }
  }, [grade, labKind]);

  // 實驗室檢查設定清單
  function getLabSemList() {
    if(showLabSemNow.current?.checked) {
      apis.getLabSemSettingsNow(year)
      .then((s) => {
        if(s.length) {
          setLabSemList(s);
        }
      })
      .catch(catchErrorForModal);
    } else {
      apis.getLabSemSetting()
      .then((s) => {
        if(s.length) {
          setLabSemList(s);
        }
      })
      .catch(catchErrorForModal);
    }
  }

  function insertStudentLabSem() {
    if(labKind) {
      toast
      .promise(
        apis.insertLabSemSetting(
          year,
          1,
          parseInt(grade),
          labKind,
          checkDate.toISOString(),
          checkDate2.toISOString(),
          currentSchool
        ),
        {
          pending: '資料新增中......',
          success: '新增成功！',
          error: '新增失敗！請查看錯誤資訊。',
        }
      )
      .then(() => {
       getLabSemList();
      })
      .catch(catchErrorForModal);
    }
  }

  // 檢查是否重複新增實驗室檢查
  function checkDuplicate() {
    const isDuplicate = labSemList.some(s => s.year == year && s.labKind == labKind && s.gradeID == parseInt(grade));
    
    if(isDuplicate) {
      toast.error(`${year}年${labKindName}實驗室檢查設定已存在`);
    } else {
      insertStudentLabSem();
    }
  }

  function onHide() {
    setEditing(false);
    setEditingStudent(undefined);
  }

  function onEditMetric(metric: LabSem) {
    setEditing(true);
    setEditingStudent(metric);
  }

  function onHideEditing() {
    setEditing(false);
    setEditingStudent(undefined);
  }

  function onUpdateLabSemExamDate(students: LabSem) {
    toast
      .promise(apis.updateLabSemExamDate(
        students.id,
        students.year,
        students.gradeID,
        students.labKind,
        students.checkDate ? students.checkDate?.toString() : "",
        students.checkDate2 ? students.checkDate2?.toString() : "",
        students.schoolId || "",
        checkDateInputRef.current?.checked ? 1 : 0,
        checkDate2InputRef.current?.checked ? 1 : 0,
      ), {
        pending: '實驗室檢查設定編輯中......',
        success: '實驗室檢查設定上傳成功！',
        error: '實驗室檢查設定上傳失敗！請查看錯誤資訊。',
      })
      .then(() =>{
        onHide();
        getLabSemList();
      })
      .catch(catchErrorForModal);
  }

  function modalInputFieldTitle(title: string, require: boolean = false) {
    return (
      <Col sm={2}>
        {require ? <span className="text-danger">*</span> : ''}
        {title}:
      </Col>
    );
  }


  return (
    <AuthedLayout>
      <Row className="mb-3">
        <Col sm={2} className='d-flex align-items-center'>
          年級：
          <DropdownButton
            title={gradeName || '請選擇' }
            onSelect={(k: string | null) => {
              if (k == null) return;
              setGrade(k);
              setGradeName(grades[k.toString()]);
            }}
          >
            {physicalGradeRange.map((g) => {
              return (
                <Dropdown.Item key={`labsemclass-${g}`} eventKey={g}>{I18N.Grades[g]}</Dropdown.Item>
              );
            })}
          </DropdownButton>
        </Col>
        <Col sm={2} className='d-flex align-items-center'>
          項目：
          <DropdownButton
            className=""
            title={labKindName || '請選擇'}
            onSelect={(k: string | null) => {
              if (k == null) return;
              setLabKind(parseInt(k));
              setLabKindName(labKinds[k.toString()]);
            }}
          >
            <Dropdown.Item key={`lab-1`} eventKey={1}>寄生蟲</Dropdown.Item>
            <Dropdown.Item key={`lab-2`} eventKey={2}>尿液</Dropdown.Item>
          </DropdownButton>
        </Col>
        初查日期：
        <Col sm={2} className='d-flex align-items-center'>
          <ReactDatePicker 
            className='wid-110' 
            dateFormat="yyyy/MM/dd" 
            selected={checkDate} 
            onChange={(date) => {
              if(date !== null) {
                setCheckDate(date);
              }
            }}
          />
        </Col>
          複查日期：
        <Col sm={2} className='d-flex align-items-center'>
          <ReactDatePicker 
            className='wid-110' 
            dateFormat="yyyy/MM/dd" 
            selected={checkDate2} 
            onChange={(date) => {
              if(date !== null) {
                setCheckDate2(date);
              }
            }}
          />
        </Col>
        <Button
          className='ml-auto'
          disabled={btnDisabled}
          onClick={() => checkDuplicate()}
        >
          新增
        </Button>
      </Row>
      <Row className='mb-2'>
        <Col sm={3}>
          <FormCheck
            className='ml-3'
            ref={showLabSemNow}
            label="只顯示現在學年"
            onChange={(e) => getLabSemList()}
          />
        </Col>
      </Row>
      <Row>
        <EditableTableDiv
          editable
          headers={modalDisplayHeader}
          values={labSemList}
          onEdit={onEditMetric}
        />
      </Row>
      <Modal
        show={editing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton onHide={onHideEditing}>
          <Modal.Title id="contained-modal-title-vcenter">
            實驗室檢查設定- {editingStudent?.labKind ? labKinds[editingStudent?.labKind] : ''}
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={editingStudent}
          onSubmit={onUpdateLabSemExamDate}
          validateOnBlur
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { values, handleSubmit } = prop;
            const rowClassName = 'pb-3 align-items-center justify-content-start';
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('入學年')}
                      <Col sm={3}>({editingStudent?.year}){I18N.Grades[1].substring(0, 1)}</Col>
                      {modalInputFieldTitle('檢查年級')}
                      <Col sm={3}>{editingStudent?.gradeID}</Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('檢查種類')}
                      <Col sm={3}>{editingStudent?.labKind ? labKinds[editingStudent?.labKind] : ''}</Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('初查日期')}
                      <Col sm={3}>
                        <DateTimeField property="checkDate" />
                      </Col>
                      {modalInputFieldTitle('複查日期')}
                      <Col sm={3}>
                        <DateTimeField property="checkDate2" />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      <Col className='text-left'>
                      <FormCheck
                        ref={checkDateInputRef}
                        label="更新檢查日期到學生實驗室檢查資料"
                      />
                      </Col>
                    </Row>
                    <Row className='mb-2'>
                      <Col className='text-left'>
                        <FormCheck
                          ref={checkDate2InputRef}
                          label="更新複查日期到學生實驗室檢查資料"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHideEditing}>
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            )
          }}
        />
      </Modal>
    </AuthedLayout>
  );
};

function onUpdateLabSemResult(labkind: number, year: number, gradeID: number) {
  toast
    .promise(apis.updateLabSemResult(
      labkind,
      year,
      gradeID
    ), {
      pending: '實驗室檢查設定編輯中......',
      success: '實驗室檢查設定上傳成功！',
      error: '實驗室檢查設定上傳失敗！請查看錯誤資訊。',
    })
    .then(() =>{
    })
    .catch();
}

export const LabSemPage = connector(labSemPage);
export {onUpdateLabSemResult}