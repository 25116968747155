import { EventEmitter } from 'events';

const { REACT_APP_ACTIVE_TIMEOUT_MIN } = process.env;
const localStorageKey = 'token';
const ActiveTimeoutMin =
  REACT_APP_ACTIVE_TIMEOUT_MIN &&
  typeof +REACT_APP_ACTIVE_TIMEOUT_MIN === 'number'
    ? +REACT_APP_ACTIVE_TIMEOUT_MIN
    : 60;
const ActiveTimeoutMS = ActiveTimeoutMin * 60 * 1000; // millisecond

enum TokenStorageEvent {
  PING = 'PING',
  TIMEOUT = 'TIMEOUT',
}

let timeout: NodeJS.Timeout | undefined = undefined;
const eventEmitter = new EventEmitter();
const activeCallbackPool = new Set();

eventEmitter.on(TokenStorageEvent.PING, startTimeout);
eventEmitter.on(TokenStorageEvent.TIMEOUT, () => {
  console.log(
    Date.now(),
    `kick user because inactived ${ActiveTimeoutMin} min`
  );
});

eventEmitter.emit(TokenStorageEvent.PING);

function stopTimeout() {
  if (timeout) {
    clearTimeout(timeout);
  }
}

function startTimeout() {
  stopTimeout();
  timeout = setTimeout(() => {
    eventEmitter.emit(TokenStorageEvent.TIMEOUT);
  }, ActiveTimeoutMS);
}

export function registerActiveTimeout(callback: () => void) {
  if (!activeCallbackPool.has(callback)) {
    startTimeout();
    eventEmitter.on(TokenStorageEvent.TIMEOUT, callback);
  } else {
    console.log('Callback Registered');
  }
}

export function updateCacheToken(newToken?: string): void {
  if (newToken) {
    eventEmitter.emit(TokenStorageEvent.PING);
    window.localStorage.setItem(localStorageKey, newToken);
  } else {
    window.localStorage.removeItem(localStorageKey);
  }
}

export function loadTokenfromStorage(): string | null {
  eventEmitter.emit(TokenStorageEvent.PING);
  return window.localStorage.getItem(localStorageKey);
}
