import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { sightDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
type PageData = StudentGradeNoSeat & Nullable<Sight> & { diag: string };

const tableHeader: (SheetHeaderDisplay<PageData> &
  HeaderDisplayKeysWithType<PageData>)[] = [
  { display: '座號', property: 'seat' },
  { display: '姓名', property: 'name' },
  { display: '裸視右', property: 'sight0R' },
  { display: '裸視左', property: 'sight0L' },
  { display: '戴鏡右', property: 'sightR' },
  { display: '戴鏡左', property: 'sightL' },
];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const sightClassCorrect: FunctionComponent<Props> = ({
  user,
  catchErrorForModal,
}) => {
  const { yearSem, element: semGradeElement } = useSemGrade();
  const { selectedClassGrade: classGrade, element: classGradeElement } =
    useClassGrade({
      yearSem,
    });
  const [students, setStudents] = useState<PageData[]>([]);

  useEffect(() => {
    if (yearSem && classGrade) {
      apis
        .getStudentsSightCorrect(yearSem.year, yearSem.sem, classGrade.id)
        .then((r) =>
          setStudents(
            r.map(({ sight, ...s }) => ({
              ...sight,
              ...s,
              diag: sightDiag(sight),
            }))
          )
        )
        .catch(catchErrorForModal);
    }
  }, [classGrade?.id, yearSem?.year, yearSem?.sem]);

  return (
    <AuthedLayout>
      <Row className="justify-content-between">
        <Col>
          <Row>
            <Col xs={3} className="mr-3">
              {semGradeElement}
            </Col>
            <Col xs={3} className="mr-3">
              {classGradeElement}
            </Col>
          </Row>
        </Col>
        <div>
          <Button
            disabled={!yearSem}
            variant="info"
            className="text-dark mr-3"
            onClick={() => {
              if (!yearSem) return;
              apis
                .getStudentsSightCorrect(yearSem.year, yearSem.sem)
                .then((r) => {
                  const students = r.map(({ sight, ...s }) => ({
                    ...sight,
                    ...s,
                    diag: sightDiag(sight),
                  }));
                  downloadDataAsExcel({
                    title: `${yearSem?.year}學年${yearSem?.sem}學期_裸眼視力正常清單`,
                    values: students,
                    headers: tableHeader,
                    context: SightContext,
                    groupBy: (v) => `${v.grade}年${v.no}班`,
                    footer:
                      '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                  });
                })
                .catch(catchErrorForModal);
            }}
          >
            所有班級 Excel 下載
          </Button>
          <Button
            disabled={!students.length}
            variant="success"
            className="text-dark"
            onClick={() => {
              downloadDataAsExcel({
                title: `${yearSem?.year}學年${yearSem?.sem}學期_班級視力清單`,
                values: students,
                headers: tableHeader,
                context: SightContext,
                groupBy: (v) => `${classGrade?.grade}年${classGrade?.no}班`,
                footer:
                  '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
              });
            }}
          >
            Excel 下載
          </Button>
        </div>
      </Row>
      <hr />
      <Row>
        <EditableTableDiv
          headers={tableHeader}
          values={students}
          context={SightContext}
        />
      </Row>
    </AuthedLayout>
  );
};

export const SightClassCorrect = connector(sightClassCorrect);
