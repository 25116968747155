import React, { ReactNode, useEffect, useState } from 'react';
import { InjuryDate, YearSem } from '../model';
import apis from '../apis';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { listToObject } from '../utils';
import { useDispatch } from 'react-redux';
import { ErrorDispatches } from '../redux/Dispatches';

type Argument = {
    yearSem?: YearSem;
};

export const useInjuryDate = ({
    yearSem,
}: Argument): {
    selectedInjuryDate?: InjuryDate;
    allInjuryDate: Record<string, InjuryDate>;
    element: ReactNode;
} => {
    const [showDateId, setShowDateId] = useState<string>('');
    const [injuries, setInjuries] = useState<{ [k: string]: InjuryDate }>({});
    const dispatch = useDispatch();
    useEffect(() => {
        if (yearSem)
            apis
                .getInjuryAccDateList(yearSem.year, yearSem.sem)
                .then((injuries) => {
                    if (injuries.length)
                        setInjuries(
                            listToObject(
                                injuries,
                                (c) => c.id,
                                (c) => c
                            )
                        );
                    setShowDateId('');
                })
                .catch(dispatch(ErrorDispatches.globalCatch));
    }, [yearSem?.year, yearSem?.sem]);

    return {
        selectedInjuryDate: injuries[showDateId],
        allInjuryDate: injuries,
        element: (
            <>
                <DropdownButton
                    title={
                        yearSem
                            ? (showDateId && injuries[showDateId].date) || '請選擇日期'
                            : '請先選擇學年'
                    }
                    onSelect={(k: string | null) => {
                        if (k == null || !injuries[k]) return;
                        setShowDateId(k);
                    }}
                >
                    {Object.entries(injuries).map(([i, n]) => (
                        <Dropdown.Item key={`class-${i}`} eventKey={i}>
                            {n.date}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </>
        ),
    };
};
