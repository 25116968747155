import { z } from 'zod';

export const HDIDStatisticZod = z.object({
  hdid: z.number(),
  total: z.number(),
  male: z.number(),
  female: z.number(),
});

export const ClassHDIDStatisticZod = z.object({
  grade: z.number(),
  classNo: z.number(),
  hdids: z.record(z.string(), HDIDStatisticZod),
});

export const GradeHDIDStatisticZod = z.object({
  grade: z.number(),
  hdids: z.record(z.string(), HDIDStatisticZod),
});

export const ClassWHAvgStatisticZod = z.object({
  grade: z.number(),
  femaleHavg: z.number(),
  femaleWavg: z.number(),
  femaleBMIavg: z.number(),
  maleWavg: z.number(),
  maleHavg: z.number(),
  maleBMIavg: z.number(),
  totalWavg: z.number(),
  totalHavg: z.number(),
  totalBMIavg: z.number(),
});

export const ClassSightDiagrate2StatisticZod = z.object({
  grade: z.number(),
  classNo: z.number(),
  allCount: z.number(),
  badCount: z.number(),
  referralCount: z.number(),
  badRate: z.number(),
  referralRate: z.number(),
});

export const ClassSightRateZod = z.object({
  grade: z.number(),
  classNo: z.number(),
  countD: z.number(),
  countN: z.number(),
  returnRate: z.string(),
});

export const CompareYearClassSightRateZod = z.object({
  thisGrade: z.number(),
  thisYearCount: z.number(),
  thisYearFailt: z.number(),
  lastGrade: z.number(),
  lastYearCount: z.number(),
  lastYearFailt: z.number(),
  thisYearFailtP: z.number().nullish(),
  lastYearFailtP: z.number().nullish(),
  failtGAP: z.number().nullish(),
});


export const TeeSexStatisticZod = z.object({
  count: z.number().nullish(),
  countT01: z.number().nullish(),
  countT011: z.number().nullish(),
  countT012: z.number().nullish(),
  countT013: z.number().nullish(),
  countT0123: z.number().nullish(),
  countT11: z.number().nullish(),
  countT12: z.number().nullish(),
  countT13: z.number().nullish(),
  countT15: z.number().nullish(),
  tsheetadultCount: z.number().nullish(),
  tsheetchildrenCount: z.number().nullish(),
  reTsheetadultCount: z.number().nullish(),
  reTsheetchildrenCount: z.number().nullish(),
});

export const TeeGradeClassStatisticZod = z.object({
  grade: z.number(),
  classNo: z.number().nullish(),
  female: TeeSexStatisticZod.nullish(),
  male: TeeSexStatisticZod.nullish(),
  total: TeeSexStatisticZod.nullish(),
});

export const InjurySexStatisticZod = z.object({
  count: z.number().nullish(),
});

export const InjuryGradeClassStatisticZod = z.object({
  grade: z.number(),
  classNo: z.number().nullish(),
  female: InjurySexStatisticZod.nullish(),
  male: InjurySexStatisticZod.nullish(),
  total: InjurySexStatisticZod.nullish(),
});

export const CleanTeethStudentStatisticZod = z.object({
  grade: z.number(),
  classNo: z.number().nullish(),
  month: z.number(),
  studentCount: z.number(),
  brushTimes: z.number(),
  stDontBrushTimes: z.number(),
});

export const FluorStudentStatisticZod = z.object({
  grade: z.number(),
  classNo: z.number().nullish(),
  neverFluor: z.number(),
  studentCount: z.number(),
  yearSemDontFluor: z.number(),
  fluorStudentCount: z.number(),
  isFluorStudentCount: z.number(),
  fluorWeek: z.number().nullish()
});

export const SightUnCorrectChartZod = z.object({
  grade: z.number(),
  sex: z.number(),
  count: z.number(),
});

export const WHChartZod = z.object({
  grade: z.number(),
  sex: z.number(),
  count: z.number(),
});

export const WHBmiChartZod = z.object({
  bmiCode: z.string().nullish(),
  count: z.number(),
});

export const SightChartZod = z.object({
  a1: z.number(),
  a2: z.number(),
  a3: z.number(),
  a4: z.number(),
  a6: z.number(),
  b1: z.number(),
  b2: z.number(),
  b3: z.number(),
});

export const DiseaseChartZod = z.object({
  grade: z.number(),
  diseaseId: z.number(),
  diseaseName: z.string(),
  count: z.number()
});

export const StudentCountZod = z.object({
  grade: z.number(),
  classNo: z.number(),
  femaleCount: z.number(),
  maleCount: z.number(),
  totalCount: z.number()
});

export type HDIDStatistic = z.infer<typeof HDIDStatisticZod>;
export type ClassHDIDStatistic = z.infer<typeof ClassHDIDStatisticZod>;
export type ClassSightDiagrate2Statistic = z.infer<typeof ClassSightDiagrate2StatisticZod>;
export type ClassSightRate = z.infer<typeof ClassSightRateZod>;
export type CompareYearClassSightRate = z.infer<typeof CompareYearClassSightRateZod>;
export type ClassWHAvgStatistic = z.infer<typeof ClassWHAvgStatisticZod>;
export type GradeWHAvgStatistic = z.infer<typeof GradeHDIDStatisticZod>;
export type TeeSexStatistic = z.infer<typeof TeeSexStatisticZod>;
export type TeeGradeClassStatistic = z.infer<typeof TeeGradeClassStatisticZod>;
export type InjuryGradeClassStatistic = z.infer<typeof InjuryGradeClassStatisticZod>;
export type CleanTeethStudentStatistic = z.infer<typeof CleanTeethStudentStatisticZod>;
export type FluorStudentStatistic = z.infer<typeof FluorStudentStatisticZod>;
export type SightUnCorrectChartStatistic = z.infer<typeof SightUnCorrectChartZod>;
export type WHChartStatistic = z.infer<typeof WHChartZod>;
export type WHBmiChartStatistic = z.infer<typeof WHBmiChartZod>;
export type SightChartStatistic = z.infer<typeof SightChartZod>;
export type DiseaseChartStatistic = z.infer<typeof DiseaseChartZod>;
export type StudentCountStatistic = z.infer<typeof StudentCountZod>;
