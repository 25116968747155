import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { ClassHDIDStatistic, GradeWHAvgStatistic, HDIDStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';


const HDID = {
    // Total: -9,
    UnderWeight: -1,
    Healty: 0,
    OverWeight: 1,
    Obesity: 2,
};

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const whGradeStatistic: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element } = useSemGrade();
    const [datas, setDatas] = useState<GradeWHAvgStatistic[]>([]);

    useEffect(() => {
        if (yearSem) {
            apis.getWHGradeStatistic(yearSem.year, yearSem.sem).then((s) => {
                setDatas(s);
            });
        }
    }, [yearSem?.year, yearSem?.sem]);

    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, GradeWHAvgStatistic[]>);

    const totalHdidObj = {
        // [HDID.Total]: { hdid: HDID.Total, total: 0, male: 0, female: 0 },
        [HDID.Obesity]: { hdid: HDID.Obesity, total: 0, male: 0, female: 0 },
        [HDID.OverWeight]: {
            hdid: HDID.OverWeight,
            total: 0,
            male: 0,
            female: 0,
        },
        [HDID.Healty]: { hdid: HDID.Healty, total: 0, male: 0, female: 0 },
        [HDID.UnderWeight]: {
            hdid: HDID.UnderWeight,
            total: 0,
            male: 0,
            female: 0,
        },
    };
    // let classcount = 0;
    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const gradeHDID = gs.reduce(
                (pv, g) => {
                    Object.values(g.hdids).forEach((h) => {
                        const hdid = pv[h.hdid];
                        if (hdid) {
                            hdid.total += h.total;
                            hdid.female += h.female;
                            hdid.male += h.male;
                        }
                        const totalHdid = totalHdidObj[h.hdid];
                        if (totalHdid) {
                            totalHdid.total += h.total;
                            totalHdid.female += h.female;
                            totalHdid.male += h.male;
                        }
                    });

                    return pv;
                },
                {
                    // [HDID.Total]: { hdid: HDID.Total, total: 0, male: 0, female: 0 },
                    [HDID.Obesity]: { hdid: HDID.Obesity, total: 0, male: 0, female: 0 },
                    [HDID.OverWeight]: {
                        hdid: HDID.OverWeight,
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    [HDID.Healty]: { hdid: HDID.Healty, total: 0, male: 0, female: 0 },
                    [HDID.UnderWeight]: {
                        hdid: HDID.UnderWeight,
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                }

            );
            const rows = gs.flatMap((g, idx) => {
                return hdidToRows(
                    g.grade,
                    gradeHDID
                )
            });
            if (idx === allArray.length - 1) {
                const lastRow = rows[rows.length - 1];

                lastRow.cells = lastRow?.cells.map((c) => {
                    const { style: { border = {} } = {} } = c;
                    border.bottom = borderStyle;
                    c.style = { ...c.style, border };
                    return c;
                });
            }
            return rows;
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級　/ 　性別 ',
                    merge: { column: 3, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '體位判讀',
                    merge: { column: 5 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        {
            cells: [

                {
                    value: '體位過輕',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '體位適中',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '體位過重',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '體位肥胖',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '合計',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
        ...hdidToRows('全校合計', totalHdidObj),
    ];

    // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <span className="mr-3">{element}</span>
                <Button
                    disabled={!datas.length}
                    onClick={() => {
                        const workSheet = generateSheet(
                            [
                                {
                                    cells: [
                                        {
                                            value: `${yearSem?.year}學年第${yearSem?.sem}學期_體位判讀結果統計報表(年級)`,
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                                ...content,
                                {
                                    cells: [
                                        {
                                            value:
                                                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets('體位判讀結果統計報表(年級)', { sheet: workSheet, name: 'st' });
                    }}
                >
                    Excel 下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function hdidToRows(
    grade: number | string,
    hdidObj: Record<number, HDIDStatistic>,
): Row[] {
    const totalCount = Object.values(hdidObj).reduce(
        (p, v) => {
            p.total += v.total;
            p.male += v.male;
            p.female += v.female;
            return p;
        },
        {
            total: 0,
            male: 0,
            female: 0,
        }
    );
    const gradeNo = [
        {
            value: grade,
            merge: { row: 6 },
            style: { border: fullBorderStyle },
        }
    ];

    return [
        {
            cells: [
                ...gradeNo,
                {
                    value: "男",
                    merge: { row: 2 }
                },
                {
                    value: "人數",
                },
                ...[
                    HDID.UnderWeight,
                    HDID.Healty,
                    HDID.OverWeight,
                    HDID.Obesity,
                ].flatMap((hdid) => {
                    return [

                        {
                            value: hdidObj[hdid]?.male ?? 0,
                        }

                    ] as Cell[];
                }),
                {
                    value: totalCount?.male ?? 0,
                },
            ],

        },
        {
            cells: [
                {
                    style: { border: fullBorderStyle },
                },
                {
                    style: { border: fullBorderStyle },
                },
                {
                    value: "比率",
                },
                ...[
                    HDID.UnderWeight,
                    HDID.Healty,
                    HDID.OverWeight,
                    HDID.Obesity,
                ].flatMap((hdid) => {
                    return [
                        {
                            value: percent(hdidObj[hdid]?.male ?? 0, totalCount.total),
                            style: { border: { bottom: borderStyle } },
                        },
                    ] as Cell[];
                }),
                {
                    value: percent(totalCount.male ?? 0, totalCount.male),
                    style: { border: { left: borderStyle, bottom: borderStyle } },
                },
            ],

        },
        {
            cells: [
                {
                    style: { border: fullBorderStyle },
                },
                {
                    value: "女",
                    merge: { row: 2 }
                },
                {
                    value: "人數",
                },
                ...[
                    HDID.UnderWeight,
                    HDID.Healty,
                    HDID.OverWeight,
                    HDID.Obesity,
                ].flatMap((hdid) => {
                    return [

                        {
                            value: hdidObj[hdid]?.female ?? 0,
                        }

                    ] as Cell[];
                }),
                {
                    value: totalCount?.female ?? 0,
                },
            ],

        },
        {
            cells: [
                {
                    style: { border: fullBorderStyle },
                },
                {
                    style: { border: fullBorderStyle },
                },
                {
                    value: "比率",
                },
                ...[
                    HDID.UnderWeight,
                    HDID.Healty,
                    HDID.OverWeight,
                    HDID.Obesity,
                ].flatMap((hdid) => {
                    return [
                        {
                            value: percent(hdidObj[hdid]?.female ?? 0, totalCount.total),
                            style: { border: { bottom: borderStyle } },
                        },
                    ] as Cell[];
                }),
                {
                    value: percent(totalCount.female ?? 0, totalCount.female),
                    style: { border: { left: borderStyle, bottom: borderStyle } },
                },
            ],
        },
        {
            cells: [
                {
                    style: { border: fullBorderStyle },
                },
                {
                    style: { border: fullBorderStyle },
                },
                {
                    value: "小計",
                    merge: { row: 2 }
                },
                {
                    value: "人數",
                },
                ...[
                    HDID.UnderWeight,
                    HDID.Healty,
                    HDID.OverWeight,
                    HDID.Obesity,
                ].flatMap((hdid) => {
                    return [

                        {
                            value: hdidObj[hdid]?.total ?? 0,
                        }

                    ] as Cell[];
                }),
                {
                    value: totalCount?.total ?? 0,
                },
            ],

        },
        {
            cells: [
                {
                    style: { border: fullBorderStyle },
                },
                {
                    style: { border: fullBorderStyle },
                },
                {
                    value: "比率",
                },
                ...[
                    HDID.UnderWeight,
                    HDID.Healty,
                    HDID.OverWeight,
                    HDID.Obesity,
                ].flatMap((hdid) => {
                    return [
                        {
                            value: percent(hdidObj[hdid]?.total ?? 0, totalCount.total),
                            style: { border: { bottom: borderStyle } },
                        },
                    ] as Cell[];
                }),
                {
                    value: percent(totalCount.total ?? 0, totalCount.total),
                    style: { border: { left: borderStyle, bottom: borderStyle } },
                },
            ],
        },
    ];
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}

export const WHGradeStatistic = connector(whGradeStatistic);
