import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../../components';
import Apis from '../../../apis';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { Nullable } from '../../../types';
import { PHIWH, StudentWH } from '../../../model';
import {
  Row,
  Table,
  Container,
  Button,
  Col,
  FormControl,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { ClassSem, WrapClassBaseLayout } from '../../../layouts';
import { ApplicationState } from '../../../redux/States';
import { WHPropertyFields, WHTableHeader } from './../../TableHeaders/WHHeader';
import { semesterRange } from '../../../utils/date';
import { useSemGrade } from '../../../hook';
import { SheetHeaderDisplay, downloadDataAsExcel } from '../../../utils';
import { SightContext } from '../../TableHeaders/SightHeader';

type MetricPageData = StudentWH & Nullable<PHIWH>;

const displayHeader: (SheetHeaderDisplay<MetricPageData> &
  HeaderDisplayKeysWithType<MetricPageData>)[] = [
  { property: 'pid', display: '統編' },
  { property: 'name', display: '學生' },
  {
    display: '性別',
    property: 'sex',
    onRender: (v) => (v === '1' ? '男' : '女'),
  },
  { property: 'grade', display: '年級' },
  { property: 'no', display: '班級' },
  { property: 'seat', display: '座號' },
  { property: 'height', display: '身高' },
  { property: 'weight', display: '體重' },
  { display: 'BMI', property: 'bmi' },
];

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
  ...state.auth,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;
const wHCantCheck: FunctionComponent<Props> = ({
  year,
  sem,
  catchErrorForModal,
}) => {
  const { yearSem, element: semGradeElement } = useSemGrade();
  const [students, setStudent] = useState([] as MetricPageData[]);
  const [inputing, setInputing] = useState(false);
  const [editingStudent, setEditingStudent] = useState<MetricPageData>();

  useEffect(() => {
    if (yearSem) {
      Apis.getClassStudentCantCheck(yearSem.year, yearSem.sem)
        .then((metrics) => {
          const datas: MetricPageData[] = metrics.map((m) => {
            const latestMetric =
              m.phiWHs.length > 0
                ? m.phiWHs.find((cv) => cv.year === year && cv.sem === sem)
                : undefined;
            return {
              ...m,
              ...latestMetric,
            };
          });
          setStudent(datas);
        })
        .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }, [yearSem?.year, yearSem?.sem, inputing]);

  return (
    <AuthedLayout>
      <Row className="justify-content-between">
        <Col>
          <Row>
            <Col xs={3} className="mr-3">
              {semGradeElement}
            </Col>
          </Row>
        </Col>
        <div>
          <Button
            disabled={!students.length}
            variant="success"
            className="text-dark"
            onClick={() => {
              downloadDataAsExcel({
                title: `${yearSem?.year}學年${yearSem?.sem}學期_生長發育無法測量名單`,
                values: students,
                headers: displayHeader,
                context: SightContext,
                footer:
                  '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
              });
            }}
          >
            Excel 下載
          </Button>
        </div>
      </Row>
      <hr />
      <Row>
        <EditableTableDiv
          headers={displayHeader}
          values={students}
          context={SightContext}
        />
      </Row>
    </AuthedLayout>
  );
};

export const WHCantCheck = connector(wHCantCheck);
