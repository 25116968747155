import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  className?: string;
  children?: ReactNode;
};

export const MyInputGroup: FunctionComponent<Props> = ({
  children,
  className = '',
}) => {
  return <div className={`my-input-group ${className}`}>{children}</div>;
};
