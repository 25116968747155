import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { TeeGradeClassStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';


const HDID = {
    // Total: -9,
    UnderWeight: -1,
    Healty: 0,
    OverWeight: 1,
    Obesity: 2,
};

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const teeClassStaticSexNeatInit: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element } = useSemGrade();
    const [datas, setDatas] = useState<TeeGradeClassStatistic[]>([]);

    useEffect(() => {
        if (yearSem) {
            apis.getTeethTeeClassStaticSexNeatInit(yearSem.year, yearSem.sem).then((s) => {
                setDatas(s);
            });
        }
    }, [yearSem?.year, yearSem?.sem]);

    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, TeeGradeClassStatistic[]>);

    let schoolcount = 0;
    let schoolT01count = 0;

    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const classTee = {
                grade: 0,
                classNo: 0,
                totalCount: 0,
                totalCountT01: 0,
                Rate: "",
            };
            const rows = gs.flatMap((g, idx) => {
                return TeeToRows(
                    g.grade,
                    g.classNo ?? 0,
                    g.total?.count ?? 0,
                    g.total?.countT01 ?? 0,
                    percent(g.total?.countT01 ?? 0, g.total?.count ?? 0),
                    idx === 0 ? { row: (gs.length + 1) } : undefined
                );
            });
            gs.forEach(tee => {
                classTee.totalCount += tee.total?.count ?? 0,
                    classTee.totalCountT01 += tee.total?.countT01 ?? 0,
                    classTee.Rate = percent(classTee.totalCountT01, classTee.totalCount);
                schoolcount += tee.total?.count ?? 0
                schoolT01count += tee.total?.countT01 ?? 0
            });
            rows.push(...TeeToRows('', '小計', classTee.totalCount, classTee.totalCountT01, classTee.Rate));
            if (idx === allArray.length - 1) {
                const lastRow = rows[rows.length - 1];

                lastRow.cells = lastRow?.cells.map((c) => {
                    const { style: { border = {} } = {} } = c;
                    border.bottom = borderStyle;
                    c.style = { ...c.style, border };
                    return c;
                });
            }

            return rows;
        }
    );
    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級班級',
                    merge: { column: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '人數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '初檢齲齒人數A',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '初檢齲齒％',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
        ...TeeToRows('全校合計', '', schoolcount, schoolT01count, percent(schoolT01count, schoolcount), { column: 2, row: 2 }),
    ];

    // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <span className="mr-3">{element}</span>
                <Button
                    disabled={!datas.length}
                    onClick={() => {
                        const workSheet = generateSheet(
                            [
                                {
                                    cells: [
                                        {
                                            value: `${yearSem?.year}學年第${yearSem?.sem}學期_口腔齲齒初檢結果分班統計`,
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            value: '一、A：初檢齲齒人數（未治療齲齒資料代號為 1、2、3）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                ...content,
                                {
                                    cells: [
                                        {
                                            value:
                                                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets('口腔齲齒初檢結果分班統計`', { sheet: workSheet, name: 'st' });
                    }}
                >
                    Excel 下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function TeeToRows(
    grade: number | string,
    classNo: number | string,
    totalCount: number,
    totalCountT01: number,
    totalRate: string,
    gradeMerge?: { row?: number; column?: number }
): Row[] {
    const gradeNo = [
        {
            value: gradeMerge ? grade : undefined,
            merge: gradeMerge,
            style: { border: fullBorderStyle },
        },
    ];
    if (!gradeMerge?.column) {
        gradeNo.push({
            value: classNo,
            merge: { row: 1 },
            style: { border: fullBorderStyle },
        });
    }
    return [
        {
            cells: [
                ...gradeNo,
                {
                    value: totalCount ?? 0,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: totalCountT01 ?? 0,
                },
                {
                    value: totalRate ?? "0%",
                    style: { border: { right: borderStyle } },
                },
            ],
        },

    ];
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}

export const TeeClassStaticSexNeatInit = connector(teeClassStaticSexNeatInit);
