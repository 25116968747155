import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Student, YearSem } from '../model';
import apis from '../apis';
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { listToObject } from '../utils';
import { useDispatch } from 'react-redux';
import { ErrorDispatches } from '../redux/Dispatches';
import { useHistory, useParams } from 'react-router';
import { useQuery } from './useQuery';

type Argument = {
  yearSem?: YearSem;
  classId?: string;
};

const ID_QUERY_PARAM_KEY = 'id';

export const useClassStudents = ({
  yearSem,
  classId,
}: Argument): {
  selectedStudent?: Student;
  element: ReactNode;
} => {
  const searchParams = useQuery();
  const id = searchParams.get(ID_QUERY_PARAM_KEY) ?? '';
  const history = useHistory();
  const [inputId, setInputId] = useState(id);
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [students, setStudents] = useState<Record<string, Student>>({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (yearSem && classId)
      apis
        .getClassStudents(classId, yearSem.year, yearSem.sem)
        .then((s) => {
          setStudents(
            listToObject(
              s,
              (s) => s.pid,
              (s) => s
            )
          );
          setSelectedStudentId('');
        })
        .catch(dispatch(ErrorDispatches.globalCatch));
    else {
      setStudents({});
      setSelectedStudentId('');
    }
  }, [yearSem, classId]);

  useEffect(() => {
    if (id) {
      if (id.startsWith('=')) {
        apis
          .GetStudentBySid(id.slice(1))
          .then((s) => {
            if (Object.keys(students).length == 0) {
              setStudents({ [s.pid]: s });
            }
            setSelectedStudentId(s.pid);
          })
          .catch(dispatch(ErrorDispatches.globalCatch));
      } else {
        apis
          .GetStudent(id)
          .then((s) => {
            if (Object.keys(students).length == 0) {
              setStudents({ [s.pid]: s });
            }
            setSelectedStudentId(s.pid);
          })
          .catch(dispatch(ErrorDispatches.globalCatch));
      }
    } else {
      setSelectedStudentId('');
    }
  }, [id]);

  function updateStudentIdentify(value: string) {
    if (value) {
      searchParams.set(ID_QUERY_PARAM_KEY, value);
      history.push({ search: searchParams.toString() });
    } else {
      history.push({ search: '' });
    }
  }

  const studentSelection = classId ? (
    <DropdownButton
      disabled={!classId || !yearSem}
      title={
        (selectedStudentId &&
          students[selectedStudentId] &&
          `${students[selectedStudentId].seat} ${students[selectedStudentId].name}`) ||
        '請選擇學生'
      }
      onSelect={(k) => {
        if (k && students[k]) {
          setSelectedStudentId(k);
          updateStudentIdentify(k);
        }
      }}
    >
      {Object.entries(students).map(([k, n]) => (
        <Dropdown.Item key={`student-${k}`} eventKey={k}>
          {n.seat} {n.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  ) : (
    <div>
      <span className="mr-1">請輸入班級座號或身分證或學號(學號前請加=):</span>
      <Form.Control
        className="wid-150 d-inline mr-2"
        value={inputId}
        onChange={(e) => {
          setInputId(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          updateStudentIdentify(inputId);
        }}
      >
        搜尋
      </Button>
    </div>
  );

  return {
    selectedStudent: selectedStudentId
      ? students[selectedStudentId]
      : undefined,
    element: studentSelection,
  };
};
