import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, Container, FormCheck, Modal, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import {
  AuthedLayout,
  CheckBoxField,
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  InputTransformField,
  NumberField,
  TextField,
} from '../components';
import { ClassData, ClassSetting, SchoolClassNameEnum } from '../model';
import Apis from '../apis';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import { getGradeMinMax } from '../utils';
import { I18N } from '../i18n-raw';
import { useActiveSems } from '../hook';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const validator = (v: number) => (0 < +v && +v < 101 ? undefined : '1~100');

const headerDisplayKeys: HeaderDisplayKeysWithType<ClassSetting>[] = [
  {
    display: '年級',
    property: 'grade',
  },
  {
    display: '班級代號',
    property: 'no',
  },
  {
    display: '班級名稱',
    property: 'name',
    onRender: (v, i, e) => (e ? <TextField property="name" /> : v),
  },
  {
    display: '學生數',
    property: 'studentCount',
  },
  // {
  //   display: '高職學制',
  //   property: 'education',
  // },
];

const checkTee: HeaderDisplayKeysWithType<ClassSetting> = {
  display: '有檢查口腔',
  property: 'checkTee',
  onRender: (v, i, e) =>
    e ? (
      <CheckBoxField property="checkTee" />
    ) : (
      <FormCheck checked={!!v} readOnly />
    ),
};
const checkTeeSem2: HeaderDisplayKeysWithType<ClassSetting> = {
  display: '有檢查口腔',
  property: 'checkTeeSem2',
  onRender: (v, i, e) =>
    e ? (
      <CheckBoxField property="checkTeeSem2" />
    ) : (
      <FormCheck checked={!!v} readOnly />
    ),
};

const classesSetting: FunctionComponent<Props> = ({
  user,
  loading,
  catchErrorForModal,
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  const { currentSemester, semesters, currentRank } = user;
  const [classSettings, setClassSettings] = useState<ClassSetting[]>([]);
  const [inputing, setInputing] = useState(false);
  const [classNameInputing, setClassNameInputing] = useState(false);
  const [defaultClassName, SetDefaultClassName] =
    useState<SchoolClassNameEnum>();
  const { selectedSemester, yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );
  const tableHeader = useMemo(() => {
    if (selectedSemester.sem === 2) {
      return [...headerDisplayKeys, checkTeeSem2];
    }
    return [...headerDisplayKeys, checkTee];
  }, [selectedSemester]);
  function refreshClasses() {
    Apis.getClassSettings(selectedSemester.year, selectedSemester.sem)
      .then((classes) => {
        setClassSettings(classes);
      })
      .catch(catchErrorForModal);
  }

  useEffect(() => {
    refreshClasses();
  }, [selectedSemester]);

  function onHide() {
    setInputing(false);
    refreshClasses();
  }

  async function onCreateClass(data: { count: number; grade: number }) {
    await Apis.createClass(data, selectedSemester.year)
      .catch(catchErrorForModal)
      .then(onHideInputing);
  }

  async function onUpdateClass(data: ClassSetting) {
    await Apis.updateClass(data.id, data, selectedSemester.year, selectedSemester.sem)
      .catch(catchErrorForModal)
      .then(onHideInputing);
  }

  async function onDeleteClass(value: ClassSetting) {
    await Apis.deleteClass(value.id, selectedSemester.year)
      .catch(catchErrorForModal)
      .then(onHideInputing);
  }

  function onCreating() {
    setInputing(true);
  }

  function onHideInputing() {
    setInputing(false);
    refreshClasses();
  }

  async function onClearEmptyClass() {
    await Apis.removeEmptyClasses(selectedSemester.year)
      .catch(catchErrorForModal)
      .then(() => refreshClasses());
  }

  async function onSetClassDefaultName() {
    if (defaultClassName !== undefined) {
      await Apis.setClassesDefaultName(defaultClassName, selectedSemester.year)
        .catch(catchErrorForModal)
        .then(() => {
          setClassNameInputing(false);
          SetDefaultClassName(undefined);
          refreshClasses();
        });
    }
  }

  async function setAllClassesCheckTee(check: boolean) {
    await Apis.updateClassesCheckTee(
      selectedSemester.year,
      selectedSemester.sem,
      check
    )
      .catch(catchErrorForModal)
      .then(() => refreshClasses());
  }

  const { max, min } = useMemo(() => {
    return getGradeMinMax(currentRank);
  }, []);
  const form = inputing ? (
    <Form
      onSubmit={(value: { grade: number; count: number }) => {
        onCreateClass(value);
      }}
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit }) => (
        <React.Fragment>
          <Modal.Body className="text-center">
            <Row className="pb-3 justify-content-center align-items-center">
              <InputTransformField
                property="grade"
                type="number"
                className="wid-200 mr-2"
                min={min}
                max={max}
                transform={(v) =>
                  (+v >= min && +v <= max && I18N.Grades[+v]) || '未知年級'
                }
                validate={(v) => {
                  return +v >= min && +v <= max
                    ? undefined
                    : `範圍應在${min}~${max}之間`;
                }}
              />
              <span className="mr-2">新增班級數</span>
              <NumberField
                property="count"
                validate={validator}
                className="wid-60 mr-1"
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={handleSubmit}>
              新增
            </Button>
            <Button type="reset" variant="secondary" onClick={onHide}>
              關閉
            </Button>
          </Modal.Footer>
        </React.Fragment>
      )}
    />
  ) : (
    <></>
  );

  return (
    <AuthedLayout>
      <Row className="mb-2">
        <Button className="mr-2" onClick={onCreating}>
          新增班級
        </Button>
        <Button className="mr-2" onClick={() => setClassNameInputing(true)}>
          重設班級名稱
        </Button>
        <Button className="mr-2" onClick={onClearEmptyClass}>
          清除沒有學生的班級
        </Button>
      </Row>
      <Row className="mb-2">
        <Button className="mr-2" onClick={() => setAllClassesCheckTee(true)}>
          設定全校口腔經常性檢查為有受檢
        </Button>
        <Button className="mr-2" onClick={() => setAllClassesCheckTee(false)}>
          設定全校口腔經常性檢查為未受檢
        </Button>
      </Row>
      <Row className="mb-2">{yearSemElement}</Row>
      <hr />
      <Row>
        <InlineEditableTable
          headers={tableHeader}
          values={classSettings}
          onValueUpdate={onUpdateClass}
          onValueDelete={onDeleteClass}
          onRowRender={() => ({ deleteable: true })}
        />
      </Row>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">新增班級</Modal.Title>
        </Modal.Header>
        {form}
      </Modal>
      <Modal
        show={classNameInputing}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          onHide={() => {
            SetDefaultClassName(undefined);
            setClassNameInputing(false);
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            重設班級名稱
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {[
              SchoolClassNameEnum.Regular,
              SchoolClassNameEnum.JiaYiBingDing,
              SchoolClassNameEnum.ZhongXiaoRenAi,
              SchoolClassNameEnum.MeiLanZhuJu,
            ].map((s) => (
              <FormCheck
                key={'name-' + s}
                type="radio"
                className="mb-3"
                label={I18N.SchoolClassNameEnum[s]}
                name="classDefaultName"
                checked={s === defaultClassName}
                onChange={(e) => {
                  SetDefaultClassName(s);
                }}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" onClick={() => onSetClassDefaultName()}>
            重設
          </Button>
          <Button
            type="reset"
            variant="secondary"
            onClick={() => {
              SetDefaultClassName(undefined);
              setClassNameInputing(false);
            }}
          >
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthedLayout>
  );
};

export const ClassesSetting = connector(classesSetting);
