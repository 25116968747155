import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { StudentGradeNoSeat, StudentDisease } from '../../../model';
import apis from '../../../apis';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
type PageData = StudentDisease & StudentGradeNoSeat;

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '年', property: 'grade', style: { width: "7%" } },
        { display: '班', property: 'no', style: { width: "7%" } },
        { display: '座號', property: 'seat', style: { width: "7%" } },
        { display: '姓名', property: 'name', style: { width: "7%" } },
        { display: '疾病', property: 'diseaseName', style: { width: "7%" } },
        { display: '陳述', property: 'description' },
        { display: '照護', property: 'treatment' },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const diseaseClass: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    const yearSem = { year: year, sem: sem };
    const { selectedClassGrade: classGrade, element: classGradeElement } =
        useClassGrade({
            yearSem
        });
    const [students, setStudents] = useState<PageData[]>([]);

    useEffect(() => {
        if (yearSem && classGrade) {
            apis
                .getClassDisease(yearSem.year, yearSem.sem, classGrade.id)
                .then((r) =>
                    setStudents(
                        r.map(({ studentDisease, ...s }) => ({
                            ...studentDisease,
                            ...s,
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [classGrade?.id]);

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {classGradeElement}
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!students.length}
                        variant="info"
                        className="text-dark mr-3"
                        onClick={() => {
                            if (!yearSem) return;
                            apis
                                .getClassDisease(yearSem.year, yearSem.sem)
                                .then((r) => {
                                    const students = r.map(({ studentDisease, ...s }) => ({
                                        ...studentDisease,
                                        ...s,
                                    }));
                                    downloadDataAsExcel({
                                        title: `${yearSem?.year}學年${yearSem?.sem}學期_個人疾病史清單(分班)`,
                                        values: students,
                                        headers: tableHeader,
                                        groupBy: (v) => `${v.grade}年${v.no}班`,
                                        footer:
                                            '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                    });
                                })
                                .catch(catchErrorForModal);
                        }}
                    >
                        所有班級 Excel 下載
                    </Button>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_個人疾病史清單(分班)`,
                                values: students,
                                headers: tableHeader,
                                groupBy: (v) => `${classGrade?.grade}年${classGrade?.no}班`,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                />
            </Row>
        </AuthedLayout>
    );
};

export const DiseaseClass = connector(diseaseClass);