import React, {
  FunctionComponent,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ExaminedResultEnum,
  ExtractedPHIParasite,
  LabSem,
  PHIParasite,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import {
  DateTimeField,
  CheckBoxField,
} from '../../components';
import {
  Container,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { I18N } from '../../i18n-raw';
import apis, { ApiError, E_GRADE_INVALID } from '../../apis';
import { DateTime } from 'luxon';

type Props = {
  editable: boolean;
  parasite: PHIParasite[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  grade: number;
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};

export const LabParasiteSection: FunctionComponent<Props> = ({
  editable,
  parasite,
  student,
  semesters,
  yearSems,
  grade,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const [labSemList, setLabSemList] = useState([] as LabSem[]);
  const currentParasite = useMemo(
    () => {
      const current =  parasite.filter((s) => s.grade === grade && s.sem === 1);
      return getParasiteFormData(current);
    },
    [grade, parasite]
  );

  function onValueUpdate(value: ExtractedPHIParasite, examined: boolean, result: number) {
    const validGrade = yearSems.find((s) => s.grade === grade);
    if (validGrade) {
      const promise = examined
        ? apis.InsertStudentParasite(student.pid, {
            ...DEFAULT_PARASITE_RESULT,
            ...value,
            parasite: result,
            grade: validGrade.grade,
            sem: 1,
            year: validGrade.year,
          })
        : apis.deleteStudentParasite(student.pid, validGrade.year, 1);

      promise.catch(onApiError).then(onValueUpdated);
    } else {
      onApiError(new ApiError(E_GRADE_INVALID));
    }
    setInputing(false);
  }

  function getLabSemList() {
    const validGrade = yearSems.find((s) => s.grade === grade);
    if(!labSemList.length) {
      if(validGrade) {
        apis.getLabSemSettingsNow(validGrade.year)
        .then((s) => {
          if(s.length) {
            setLabSemList(s);
          }
        })
        .catch(onApiError);
      }
    }
  }
  return (
    <>
      <div className="sheet-title">
        實驗室檢查 {grade ?? ''}年級
      </div>
      <Table bordered className="student-phi mb-2">
        <tbody>
          <tr>
            <th>
              寄生蟲
              <Button
                key="cell-edit"
                className="border-0 px-2 py-1"
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  setInputing(true);
                  getLabSemList();
                }}
              >
                <span className="feather icon-edit" />
              </Button>
            </th>
            <td>
              {currentParasite.parasite == 9 ? (
                "尚未受檢"
              ) : (
                <div>
                  <div className={currentParasite.parasite != 0 ? 'text-danger' : ''}>
                    初/複查結果{currentParasite.parasite}
                  </div>
                  <div>
                    初查日期{' '}
                    {currentParasite.examDate?.toFormat('yyyy/MM/dd')}
                  </div>
                  <div>{currentParasite.drug ? '已服藥' : '未服藥'}</div>
                  {currentParasite.reExamDate && (
                    <p>
                      複查日期:{' '}
                      {currentParasite.reExamDate.toFormat('yyyy/MM/dd')}
                    </p>
                  )}
                </div>
              )}
            </td> 
          </tr>
        </tbody>
      </Table>
      {inputing ? (
        <ParasiteModel
          parasite={currentParasite}
          student={student}
          grade={grade}
          settings={labSemList.filter(x => x.labKind == 1)}
          onClose={() => setInputing(false)}
          onValueUpdate={onValueUpdate}
        />
      ) : (
        <></>
      )}
    </>
  );
};

type ModelProps = {
  parasite?: ExtractedPHIParasite;
  student: Student;
  grade: number;
  settings: LabSem[],
  onClose: () => void;
  onValueUpdate: (v: ExtractedPHIParasite, examined: boolean, result: number) => void;
};
function ParasiteModel({
  parasite,
  student,
  grade,
  settings,
  onClose,
  onValueUpdate,
}: ModelProps) {
  const [updating, setUpdating] = useState(false);
  const [examResult, setExamResult] = useState(parasite?.parasite || 0);
  const [examined, setExamined] = useState(!(parasite?.parasite == 9));
  const [reExamined, setReExamined] = useState((parasite?.parasite == 1 || parasite?.parasite == 2 || parasite?.parasite == 3));
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <Form
      initialValues={parasite}
      onSubmit={(v: ExtractedPHIParasite) => {
        if(examResult == ExaminedResultEnum.NoExamined && !updating) {
          setUpdating(true);
        } else {
          onValueUpdate(v, examined, examResult);
        }
      }}
      validateOnBlur
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, form }) => {
        return (
          <>
            <Modal
              show={true}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              dialogClassName="modal-dialog-full"
              centered
            >
              <Modal.Header closeButton onHide={onClose}>
                <Modal.Title id="contained-modal-title-vcenter">
                  實驗室檢查 寄生蟲-{' '}
                  <strong>
                    {student.seat} {student.name}
                  </strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body ref={divRef} className="overflow-auto">
                <Table striped bordered hover className="text-break">
                  <colgroup>
                    <col width={'7%'} />
                    <col width={'90%'} />
                  </colgroup>
                  <thead>
                    <tr>
                      <td className="align-middle">項目</td>
                      <td className="align-middle">
                        <Row noGutters className="align-items-center">
                          <Col xs={12} md={2}>
                            身分證： {student.pid}
                          </Col>
                          <Col xs={12} md={2}>
                            檢查年級： {grade}
                          </Col>
                          <Col xs={12} md={2}>
                            結果：{' '}
                            <DropdownButton
                              className="d-inline mr-2"
                              title={examResult == 9 ? '未受檢' : I18N.ExaminationResult[examResult]}
                              onSelect={(k: string | null) => {
                                const examResult = k;
                                setExamResult(examResult ? parseInt(examResult) : 0);
                                setExamined((lastExamine) => {
                                  const nextExamine = k == '9' ? false : true;
                                  // 代入實驗室檢查日期
                                  if (nextExamine) {
                                    if(settings.length) { 
                                      DEFAULT_PARASITE_RESULT.examDate = settings[0].checkDate;
                                      if(k != '0'){
                                        DEFAULT_PARASITE_RESULT.reExamDate = settings[0].checkDate2;
                                      } else {
                                        DEFAULT_PARASITE_RESULT.reExamDate = null;
                                      }
                                    }
                                    form.reset(DEFAULT_PARASITE_RESULT);
                                  } else if (!nextExamine) {
                                    form.reset(NO_EXAMINED_PARASITE_RESULT);
                                  }
                                  return nextExamine;
                                });
                                setReExamined((k == '0' || k == '9') ? false : true);
                              }}
                            >
                              <Dropdown.Item eventKey={ExaminedResultEnum.NoProblem}>無異狀</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.InitialAbnoraml}>初檢異常</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.FollowUpNormal}>複診正常</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.FollowUpAbnormal}>複診異常</Dropdown.Item>
                              <Dropdown.Item eventKey={ExaminedResultEnum.NoExamined}>未受檢</Dropdown.Item>
                            </DropdownButton>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>初檢</td>
                      <td>
                        <Row noGutters>
                          檢查日期：
                          <Col sm={4}>
                            <DateTimeField
                              property="examDate"
                              disabled={!examined}
                              className="ml-1 wid-200"
                              end={DateTime.now()}
                            />
                          </Col>
                          <div>{I18N.Parasite['drug']}：</div>
                          <Col sm={1} className="d-flex align-items-center my-1">
                            <CheckBoxField 
                              property="drug"
                              className="wid-20"
                              disabled={!examined}
                            />
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td>複檢</td>
                      <td>
                        <Row noGutters>
                          檢查日期：
                          <Col sm={4}>
                            <DateTimeField
                              property="reExamDate"
                              disabled={!reExamined}
                              className="ml-1 wid-200"
                              end={DateTime.now()}
                            />
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="align-middle">
                        <Button className="mx-2" onClick={() => handleSubmit()}>
                          <span className="feather icon-check mr-1" />
                          儲存
                        </Button>
                        <Button
                          className="mx-2"
                          variant="danger"
                          onClick={onClose}
                        >
                          <span className="feather icon-x  mr-1" />
                          取消
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
            <Modal
              show={updating}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              dialogClassName="modal-dialog-full"
              centered
            >
              <Modal.Header closeButton onHide={() => setUpdating(false)}></Modal.Header>
              <Modal.Body className="overflow-auto">
                <Container>
                  <Row>
                    <Col className="my-2" sm={12}>
                      由於該生檢查結果設定為『未受檢』，若點擊『確定』後該生初複查日期與已服藥資料，將由系統設定為空值與未選取狀態，如要修改檢查結果則點擊『取消』返回資料輸入畫面。
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" onClick={() => handleSubmit()}>
                  確認
                </Button>
                <Button
                  type="reset"
                  variant="secondary"
                  onClick={() => setUpdating(false)}
                >
                  取消
                </Button>
              </Modal.Footer>
            </Modal>              
          </>
        );
      }}
    />
  );
}

function invalidStatus(data: ExaminedResultEnum): boolean {
  return data === 1 || data === 2 || data === 3;
}

function getParasiteFormData(data: PHIParasite[]): ExtractedPHIParasite {
  if (data && data.length > 0) {
    let parasiteData: ExtractedPHIParasite | null = null;
    let reExamDate: DateTime | undefined;

    for (const p of data) {
      if (p.inspect === 1) {
        parasiteData = {
          id: p.id,
          year: p.year,
          sem: p.sem,
          grade: p.grade,
          inspect: p.inspect,
          parasite: p.parasite,
          examDate: p.examDate ?? undefined,
          drug: p.drug,
          yearClassId: p.yearClassId,
        };
      } else if (p.inspect === 2 && p.examDate) {
        reExamDate = p.examDate;
      }

      if (parasiteData && reExamDate) break;
    }

    if (parasiteData) {
      return { 
        ...parasiteData, 
        ...(reExamDate !== undefined && { reExamDate })
      };
    }
  }
  return NO_EXAMINED_PARASITE_RESULT;
}

const DEFAULT_PARASITE_RESULT: ExtractedPHIParasite = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  inspect: 1,
  parasite: ExaminedResultEnum.NoProblem,
  drug: false,
  yearClassId: 0,
};
const NO_EXAMINED_PARASITE_RESULT: ExtractedPHIParasite = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  inspect: 1,
  parasite: ExaminedResultEnum.NoExamined,
  drug: false,
  yearClassId: 0,
};