import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
} from '../../../components';
import { useDownloadChart, useSemGrade } from '../../../hook';
import { SightChartStatistic } from '../../../model';
import apis from '../../../apis';
import useApexChart, { ChartState } from '../../../hook/useApexBarChart';
import { whbmiDiag } from '../../../utils/sight';
import Chart from 'react-apexcharts'
import useApexDonutChart, { DonutChartState } from '../../../hook/useApexDonutChart';

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;


const sightStatisticChart: FunctionComponent<Props> = ({
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<SightChartStatistic>();
    const chartRef = useRef(null);
    useEffect(() => {
        if (yearSem) {
            apis
                .getSightChart(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(r)
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);

    const series = (students ? [students.a1, students.b1, students.b2 + students.b3, students.a2 + students.a3 + students.a4 + students.a6 - (students.b1 + students.b2 + students.b3)] : [0, 0, 0, 0]);
    const labels = ["正常", "配鏡後視力達到標準", "配鏡後視力未達標準", "未接受配鏡矯正"];

    const state: DonutChartState = {
        options: {
            chart: {
                type: 'donut',
            },
            labels: labels,
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '70%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                formatter: val => `${val}`
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: 'Total',
                                formatter: w => w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0)
                            }
                        }
                    }
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                formatter: function (val, opts) {
                    return val + " - " + opts.w.globals.series[opts.seriesIndex] + "人";
                }
            }
        },
        series: series
    };
    const { element: donutchart } = useApexDonutChart(state);
    const { element: downloadChart } = useDownloadChart({ chartdata: series, yearSem: yearSem, chartRef: chartRef, title: `${yearSem?.year}學年第${yearSem?.sem}學期_視力統計圖` });

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col xs={3} className="mr-3">
                    {semGradeElement}
                </Col>
                <Col xs={1} className="mr-1">
                    {downloadChart}
                </Col>
            </Row>
            <hr />
            <Row>
                <Col className="text-center">
                    {yearSem?.year}學年第{yearSem?.sem}學期_視力統計圖
                </Col>
            </Row>

            <Row className="justify-content-center">
                <div ref={chartRef}>
                    {donutchart}
                </div>
            </Row>
        </AuthedLayout>
    );
};

export const SightStatisticChart = connector(sightStatisticChart);
