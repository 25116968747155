import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';
import apis from '../../apis';
import {
  EditableProperties,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../components';
import { GradeSem, WrapGradeBaseLayout } from '../../layouts';
import { StudentGradeNoSeat, PHISightSolid } from '../../model';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import { I18N } from '../../i18n-raw';

type StudentWithEditable = StudentGradeNoSeat & PHISightSolid & EditableProperties;
const sightSolidKind = [
  {
    Id: 0,
    Name: '斜視',
  },
  {
    Id: 1,
    Name: '弱視',
  },
  {
    Id: 2,
    Name: '斜、弱視',
  },
  {
    Id: 3,
    Name: '屈光不正',
  },
  {
    Id: 4,
    Name: '無確定診斷',
  },
  {
    Id: 5,
    Name: '診治正常',
  },
  {
    Id: 6,
    Name: '其他眼疾',
  },
  {
    Id: 7,
    Name: '未就醫',
  }
];
const modalDisplayHeader: HeaderDisplayKeysWithType<StudentWithEditable>[] = [
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '學生', property: 'name' },
  { display: '性別', property: 'sex' },
  { display: '座號', property: 'seat' },
  { display: '統編', property: 'pid' },
  { 
    property: 'sightSolid', 
    display: '診斷',
    onRender: (value: unknown) => {
      if (value) {
        return typeof value == 'number'
          ? sightSolidKind[value].Name
          : '無診斷';
      }
    },
  },
  { property: 'states', display: '其他診斷' },
  { property: 'hospital', display: '醫院' }
];

const mapState = (state: ApplicationState, ownProps: GradeSem) => ({
  ...ownProps,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const solidPDFPage: FunctionComponent<Props> = ({
  grade,
  year,
  sem,
  catchErrorForModal,
}) => {
  const [students, setStudent] = useState<StudentWithEditable[]>([]);
  useEffect(() => {
    refreshStudents();
  }, [grade, year, sem]);

  function refreshStudents() {
    if (grade && year && sem) {
      apis
        .getStudentSightSolidListByGrade(grade, year, sem)
        .then((ss) => {
          setStudent(
            ss.map(({ sightSolid, ...s }) => ({
              ...sightSolid,
              ...s,
            }))
          );
        })
        .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }
  if (!grade) return <></>;
  return (
    <>
      <Row className="mb-2">
        <Button
          className="ml-auto"
          onClick={() => {
            let pids = students.filter((s) => s.selected).map((s) => s.pid);
            pids = pids.length == 0 ? students.map((s) => s.pid) : pids;
            toast
              .promise(apis.downloadSolidPDF(grade, pids, year, sem), {
                pending: '下載中...',
                success: '下載成功！',
                error: '下載失敗！請查看錯誤資訊。',
              })
              .then((blob) => {
                const objlink = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objlink;
                a.setAttribute('download', `視力立體感檢查不良通知單-${I18N.Grades[grade]}級.pdf`);
                document.body.appendChild(a);
                a.click();

                a.parentNode?.removeChild(a);
              })
              .catch(catchErrorForModal);
          }}
        >
          <span className="feather icon-download"></span>
        </Button>
      </Row>
      <Row>
        <EditableTableDiv
          headers={modalDisplayHeader}
          values={students}
          onSelected={(s) => {
            if (s.index !== undefined) {
              const v = students[s.index];
              v.selected = s.checked;
            } else {
              students.forEach((v) => {
                v.selected = s.checked;
              });
            }
            setStudent([...students]);
          }}
        />
      </Row>
    </>
  );
};

export const SolidPDFPage = connector(WrapGradeBaseLayout(solidPDFPage));
