import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, SightAndPreSightNoDiags, StudentGradeNoSeat, YearSem } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { sightDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
type PageData = StudentGradeNoSeat & Nullable<SightAndPreSightNoDiags>;

function boolRender(v: unknown) {
    return v ? '是' : '否';
}

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '身分證字號', property: 'pid', style: { width: "7%" } },
        { display: '姓名', property: 'name' },
        { display: '年級', property: 'grade', style: { width: "3%" } },
        { display: '學期', property: 'year' },
        { display: '班級', property: 'no', style: { width: "3%" } },
        { display: '座號', property: 'seat', style: { width: "3%" } },
        {
            display: '性別', property: 'sex',
            onRender: (v) => (v === '1' ? '男' : '女'),
            onSheetRender: (v) => (v === '1' ? '男' : '女'),
            style: { width: "3%" }
        },
        {
            display: '散瞳治療',
            property: 'isDilated',
            onRender: boolRender,
            onSheetRender: boolRender,
        },
        { display: '近視右', property: 'eNearR', style: { width: "4%" } },
        { display: '近視左', property: 'eNearL', style: { width: "4%" } },
        { display: '屈光右', property: 'diopR' },
        { display: '屈光左', property: 'diopL' },
        {
            display: '散瞳',
            property: 'isDilating',
            onRender: boolRender,
            onSheetRender: boolRender,
        },
        { display: '年級', property: 'preGrade', style: { width: "3%" } },
        { display: '學期', property: 'preYear' },
        {
            display: '散瞳治療',
            property: 'preisDilated',
            onRender: boolRender,
            onSheetRender: boolRender,
        },
        { display: '近視右', property: 'preENearR', style: { width: "4%" } },
        { display: '近視左', property: 'preENearL', style: { width: "4%" } },
        { display: '屈光右', property: 'preDiopR' },
        { display: '屈光左', property: 'preDiopL' },
        {
            display: '散瞳',
            property: 'preisDilating',
            onRender: boolRender,
            onSheetRender: boolRender,
        },
        { display: '增加', property: 'differ' },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;


const useCalculateInterval = (): {
    calculateintervalId?: string;
    element: ReactNode;
} => {
    const [chooseId, settype] = useState<string>();
    return {
        calculateintervalId: chooseId,
        element: (
            <>
                <DropdownButton
                    title={(chooseId) || '請選擇查詢區間'}
                    onSelect={(k: string | null) => {
                        if (k == null) return;
                        settype(k);
                    }}
                >
                    <Dropdown.Item eventKey="學年">學年</Dropdown.Item>
                    <Dropdown.Item eventKey="學期">學期</Dropdown.Item>
                </DropdownButton>
            </>
        ),
    };
};

const useIncreasedShortSight = (): {
    increasedShortSightId?: string;
    element: ReactNode;
} => {
    const [chooseId, settype] = useState<string>();
    return {
        increasedShortSightId: chooseId,
        element: (
            <>
                <DropdownButton
                    title={(chooseId) || '請選擇增加度數'}
                    onSelect={(k: string | null) => {
                        if (k == null) return;
                        settype(k);
                    }}
                >
                    <Dropdown.Item eventKey="50">50</Dropdown.Item>
                    <Dropdown.Item eventKey="100">100</Dropdown.Item>
                    <Dropdown.Item eventKey="150">150</Dropdown.Item>
                    <Dropdown.Item eventKey="200">200</Dropdown.Item>
                </DropdownButton>
            </>
        ),
    };
};

const sightIncreasedShort: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { calculateintervalId, element: calculateIntervalElement } = useCalculateInterval();
    const { increasedShortSightId, element: increasedShortSightElement } = useIncreasedShortSight();

    const [students, setStudents] = useState<PageData[]>([]);
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    useEffect(() => {
        if (calculateintervalId && increasedShortSightId) {
            apis
                .getIncreasedShortSight(year, sem, calculateintervalId == "學年" ? 0 : 1, Number(increasedShortSightId))
                .then((r) =>
                    setStudents(
                        r.map(({ sight, ...s }) => ({
                            ...sight,
                            ...s,
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [increasedShortSightId, calculateintervalId, year,sem]);

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-2">
                            {calculateIntervalElement}
                        </Col>
                        <Col xs={3} className="mr-2">
                            {increasedShortSightElement}
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_近視度數增加清單`,
                                values: students,
                                headers: tableHeader,
                                context: SightContext,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                />
            </Row>
        </AuthedLayout>
    );
};


export const SightIncreasedShort = connector(sightIncreasedShort);
