import React, { ReactNode, useEffect, useState } from 'react';
import { Diseases, YearSem } from '../model';
import apis from '../apis';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { listToObject } from '../utils';
import { useDispatch } from 'react-redux';
import { ErrorDispatches } from '../redux/Dispatches';

type Argument = {
    yearSem?: YearSem;
};

export const useDisease = ({
    yearSem,
}: Argument): {
    selectedDisease?: Diseases;
    allDisease: Record<string, Diseases>;
    element: ReactNode;
} => {
    const [showDiseaseId, setShowDiseaseId] = useState<string>('');
    const [Diseases, setDiseases] = useState<{ [k: string]: Diseases }>({});
    const dispatch = useDispatch();
    useEffect(() => {
        if (yearSem)
            apis
                .GetDiseaseDropDown()
                .then((disease) => {
                    if (disease.length)
                        setDiseases(
                            listToObject(
                                disease,
                                (c) => c.id,
                                (c) => c
                            )
                        );
                    setShowDiseaseId('');
                })
                .catch(dispatch(ErrorDispatches.globalCatch));
    }, [yearSem?.year, yearSem?.sem]);

    return {
        selectedDisease: Diseases[showDiseaseId],
        allDisease: Diseases,
        element: (
            <>
                <DropdownButton
                    title={
                        yearSem
                            ? (showDiseaseId && Diseases[showDiseaseId].name) || '請選擇疾病'
                            : '請先選擇學年'
                    }
                    onSelect={(k: string | null) => {
                        if (k == null || !Diseases[k]) return;
                        setShowDiseaseId(k);
                    }}
                >
                    {Object.entries(Diseases).map(([i, n]) => (
                        <Dropdown.Item key={`class-${i}`} eventKey={i}>
                            {n.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </>
        ),
    };
};
