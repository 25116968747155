import { z } from 'zod';

export enum SchoolRank {
  Primary = 1,
  PrimaryJunior = 6,
  Junior = 7,
  JuniorSenior = 8,
  Senior = 10,
  Senior4 = 11,
}
export const schoolRankZod = z.nativeEnum(SchoolRank);

export enum SchoolClassNameEnum {
  Regular = 0,
  JiaYiBingDing = 1,
  ZhongXiaoRenAi = 2,
  MeiLanZhuJu = 3,
}
export const SchoolClassNameEnumZod = z.nativeEnum(SchoolClassNameEnum);

export const schoolZod = z.object({
  id: z.string(),
  name: z.string(),
  tel: z.string(),
  zip: z.string(),
  address: z.string(),
  rank: z.number().int(),
  grade: z.string().nullish(),
  cityCode: z.string().nullish(),
  countryCode: z.string(),
});

export const simpleSchoolZod = z.object({
  id: z.string(),
  name: z.string(),
  rank: schoolRankZod,
});

export type School = z.infer<typeof schoolZod>;
export type SimpleSchool = z.infer<typeof simpleSchoolZod>;
