import {
  PhiWH,
  PhiSight,
  PhiTee,
  PhiSundry,
  PhiLab,
  PhiPhysical,
  CheckField,
  PhiWHSight,
  YearSem,
} from '../model';
import { PhiBlood } from '../model/PhiBlood';
import { PhiXRay } from '../model/PhiXRay';
import { put } from './BaseApi';

export async function insertPhiWH(
  students: PhiWH[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  await put(`/api/phi/wh/batch`, { checkField, students }, yearSem);
}

export async function insertPhiSight(
  students: PhiSight[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  await put(`/api/phi/sight/batch`, { students, checkField }, yearSem);
}

export async function insertPhiTee(
  teeData: PhiTee[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  const students = teeData.map((x) =>
    x.tSheet != null ? { ...x, tSheet: deserializeTeeSheet(x.tSheet) } : x
  );
  await put(`/api/phi/tee/batch`, { students, checkField }, yearSem);
}

function deserializeTeeSheet(value: string): Record<string, string> {
  return value
    .split(',')
    .map((s) => s.trim())
    .reduce((des, str) => {
      const key = str.slice(0, 2);
      let value = str.slice(2);
      // 1X,2X,3X,4X is adult teeth, convert to uppercase
      // 5X,6X,7X,8X is child teeth, convert to lowercase
      if (key[0] >= '5') value = value.toLowerCase();
      else value = value.toUpperCase();
      des[key] = value;
      return des;
    }, {} as Record<string, string>);
}

export async function insertPhiSundry(
  students: PhiSundry[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  await put(`/api/phi/sundry/batch`, { students, checkField }, yearSem);
}

export async function insertPhiLab(
  labData: PhiLab[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  await put(`/api/phi/lab/batch`, { students: labData, checkField }, yearSem);
}

export async function insertPhiPhysical(
  physicalData: PhiPhysical[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  const tSheetApiData = physicalData.map((x) => {
    return {
      ...x,
      tSheet: x.tSheet != null ? deserializeTeeSheet(x.tSheet) : x.tSheet,
      retSheet:
        x.retSheet != null ? deserializeTeeSheet(x.retSheet) : x.retSheet,
    };
  });
  await put(
    `/api/phi/physical/batch`,
    { students: tSheetApiData, checkField },
    yearSem
  );
}

export async function insertPhiBlood(
  students: PhiBlood[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  await put(`/api/phi/blood/batch`, { checkField, students }, yearSem);
}

export async function insertPhiXRay(
  students: PhiXRay[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  await put(`/api/phi/xray/batch`, { checkField, students }, yearSem);
}

export async function insertPhiWHSight(
  students: PhiWHSight[],
  checkField: CheckField,
  yearSem: YearSem
): Promise<void> {
  await put(`/api/phi/whSight/batch`, { checkField, students }, yearSem);
}
