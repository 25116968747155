import { put } from './BaseApi';

export async function insertStudentInheritDisease(
  year: number,
  sem: number,
  sid: string,
  seat: number,
  no: number,
  pid: string,
  inheritDisease: number,
  relative: number,
  states: string,
): Promise<void> {
  await put(`/api/inheritdisease/student/insert`, {
    year,
    sem,
    sid,
    seat,
    no,
    pid,
    inheritDisease,
    relative,
    states,
  })
}

export async function deleteStudentDeleteInheritDisease(
  id: number,
  inheritDisease: number,
  relative: number,
  states: string,
  studentId: string,
): Promise<void> {
  await put(`/api/inheritdisease/student/delete`, {
    id,
    inheritDisease,
    relative,
    states,
    studentId,
  })
}