import { DateTime } from 'luxon';
import { Reducer } from 'redux';
import { AppThunkAction } from '..';

import Apis from '../../apis';
import {
  LoginResponse,
  MenuItem,
  SchoolRank,
  Semester,
  SimpleSchool,
} from '../../model';
import { SemesterUtils } from '../../utils/semester';
import { listToObject } from '../../utils/transform';

type UserState = {
  name: string;
  currentSchool: string;
  currentRank: SchoolRank;
  schools: { [k: string]: SimpleSchool };
  menus: MenuItem[];
  semesters: { [k: string]: Semester };
  currentSemester: string;
  currentDate: string;
};

export const NullUserState: UserState = Object.freeze({
  name: 'Unknown',
  currentSchool: 'Unknown',
  currentRank: SchoolRank.Primary,
  schools: {},
  menus: [],
  semesters: {},
  currentSemester: 'Unknown',
  currentDate: '',
});

export interface AuthState {
  loading: boolean;
  isAuth?: boolean;
  user: UserState;
}

export enum UserActionType {
  SignIn = 'UserSignIn',
  SignOut = 'UserSignOut',
  KickOut = 'UserKickOut',
  Loading = 'Loading',
}

export interface UserSignIn {
  type: UserActionType.SignIn;
  user: UserState;
}
export interface UserSignOut {
  type:
    | UserActionType.SignOut
    | UserActionType.Loading
    | UserActionType.KickOut;
}

export type AuthActions = UserSignIn | UserSignOut;

function wrapGroupMEnuItem(menuItems: MenuItem[]): MenuItem[] {
  return [
    {
      icon: 'icon-navigation',
      id: 'root',
      type: 'group',
      title: '目錄',
      children: menuItems,
    },
  ];
}

const transfromUserResponse = (userResponse: LoginResponse): UserState => {
  const {
    user,
    schools,
    menus,
    currentSchool,
    currentRank,
    semesters,
    currentDate,
  } = userResponse;
  const currentSemester = SemesterUtils.stringify(
    semesters.find((s) => s.isNow)
  );
  return {
    name: user.name,
    currentSchool,
    currentRank,
    schools: listToObject(
      schools,
      (s) => s.id,
      (s) => s
    ),
    menus: wrapGroupMEnuItem(menus),
    semesters: listToObject(
      semesters,
      (s) => SemesterUtils.stringify(s),
      (s) => s
    ),
    currentSemester,
    currentDate: DateTime.fromISO(currentDate).toFormat('yyyy/MM/dd HH:mm:ss'),
  };
};

const refreshToken =
  (id?: string): AppThunkAction<AuthActions> =>
  async (dispatch, getState) => {
    dispatch({ type: UserActionType.Loading });
    try {
      const token = Apis.loadTokenfromStorage();
      if (token) {
        const response = await Apis.refreshToken(id);
        const { token } = response;
        Apis.updateCacheToken(token);
        return dispatch({
          type: UserActionType.SignIn,
          user: transfromUserResponse(response),
        });
      }
      return dispatch({ type: UserActionType.SignOut });
    } catch (e) {
      dispatch({ type: UserActionType.SignOut });
      throw e;
    }
  };

export const AuthDispatches = Object.freeze({
  signIn:
    (
      account: string,
      password: string
    ): AppThunkAction<UserSignIn | UserSignOut> =>
    async (dispatch, getState) => {
      dispatch({ type: UserActionType.Loading });
      try {
        const response = await Apis.login(account, password);
        const { token } = response;
        Apis.updateCacheToken(token);
        dispatch({
          type: UserActionType.SignIn,
          user: transfromUserResponse(response),
        });
      } catch (e) {
        dispatch({ type: UserActionType.SignOut });
        throw e;
      }
    },
  signOut: (): AppThunkAction<UserSignOut> => async (dispatch, getState) => {
    dispatch({ type: UserActionType.Loading });
    Apis.updateCacheToken('');
    dispatch({ type: UserActionType.SignOut });
  },
  checkAuth: refreshToken,
  changeShcool: refreshToken,
});

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const authReducer: Reducer<AuthState, AuthActions> = (
  state: AuthState | undefined,
  incomingAction: AuthActions
): AuthState => {
  if (state === undefined) {
    return { loading: false, user: NullUserState };
  }
  const action = incomingAction;
  switch (action.type) {
    case UserActionType.Loading:
      return {
        ...state,
        loading: true,
      };
    case UserActionType.SignIn:
      if (!action.user) {
        return { user: NullUserState, loading: false, isAuth: false };
      }
      return {
        loading: false,
        isAuth: true,
        user: action.user,
      };
    case UserActionType.SignOut:
    case UserActionType.KickOut:
      return { user: NullUserState, loading: false, isAuth: false };
    default:
      return state;
  }
};
