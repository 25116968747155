import React, {
  FunctionComponent,
  useState,
  ReactNode,
  useEffect,
  ChangeEvent,
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  TextField,
  OverflowWithTooltip,
} from '../../components';
import {
  StudentHospital,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { InputDropdownGroup } from '../../components';
import { toast } from 'react-toastify';

type Props = {
  editable: boolean;
  deleteable: boolean;
  hospitals: StudentHospital[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};
const transformTextInput = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? <TextField property={property} /> : wrapOverflow(v as string));

const inlineDiseaseTableHeader: HeaderDisplayKeysWithType<StudentHospital>[] = [
  {
    property: 'hospitalName',
    display: '醫院名稱',
    onRender: transformTextInput.bind(null, 'hospitalName'),
  },
];

function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}
export const HospitalSection: FunctionComponent<Props> = ({
  editable,
  deleteable,
  hospitals,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const [hospitalItems, setHospitalItems] = useState<object>();
  const [hospitalDropDownItems, setdiseaseDropDownItems] =
    useState<Record<number, string>>();
  const [hospitalDropDown, setHospitalDropDown] = useState<number>();
  const [isDiseaseValid, setIsHospitalValid] = useState<boolean>(false);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);

  useEffect(() => {
    if(inputing) {
      apis
        .getHospitalList()
        .then((results) => {
          if (results.length) {
            const dropDown = results.reduce((obj, item) => {
              obj[item.id] = item.name;
              return obj;
            }, {} as Record<number, string>);
            setdiseaseDropDownItems(dropDown);
            setHospitalItems(results);
          }
        })
        .catch(onApiError);
    }
  }, [inputing]);

  useEffect(() => {
    if (hospitalDropDown) {
      setInsertBtn(false);
    } else {
      setInsertBtn(true);
    }
  }, [hospitalDropDown]);

  useEffect(() => {
    // 檢查護送醫院Id
    setIsHospitalValid(false);
    if (hospitalDropDown) {
      if (hospitalItems) {
        Object.values(hospitalItems).forEach((item, index) => {
          if (item.id == hospitalDropDown) {
            setIsHospitalValid(true);
          }
        });
      }
    }
  }, [hospitalDropDown]);

  function onValueUpdate(value: StudentHospital) {
    apis
      .updateStudentHospital(value?.hospitalId, value?.studentId)
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function onValueDelete(value: StudentHospital) {
    apis
      .deleteStudentHospital(value?.hospitalId, value?.studentId)
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function insertHospitalCheck() {
    // 檢查hospitalId
    if (isDiseaseValid) {
      insertStudentHospital();
    } else {
      toast.error('無該醫院!請至護送醫院設定', {});
    }
  }

  function insertStudentHospital() {
    if (hospitalDropDown) {
      toast
        .promise(apis.createStudentHospital(hospitalDropDown, student.id), {
          pending: '資料新增中......',
          success: '新增成功！',
        })
        .then(onValueUpdated)
        .catch(onApiError);
    }
  }

  return (
    <>
      護送醫院
      <Button
        key="cell-edit"
        className="border-0 px-2 py-1"
        variant="outline-primary"
        size="sm"
        onClick={() => {
          setInputing(true);
        }}
      >
        <span className="feather icon-edit" />
      </Button>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            護送醫院 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <InlineEditableTable
            values={hospitals}
            headers={inlineDiseaseTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable: false,
              deleteable: deleteable,
            })}
          />
          {hospitals.length ? (
            ''
          ) : (
            <div className="d-flex align-items-center">
              護送醫院：
              <InputDropdownGroup
                className={'p-1 text-center mr-2'}
                name="hospitalId"
                type="number"
                options={hospitalDropDownItems || {}}
                defaultShow="請選擇"
                value={hospitalDropDown || ''}
                onChange={(
                  e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                  >
                ) => {
                  setHospitalDropDown(parseInt(e.target.value));
                }}
                onBlur={() => {}}
                onFocus={() => {}}
              />
              <Button disabled={insertBtn} onClick={insertHospitalCheck}>
                新增
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
