import { z } from 'zod';

export const ZipCodeZod = z.object({
  zip: z.number().int(),
  county: z.string(),
  town: z.string(),
  telExt: z.string(),
  countyId: z.number().int(),
});

export type ZipCode = z.infer<typeof ZipCodeZod>;
