import { z } from 'zod';
import { StudentZod } from './Student';

export enum TransferTypeEnum {
  None = 0,
  Transfer = 1,
  Break = 2,
  Drop = 3,
  Return = 4,
  Delete = 5,
  Other = 99,
}

export const TransferTypeEnumZod = z.nativeEnum(TransferTypeEnum);

export const TransferInfoZod = z.object({
  transferId: z.number(),
  pid: z.string(),
  name: z.string(),
  schoolName: z.string(),
  schoolId: z.string(),
  student: StudentZod.nullish(),
});

export const BeforeTransferClassZod = z.object({
  classGrade: z.number(),
  classNo: z.number(),
  seat: z.number(),
  year: z.number(),
  sid: z.string(),
});

export const TransferListDataZod = z.object({
  transferId: z.number(),
  student: StudentZod,
  yearClass: BeforeTransferClassZod.nullish(),
  transferType: TransferTypeEnumZod,
});

export type TransferInfo = z.infer<typeof TransferInfoZod>;
export type TransferListData = z.infer<typeof TransferListDataZod>;
