import React, {
  EventHandler,
  FunctionComponent,
  ReactElement,
  useContext,
} from 'react';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';

type RenderProps = {
  isOpen: boolean;
  trigger: EventHandler<React.SyntheticEvent>;
};

type Props = {
  eventKey: string;
  render: (props: RenderProps) => ReactElement;
};

export const CustomAccordionToggle: FunctionComponent<Props> = ({
  eventKey,
  render,
}) => {
  const trigger = useAccordionToggle(eventKey);
  const isOpen = useContext(AccordionContext) === eventKey;
  const children = render({ isOpen, trigger });
  return children;
};
