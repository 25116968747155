import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { StudentCountStatistic, TeeGradeClassStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';


const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const otherStCount: FunctionComponent<Props> = ({ user, catchErrorForModal }) => {
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    const [datas, setDatas] = useState<StudentCountStatistic[]>([]);

    useEffect(() => {
        if (year && sem) {
            apis.getStudentCount(year, sem).then((s) => {
                setDatas(s);
            });
        }
    }, [year, sem]);

    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, StudentCountStatistic[]>);

    let schooltotalcount = 0;
    let schoolmalecount = 0;
    let schoolfemalelcount = 0;

    console.log(gradeBase)
    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const classCount = {
                grade: 0,
                classNo: 0,
                totalCount: 0,
                femaleCount: 0,
                maleCount: 0
            };
            const rows = gs.flatMap((g, idx) => {
                return StCountToRows(
                    g.grade,
                    g.classNo ?? 0,
                    g.totalCount ?? 0,
                    g.femaleCount ?? 0,
                    g.maleCount ?? 0,
                    idx === 0 ? { row: (gs.length + 1) } : undefined
                );
            });
            gs.forEach(count => {
                classCount.totalCount += count.totalCount ?? 0;
                classCount.femaleCount += count.femaleCount ?? 0;
                classCount.maleCount += count.maleCount ?? 0;
                schooltotalcount += count.totalCount ?? 0;
                schoolfemalelcount += count.femaleCount ?? 0;
                schoolmalecount += count.maleCount ?? 0
            });
            rows.push(...StCountToRows('', '小計', classCount.totalCount, classCount.femaleCount, classCount.maleCount));
            if (idx === allArray.length - 1) {
                const lastRow = rows[rows.length - 1];

                lastRow.cells = lastRow?.cells.map((c) => {
                    const { style: { border = {} } = {} } = c;
                    border.bottom = borderStyle;
                    c.style = { ...c.style, border };
                    return c;
                });
            }

            return rows;
        }
    );
    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級/班級',
                    merge: { column: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '小計',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '男',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '女',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                }
            ],
        },
        ...contentRows,
        ...StCountToRows('全校合計', '', schooltotalcount, schoolfemalelcount, schoolmalecount, { column: 2, row: 1 }),
    ];

    // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <Button
                    disabled={!datas.length}
                    onClick={() => {
                        const workSheet = generateSheet(
                            [
                                ...content,
                                {
                                    cells: [
                                        {
                                            value:
                                                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets('班級人數統計', { sheet: workSheet, name: 'st' });
                    }}
                >
                    Excel 下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function StCountToRows(
    grade: number | string,
    classNo: number | string,
    totalCount: number,
    femaleCount: number,
    maleCount: number,
    gradeMerge?: { row?: number; column?: number }
): Row[] {
    const gradeNo = [
        {
            value: gradeMerge ? grade : undefined,
            merge: gradeMerge,
            style: { border: fullBorderStyle },
        },
    ];
    if (!gradeMerge?.column) {
        gradeNo.push({
            value: classNo,
            merge: { row: 1 },
            style: { border: fullBorderStyle },
        });
    }
    return [
        {
            cells: [
                ...gradeNo,
                {
                    value: totalCount ?? 0,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: maleCount ?? 0,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: femaleCount ?? 0,
                    style: { border: { right: borderStyle } },
                },
            ],
        },

    ];
}



export const OtherStCount = connector(otherStCount);
