import React, { FunctionComponent } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import Background404 from '../assets/images/maintenance/404.png';
import { AuthedLayout } from '../components';
import { ApplicationState } from '../redux/States';

const mapState = (state: ApplicationState) => state.auth;
const connector = connect(mapState);
type Props = ConnectedProps<typeof connector>;

const notFound: FunctionComponent<Props> = ({ isAuth }) => {
  const content = (
    <div className="auth-wrapper maintenance">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center px-4">
            <div>
              <img
                style={{ maxHeight: '300px', maxWidth: '100%' }}
                src={Background404}
                alt="404 - Page Not Found"
              />
            </div>
            <h5 className="mt-4">Oops! Page not found!</h5>
            <Form>
              <Link to="/">
                <Button variant="danger">
                  <i className="feather icon-refresh-ccw mr-2" />
                  HomePage
                </Button>
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
  return isAuth ? <AuthedLayout>{content}</AuthedLayout> : content;
};

export const NotFound = connector(notFound);
