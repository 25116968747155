import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  RefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
type Props = {
  children: ReactNode;
  className?: string;
  forceToolTip?: boolean;
};
export const OverflowWithTooltip: FunctionComponent<Props> = ({
  className = '',
  children,
  forceToolTip,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (
      (ref.current && ref.current?.clientWidth < ref.current?.scrollWidth) ||
      forceToolTip
    ) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  });

  let content: ReactElement = (
    <div ref={ref} className={`overflow-hidden text-truncate ${className}`}>
      {children}
    </div>
  );
  if (isOverflow) {
    content = (
      <React.Fragment>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          overlay={<Tooltip id="overflow-tooltip">{children}</Tooltip>}
        >
          {content}
        </OverlayTrigger>
      </React.Fragment>
    );
  }

  return content;
};
