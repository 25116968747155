import React, { Fragment, FunctionComponent, useEffect, useState, useRef, ReactNode } from 'react';
import { ApplicationState } from '../redux/States';
import { ErrorDispatches } from '../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { SheetHeaderDisplay} from '../utils';
import { Button, FormControl, Col, Row as BSRow } from 'react-bootstrap';
import { 
    AuthedLayout, 
    HeaderDisplayKeysWithType,
    InlineEditableTable,
    OverflowWithTooltip,
    TextField,
 } from '../components';
import { Hospital } from '../model';
import apis from '../apis';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const hospitalPage: FunctionComponent<Props> = ({ 
  user,
  loading,
  catchErrorForModal 
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  type MetricPageData = Hospital;
  const currentSchool = user.currentSchool;
  const [insertDisabled, setInsertDisabled] = useState<boolean>(true);
  const hospitalInputRef = useRef<HTMLInputElement>(null);
  const [hospitalName, setHospitalName] = useState<string>('');
  const [hospitals, setHospitals] = useState([] as MetricPageData[]);
  const transformTextInput = (
    property: string,
    v: unknown,
    i: number,
    e?: boolean
  ) => (e ? <TextField property={property} /> : wrapOverflow(v as string));
  function wrapOverflow(content: ReactNode) {
    return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
  }
  const displayHeader: (SheetHeaderDisplay<MetricPageData> & 
    HeaderDisplayKeysWithType<MetricPageData>)[] = [
    { 
      property: 'name', 
      display: '醫院名稱',
      onRender: transformTextInput.bind(null, 'name')
    }
  ];
 
  useEffect(() => {
    getList();
  }, [user]);


  function insertHospital() {
    toast
    .promise(
      apis.createHospital(
        currentSchool,
        hospitalName
      ),
      {
        pending: '資料新增中......',
        success: '新增成功！',
      }
    )
    .then((r) => {
      setHospitalName("");
      setInsertDisabled(true);
      getList();
    })
    .catch(catchErrorForModal);
  }

  function onValueUpdate(value: MetricPageData) {
    if(value) {
      apis
        .updateHospital(
          value?.id,
          value?.name,
          value?.schoolId
        )
        .then(() => {
          getList();
        })
        .catch(catchErrorForModal);
    }
  }

  function getList() {
    apis.getHospitalList()
    .then((s) => {
      if(s.length) {
        setHospitals(s);
      }
    })
    .catch(catchErrorForModal);
  }

  function onValueDelete(value: MetricPageData) {
    if(value) {
      apis
        .deleteHospital(
          value?.id,
          value?.name,
          value?.schoolId
        )
        .then(() => {
          getList();
        })
        .catch(catchErrorForModal);
    }
  }

  return (
    <AuthedLayout>
      <BSRow className="mb-4">
        醫院：
        <Col sm={3} className='mr-2'>
            <FormControl 
              type="string"
              aria-label="studentInput"
              aria-describedby="basic-addon1"
              value={hospitalName}
              ref={hospitalInputRef}
              onChange={(e) => {
                setHospitalName(e.target.value)
                if(e.target.value != null) {
                  setInsertDisabled(false);
                }
              }}
            />
        </Col>
        <Col sm={7}>
          <Button
            disabled={insertDisabled}
            onClick={insertHospital}
          >
          新增護送醫院
          </Button>
        </Col>
      </BSRow>
      <BSRow>
        <InlineEditableTable
          values={hospitals}
          headers={displayHeader}
          onValueUpdate={onValueUpdate}
          onValueDelete={onValueDelete}
          onRowRender={(v) => ({
            editable: true,
            deleteable: true
          })}
        />
      </BSRow>
    </AuthedLayout>
  );
};

export const HospitalPage = connector(hospitalPage);
