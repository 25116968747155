import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { ApplicationState } from '../../redux/States';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTable,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../components';
import { PhiBlood, PhiBloodFile, PhiBloodFileZod } from '../../model/PhiBlood';
import { CheckField } from '../../model';
import { Button, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import {
  CheckShouldUseIdentify,
  assignExists,
  checkOkToUpload,
  takeClassStudent,
} from '../../utils';
import { z } from 'zod';
import apis from '../../apis';
import { toast } from 'react-toastify';
import { useActiveSems } from '../../hook';

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = { ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const inputFileParser = z.array(PhiBloodFileZod);
const tableHeader: HeaderDisplayKeysWithType<PhiBloodFile>[] = [
  { property: 'remark', display: '備註' },
  { display: '身份證字號', property: 'pid' },
  { display: '學號', property: 'sid' },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { property: 'hb', display: '血色素' },
  { property: 'hbResult', display: '血色素判讀' },
  { property: 'wbc', display: '白血球' },
  { property: 'wbcResult', display: '白血球判讀' },
  { property: 'rbc', display: '紅血球' },
  { property: 'rbcResult', display: '紅血球判讀' },
  { property: 'pl', display: '血小板' },
  { property: 'plResult', display: '血小板判讀' },
  { property: 'mcv', display: '平均血球容積' },
  { property: 'mcvResult', display: '平均血球容積判讀' },
  { property: 'cl', display: '總膽固醇' },
  { property: 'clResult', display: '總膽固醇判讀' },
  { property: 'cr', display: '肌酸酐' },
  { property: 'crResult', display: '肌酸酐判讀' },
  { property: 'ua', display: '尿酸' },
  { property: 'uaResult', display: '尿酸判讀' },
  { property: 'got', display: 'SGOT' },
  { property: 'gotResult', display: 'SGOT判讀' },
  { property: 'gpt', display: 'SGPT' },
  { property: 'gptResult', display: 'SGPT判讀' },
  { property: 'hbsa', display: 'B型肝炎表面抗原' },
  { property: 'hbsaResult', display: 'B型肝炎表面抗原判讀' },
  { property: 'antiHB', display: 'B型肝炎表面抗體' },
  { property: 'antiHBResult', display: 'B型肝炎表面抗體判讀' },
  { property: 'tg', display: '三酸甘油酯' },
  { property: 'tgResult', display: '三酸甘油酯判讀' },
  { property: 'hdl', display: '高密度膽固醇' },
  { property: 'hdlResult', display: '高密度膽固醇判讀' },
  { property: 'ldl', display: '低密度膽固醇' },
  { property: 'ldlResult', display: '低密度膽固醇判讀' },
  { property: 'pc', display: '空腹血糖' },
  { property: 'pcResult', display: '空腹血糖判讀' },
  { property: 'ac', display: '飯後血糖' },
  { property: 'acResult', display: '飯後血糖判讀' },
  { property: 'hBeAg', display: 'B型肝炎e抗原' },
  { property: 'hBeAgResult', display: 'B型肝炎e抗原判讀' },
  { property: 'bun', display: '血尿素氮' },
  { property: 'bunResult', display: '血尿素氮判讀' },
  { property: 'ht', display: '血球容積比' },
  { property: 'htResult', display: '血球容積比判讀' },
  { property: 'bloodCheckMemo', display: '備註' },
];
function filterOutNonPhiBloodField({
  remark,
  okUpload,
  nouse,
  ...phiblood
}: PhiBloodFile): PhiBlood {
  return { ...phiblood };
}

const importPhiBlood: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案');
  const [fileData, setFileData] = useState([] as PhiBloodFile[]);
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { selectedSemester, yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );
  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        下載匯入格式：
        <a href="/Doc/血液檢查17.xls" style={{ textDecoration: 'underline' }}>
          血液檢查匯入格式
        </a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const ss = (
                    await inputFileParser.parseAsync(
                      jsonData.map((j: any) => ({ ...j, ...selectedSemester }))
                    )
                  ).filter((s) => !s.nouse);
                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級做號"'
                      );
                      return;
                    }
                    await apis
                      .checkClassesStudentExists(
                        takeClassStudent(ss),
                        cf,
                        selectedSemester
                      )
                      .then((result) => {
                        // console.log('importPhiWH.ts #104',result)
                        // setFileData (merge result&ss)
                        setFileData(assignExists(matchFunc, ss, result));
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, ss));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            } finally {
              e.target.value = '';
            }
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0 && checkField != CheckField.None)
              toast
                .promise(
                  apis.insertPhiBlood(
                    fileData.map(filterOutNonPhiBloodField),
                    checkField,
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      <Row>
        <EditableTable
          headers={tableHeader}
          values={fileData}
          scrollable={true}
          onRowRender={({ remark }) => ({
            rowClassName: remark ? 'bg-danger text-white' : '',
          })}
        />
      </Row>
    </AuthedLayout>
  );
};

export const ImportPhiBloodPage = connector(importPhiBlood);
