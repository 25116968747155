import { z } from 'zod';

export const SightUntestZod = z.object({
  pid: z.string(),
  name: z.string(),
  sex: z.string(),
  grade: z.number(),
  no: z.number(),
  seat: z.number(),
  sightL: z.number().nullish(),
  sightR: z.number().nullish(),
  sight0L: z.number().nullish(),
  sight0R: z.number().nullish(),
});

export type SightUntest = z.infer<typeof SightUntestZod>;
