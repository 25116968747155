import React, { CSSProperties, ReactElement } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  EditableProperties,
  HeaderDisplayKeysWithType,
  OnRowRender,
  RowRenderResult,
} from './EditableTable';

type TValue = {
  [k: string]: unknown;
} & EditableProperties;

type SelectOn = {
  index?: number;
  checked: boolean;
};

type Props<T extends TValue> = {
  editable?: boolean;
  deleteable?: boolean;
  restorable?: boolean;
  scrollable?: boolean;
  headers: HeaderDisplayKeysWithType<T, keyof T>[];
  values: T[];
  fixCol?: number;
  onEdit?: (value: T) => void;
  onDelete?: (value: T) => void;
  onRestore?: (value: T) => void;
  onRowRender?: OnRowRender<T>;
  onSelected?: (selectOn: SelectOn) => void;
  context?: Record<string, unknown>;
};
const tableStyle: CSSProperties = {
  display: 'block',
  overflowX: 'auto',
  whiteSpace: 'nowrap'
}
const editableTableDiv = <T extends TValue>({
  headers,
  values,
  editable,
  deleteable,
  restorable,
  scrollable,
  fixCol,
  onEdit,
  onDelete,
  onRestore,
  onRowRender,
  onSelected,
  context = {},
}: Props<T>) => {
  const headerElements = headers.map((h, i) => {
    const className = ['div-table-cell'];
    if (fixCol && i < fixCol) {
      className.push('div-sticky-left');
    }
    return (
      <div className={className.join(' ')} style={h.style} key={`header-${i}`}>
        {h.display}
      </div>
    );
  });

  if (onSelected) {
    headerElements.unshift(
      <div
        className="div-table-cell"
        key={`header-${headerElements.length}-select`}
        style={{ width: '2rem' }}
      >
        <Form.Check
          type="checkbox"
          checked={values.every((v) => v.selected)}
          onChange={(e) => {
            const checked = e.target.checked;
            onSelected({ checked });
          }}
        />
      </div>
    );
  }

  if (editable || deleteable || restorable) {
    headerElements.push(
      <div
        className="div-table-cell"
        style={{ width: (+!!editable + +!!deleteable + +!!restorable) * 2.2 + 'rem' }}
        key={`header-${headerElements.length}-edit`}
      ></div>
    );
  }

  const bodyElements = values.map((v, i) => {
    const row = headers.reduce((elements, h, colIdx) => {
      const value = h.onRender
        ? h.onRender(v[h.property], i, false, { context, allValues: v })
        : v[h.property];
      const className = ['div-table-cell'];
      if (fixCol && colIdx < fixCol) {
        className.push('div-sticky-left');
      }
      elements.push(
        <div
          className={className.join(' ')}
          style={h.style}
          key={`body-${i}-${Math.random().toString(16).slice(-6)}`}
        >
          {value as ReactElement}
        </div>
      );
      return elements;
    }, [] as ReactElement[]);
    const modifyCell: ReactElement[] = [];
    if (editable) {
      modifyCell.push(
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1 m-0 mr-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            if (onEdit && editable) {
              onEdit(v);
            }
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      );
    }
    if (deleteable) {
      modifyCell.push(
        <Button
          key="cell-delete"
          className="border-0 px-2 py-1 m-0"
          variant="outline-danger"
          size="sm"
          onClick={() => {
            if (onDelete) {
              // if (onDelete && editable) {
              onDelete(v);
            }
          }}
        >
          <span className="feather icon-trash-2" />
        </Button>
      );
    }
    if (restorable) {
      modifyCell.push(
        <Button
          key="cell-restore"
          className="border-0 px-2 py-1 m-0"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            if (onRestore) {
              onRestore(v);
            }
          }}
        >
          <span className="feather icon-refresh-cw" />
        </Button>
      );
    }

    if (onSelected) {
      row.unshift(
        <div className="div-table-cell" key={`body-${row.length}-select`}>
          <Form.Check
            type="checkbox"
            checked={!!v.selected}
            onChange={() => {
              onSelected({ index: i, checked: !v.selected });
            }}
          />
        </div>
      );
    }
    if (editable || deleteable || restorable) {
      row.push(
        <div className="div-table-cell" key={`body-${row.length}-edit`}>
          {modifyCell}
        </div>
      );
    }

    const { rowStyle, rowClassName } = onRowRender
      ? onRowRender(v)
      : ({} as RowRenderResult);
    return (
      <div
        style={rowStyle}
        className={['div-table-row', rowClassName].filter((s) => s).join(' ')}
        key={`body-${i}`}
      >
        {row}
      </div>
    );
  });

  return (
    <div className="div-table-responsive">
      <div className="table table-bordered table-striped table-hover div-table text-center" style={scrollable ? tableStyle : {}}>
        <div className="div-table-row div-sticky-top div-table-header">
          {headerElements}
        </div>
        {bodyElements}
      </div>
      {bodyElements.length === 0 ? (
        <div className="p-2 text-center border">目前資料表中沒有資料</div>
      ) : null}
    </div>
  );
};

export const EditableTableDiv = editableTableDiv;
