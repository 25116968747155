import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AuthedLayout, EnchanceLink } from '../../components';
import { MenuItem } from '../../model';
import apis from '../../apis';
import { ApplicationState } from '../../redux/States';
import { ConnectedProps, connect } from 'react-redux';

const mapState = (app: ApplicationState) => ({ ...app.auth });

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const noticePage: FunctionComponent<Props> = ({ user: { menus } = {} }) => {
  const searchNotice = (menus: MenuItem[]): MenuItem[] => {
    return menus.reduce((seed, v) => {
      if (v.type === 'notice') return [...seed, v];
      const r = searchNotice(v.children || []);
      if (r.length) return [...seed, ...r];
      return seed;
    }, [] as MenuItem[]);
  };

  const renderMenuItem = (item: MenuItem) => {
    if (item.type !== 'notice') return;
    if (item.children?.length ?? 0 > 0) {
      return (
        <li key={item.id}>
          <div className="sheet-title">{item.title}</div>
          <ul style={{ paddingLeft: '10px' }}>
            {item.children?.flatMap((child) => renderMenuItem(child))}
          </ul>
        </li>
      );
    } else {
      return (
        <li key={item.id}>
          <EnchanceLink to={item.url ?? ''}>{item.title}</EnchanceLink>
        </li>
      );
    }
  };

  const menurow = useMemo(() => {
    return searchNotice(menus || []).flatMap((gs, idx, allArray) => {
      return renderMenuItem(gs);
    });
  }, [menus]);

  return (
    <AuthedLayout>
      <Row className="justify-content-between align-items-start">
        <Col lg="3" className="mr-2 mb-2">
          <div>
            <ul>{menurow[0]}</ul>
          </div>
        </Col>
        <Col lg="3" className="mr-2 mb-2">
          <div>
            <ul>{menurow[1]}</ul>
          </div>
        </Col>
        <Col lg="3" className="mr-2 mb-2">
          <div>
            <ul>{menurow[2]}</ul>
          </div>
        </Col>
        <Col lg="2" className="mr-2 mb-2">
          <div>
            <ul>{menurow[3]}</ul>
            <ul>{menurow[4]}</ul>
            <ul>{menurow[5]}</ul>
            <ul>{menurow[6]}</ul>
          </div>
        </Col>
      </Row>
    </AuthedLayout>
  );
};

export const NoticePage = connector(noticePage);
