import { z } from 'zod';
import { 
  SeriousDiseaseKindZod,
  SeriousDiseaseZod,
  StudentGradeNoSeatZod,
} from '../model';
import { get, post, put } from './BaseApi';

const SeriousDiseaseKindItem = z.array(SeriousDiseaseKindZod);
const SeriousDiseaseListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      seriousDisease: SeriousDiseaseZod,
    })
  )
);

type SeriousDiseaseKinds = z.infer<typeof SeriousDiseaseKindItem>;
type SeriousDiseaseList = z.infer<typeof SeriousDiseaseListZod>;

export async function insertSeriousDiseases(
  year: number,
  sem: number,
  sid: string,
  seat: number,
  no: number,
  grade: number,
  pid: string,
  diseaseId: number,
  checkField: number,
): Promise<void> {
  await put(`/api/seriousdisease/insert`, {
    year,
    sem,
    sid,
    seat,
    no,
    grade,
    pid,
    diseaseId,
    checkField
  })
}

export async function deleteSeriousDiseases(
  id: number,
  studentId: string,
  diseaseId: number,
): Promise<void> {
  await put(`/api/seriousdisease/student/delete`, {
    id,
    studentId,
    diseaseId,
  })
}

export async function getSeriousDiseaseKindsDropDown(
): Promise<SeriousDiseaseKinds> {
  return await get(`/api/seriousdisease/kind`, SeriousDiseaseKindItem, {})
}

export async function getSeriousDiseaseAll(
  year: number,
  sem: number,
): Promise<SeriousDiseaseList> {
  return await get(`/api/seriousdisease/student/all`, SeriousDiseaseListZod, {
    year,
    sem
  })
}

export async function getSeriousDiseaseInSchoolList(
  year: number,
  sem: number,
): Promise<SeriousDiseaseList> {
  return await get(`/api/seriousdisease/student/inschool`, SeriousDiseaseListZod, {
    year,
    sem
  })
}
