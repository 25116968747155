import React, { FunctionComponent } from 'react';
import { withNProgress } from '@tanem/react-nprogress';

import { Bar } from './Bar';
import { Container } from './Container';
import { Spinner } from './Spinner';

type Props = { isFinished?: any; progress?: any; animationDuration?: any };

const progress: FunctionComponent<Props> = ({
  isFinished,
  progress,
  animationDuration,
}) => {
  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
      <Spinner />
    </Container>
  );
};

export const Progress = withNProgress(progress);
