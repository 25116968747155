import React, { ReactElement, FunctionComponent } from 'react';
import { ListGroup } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MenuItem } from '../../model';
import { SideMenuGroup } from './SideMenuGroup';

type Props = { navigation: MenuItem[] };

export const SideMenuContent: FunctionComponent<Props> = ({ navigation }) => {
  const navItems = navigation.reduce((elements, item) => {
    switch (item.type) {
      case 'group':
        elements.push(
          <SideMenuGroup key={'nav-group-' + item.id} group={item} />
        );
        break;
    }
    return elements;
  }, [] as ReactElement[]);

  return (
    <React.Fragment>
      <div className="navbar-content datta-scroll">
        <PerfectScrollbar>
          <ListGroup
            variant="flush"
            as="ul"
            bsPrefix=" "
            className="nav pcoded-inner-navbar"
            id="nav-ps-next"
          >
            {navItems}
          </ListGroup>
        </PerfectScrollbar>
      </div>
    </React.Fragment>
  );
};
