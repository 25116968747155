import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  FocusEvent,
  ChangeEvent,
} from 'react';
import { FormCheck, FormControl, Overlay, Popover } from 'react-bootstrap';

type Props = {
  content: Record<string, string>;
  checked: string[];
  // onChange: (e: string[]) => void;
  onFocus: (e: FocusEvent<HTMLElement>) => void;
  onBlur: (e: FocusEvent<HTMLElement>) => void;
  onChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
};
export const DropdownCheckList: FunctionComponent<Props> = (
  { onChange, onBlur, onFocus, content, checked } //   ...props
) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fadeTimerRef = useRef<NodeJS.Timeout | null>(null);
  // const [inputDatas, setInputDatas] = useState(checked.join(','));

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     validInputData(inputDatas);
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, [inputDatas]);

  // useEffect(() => {
  //   if (checked.join(',') !== inputDatas) {
  //     setInputDatas(checked.join(','));
  //   }
  // }, [checked]);

  function deserialize(str: string): string[] {
    return str
      .split(',')
      .map((s) => s.trim())
      .filter((s) => s);
  }

  function serialize(data: string[]): string {
    return data.join(',');
  }

  function validInputData(str: string) {
    const deserialized = deserialize(str);
    const validData = deserialized.filter((s) => !!content[s]);
    const newData = serialize(validData);

    // if (inputDatas !== newData) {
    //   setInputDatas(newData);
    // }

    // onChange(validData);
  }

  function checkboxChange(key: string, c: boolean) {
    if (inputRef.current) {
      const checkedKeys = deserialize(
        inputRef.current.value.replaceAll('，', ',')
      );
      const keySet = new Set(checkedKeys);
      if (c) {
        keySet.add(key);
      } else if (!c) {
        keySet.delete(key);
      }
      const newDatas = [...keySet.values()];
      const serialized = serialize(newDatas);

      inputRef.current.value = serialized;
      const e = new Event('change', { bubbles: true });
      inputRef.current.dispatchEvent(e);
      onChange(e as any);
    }
    // if (serialized !== inputDatas) {
    //   setInputDatas(inputDatas);
    //   // onChange(newDatas);
    // }
  }

  function showOverlay() {
    if (fadeTimerRef.current) clearTimeout(fadeTimerRef.current);
    setShow(true);
  }

  function hideOverlay() {
    if (fadeTimerRef.current) clearTimeout(fadeTimerRef.current);
    fadeTimerRef.current = setTimeout(() => {
      setShow(false);
    }, 300);
  }

  const checkboxList = Object.entries(content).map(([k, v]) => (
    <FormCheck
      key={k}
      name={k}
      checked={checked?.some((s) => s === k)}
      label={`${k} - ${v}`}
      onChange={(e) => checkboxChange(k, e.target.checked)}
    />
  ));

  return (
    <React.Fragment>
      <div ref={containerRef}>
        <FormControl
          type="text"
          ref={inputRef}
          value={checked.join(',')}
          onChange={(e) => {
            // setInputDatas(e.target.value);
            onChange(e);
          }}
          onFocus={(e: any) => {
            showOverlay();
            onFocus(e);
          }}
          onBlur={(e: any) => {
            hideOverlay();
            onBlur(e);
          }}
        />
        <Overlay
          container={containerRef}
          target={containerRef.current}
          show={show}
          placement="bottom"
        >
          <Popover id={`input-dropdown-popover`}>
            <Popover.Content
              style={{ minWidth: '200px' }}
              onBlur={() => hideOverlay()}
              onFocus={() => showOverlay()}
            >
              {checkboxList}
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </React.Fragment>
  );
};
