import { DateTime } from 'luxon';
import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Button, FormCheck, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { connect, ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';
import apis from '../../apis';
import {
  EditableProperties,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../components';
import { ClassSem, WrapClassBaseLayout } from '../../layouts';
import { PHITee, SimpleStudent } from '../../model';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import { Nullable } from '../../types';
import { TeeTableHeader } from '../TableHeaders/TeeHeader';

type TeePageData = SimpleStudent & Nullable<PHITee> & EditableProperties;

const modalDisplayHeader: HeaderDisplayKeysWithType<TeePageData>[] = [
  { property: 'seat', display: '座號' },
  { property: 'name', display: '學生' },
  ...(TeeTableHeader as HeaderDisplayKeysWithType<TeePageData>[]),
];

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const whClassList: FunctionComponent<Props> = ({
  classId,
  className,
  year,
  sem,
  catchErrorForModal,
}) => {
  const [filterT08, setFilterT08] = useState(false);
  const [filterT05, setFilterT05] = useState(false);
  const [filterT07, setFilterT07] = useState(false);
  const [filterT04, setFilterT04] = useState(false);
  const [filterT16, setFilterT16] = useState(false);
  const [filterT17, setFilterT17] = useState(false);
  const [filterT03, setFilterT03] = useState(false);
  const [filterT18, setFilterT18] = useState(false);
  const [filterT02, setFilterT02] = useState(false);
  const [filterT19, setFilterT19] = useState(false);
  const [originalStudents, setOriginalStudents] = useState<TeePageData[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<Set<number>>(
    new Set<number>()
  );
  // const [students, setStudents] = useState<TeePageData[]>([]);
  const [deadline, setDeadline] = useState<Date>();

  useEffect(() => {
    refreshStudents();
  }, [classId, year, sem]);

  const students = useMemo(filterTeeIssue, [
    filterT08,
    filterT05,
    filterT07,
    filterT04,
    filterT16,
    filterT17,
    filterT03,
    filterT18,
    filterT02,
    filterT19,
    selectedIndex,
    originalStudents,
  ]);

  function refreshStudents() {
    if (classId && year && sem) {
      apis.getClassStudentTee(classId, year, sem).then((s) => {
        const students = s.map(({ tee, ...s }) => ({
          ...s,
          ...tee,
        }));
        setOriginalStudents(students);
        setSelectedIndex(new Set(students.map((_, i) => i)));
        // setStudents(students);
      });
    } else {
      setOriginalStudents([]);
      // setStudents([]);
    }
  }

  function filterTeeIssue() {
    let newStudents = originalStudents;
    if (filterT08)
      newStudents = newStudents.filter((s) => s.t08 && s.t08 !== 9);
    if (filterT05)
      newStudents = newStudents.filter((s) => s.t05 && s.t05 !== 9);
    if (filterT07)
      newStudents = newStudents.filter((s) => s.t07 && s.t07 !== 9);
    if (filterT04)
      newStudents = newStudents.filter((s) => s.t04 && s.t04 !== 9);
    if (filterT16)
      newStudents = newStudents.filter((s) => s.t16 && s.t16 !== 9);
    if (filterT17)
      newStudents = newStudents.filter((s) => s.t17 && s.t17 !== 9);
    if (filterT03)
      newStudents = newStudents.filter((s) => s.t03 && s.t03 !== 9);
    if (filterT18)
      newStudents = newStudents.filter((s) => s.t18 && s.t18 !== 9);
    if (filterT02)
      newStudents = newStudents.filter((s) => s.t02 && s.t02 !== 9);
    if (filterT19)
      newStudents = newStudents.filter((s) => s.t19 && s.t19 !== 9);
    return newStudents.map((s, i) => ({
      ...s,
      selected: selectedIndex.has(i),
    }));
  }

  if (!classId) return <></>;

  return (
    <>
      <Row className="mb-2">
        繳回期限：
        <div style={{ flex: '0 0 25%' }} className="mr-2">
          <ReactDatePicker
            className="form-control px-2 py-1"
            placeholderText="yyyy/MM/dd"
            dateFormat={'yyyy/MM/dd'}
            selected={deadline}
            onChange={(date) => {
              if (date instanceof Date) {
                setDeadline(date);
              } else {
                setDeadline(undefined);
              }
            }}
          />
        </div>
        <Button
          className="ml-auto"
          onClick={() => {
            let pids = students.filter((s) => s.selected).map((s) => s.pid);
            pids = pids.length == 0 ? students.map((s) => s.pid) : pids;
            toast
              .promise(
                apis.downloadTeePDF(
                  classId,
                  pids,
                  year,
                  sem,
                  deadline ? DateTime.fromJSDate(deadline) : undefined
                ),
                {
                  pending: '下載中...',
                  success: '下載成功！',
                  error: '下載失敗！請查看錯誤資訊。',
                }
              )
              .then((blob) => {
                const objlink = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objlink;
                a.setAttribute('download', `口腔檢查通知單-${className}.pdf`);
                document.body.appendChild(a);
                a.click();

                a.parentNode?.removeChild(a);
              })
              .catch(catchErrorForModal);
          }}
        >
          <span className="feather icon-download"></span>
        </Button>
      </Row>
      <Row className="mb-2">
        追加條件：
        <FormCheck
          className="mr-4"
          label="牙齦炎"
          checked={filterT08}
          onChange={() => setFilterT08(!filterT08)}
        />
        <FormCheck
          className="mr-4"
          label="牙結石"
          checked={filterT05}
          onChange={() => setFilterT05(!filterT05)}
        />
        <FormCheck
          className="mr-4"
          label="咬合不正"
          checked={filterT07}
          onChange={() => setFilterT07(!filterT07)}
        />
        <FormCheck
          className="mr-4"
          label="口腔衛生不良"
          checked={filterT04}
          onChange={() => setFilterT04(!filterT04)}
        />
        <FormCheck
          className="mr-4"
          label="牙周病"
          checked={filterT16}
          onChange={() => setFilterT16(!filterT16)}
        />
        <FormCheck
          className="mr-4"
          label="乳牙待拔牙"
          checked={filterT17}
          onChange={() => setFilterT17(!filterT17)}
        />
        <FormCheck
          className="mr-4"
          label="待拔牙"
          checked={filterT03}
          onChange={() => setFilterT03(!filterT03)}
        />
        <FormCheck
          className="mr-4"
          label="贅生牙"
          checked={filterT18}
          onChange={() => setFilterT18(!filterT18)}
        />
        <FormCheck
          className="mr-4"
          label="缺牙"
          checked={filterT02}
          onChange={() => setFilterT02(!filterT02)}
        />
        <FormCheck
          className="mr-4"
          label="阻生牙"
          checked={filterT19}
          onChange={() => setFilterT19(!filterT19)}
        />
      </Row>
      <Row>
        <EditableTableDiv
          headers={modalDisplayHeader}
          values={students}
          onSelected={(s) => {
            setSelectedIndex((last) => {
              if (s.index !== undefined) {
                if (s.checked) last.add(s.index);
                else last.delete(s.index);

                return new Set(last);
              } else {
                if (s.checked) return new Set(students.keys());
                else return new Set();
              }
            });
          }}
        />
      </Row>
    </>
  );
};

export const WHClassListPDFPage = connector(
  WrapClassBaseLayout(whClassList)
);
