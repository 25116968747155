import React, { ReactNode } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { Field } from 'react-final-form';

type Props<T extends 'text' | 'number'> = {
  type: T;
  className?: string;
  property: string;
  disabled?: boolean;
  validate?: (value: T extends 'text' ? string : number) => string | undefined;
  transform: (value: T extends 'text' ? string : number) => ReactNode;
  min: number;
  max: number;
};
export const InputTransformField = <T extends 'text' | 'number'>({
  type,
  className,
  property,
  disabled,
  validate,
  transform,
  min,
  max,
}: Props<T>) => {
  return (
    <Field name={property} type={type} validate={validate}>
      {({ input, meta }) => (
        <>
          <InputGroup className={className}>
            <FormControl
              className="w-50"
              autoComplete='off'
              disabled={disabled}
              {...input}
              min={min}
              max={max}
            />
            <InputGroup.Append className="w-50 form-control">
              {transform(input.value)}
            </InputGroup.Append>
          </InputGroup>
          {meta.error &&
            (meta.modified || meta.submitFailed) &&
            !meta.active && <span className="text-danger">{meta.error}</span>}
        </>
      )}
    </Field>
  );
};
