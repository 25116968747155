import { DOMParser } from 'xmldom';

const DOM = new DOMParser();

// export async function getBulletinBoard(
//   schoolId: string
// ): Promise<BulletinBoard[]> {
//   return [
//     {
//       type: '重要通告',
//       content: '111學年學生健康資料上傳作業之資料檢核注意事項',
//       publishAt: '2023-01-31',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=A9AC742E4D3BC5EA',
//     },
//     {
//       type: '重要通告',
//       content: '112年度「學校學生健康資訊系統」作業寒假研習活動，開始報名了。',
//       publishAt: '2022-12-21',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=93B32C89FA927423',
//     },
//     {
//       type: '重要通告',
//       content: '學生健康檢查「初檢異常但毋需複檢」項目調查',
//       publishAt: '2022-11-11',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=70290BCF6E96C51A',
//     },
//     {
//       type: '重要通告',
//       content: '學校學生健康資訊系統(SSHIS)共用設定說明',
//       publishAt: '2022-08-15',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=24C5CD78AA0F4F8B',
//     },
//     {
//       type: '重要通告',
//       content:
//         '111年度「學校學生健康資訊系統」作業暑期研習活動報名時間延長通知',
//       publishAt: '2022-07-27',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=B7E5B4C3E4013804',
//     },
//     {
//       type: '重要通告',
//       content: '111年度「學校學生健康資訊系統」作業暑期研習活動，開始報名了。',
//       publishAt: '2022-07-15',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=F1168CDEE73EF503',
//     },
//     {
//       type: '重要通告',
//       content: '停電暫停服務公告',
//       publishAt: '2022-07-14',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=C1CB03BF73AA5CE7',
//     },
//     {
//       type: '重要通告',
//       content:
//         '新版學生健康資訊系統(SSHIS 9.9版)釋出，111年7月1日起開放下載安裝!!',
//       publishAt: '2022-06-30',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=2B5FAFB6CADD90D2',
//     },
//     {
//       type: '重要通告',
//       content: '補正上傳通知',
//       publishAt: '2022-06-24',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=21A562CEDF91C875',
//     },
//     {
//       type: '重要通告',
//       content: '補上傳公告',
//       publishAt: '2022-05-03',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=AD87898F9D96B247',
//     },
//     {
//       type: '重要通告',
//       content: '111年度教育部「學校學生健康資訊系統」作業線上研習影片 已上架',
//       publishAt: '2022-04-20',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=8A44CB2A5A0F53BF',
//     },
//     {
//       type: '重要通告',
//       content: '諮詢中心延長服務時間為09:00~12:00、13:00~17:00',
//       publishAt: '2022-03-30',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=269FEF409CE5C414',
//     },
//     {
//       type: '重要通告',
//       content: '111年度教育部「學校學生健康資訊系統」作業線上研習活動研習證明',
//       publishAt: '2022-03-29',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=1F465E74440F1810',
//     },
//     {
//       type: '重要通告',
//       content: '111年度「學校學生健康資訊系統」作業線上研習活動，開始報名了。',
//       publishAt: '2022-02-23',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=60B8149CFB1F3E75',
//     },
//     {
//       type: '重要通告',
//       content: '110學年學生健康資料上傳作業之資料檢核注意事項',
//       publishAt: '2022-01-28',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=B4020B649638B879',
//     },
//     {
//       type: '重要通告',
//       content: '111年寒假研習實名制報到程序',
//       publishAt: '2022-01-11',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=693172974C80CE92',
//     },
//     {
//       type: '重要通告',
//       content: '111年度「學校學生健康資訊系統」作業寒假研習活動，開始報名了。',
//       publishAt: '2021-12-02',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=CE107A9E5FC3E013',
//     },
//     {
//       type: '重要通告',
//       content:
//         '新版學生健康資訊系統(SSHIS 9.8 R01版)釋出，110年9月16日起開放下載安裝!!',
//       publishAt: '2021-09-16',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=F04FD6019B265102',
//     },
//     {
//       type: '重要通告',
//       content:
//         '110年度「學校學生健康資訊系統」作業暑期研習活動報名時間延長通知',
//       publishAt: '2021-08-24',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=460B567A6600271D',
//     },
//     {
//       type: '重要通告',
//       content: '110年度「學校學生健康資訊系統」作業暑期研習活動，開始報名了。',
//       publishAt: '2021-08-11',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=083370A1F6C30A8E',
//     },
//     {
//       type: '重要通告',
//       content:
//         '新版學生健康資訊系統(SSHIS 9.8版)釋出，110年7月30日起開放下載安裝!!',
//       publishAt: '2021-07-29',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=BCA9FB5A7557DBD3',
//     },
//     {
//       type: '重要通告',
//       content: '109學年學生健康資料上傳作業之「身分證疑誤資料檢核」注意事項',
//       publishAt: '2021-07-12',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=3AB4EB6329B8EFA2',
//     },
//     {
//       type: '重要通告',
//       content: '新版學校學生健康資訊系統安裝注意事項說明',
//       publishAt: '2021-07-19',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=1904312FE84A3092',
//     },
//     {
//       type: '重要通告',
//       content:
//         '有關「高級中等以下學校學生健康資訊系統」資料補件上 傳注意事項，請配合辦理，請查照。',
//       publishAt: '2021-04-22',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=C09E85F837081ECA',
//     },
//     {
//       type: '重要通告',
//       content:
//         '有關109學年度學生健康資料之「資料考核正確率」統計日期及查詢說明',
//       publishAt: '2021-04-19',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=565D974898773829',
//     },
//     {
//       type: '重要通告',
//       content: '諮詢中心延長服務時間為09:00~18:00',
//       publishAt: '2021-03-19',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=6D635AAA6A7EFB02',
//     },
//     {
//       type: '重要通告',
//       content: '110年度「學校學生健康資訊系統」作業研習活動，開始報名了。',
//       publishAt: '2021-03-15',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=C63616C6D2CB96D9',
//     },
//     {
//       type: '重要通告',
//       content: '系統修複公告',
//       publishAt: '2021-03-15',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=451A566AF1E1B47E',
//     },
//     {
//       type: '重要通告',
//       content: '網路機房防火牆系統升級更新作業通知',
//       publishAt: '2021-03-13',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=43437ABBA678725B',
//     },
//     {
//       type: '重要通告',
//       content:
//         '教育部國民及學前教育署110年度「學校學生健康資訊系統」作業寒假研習活動停辦通知',
//       publishAt: '2021-01-20',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=F5E16E407F6CA677',
//     },
//     {
//       type: '重要通告',
//       content: '停電暫停服務公告',
//       publishAt: '2021-01-20',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=70C51F128590B133',
//     },
//     {
//       type: '系統管理',
//       content: 'SSHIS系統首頁新功能說明-心臟病(心電圖異常)學生警示',
//       publishAt: '2021-01-08',
//       link: 'https://hs.nhu.edu.tw/serviceCenter/bulletin.aspx?id=D03876D4A97FD0BD',
//     },
//   ];
// }
export async function getBulletinBoard(
  schoolId: string
): Promise<BulletinBoard[]> {
  try {
    const res = await fetch(
      'https://hs.nhu.edu.tw/HealthService/WS_UploadStatus.asmx',
      {
        method: 'POST',
        headers: {
          'content-type': 'text/xml; charset=utf-8',
          SoapAction: 'https://hs.nhu.edu.tw/getBulletinBoard',
        },
        body: `<?xml version="1.0" encoding="utf-8"?>
        <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
          <soap:Body>
            <getBulletinBoard xmlns="https://hs.nhu.edu.tw/">
              <SchoolID>${schoolId}</SchoolID>
              <ClientVersion>1</ClientVersion>
            </getBulletinBoard>
          </soap:Body>
        </soap:Envelope>`,
      }
    );
    if (!res.ok) {
      console.log('failed to get bulletin board', await res.text());
      return [];
    }
    const s = await res.text();
    let doc = DOM.parseFromString(s);
    const htmlNode = doc.getElementsByTagName('HTML');
    doc = DOM.parseFromString(htmlNode[0]?.textContent ?? '');
    const trs = Array.from(doc.getElementsByTagName('tr'));
    trs.shift();
    const result = trs.map((s) => {
      if (!s || s.childNodes.length != 3) {
        return {
          type: '',
          content: '',
          publishAt: '',
          link: '',
        } as BulletinBoard;
      }
      return {
        type: s.childNodes[0].firstChild?.textContent ?? '',
        content: s.childNodes[1].firstChild?.firstChild?.textContent ?? '',
        publishAt: s.childNodes[2].firstChild?.textContent ?? '',
        link:
          (s.childNodes[1].firstChild as Element)?.attributes?.getNamedItem(
            'href'
          )?.value ?? '',
      };
    });
    return result;
  } catch (e) {
    console.log('bulletin board api error', e);
    return [];
  }
}

export type BulletinBoard = {
  type: string;
  content: string;
  publishAt: string;
  link: string;
};
