import React from 'react';
import { FunctionComponent } from 'react';
import { MenuItem } from '../../model';

type Props = { items: MenuItem };

export const SideMenuIcon: FunctionComponent<Props> = ({ items }) => {
  if (items.icon) {
    return (
      <span className="pcoded-micon">
        <i className={items.icon} />
      </span>
    );
  }

  return <React.Fragment></React.Fragment>;
};
