import { z } from 'zod';

export const ClassMapZod = z.object({
  id: z.string(),
  name: z.string(),
});

export const ClassDataZod = z.object({
  grade: z.number(),
  no: z.number(),
  name: z.string(),
});

export const ClassZod = ClassDataZod.merge(
  z.object({
    id: z.string(),
    schoolId: z.string(),
  })
);

export const GraduatedClassZod = ClassZod.merge(
  z.object({
    year: z.number().positive(),
  })
);

export const ClassSettingZod = ClassZod.merge(
  z.object({
    studentCount: z.number(),
    checkTee: z.boolean().nullish(),
    checkTeeSem2: z.boolean().nullish(),
    education: z.number().nullish(),
  })
);

export const ClassStudentZod = z.object({
  grade: z.number().nullish(),
  no: z.number().nullish(),
  // sem: z.string().nullish(),
  seat: z.number().nullish(),
  pid: z.string().nullish(),
  sid: z.string().nullish(),
});

export const GradeNoSeatZod = z.object({
  grade: z.string(),
  no: z.string(),
  seat: z.string(),
});

export const StudentPidZod = z.object({
  pid: z.string(),
});

export const StudentSidZod = z.object({
  sid: z.string(),
});

export enum CheckField {
  Pid = 1,
  Sid = 2,
  GradeNoSeat = 3,
  None = 0,
}

export const CheckFieldZod = z.nativeEnum(CheckField);

export const ClassStudentResponseZod = ClassStudentZod.merge(
  z.object({
    exists: z.boolean(),
    fieldIssues: z.array(CheckFieldZod),
  })
);

export type ClassMap = z.infer<typeof ClassMapZod>;
export type Class = z.infer<typeof ClassZod>;
export type GraduatedClass = z.infer<typeof GraduatedClassZod>;
export type ClassData = z.infer<typeof ClassDataZod>;
export type ClassSetting = z.infer<typeof ClassSettingZod>;
export type ClassStudent = z.infer<typeof ClassStudentZod>;
export type ClassStudentResponse = z.infer<typeof ClassStudentResponseZod>;
export type GradeNoSeat = z.infer<typeof GradeNoSeatZod>;
export type StudentPid = z.infer<typeof StudentPidZod>;
export type StudentSid = z.infer<typeof StudentSidZod>;
