import React, { ReactNode } from 'react';
import { FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import createCalculator from 'final-form-calculate';
import {
  HeaderDisplayKeysWithType,
  OverflowWithTooltip,
  DateTimeField,
  NumberField,
  CheckBoxField,
  TextField,
  DropdownCheckListField,
} from '../../components';
import { PHISight, Sight } from '../../model';
import { Nullable } from '../../types';
import { DateTime } from 'luxon';

export const SIGHT_MANAGE_MAP = 'SightManageMap';

export const DefaultSightManageMap: Record<string, string> = {
  '1': '長效散瞳劑\n(阿托平 Atropine)',
  '2': '短效散瞳劑',
  '3': '其他藥物',
  '4': '配鏡矯治',
  '5': '更換鏡片',
  '6': '遮眼治療',
  '7': '配戴隱形眼鏡',
  '8': '角膜塑型片',
  '9': '視力保健衛教',
  '10': '其他',
  '11': '定期檢查',
};
let cachedSightManageMap = DefaultSightManageMap;

export const SightContext = {
  [SIGHT_MANAGE_MAP]: DefaultSightManageMap,
} as const;

export const SightPropertyFields = {
  sight0L: (
    <NumberField
      property="sight0L"
      min={-10}
      max={20}
      validate={(v) => {
        return v !== undefined && v !== null
          ? (v <= 20 && v >= 1) || [-1, -5, -6, -7, -8].some((i) => i === +v)
            ? undefined
            : `錯誤`
          : undefined;
      }}
    />
  ),
  sight0R: (
    <NumberField
      property="sight0R"
      min={-10}
      max={20}
      validate={(v) =>
        v !== undefined && v !== null
          ? (v <= 20 && v >= 1) ||
            [-1, -5, -6, -7, -8, -9].some((i) => i === +v)
            ? undefined
            : `錯誤`
          : undefined
      }
    />
  ),
  sightR: (
    <NumberField
      property="sightR"
      min={-1}
      max={20}
      validate={(v) =>
        v !== undefined && v !== null
          ? (v <= 20 && v >= 1) || +v === -1
            ? undefined
            : `錯誤`
          : undefined
      }
    />
  ),
  sightL: (
    <NumberField
      property="sightL"
      min={-1}
      max={20}
      validate={(v) =>
        v !== undefined && v !== null
          ? (v <= 20 && v >= 1) || +v === -1
            ? undefined
            : `錯誤`
          : undefined
      }
    />
  ),
  eNearR: (
    <NumberField
      property="eNearR"
      min={1}
      max={5000}
      validate={(v) =>
        v !== undefined && v !== null
          ? v > 5000 || v < 1
            ? `錯誤`
            : undefined
          : undefined
      }
    />
  ),
  eNearL: (
    <NumberField
      property="eNearL"
      min={1}
      max={5000}
      validate={(v) =>
        v !== undefined && v !== null
          ? v > 5000 || v < 1
            ? `錯誤`
            : undefined
          : undefined
      }
    />
  ),
  eFarR: (
    <NumberField
      property="eFarR"
      min={1}
      max={2500}
      validate={(v) =>
        v !== undefined && v !== null
          ? v > 2500 || v < 1
            ? `錯誤`
            : undefined
          : undefined
      }
    />
  ),
  eFarL: (
    <NumberField
      property="eFarL"
      min={1}
      max={2500}
      validate={(v) =>
        v !== undefined && v !== null
          ? v > 2500 || v < 1
            ? `錯誤`
            : undefined
          : undefined
      }
    />
  ),
  eSanR: (
    <NumberField
      property="eSanR"
      min={-2500}
      max={2500}
      validate={(v) =>
        v !== undefined && v !== null
          ? Math.abs(v) > 2500 || Math.abs(v) < 1
            ? `錯誤`
            : undefined
          : undefined
      }
    />
  ),
  eSanL: (
    <NumberField
      property="eSanL"
      min={-2500}
      max={2500}
      validate={(v) =>
        v !== undefined && v !== null
          ? Math.abs(v) > 2500 || Math.abs(v) < 1
            ? `錯誤`
            : undefined
          : undefined
      }
    />
  ),
  manageID: (content: Record<number, string>) => (
    <DropdownCheckListField content={content} property="manageID" />
  ),
} as const;

export function updateSightManageMap(mapData: Record<string, string>) {
  cachedSightManageMap = mapData;
}

const transformCheckedBox = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) =>
  e ? (
    <CheckBoxField property={property} />
  ) : (
    <FormCheck checked={!!v} readOnly type="checkbox" />
  );

const transformTextInput = (
  node: ReactNode,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? node : (v as string));

export const SightTableHeader: HeaderDisplayKeysWithType<Sight>[] = [
  {
    property: 'isDilated',
    display: '散瞳治療',
    onRender: transformCheckedBox.bind(null, 'isDilated'),
    order: 9,
  },
  {
    property: 'sight0R',
    display: '裸視右',
    order: 10,
    onRender: transformTextInput.bind(null, SightPropertyFields.sight0R),
  },
  {
    property: 'sight0L',
    display: '裸視左',
    order: 11,
    onRender: transformTextInput.bind(null, SightPropertyFields.sight0L),
  },
  {
    property: 'sightR',
    display: '戴鏡右',
    order: 12,
    onRender: transformTextInput.bind(null, SightPropertyFields.sightR),
  },
  {
    property: 'sightL',
    display: '戴鏡左',
    order: 13,
    onRender: transformTextInput.bind(null, SightPropertyFields.sightL),
  },
  {
    property: 'isDilating',
    display: '散瞳',
    onRender: transformCheckedBox.bind(null, 'isDilating'),
    order: 14,
  },
  {
    property: 'eNearR',
    display: '近視右',
    order: 16,
    onRender: transformTextInput.bind(null, SightPropertyFields.eNearR),
  },
  {
    property: 'eNearL',
    display: '近視左',
    order: 17,
    onRender: transformTextInput.bind(null, SightPropertyFields.eNearL),
  },
  {
    property: 'eFarR',
    display: '遠視右',
    order: 18,
    onRender: transformTextInput.bind(null, SightPropertyFields.eFarR),
  },
  {
    property: 'eFarL',
    display: '遠視左',
    order: 19,
    onRender: transformTextInput.bind(null, SightPropertyFields.eFarL),
  },
  {
    property: 'eSanR',
    display: '散光右',
    order: 20,
    onRender: transformTextInput.bind(null, SightPropertyFields.eSanR),
  },
  {
    property: 'eSanL',
    display: '散光左',
    order: 21,
    onRender: transformTextInput.bind(null, SightPropertyFields.eSanL),
  },
  {
    property: 'eNear',
    display: '近視',
    onRender: transformCheckedBox.bind(null, 'eNear'),
    order: 22,
  },
  {
    property: 'eFar',
    display: '遠視',
    onRender: transformCheckedBox.bind(null, 'eFar'),
    order: 23,
  },
  {
    property: 'eSan',
    display: '散光',
    onRender: transformCheckedBox.bind(null, 'eSan'),
    order: 24,
  },
  {
    property: 'eWeak',
    display: '弱視',
    onRender: transformCheckedBox.bind(null, 'eWeak'),
    order: 25,
  },
  {
    property: 'noProblem',
    display: '複檢無異狀',
    onRender: transformCheckedBox.bind(null, 'noProblem'),
    order: 26,
  },
  {
    property: 'eSight99',
    display: '其他',
    onRender: transformCheckedBox.bind(null, 'eSight99'),
    order: 27,
  },
  {
    property: 'eSight99State',
    display: '其他陳述',
    style: {width: '15%', whiteSpace: 'nowrap'},
    onRender: (v, i, e) =>
      e ? (
        <TextField property="eSight99State" />
      ) : (
        <OverflowWithTooltip>{v}</OverflowWithTooltip>
      ),
    order: 28,
  },
  {
    property: 'manageID',
    display: '處置代號',
    order: 29,
    onRender: function renderManageId(v, i, e, c) {
      if (!Array.isArray(v) || !v.length || typeof v[0] != 'string') {
        return null;
      }

      const { [SIGHT_MANAGE_MAP]: SightMap = DefaultSightManageMap } =
        (c.context as typeof SightContext) || SightContext;
      return e ? (
        SightPropertyFields.manageID(SightMap)
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id={`sight-manage-${i}`}>
              <table>
                <tbody>
                  {v.map((v, idx) => (
                    <tr key={`sight-manage-${idx}`}>
                      <td>{v}</td>
                      <td>:</td>
                      <td>{SightMap[v] || '未知處置'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tooltip>
          }
        >
          <span className="btn btn-outline-secondary border-0 px-2 py-1 m-0 mr-1">
            <span className="feather icon-search" />
          </span>
        </OverlayTrigger>
      );
    },
  },
  {
    property: 'periodical',
    display: '定期檢查',
    order: 30,
    style: {width: '10%'},
    onRender: function datetimeRender(value, idx, editing) {
      if (editing) return <DateTimeField property="periodical" />;
      if (value instanceof DateTime) return value.toFormat('yyyy/MM/dd');
      if (typeof value === 'string') return value;
      return '';
    },
  },
];

export const SightCalculators = [
  createCalculator<Sight>(
    {
      field: /eNear[RL]/i,
      updates: {
        eNear: (value: boolean, allValues: Sight | undefined) => {
          return !!allValues && (!!allValues.eNearL || !!allValues.eNearR);
        },
      },
    },
    {
      field: /eFar[RL]/i,
      updates: {
        eFar: (value: boolean, allValues: Sight | undefined) => {
          return !!allValues && (!!allValues.eFarL || !!allValues.eFarR);
        },
      },
    },
    {
      field: /eSan[RL]/i,
      updates: {
        eSan: (value: boolean, allValues: Sight | undefined) => {
          return !!allValues && (!!allValues.eSanL || !!allValues.eSanR);
        },
      },
    },
    {
      field: /sight0R/i,
      updates: {
        sight0L: (value: number | null, allValues: Sight | undefined) => {
          if(allValues?.sight0R == -9) {
            return null;
          }
          return (allValues ? allValues.sight0L : null);
        },
        sightL: (value: number | null, allValues: Sight | undefined) => {
          if(allValues?.sight0R == -9) {
            return null;
          }
          return (allValues ? allValues.sightL : null);
        },
        sightR: (value: number | null, allValues: Sight | undefined) => {
          if(allValues?.sight0R == -9) {
            return null;
          }
          return (allValues ? allValues.sightR : null);
        },
        noProblem: (value: boolean, allValues: Sight | undefined) => {
          if(!!allValues && typeof allValues.sight0R === 'string' && ['-8', '-7', '-6'].includes(allValues?.sight0R)) {
            return false;
          }
          return (!!allValues && allValues.noProblem) || false;
        },
      },
    },
    {
      field: /sight0L/i,
      updates: {
        noProblem: (value: boolean, allValues: Sight | undefined) => {
          if(!!allValues && typeof allValues.sight0L === 'string' && ['-8', '-7', '-6'].includes(allValues?.sight0L)) {
            return false;
          }
          if(!!allValues && allValues.eNear) {
            return false;
          }
          return (!!allValues && allValues.noProblem) || false;
        },
      },
    },
    {
      field: /(eSan|eFar|eNear|eWeak|eSight99)/,
      updates: {
        noProblem: (value: boolean, allValues: Sight | undefined) => {
          if(!!allValues && typeof allValues.sight0L === 'string' && ['-8', '-7', '-6'].includes(allValues?.sight0L)) {
            return false;
          }
          if(!!allValues && allValues.eNear) {
            return false;
          }
          if(!!allValues && allValues.eFar) {
            return false;
          }
          if(!!allValues && allValues.eSan) {
            return false;
          }
          if(!!allValues && allValues.eWeak) {
            return false;
          }
          if(!!allValues && allValues.eSight99) {
            return false;
          }
          return (!!allValues && allValues.noProblem) || false;
        },
      },
    },
    // {
    //   field: /sight0[RL]/i,
    //   updates: {
    //     sight0L: (value: boolean, allValues: Sight | undefined) => {
    //       return (
    //         !!allValues &&
    //         (allValues.sight0R == -9 ? undefined : allValues.sight0L)
    //       );
    //     },
    //     sightL: (value: boolean, allValues: Sight | undefined) => {
    //       return (
    //         !!allValues &&
    //         (allValues.sight0R == -9 ? undefined : allValues.sightL)
    //       );
    //     },
    //     sightR: (value: boolean, allValues: Sight | undefined) => {
    //       return (
    //         !!allValues &&
    //         (allValues.sight0R == -9 ? undefined : allValues.sightR)
    //       );
    //     },
    //   },
    // },
    {
      field: 'eSight99State',
      updates: {
        eSight99: (value: boolean, allValues: Sight | undefined) => {
          return (
            allValues?.eSight99 || !!allValues?.eSight99State?.length || false
          );
        },
      },
    }
  ),
];
export const PHISightCalculators = [
  createCalculator<PHISight>(
    {
      field: /eNear[RL]/i,
      updates: {
        eNear: (value: boolean, allValues: Sight | undefined) => {
          console.log(value)
          return !!allValues && (!!allValues.eNearL || !!allValues.eNearR);
        },
      },
    },
    {
      field: /eFar[RL]/i,
      updates: {
        eFar: (value: boolean, allValues: Sight | undefined) => {
          return !!allValues && (!!allValues.eFarL || !!allValues.eFarR);
        },
      },
    },
    {
      field: /eSan[RL]/i,
      updates: {
        eSan: (value: boolean, allValues: Sight | undefined) => {
          return !!allValues && (!!allValues.eSanL || !!allValues.eSanR);
        },
      },
    },
    {
      field: /sight0R/i,
      updates: {
        sight0L: (value: number | null, allValues: Sight | undefined) => {
          if(allValues?.sight0R == -9) {
            return null;
          }
          return (allValues ? allValues.sight0L : null);
        },
        sightL: (value: number | null, allValues: Sight | undefined) => {
          if(allValues?.sight0R == -9) {
            return null;
          }
          return (allValues ? allValues.sightL : null);
        },
        sightR: (value: number | null, allValues: Sight | undefined) => {
          if(allValues?.sight0R == -9) {
            return null;
          }
          return (allValues ? allValues.sightR : null);
        },
        noProblem: (value: boolean, allValues: Sight | undefined) => {
          if(!!allValues && typeof allValues.sight0R === 'string' && ['-8', '-7', '-6'].includes(allValues?.sight0R)) {
            return false;
          }
          return (!!allValues && allValues.noProblem) || false;
        },
      },
    },
    {
      field: /sight0L/i,
      updates: {
        noProblem: (value: boolean, allValues: Sight | undefined) => {
          if(!!allValues && typeof allValues.sight0L === 'string' && ['-8', '-7', '-6'].includes(allValues?.sight0L)) {
            return false;
          }
          if(!!allValues && allValues.eNear) {
            return false;
          }
          return (!!allValues && allValues.noProblem) || false;
        },
      },
    },
    {
      field: /(eSan|eFar|eNear|eWeak|eSight99)/,
      updates: {
        noProblem: (value: boolean, allValues: Sight | undefined) => {
          if(!!allValues && typeof allValues.sight0L === 'string' && ['-8', '-7', '-6'].includes(allValues?.sight0L)) {
            return false;
          }
          if(!!allValues && allValues.eNear) {
            return false;
          }
          if(!!allValues && allValues.eFar) {
            return false;
          }
          if(!!allValues && allValues.eSan) {
            return false;
          }
          if(!!allValues && allValues.eWeak) {
            return false;
          }
          if(!!allValues && allValues.eSight99) {
            return false;
          }
          return (!!allValues && allValues.noProblem) || false;
        },
      },
    },
    {
      field: 'eSight99State',
      updates: {
        eSight99: (value: boolean, allValues: Sight | undefined) => {
          return (
            allValues?.eSight99 || !!allValues?.eSight99State?.length || false
          );
        },
      },
    }
  ),
];

// denormalize value to let user easier to key data
// 0.8 => 8
export function denormalizeStudentSight({
  sight0L,
  sight0R,
  sightL,
  sightR,
  ...data
}: Nullable<Sight>): Sight {
  return {
    ...data,
    eNear: data.eNear ?? false,
    eFar: data.eFar ?? false,
    eSan: data.eSan ?? false,
    eWeak: data.eWeak ?? false,
    eSight99: data.eSight99 ?? false,
    noProblem: data.noProblem ?? false,
    isDilated: data.isDilated ?? false,
    isDilating: data.isDilating ?? false,
    sight0L: sight0L && sight0L > 0 ? +(sight0L * 10).toFixed(1) : sight0L,
    sight0R: sight0R && sight0R > 0 ? +(sight0R * 10).toFixed(1) : sight0R,
    sightL: sightL && sightL > 0 ? +(sightL * 10).toFixed(1) : sightL,
    sightR: sightR && sightR > 0 ? +(sightR * 10).toFixed(1) : sightR,
  };
}

// normalize value to store right data
// 8 => 0.8
export function normalizeStudentSight({
  sight0L,
  sight0R,
  sightL,
  sightR,
  eSanL,
  eSanR,
}: {
  sight0L?: number | null | undefined;
  sight0R?: number | null | undefined;
  sightL?: number | null | undefined;
  sightR?: number | null | undefined;
  eSanL?: number | null | undefined;
  eSanR?: number | null | undefined;
}) {
  return {
    eSanL: eSanL && eSanL > 0 ? -eSanL : eSanL,
    eSanR: eSanR && eSanR > 0 ? -eSanR : eSanR,
    sight0L: sight0L && sight0L > 0 ? +(sight0L / 10).toFixed(1) : sight0L,
    sight0R: sight0R && sight0R > 0 ? +(sight0R / 10).toFixed(1) : sight0R,
    sightL: sightL && sightL > 0 ? +(sightL / 10).toFixed(1) : sightL,
    sightR: sightR && sightR > 0 ? +(sightR / 10).toFixed(1) : sightR,
  };
}
