import React, { FunctionComponent, useState, useRef } from 'react';
import { Button, Col, FormControl, Modal, Row, Table } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../../apis';
import { AuthedLayout } from '../../components';
import { Student, TransferInfo } from '../../model';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import { useHistory } from 'react-router';

const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const transferOutPage: FunctionComponent<Props> = ({
  user,
  loading,
  catchErrorForModal,
}) => {
  const [student, setStudent] = useState<Student>();
  const [transferInfo, setTransfrInfo] = useState<TransferInfo>();
  const [modalContent, setModalContent] = useState<string>();

  const ref = useRef<HTMLInputElement>(null);
  if (loading) {
    useHistory().go(0);
    return <></>;
  }

  const button =
    !transferInfo && student ? (
      <>
        <Col xs={1} className="mr-2">
          <Button
            onClick={() => {
              apis
                .transferOut(student.pid)
                .then(() => {
                  setTransfrInfo(undefined);
                  setStudent(undefined);
                  setModalContent('轉出成功');
                })
                .catch(catchErrorForModal);
            }}
          >
            轉出
          </Button>
        </Col>
      </>
    ) : (
      <></>
    );

  const studentContent = student ? (
    <>
      <Table bordered className="student-phi">
        <tbody>
          <tr>
            <th>身份證</th>
            <td>{student.pid}</td>
          </tr>
          <tr>
            <th>座號</th>
            <td>{student.seat}</td>
          </tr>
          <tr>
            <th>學生</th>
            <td>{student.name}</td>
          </tr>
          <tr>
            <th>學號</th>
            <td>{student.sid}</td>
          </tr>
          <tr>
            <th>性別</th>
            <td>{student.sex}</td>
          </tr>
          <tr>
            <th>生日</th>
            <td>{student.birth?.toFormat('yyyy/MM/dd')}</td>
          </tr>
          <tr>
            <th>父親</th>
            <td>{student.dad}</td>
          </tr>
          <tr>
            <th>母親</th>
            <td>{student.mom}</td>
          </tr>
          <tr>
            <th>連絡電話</th>
            <td>{student.tel}</td>
          </tr>
          <tr>
            <th>緊急連絡</th>
            <td>{student.erTel}</td>
          </tr>
          <tr>
            <th>身份</th>
            <td>{student.aborigine}</td>
          </tr>
        </tbody>
      </Table>
    </>
  ) : (
    <></>
  );

  return (
    <AuthedLayout>
      <Row className="mb-2">
        學生身份證：
        <Col xs={2} className="mr-2">
          <FormControl ref={ref} />
        </Col>
        <Col xs={1} className="mr-2">
          <Button
            onClick={() => {
              if (ref.current && ref.current?.value.length > 5)
                apis
                  .GetStudent(ref.current?.value)
                  .then((s) => {
                    setStudent(s);
                    apis
                      .getTransferOutInfo(s.pid)
                      .then((t) => setTransfrInfo(t))
                      .catch(() => setTransfrInfo(undefined));
                  })
                  .catch((e) => {
                    setTransfrInfo(undefined);
                    setStudent(undefined);
                    catchErrorForModal(e);
                  });
            }}
          >
            搜尋
          </Button>
        </Col>
        {button}
      </Row>
      <hr />
      {transferInfo && transferInfo.schoolId === user.currentSchool ? (
        <>
          <Row className="mb-2">
            <b className="text-danger">已設定學生轉出</b>
          </Row>
        </>
      ) : (
        <></>
      )}
      <Row className="mb-2">
        <Col>{studentContent}</Col>
      </Row>
      <Modal
        show={!!modalContent}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header
          closeButton
          onHide={() => {
            setStudent(undefined);
            setTransfrInfo(undefined);
            setModalContent(undefined);
            if (ref.current) ref.current.value = '';
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            轉學生 - 轉出
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{modalContent}</Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setStudent(undefined);
              setTransfrInfo(undefined);
              setModalContent(undefined);
              if (ref.current) ref.current.value = '';
            }}
          >
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthedLayout>
  );
};

export const TransferOutPage = connector(transferOutPage);
