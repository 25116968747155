import React, { CSSProperties, ReactNode } from 'react';
import { Button, Table } from 'react-bootstrap';
import XLSX from 'xlsx';
import { downloadBlob } from '../utils';
import { HeaderDisplayKeysWithType } from './EditableTable';

type Props<T extends Record<string, unknown>> = {
  tableId?: string;
  title: string;
  values: T[];
  headers: HeaderDisplayKeysWithType<T>[];
  context?: Record<string, unknown>;
  btnname?: string;
};

const headerStyle: CSSProperties = {
  position: 'sticky',
  top: 0,
  backgroundColor: '#efefef',
  color: '#3f4d67',
  zIndex: 99,
  border: '0.5px solid #e5e5e5',
  padding: '0.2rem 0.3rem',
};
const oddRowStyle: CSSProperties = {
  backgroundColor: '#a6f0ff',
};
const cellStyle: CSSProperties = {
  border: '0.5px solid #e5e5e5',
  padding: '0.2rem 0.3rem',
};

export const SheetTemplate = <T extends Record<string, unknown>>({
  tableId = 'sheet',
  title,
  values,
  headers,
  context = {},
  btnname = 'Excel 下載',
}: Props<T>) => {
  const headerElements = headers.map((h, i) => {
    return (
      <th style={headerStyle} key={`header-${i}`}>
        {h.display}
      </th>
    );
  });

  let bodyElements = values.map((v, i) => {
    const elements = headers.map((h, j) => {
      const value = h.onRender
        ? h.onRender(v[h.property], i, false, { context, allValues: v })
        : v[h.property];
      return (
        <td style={cellStyle} key={`body-v${i}-h${j}`}>
          {value}
        </td>
      );
    });
    return (
      <tr style={i % 2 == 0 ? oddRowStyle : {}} key={`body-v${i}`}>
        {elements}
      </tr>
    );
  });

  if (values.length === 0) {
    bodyElements = [
      <tr key="empty-body">
        <td colSpan={999}>無資料</td>
      </tr>,
    ];
  }
  return {
    downloadButton: (
      <Button
        disabled={!values.length}
        variant="success"
        className="text-dark"
        onClick={() => {
          const tableElement = document.querySelector(`#${tableId}`);
          if (tableElement !== null) {
            // const worksheet = XLSX.utils.table_to_sheet(tableElement);
            // const workbook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, title);
            // const buffer = XLSX.write(workbook, {
            //   type: 'array',
            // });
            const content =
              '<meta charset=utf-8>' +
              tableElement.innerHTML +
              '<br/> <br/>承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　';

            const blob = new Blob([content], { type: 'application/excel' });
            downloadBlob(blob, `${title}.xls`);
          }
        }}
      >
      {btnname}
      </Button>
    ),
    sheetTable: (
      <div id={tableId} className="div-table-responsive">
        <table style={{ textAlign: 'center', border: '0.5px solid #e5e5e5' }}>
          <thead>
            <tr>
              <th style={headerStyle} colSpan={headerElements.length}>
                <b>{title}</b>
              </th>
            </tr>
            <tr>{headerElements}</tr>
          </thead>
          <tbody>{bodyElements}</tbody>
        </table>
      </div>
    ),
  };
};
