import { DateTime } from 'luxon';
import { z, ZodNullable, ZodNumber, ZodOptional, ZodString } from 'zod';

export const zodStringToNumber = (
  errorMessage: string,
  numSetting: (ztype: ZodNumber) => ZodNumber = (ztype) => ztype
) => {
  return z
    .string()
    .transform((v) => numSetting(z.number()).safeParse(+v))
    .refine((v) => v.success && !isNaN(v.data), errorMessage)
    .transform((v) => (v.success ? v.data : 0));
};

export const zodStringOrNumberToString = (
  errorMessage: string,
  strSetting: (ztype: ZodString) => ZodString = (ztype) => ztype
) => {
  return z
    .union([z.string(), z.number()])
    .transform((v) => strSetting(z.string()).safeParse(`${v}`))
    .refine((v) => v.success, errorMessage)
    .transform((v) => (v.success ? v.data : ''));
};

export const zodStringOrNumberNullishToString = (
  errorMessage: string,
  strSetting: (
    ztype: ZodNullable<ZodOptional<ZodString>>
  ) => ZodNullable<ZodOptional<ZodString>> = (ztype) => ztype
) => {
  return z
    .union([z.string(), z.number()])
    .nullish()
    .transform((v) => strSetting(z.string().nullish()).safeParse(v ?? `${v}`))
    .refine((v) => v.success, errorMessage)
    .transform((v) => (v.success ? v.data : ''));
};

export const zodStringOrNumberToNumber = (
  errorMessage: string,
  numSetting: (ztype: ZodNumber) => ZodNumber = (ztype) => ztype
) => {
  return z
    .union([z.string(), z.number()])
    .transform((v) => numSetting(z.number()).safeParse(+v))
    .refine((v) => v.success, errorMessage)
    .transform((v) => (v.success ? v.data : 0));
};

export const zodStringToDateTime = () =>
  z
    .string()
    .transform((d) => (d ? DateTime.fromISO(d) : undefined))
    .nullish();
