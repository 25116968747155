import { z } from 'zod';

const badgeZod = z.object({
  title: z.string(),
  type: z.string(),
});

const itemZod = z.object({
  id: z.string(),
  title: z.string(),
  type: z.enum(['item', 'collapse', 'group', 'notice']),
  icon: z.string().optional().nullable(),
  url: z.string().optional().nullable(),
  badge: badgeZod.optional().nullable(),
});

const endLayer = itemZod.merge(
  z.object({
    children: z.array(itemZod).optional().nullable(),
  })
);

const lowerLayer = itemZod.merge(
  z.object({
    children: z.array(endLayer).optional().nullable(),
  })
);

const middleLayer = itemZod.merge(
  z.object({
    children: z.array(lowerLayer).optional().nullable(),
  })
);
export const menuItemZod = itemZod.merge(
  z.object({
    children: z.array(middleLayer).optional().nullable(),
  })
);

export type MenuItem = z.infer<typeof menuItemZod>;
