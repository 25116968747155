import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import XLSX from 'xlsx';
import { Button, Col, FormFile, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../apis';
import {
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  NumberField,
} from '../components';
import { GradeSem, WrapGradeBaseLayout } from '../layouts';
import {
  StudentClassGrade,
  StudentSwitchClassExcelZod,
  SwitchClassResponse,
  transformClassGradeToExcelClass,
  transformExcelClassToClassGrade,
} from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { z } from 'zod';
import { DateTime } from 'luxon';
import { downloadBlob, downloadObjectUrl } from '../utils';

type StudentClassGradeOldNew = StudentClassGrade & {
  remark?: string;
};

const mapState = (state: ApplicationState, ownProps: GradeSem) => ({
  ...ownProps,
});

const mapDispatch = { ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const changeClassPage: FunctionComponent<Props> = ({
  grade,
  gradeName,
  year,
  sem,
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案');
  const [students, setStudents] = useState<StudentClassGradeOldNew[]>([]);
  const [editing, setEditing] = useState(false);
  const [okToUpload, setOkToUpload] = useState(false);

  const headerDisplayKeys: HeaderDisplayKeysWithType<StudentClassGradeOldNew>[] =
    [
      { display: '身份證字號', property: 'pid' },
      { display: '學號', property: 'sid' },
      { display: '姓名', property: 'name' },
      { display: '性別', property: 'sex' },
      {
        display: '生日',
        property: 'birth',
        onRender(v) {
          return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v ?? '';
        },
      },
      { display: '班級', property: 'classNo' },
      { display: '座號', property: 'seat' },
      {
        display: '新班級',
        property: 'newClassNo',
        onRender: editing ? OnEditingRender('newClassNo') : OnNormalRender,
      },
      {
        display: '新座號',
        property: 'newSeat',
        onRender: editing ? OnEditingRender('newSeat') : OnNormalRender,
      },
      { display: '備註', property: 'remark' },
    ];
  useEffect(() => {
    refreshStudents();
  }, [grade, year, sem]);

  function refreshStudents() {
    if (grade && year && sem) {
      apis
        .GetStudentClassGrades(grade, year, sem)
        .then((ss) => {
          setStudents(ss.map((s) => ({ ...s, editing: false })));
          setEditing(false);
        })
        .catch(catchErrorForModal);
    } else {
      setStudents([]);
    }
  }

  function OnEditingSubmit(value: StudentClassGradeOldNew[]) {
    const newClassData = value
      .filter((v) => v.newSeat && v.newClassNo && +v.newSeat && +v.newClassNo)
      .map(({ pid, classNo, seat, newClassNo, newSeat }) => ({
        pid,
        newClassNo: (newClassNo && +newClassNo) || 0,
        newSeat: (newSeat && +newSeat) || 0,
        oldClassNo: classNo,
        oldSeat: seat,
      }));

    apis
      .SwitchStudentsClass(newClassData, year, sem)
      .then((s) => refreshStudents())
      .catch(catchErrorForModal);
  }

  let content = (
    <EditableTableDiv headers={headerDisplayKeys} values={students} />
  );
  if (editing) {
    content = (
      <EditableTableDiv headers={headerDisplayKeys} values={students} />
    );
  }
  return (
    <Form
      initialValues={students}
      onSubmit={OnEditingSubmit}
      render={(prop) => {
        const { handleSubmit } = prop;
        return (
          <>
            <Row className="mb-2">
              <FormFile
                type="file"
                className="my-file-label"
                id="inputGroupFile01"
                label={fileName}
                accept=".xlsx, .csv, .xls, .ods, .ots"
                onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                  const { files } = e.target;
                  try {
                    if (files?.length === 1) {
                      setFileName(files[0].name);
                      const buf = await files[0].arrayBuffer();
                      const workShop = XLSX.read(buf, {
                        type: 'buffer',
                        sheets: 0,
                      });
                      if (workShop.SheetNames[0]) {
                        const jsonData = XLSX.utils.sheet_to_json(
                          workShop.Sheets[workShop.SheetNames[0]],
                          { raw: false, rawNumbers: false }
                        );
                        const ss: StudentClassGradeOldNew[] = (
                          await z
                            .array(StudentSwitchClassExcelZod)
                            .parseAsync(jsonData)
                        ).map((s) => transformExcelClassToClassGrade(s));
                        if (ss && ss.length) {
                          const studentToChange = ss
                            .filter(
                              ({ newClassNo, newSeat }) => newClassNo && newSeat
                            )
                            .map(
                              ({
                                pid,
                                classNo,
                                seat,
                                newClassNo,
                                newSeat,
                              }) => ({
                                pid,
                                oldClassNo: classNo,
                                oldSeat: seat,
                                newClassNo: newClassNo ?? 0,
                                newSeat: newSeat ?? 0,
                              })
                            );
                          await apis
                            .CheckStudentCanSwitch(studentToChange)
                            .then((result) => {
                              setEditing(true);
                              const [ok, newStudents] = checkOkAndRemark(
                                result,
                                ss
                              );
                              setStudents(newStudents);
                              setOkToUpload(ok);
                            })
                            .catch((e) => {
                              catchErrorForModal(e);
                              setOkToUpload(false);
                              setEditing(false);
                            });
                        } else {
                          console.log(ss);
                        }
                      }
                    }
                  } catch (e) {
                    setFileName('檔案');
                    setOkToUpload(false);
                    setEditing(false);
                    console.log(e);
                    showError('檔案內容不正確，請檢查資料內容格式');
                  }
                }}
                custom
              />
              <Col></Col>
              <Button
                className="mr-2"
                disabled={!students.length}
                onClick={(e) => {
                  const sheet = XLSX.utils.json_to_sheet(
                    students.map((s) => transformClassGradeToExcelClass(s)),
                    {
                      dateNF: 'yyyy/MM/dd',
                      header: [
                        '身份證號',
                        '學號',
                        '生日',
                        '姓名',
                        '性別',
                        '班級',
                        '座號',
                        '新班級',
                        '新座號',
                      ],
                    }
                  );
                  const workBook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workBook, sheet);
                  const blobUrl = XLSX.write(workBook, { type: 'array' });
                  downloadBlob(
                    new Blob([blobUrl], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                    `${gradeName}級班級座號表.xlsx`
                  );
                }}
              >
                產生Excel
              </Button>
              <Button
                onClick={(e) => {
                  setEditing(!editing);
                  if (editing) {
                    handleSubmit(e);
                  }
                }}
              >
                {editing ? '儲存' : '更改'}
              </Button>
            </Row>
            <Row className="mb-2">{content}</Row>
          </>
        );
      }}
    />
  );
};

function OnNormalRender(
  v: StudentClassGradeOldNew[keyof StudentClassGradeOldNew],
  idx: number
) {
  return v instanceof DateTime ? '' : v ?? null;
}

function OnEditingRender(property: string) {
  return function f(
    v: StudentClassGradeOldNew[keyof StudentClassGradeOldNew],
    idx: number
  ) {
    return <NumberField property={`${idx}.${property}`} />;
  };
}

function checkOkAndRemark(
  response: SwitchClassResponse,
  students: StudentClassGradeOldNew[]
): [boolean, StudentClassGradeOldNew[]] {
  let ok = true;
  response.noSeatNotSameStudents.forEach((s) => {
    const student = students.find((stu) => stu.pid === s);
    if (student) {
      ok = false;
      student.remark = '學生資料不一致， 請檢查后再匯入';
    }
  });
  response.notExistsStudents.forEach((s) => {
    const student = students.find((stu) => stu.pid === s);
    if (student) {
      ok = false;
      student.remark = '學生資料不不存在';
    }
  });
  return [ok, students];
}

export const ChangeClassPage = connector(WrapGradeBaseLayout(changeClassPage));
