import { z } from 'zod';
import { SightAndPreSightNoDiagsZod, SightNoDiags, SightNoDiagsZod, StudentGradeNoSeatZod } from '../model';
import { get } from './BaseApi';


export async function getNoDiagSight(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/nodiag', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getOddSight(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/odd', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getSight0Rank(
  year: number,
  sem: number,
  sight0Rank?: number[]
): Promise<SightNoDiags> {
  return await get('/api/students/sight/0rank', SightNoDiagsZod, {
    year,
    sem,
    sight0Rank,
  });
}

export async function getStudentsSight(
  year: number,
  sem: number,
  classId?: string
): Promise<SightNoDiags> {
  return await get('/api/students/sight', SightNoDiagsZod, {
    year,
    sem,
    classId,
  });
}

export async function getSightDilated(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/dilated', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getSightSchool(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/school', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getSightLongDilation(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/longdilation', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getSightShortDilation(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/shortdilation', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getSightDilatingNear(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/dilatingnear', SightNoDiagsZod, {
    year,
    sem,
  });
}

export const SightMatrixZod = z.array(
  z.object({
    grade: z.number(),
    allSum0: z.number(),
    maleSum0: z.number(),
    femaleSum0: z.number(),
    male10: z.number(),
    female10: z.number(),
    male20: z.number(),
    female20: z.number(),
    male30: z.number(),
    female30: z.number(),
    male40: z.number(),
    female40: z.number(),
    allSum01: z.number(),
    maleSum01: z.number(),
    femaleSum01: z.number(),
    allSum1: z.number(),
    maleSum1: z.number(),
    femaleSum1: z.number(),
    male11: z.number(),
    female11: z.number(),
    male21: z.number(),
    female21: z.number(),
    male31: z.number(),
    female31: z.number(),
  })
);

export type SightMatrix = z.infer<typeof SightMatrixZod>;
export async function getSightMatrix(
  year: number,
  sem: number
): Promise<SightMatrix> {
  return await get('/api/students/sight/matrix', SightMatrixZod, {
    year,
    sem,
  });
}

export async function getSightdiagrate2(
  year: number,
  sem: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getdiagrate2', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getStudentsSightCorrect(
  year: number,
  sem: number,
  classId?: string
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getstudentssightcorrect', SightNoDiagsZod, {
    year,
    sem,
    classId,
  });
}

export async function getStudentsSightUnCorrect(
  year: number,
  sem: number,
  classId?: string
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getstudentssightuncorrect', SightNoDiagsZod, {
    year,
    sem,
    classId,
  });
}

export async function getSightCorrect(
  year: number,
  sem: number,
  classId?: string
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getsightcorrect', SightNoDiagsZod, {
    year,
    sem,
    classId,
  });
}

export async function getSightUnCorrect(
  year: number,
  sem: number,
  classId?: string
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getsightuncorrect', SightNoDiagsZod, {
    year,
    sem,
    classId,
  });
}
const SightAndPreSightNoDiagsListZod = z.array(
  StudentGradeNoSeatZod.merge(
    z.object({
      sight: SightAndPreSightNoDiagsZod,
    })
  )
);
type SightAndPreSightNoDiagsList = z.infer<typeof SightAndPreSightNoDiagsListZod>;

export async function getIncreasedShortSight(
  year: number,
  sem: number,
  interval: number,
  sightId?: number
): Promise<SightAndPreSightNoDiagsList> {
  return await get('/api/students/sight/getincreasedshortsight', SightAndPreSightNoDiagsListZod, {
    year,
    sem,
    interval,
    sightId
  });
}

export async function getSightHighENear(
  year: number,
  sem: number,
  sightId?: number
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getsighthighenear', SightNoDiagsZod, {
    year,
    sem,
    sightId,
  });
}

export async function getSightDiagMatrix(
  year: number,
  sem: number,
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getsightdiagmatrix', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getSightUnCheck(
  year: number,
  sem: number,
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getsightuncheck', SightNoDiagsZod, {
    year,
    sem,
  });
}

export async function getSightCantCheck(
  year: number,
  sem: number,
): Promise<SightNoDiags> {
  return await get('/api/students/sight/getsightcantcheck', SightNoDiagsZod, {
    year,
    sem,
  });
}