import { z } from 'zod';

export enum ExaminedResultEnum {
  /**無異狀 */ NoProblem = 0,
  /**初檢異常 */ InitialAbnoraml = 1,
  /**複診正常 */ FollowUpNormal = 2,
  /**複診異常 */ FollowUpAbnormal = 3,
  /**未實施 */ NoExamined = 9,
}

export enum LeftRightEnum {
  /** 無 */ None = 0,
  /** 左 */ Left = 1,
  /** 右 */ Right = 2,
  /** 左右 */ Both = 3,
}

export enum StrabismusKindEnum {
  /** NULL*/ None = 0,
  /** 內斜視*/ Esotropia = 1,
  /** 外斜視*/ Exotropia = 2,
  /** 上斜視*/ Hypertropia = 3,
  /** 下斜視*/ Hypotropia = 4,
  /** 外旋斜視*/ Extorsion = 5,
  /** 內旋斜視*/ Intorsion = 6,
  /** 麻痺性斜視*/ Paralytic = 7,
  /** 其他*/ Other = 9,
}

export enum TeeInspection {
  /** 無異狀*/ None = 0,
  /** 有*/ Tested = 1,
  /** 未受檢*/ NoInspection = 9,
}

export enum UrineEnum {
  Negetive = 0,
  Positive1 = 1,
  Positive2 = 2,
  Positive3 = 3,
  Positive4 = 4,
  WeakPositive = 5,
}

export enum InjuryPlaceEnum {
  None = 0,
  /** 操場 */ Fiels = 1,
  /** 遊戲運動器材 */ PlayGround = 2,
  /** 普通教室 */ Class = 3,
  /** 專科教室 */ Lab = 4,
  /** 走廊 */ Corridor = 5,
  /** 樓梯 */ Stair = 6,
  /** 地下室 */ Basement = 7,
  /** 體育館活動中心 */ ActivityCenter = 8,
  /** 廁所 */ Toilet = 9,
  /** 校外 */ OffCampus = 10,
  /** 其它 */ Other = 99,
}

export enum InjuryMomentEnum {
  None = 0,
  Morning = 1,
  Noon = 2,
  Afternoon = 3,
}

export const ExaminedResultEnumZod = z.nativeEnum(ExaminedResultEnum);
export const LeftRightEnumZod = z.nativeEnum(LeftRightEnum);
export const StrabismusKindEnumZod = z.nativeEnum(StrabismusKindEnum);
export const TeeInspectionZod = z.nativeEnum(TeeInspection);
export const UrineEnumZod = z.nativeEnum(UrineEnum);
export const InjuryPlaceEnumZod = z.nativeEnum(InjuryPlaceEnum);
export const InjuryMomentEnumZod = z.nativeEnum(InjuryMomentEnum);
