import React, { FunctionComponent, useEffect, useState, useRef, ReactNode, } from 'react';
import { Button, Col, Row, FormControl } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  HeaderDisplayKeysWithType,
  TextField,
  OverflowWithTooltip,
  InlineEditableTable
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { StudentGradeNoSeat, PHISightSolid } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { Nullable } from '../../../types';
import { toast } from 'react-toastify';
import { GradeSem, WrapGradeBaseLayout } from '../../../layouts';
import { I18N } from '../../../i18n-raw';

const sightSolidKind = [
  {
    Id: 0,
    Name: '斜視',
  },
  {
    Id: 1,
    Name: '弱視',
  },
  {
    Id: 2,
    Name: '斜、弱視',
  },
  {
    Id: 3,
    Name: '屈光不正',
  },
  {
    Id: 4,
    Name: '無確定診斷',
  },
  {
    Id: 5,
    Name: '診治正常',
  },
  {
    Id: 6,
    Name: '其他眼疾',
  },
  {
    Id: 7,
    Name: '未就醫',
  }
];

const transformTextInput = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? <TextField property={property} /> : wrapOverflow(v as string));
function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}
type PageData = StudentGradeNoSeat & Nullable<PHISightSolid>;

const tableHeader: (SheetHeaderDisplay<PageData> &
  HeaderDisplayKeysWithType<PageData>)[] = [
    { display: '年級', property: 'grade' },
    { display: '班級', property: 'no' },
    { display: '學生', property: 'name' },
    { display: '性別', property: 'sex' },
    { display: '座號', property: 'seat' },
    { display: '統編', property: 'pid' },
    {
      property: 'sightSolid',
      display: '診斷',
      onRender: (value: unknown) => {
        if (value) {
          return typeof value == 'number'
            ? sightSolidKind[value].Name
            : '無診斷';
        }
      },
    },
    {
      property: 'states',
      display: '其他診斷',
      onRender: transformTextInput.bind(null, 'states'),
    },
    {
      property: 'hospital',
      display: '醫院',
      onRender: transformTextInput.bind(null, 'hospital'),
    }
  ];

const mapState = (state: ApplicationState, ownProps: GradeSem) => ({
  ...ownProps,
});

const mapDispatch = { ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const sightSolid: FunctionComponent<Props> = ({
  grade,
  gradeName,
  year,
  sem,
  catchErrorForModal,
}) => {
  const { yearSem, element: semGradeElement } = useSemGrade();
  const { selectedClassGrade: classGrade, element: classGradeElement } =
    useClassGrade({
      yearSem,
    });
  const [students, setStudents] = useState<PageData[]>([]);
  const studentInputRef = useRef<HTMLInputElement>(null);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [studentSid, setStudentSid] = useState<string>('');
  const [studentPid, setStudentPid] = useState<string>('');
  const [studentGrade, setStudentGrade] = useState<number>();
  const [no, setNo] = useState<number>();
  const [seat, setSeat] = useState<number>();
  const [checkField, setCheckField] = useState<number>(0);

  useEffect(() => {
    getStudentSightSolid();
  }, [grade]);

  function getStudentSightSolid() {
    if (grade) {
      apis
        .getStudentSightSolidList(year, sem, grade)
        .then((r) =>
          setStudents(
            r.map(({ sightSolid, ...s }) => ({
              ...sightSolid,
              ...s,
            }))
          )
        )
        .catch(catchErrorForModal);
    }
  }

  // 檢查是否可以點選新增按鈕
  function CheckInsertBtn() {
    setInsertBtn(true);
    if (studentInputRef.current?.value) {
      setInsertBtn(false);
      CheckInput();
    }
  }

  function CheckInput() {
    const inputStudent = studentInputRef.current?.value;

    // 檢查輸入身分證、學號或班級座號
    if (inputStudent) {
      if (inputStudent.substring(0, 1) == '=') {
        setStudentSid(inputStudent.substring(1));
        setCheckField(2);
      } else {
        switch (inputStudent.length) {
          case 5:
            setStudentGrade(parseInt(inputStudent.substring(0, 1)));
            setNo(parseInt(inputStudent.substring(1, 3)));
            setSeat(parseInt(inputStudent.substring(3, 5)));
            setCheckField(3);
            break;
          case 6:
            // 代入年級、班級、座號
            setStudentGrade(parseInt(inputStudent.substring(0, 2)));
            setNo(parseInt(inputStudent.substring(2, 4)));
            setSeat(parseInt(inputStudent.substring(2, 4)));
            setCheckField(3);
            break;
          case 10:
          case 11:
          case 12:
            setStudentPid(inputStudent);
            setCheckField(1);
            break;
        }
      }
    }
  }

  function insertSightSolidCheck() {
    // 檢查診斷代號
    // 檢查有無年級班級座號
    if (checkField == 3) {
      if (!studentGrade || !no || !seat) {
        toast.error(`找不到該名學生!`);
        return
      }
    }

    insertSightSolid();
  }

  function insertSightSolid() {
    toast
      .promise(
        apis.insertNoticeSightSolid(
          year,
          sem,
          studentSid,
          seat || 0,
          no || 0,
          grade || 0,
          studentPid,
          0,
          "",
          "",
          checkField
        ),
        {
          pending: '資料新增中......',
          success: '新增成功！',
        }
      )
      .then((r) => {
        // 清空學號
        if (studentInputRef.current) {
          studentInputRef.current.value = '';
        }
        CheckInsertBtn(); // 按鈕disabled
        getStudentSightSolid();
      })
      .catch(catchErrorForModal);
  }

  function onValueUpdate(value: PageData) {
    if (value.id) {
      apis
        .updateStudentSightSolid(
          value.id,
          value?.sightSolid || 0,
          value?.states || "",
          value?.hospital || "",
          value.pid,
        )
        .then(() => {
          getStudentSightSolid();
        })
        .catch(catchErrorForModal);
    }
  }

  function onValueDelete(value: PageData) {
    if (value.id) {
      apis
        .deleteStudentSightSolid(
          value.id,
          value?.sightSolid || 0,
          value?.states || "",
          value?.hospital || "",
          value.pid,
        )
        .then(() => {
          getStudentSightSolid();
        })
        .catch(catchErrorForModal);
    }
  }

  return (
    <>
      <Row className="justify-content-between">
        <Col className='text-right'>
          <div>
            <Button
              disabled={!students.length}
              variant="success"
              className="text-dark mr-3"
              onClick={() => {
                downloadDataAsExcel({
                  title: `${year}學年${sem}學期${grade}年級_立體感異常清單`,
                  values: students,
                  headers: tableHeader,
                  context: SightContext,
                  footer:
                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                });
              }}
            >
              Excel 下載
            </Button>
            <Button
              disabled={!students}
              variant="info"
              className="text-dark"
              onClick={() => {
                let pids = students.map((s) => s.pid);
                pids = pids.length == 0 ? students.map((s) => s.pid) : pids;
                toast
                  .promise(apis.downloadSolidPDF(grade ?? 0, pids, year, sem), {
                    pending: '下載中...',
                    success: '下載成功！',
                    error: '下載失敗！請查看錯誤資訊。',
                  })
                  .then((blob) => {
                    const objlink = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = objlink;
                    a.setAttribute('download', `視力立體感檢查不良通知單-${I18N.Grades[grade ?? 0]}級.pdf`);
                    document.body.appendChild(a);
                    a.click();

                    a.parentNode?.removeChild(a);
                  })
                  .catch(catchErrorForModal);
              }}
            >
              通知單列印
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        請輸入班級座號或身分證或學號(學號前請加=)：
        <Col xs={2} className="mr-2">
          <FormControl
            type="string"
            aria-label="studentInput"
            aria-describedby="basic-addon1"
            ref={studentInputRef}
            onChange={CheckInsertBtn}
          />
        </Col>
        <Button
          disabled={insertBtn}
          onClick={insertSightSolidCheck}
        >
          新增異常學生
        </Button>
      </Row>
      <hr />
      <Row>
        <InlineEditableTable
          values={students}
          headers={tableHeader}
          onValueUpdate={onValueUpdate}
          onValueDelete={onValueDelete}
          onRowRender={(v) => ({
            editable: true,
            deleteable: true
          })}
        />
      </Row>
    </>
  );
};

export const SightSolid = connector(WrapGradeBaseLayout(sightSolid));
