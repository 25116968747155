import { z } from 'zod';
import {
  Grade,
  GradeZod,
  StudentClassGrade,
  StudentClassGradeZod,
  StudentSwitchClass,
  SwitchClassResponse,
  SwitchClassResponseZod,
  yearSemZod,
} from '../model';
import { get, post } from './BaseApi';

const GradesZod = z.array(GradeZod);
export async function GetGrades(): Promise<Grade[]>;
export async function GetGrades(year: number, sem: number): Promise<Grade[]>;
export async function GetGrades(year?: number, sem?: number): Promise<Grade[]> {
  return year && sem
    ? await get('/api/grades', GradesZod, { year, sem })
    : await get('/api/grades', GradesZod);
}

const StudentClassGradesZod = z.array(StudentClassGradeZod);
export async function GetStudentClassGrades(
  grade: number
): Promise<StudentClassGrade[]>;
export async function GetStudentClassGrades(
  grade: number,
  year: number,
  sem: number
): Promise<StudentClassGrade[]>;
export async function GetStudentClassGrades(
  grade: number,
  year?: number,
  sem?: number
): Promise<StudentClassGrade[]> {
  return year && sem
    ? await get(`/api/grades/${grade}/students`, StudentClassGradesZod, {
        year,
        sem,
      })
    : await get(`/api/grades/${grade}/students`, StudentClassGradesZod);
}

export async function CheckStudentCanSwitch(
  students: StudentSwitchClass[]
): Promise<SwitchClassResponse>;
export async function CheckStudentCanSwitch(
  students: StudentSwitchClass[],
  year: number,
  sem: number
): Promise<SwitchClassResponse>;
export async function CheckStudentCanSwitch(
  students: StudentSwitchClass[],
  year?: number,
  sem?: number
): Promise<SwitchClassResponse> {
  return year && sem
    ? await post(
        '/api/grades/student/check',
        SwitchClassResponseZod,
        {
          students,
        },
        { year, sem }
      )
    : await post('/api/grades/student/check', SwitchClassResponseZod, {
        students,
      });
}

export async function SwitchStudentsClass(
  students: StudentSwitchClass[],
  year: number,
  sem: number
): Promise<SwitchClassResponse> {
  return await post(
    '/api/grades/student/class',
    SwitchClassResponseZod,
    {
      students,
    },
    { year, sem }
  );
}

const yearSemsZod = z.array(yearSemZod);
type YearSems = z.infer<typeof yearSemsZod>;
export async function GetAllYearSem(): Promise<YearSems> {
  return await get('/api/grades/semester', yearSemsZod);
}
