import { z } from 'zod';
import { ApiError, ErrorCode } from './ApiError';
import { loadTokenfromStorage } from './TokenStorage';
const { REACT_APP_SERVER_API: SERVER_API } = process.env;

const BaseUrl = new URL(SERVER_API || window.location.origin);

export interface Parser<T> {
  parseAsync: (obj: unknown) => Promise<T>;
}

const ApiResponseZod = z.object({
  result: z.boolean(),
  data: z.any().nullish(),
  error: z.string().optional().nullable(),
});

export type ApiResponse = z.infer<typeof ApiResponseZod>;

export async function get<T>(path: string, resultParser: Parser<T>): Promise<T>;
export async function get(
  path: string,
  body?: Record<string, any>
): Promise<void>;
export async function get<T>(
  path: string,
  resultParser: Parser<T>,
  body: Record<string, any>
): Promise<T>;
export async function get<T>(
  path: string,
  resultParser?: Parser<T> | Record<string, any>,
  body?: Record<string, string>
): Promise<T | void> {
  const url = new URL(path, BaseUrl);

  if (body) {
    Object.entries(body ?? {}).forEach(([k, v]) => {
      if (v === undefined || v === null) {
        return;
      } else if (Array.isArray(v)) {
        v.forEach((s) => url.searchParams.append(k, s));
      } else {
        url.searchParams.append(k, v);
      }
    });
  } else if (
    !body &&
    resultParser &&
    typeof resultParser.parseAsync !== 'function'
  ) {
    Object.entries(resultParser ?? {}).forEach(([k, v]) =>
      url.searchParams.append(k, v)
    );
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      authorization: `Bearer ${loadTokenfromStorage()}`,
    },
  });
  const json = await response.json();
  const res = await ApiResponseZod.parseAsync(json);
  if (res.result) {
    if (resultParser && typeof resultParser.parseAsync === 'function')
      return resultParser.parseAsync(res.data);
    return;
  }

  throw new ApiError(res.error as ErrorCode, res.data);
}

export async function post<T>(
  path: string,
  resultParser: Parser<T>
): Promise<T>;
export async function post(
  path: string,
  body?: Record<string, any>
): Promise<void>;
export async function post<T>(
  path: string,
  resultParser: Parser<T>,
  body: Record<string, any>
): Promise<T>;
export async function post<T>(
  path: string,
  resultParser: Parser<T>,
  body: Record<string, any>,
  query: Record<string, any>
): Promise<T>;
export async function post<T>(
  path: string,
  body: Record<string, any>,
  query: Record<string, any>
): Promise<T | void>;
export async function post<T>(
  path: string,
  resultParser?: any,
  body?: Record<string, any>,
  query?: Record<string, any>
): Promise<T | void> {
  if (resultParser && typeof resultParser.parseAsync !== 'function') {
    query = body;
    body = resultParser;
  }
  const url = new URL(path, BaseUrl);
  Object.entries(query ?? {}).forEach(([k, v]) =>
    url.searchParams.append(k, v)
  );
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      authorization: `Bearer ${loadTokenfromStorage()}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  const json = await response.json();
  const res = await ApiResponseZod.parseAsync(json);
  if (res.result) {
    if (resultParser && typeof resultParser.parseAsync === 'function')
      return resultParser?.parseAsync(res.data);
    return;
  }
  throw new ApiError(res.error as ErrorCode, res.data);
}

export async function put<T>(path: string, resultParser: Parser<T>): Promise<T>;
export async function put(
  path: string,
  body?: Record<string, any>
): Promise<void>;
export async function put<T>(
  path: string,
  resultParser: Parser<T>,
  body: Record<string, any>
): Promise<T>;
export async function put<T>(
  path: string,
  body: Record<string, any>,
  query: Record<string, any>
): Promise<T>;
export async function put<T>(
  path: string,
  resultParser: Parser<T>,
  body: Record<string, any>,
  query: Record<string, any>
): Promise<T>;
export async function put<T>(
  path: string,
  resultParser?: any,
  body?: Record<string, any>,
  query?: Record<string, any>
): Promise<T | void> {
  if (resultParser && typeof resultParser.parseAsync !== 'function') {
    query = body;
    body = resultParser;
  }
  const url = new URL(path, BaseUrl);
  Object.entries(query ?? {}).forEach(([k, v]) =>
    url.searchParams.append(k, v)
  );
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      authorization: `Bearer ${loadTokenfromStorage()}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  const json = await response.json();
  const res = await ApiResponseZod.parseAsync(json);
  if (res.result) {
    if (resultParser && typeof resultParser.parseAsync === 'function')
      return resultParser.parseAsync(res.data);
    return;
  }
  throw new ApiError(res.error as ErrorCode, res.data);
}

export async function delet<T>(
  path: string,
  resultParser: Parser<T>
): Promise<T>;
export async function delet(
  path: string,
  body?: Record<string, any>
): Promise<void>;
export async function delet<T>(
  path: string,
  resultParser: Parser<T>,
  body: Record<string, any>
): Promise<T>;
export async function delet<T>(
  path: string,
  body: Record<string, any>,
  query: Record<string, any>
): Promise<T>;
export async function delet<T>(
  path: string,
  resultParser: Parser<T>,
  body: Record<string, any>,
  query: Record<string, any>
): Promise<T>;
export async function delet<T>(
  path: string,
  resultParser?: any,
  body?: Record<string, any>,
  query?: Record<string, any>
): Promise<T | void> {
  if (resultParser && typeof resultParser.parseAsync !== 'function') {
    query = body;
    body = resultParser;
  }
  const url = new URL(path, BaseUrl);
  Object.entries(query ?? {}).forEach(([k, v]) =>
    url.searchParams.append(k, v)
  );
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      authorization: `Bearer ${loadTokenfromStorage()}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  const json = await response.json();
  const res = await ApiResponseZod.parseAsync(json);
  if (res.result) {
    if (resultParser && typeof resultParser.parseAsync === 'function')
      return resultParser.parseAsync(res.data);
    return;
  }
  throw new ApiError(res.error as ErrorCode, res.data);
}

export async function download(
  path: string,
  body?: Record<string, any>,
  method: 'POST' | 'GET' | 'PUT' = 'POST'
): Promise<Blob> {
  const url = new URL(path, BaseUrl);
  const response = await fetch(url, {
    method,
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${loadTokenfromStorage()}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  if (response.ok) return new Blob([await response.blob()]);
  const json = await response.json();
  const res = await ApiResponseZod.parseAsync(json);
  throw new ApiError(res.error as ErrorCode, res.data);
}
