import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { TeeGradeClassStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';


const HDID = {
    // Total: -9,
    UnderWeight: -1,
    Healty: 0,
    OverWeight: 1,
    Obesity: 2,
};

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const teeStaticSexNeat: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element } = useSemGrade();
    const [datas, setDatas] = useState<TeeGradeClassStatistic[]>([]);

    useEffect(() => {
        if (yearSem) {
            apis.getTeethTeeStaticSexNeat(yearSem.year, yearSem.sem).then((s) => {
                setDatas(s);
            });
        }
    }, [yearSem?.year, yearSem?.sem]);

    const gradeBase = datas.reduce((grades, d) => {
        return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
    }, {} as Record<number, TeeGradeClassStatistic[]>);

    let schoolcount = 0;
    let schoolcountT01 = 0;
    let schoolCountT011 = 0;
    let schoolCountT012 = 0;
    let schoolCountT013 = 0;
    let schoolCountT0123 = 0;
    let countRow = 0;
    const contentRows: Row[] = Object.values(gradeBase).flatMap(
        (gs, idx, allArray) => {
            const rows = gs.flatMap((g, idx) => {
                // 計算資料筆數
                countRow++;
                schoolcount += g.total?.count ?? 0;
                schoolcountT01 += g.total?.countT01 ?? 0;
                schoolCountT011 += g.total?.countT011 ?? 0;
                schoolCountT012 += g.total?.countT012 ?? 0;
                schoolCountT013 += g.total?.countT013 ?? 0;
                schoolCountT0123 += g.total?.countT0123 ?? 0;

                return TeeToRows(
                    g.grade,
                    g.male?.count ?? 0,
                    g.male?.countT01 ?? 0,
                    percent(g.male?.countT01 ?? 0, g.male?.count ?? 0),
                    percent(g.male?.countT0123 ?? 0, g.male?.count ?? 0),
                    g.male?.countT011 ?? 0,
                    g.female?.countT012 ?? 0,
                    g.male?.countT013 ?? 0,
                    g.female?.count ?? 0,
                    g.female?.countT01 ?? 0,
                    percent(g.female?.countT01 ?? 0, g.female?.count ?? 0),
                    percent(g.female?.countT0123 ?? 0, g.female?.count ?? 0),
                    g.female?.countT011 ?? 0,
                    g.female?.countT012 ?? 0,
                    g.female?.countT013 ?? 0,
                    g.total?.count ?? 0,
                    g.total?.countT01 ?? 0,
                    percent(g.total?.countT01 ?? 0, g.total?.count ?? 0),
                    percent(g.total?.countT0123 ?? 0, g.total?.count ?? 0),
                    g.total?.countT011 ?? 0,
                    g.total?.countT012 ?? 0,
                    g.total?.countT013 ?? 0,
                );
            });

            if (datas.length == countRow) {
                rows.push(...TeeToRows(
                    0,
                    0,
                    0,
                    "",
                    "",
                    0,
                    0,
                    0,
                    0,
                    0,
                    "",
                    "",
                    0,
                    0,
                    0,
                    schoolcount ?? 0,
                    schoolcountT01 ?? 0,
                    percent(schoolcountT01 ?? 0, schoolcount ?? 0),
                    percent(schoolCountT0123 ?? 0, schoolcount ?? 0),
                    schoolCountT011 ?? 0,
                    schoolCountT012 ?? 0,
                    schoolCountT0123 ?? 0,
                ));
            }
            return rows;
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級　/ 　性別 ',
                    merge: { column: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '人數',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '初檢齲齒人數A',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '初檢齲齒％',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '齲齒未就醫人數B',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '齲齒複檢正常人數C',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '齲齒複檢異常人數D',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '齲齒複檢％（E）',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
    ];

    // const workSheet = generateSheet(content, { alignment: cellAlignStyle });

    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <span className="mr-3">{element}</span>
                <Button
                    disabled={!datas.length}
                    onClick={() => {
                        const workSheet = generateSheet(
                            [
                                {
                                    cells: [
                                        {
                                            value: `${yearSem?.year}學年第${yearSem?.sem}學期_口腔齲齒複檢結果統計`,
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            value: '一、A：初檢齲齒人數（未治療齲齒資料代號為 1、2、3）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '二、B：齲齒未就醫人數（未治療齲齒資料代號為 1）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '二、B：齲齒未就醫人數（未治療齲齒資料代號為 1）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '三、C：齲齒複檢正常人數（未治療齲齒資料代號為 2）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '四、D：齲齒複檢異常人數（未治療齲齒資料代號為 3）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            value: '五、E：齲齒複檢％ （ (C + D)/A）',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                ...content,
                                {
                                    cells: [
                                        {
                                            value:
                                                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets('口腔齲齒複檢結果統計', { sheet: workSheet, name: 'st' });
                    }}
                >
                    Excel 下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <colgroup>
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '3%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                    </colgroup>
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function TeeToRows(
    grade: number,
    maleCount: number,
    maleCountT01: number,
    maleRate: string,
    maleRateT0123: string,
    maleCountT011: number,
    maleCountT012: number,
    maleCountT013: number,
    femaleCount: number,
    femaleCountT01: number,
    femaleRate: string,
    femaleRateT0123: string,
    femaleCountT011: number,
    femaleCountT012: number,
    femaleCountT013: number,
    totalCount: number,
    totalCountT01: number,
    totalRate: string,
    totalRateT0123: string,
    totalCountT011: number,
    totalCountT012: number,
    totalCountT013: number,
): Row[] {
    let returnhtml;
    const gradeNo = [
        {
            value: grade,
            merge: { row: 3 },
            style: { border: fullBorderStyle },
        }
    ];
    if (grade != 0) {
        returnhtml = [
            {
                cells: [
                    ...gradeNo,
                    {
                        value: "男",
                        merge: { row: 1 }
                    },
                    {
                        value: maleCount,
                    },
                    {
                        value: maleCountT01,
                    },
                    {
                        value: maleRate,
                    },
                    {
                        value: maleCountT011,
                    },
                    {
                        value: maleCountT012,
                    },
                    {
                        value: maleCountT013,
                    },
                    {
                        value: maleRateT0123,
                    },
                ],

            },
            {
                cells: [
                    {
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: "女",
                        merge: { row: 1 }
                    },
                    {
                        value: femaleCount,
                    },
                    {
                        value: femaleCountT01,
                    },
                    {
                        value: femaleRate,
                    },
                    {
                        value: femaleCountT011,
                    },
                    {
                        value: femaleCountT012,
                    },
                    {
                        value: femaleCountT013,
                    },
                    {
                        value: femaleRateT0123,
                    },
                ],

            },
            {
                cells: [
                    {
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: "小計",
                        merge: { row: 1 }
                    },
                    {
                        value: totalCount,
                    },
                    {
                        value: totalCountT01,
                    },
                    {
                        value: totalRate,
                    },
                    {
                        value: totalCountT011,
                    },
                    {
                        value: totalCountT012,
                    },
                    {
                        value: totalCountT013,
                    },
                    {
                        value: totalRateT0123,
                    },
                ],

            },
        ]
    } else {
        returnhtml = [
            {
                cells: [
                    {
                        value: "總計",
                        merge: { column: 2 }
                    },
                    {
                        value: totalCount,
                    },
                    {
                        value: totalCountT01,
                    },
                    {
                        value: totalRate,
                    },
                    {
                        value: totalCountT011,
                    },
                    {
                        value: totalCountT012,
                    },
                    {
                        value: totalCountT013,
                    },
                    {
                        value: totalRateT0123,
                    },
                ],

            },

        ]
    }
    return returnhtml;
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}
export const TeeStaticSexNeat = connector(teeStaticSexNeat);
