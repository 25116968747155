import React, { FunctionComponent } from 'react';
import { FormControl } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { Nullable } from '../../types';

type Props = {
  className?: string;
  property: string;
  defaultValue?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  validate?: (v: number, allValues?: unknown) => string | undefined;
};
export const NumberField: FunctionComponent<Props> = ({
  className,
  defaultValue,
  min,
  max,
  property,
  disabled,
  validate,
}) => {
  return (
    <Field
      name={property}
      defaultValue={defaultValue}
      type="number"
      validate={validate}
    >
      {({ input, meta }) => (
        <>
          <FormControl
            {...input}
            type="number"
            min={min}
            max={max}
            className={className}
            disabled={disabled}
          />
          {meta.error &&
            (meta.modified || meta.submitFailed) &&
            !meta.active && <span className="text-danger">{meta.error}</span>}
        </>
      )}
    </Field>
  );
};
