import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AuthedLayout } from '../components';

const docPage: FunctionComponent = () => {
  return (
    <AuthedLayout>
      <Row>
        <Col>
          <a href="/Doc/基本資料17.xls">基本資料</a>
          <br />
          <a href="/Doc/身體診察(含健檢口腔)20.xls">身體診察</a>
          <br />
          <a href="/Doc/血液檢查17.xls">血液檢查</a>
          <br />
          <a href="/Doc/胸部X光11.xls">胸部X光</a>
          <br />
          <a href="/Doc/實驗室檢查14.xls">實驗室檢查</a>
          <br />
          <a href="/Doc/臨時性檢查12.xls">臨時性檢查</a>
          <br />
          <a href="/Doc/身高體重視力17.xls">身高體重視力</a>
          <br />
          <a href="/Doc/身高體重13.xls">身高體重</a>
          <br />
          <a href="/Doc/視力15.xls">視力</a>
          <br />
          <a href="/Doc/經常性檢查口腔6.xls">口腔(經常性)</a>
          <br />
          <a href="/Doc/個人疾病史7.xls">個人疾病史</a>
        </Col>
      </Row>
    </AuthedLayout>
  );
};

export const DocPage = docPage;
