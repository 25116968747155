import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row, Form, FormCheck, FormControl } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
    EditableProperties,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Student, StudentGradeNoSeatBirth, StudentPHIAll, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, getGradeMinMax, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';
import { WrapClassBaseLayout, ClassSem } from '../../../layouts';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

const mapStates = (state: ApplicationState, ownProps: ClassSem) => ({
    ...ownProps,
    ...state.auth,
});
const mapDispatch = ErrorDispatches;

const connector = connect(mapStates, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const checkNoteSel: FunctionComponent<Props> = ({
    classId,
    className,
    year,
    sem,
    grade,
    no,
    catchErrorForModal,
    user
}) => {
    const { currentRank } = user || {};


    const [students, setStudents] = useState<StudentPHIAll[]>([]);
    // 同意書
    const [checkDate, setCheckDate] = useState<DateTime>();
    const [checkselfDate, setCheckSelfDate] = useState<DateTime>();
    const [checkagreefDate, setCheckAgreeDate] = useState<DateTime>();
    const checkHospitalRef = useRef<HTMLInputElement>(null);
    const checkHospitalTelRef = useRef<HTMLInputElement>(null);
    const checkHospitalTel0Ref = useRef<HTMLInputElement>(null);
    // 通知單
    const [checkpdfDate, setCheckPDFDate] = useState<DateTime>();

    const [radioAction, setRadioAction] = useState<string>("同意書");
    const handleRadioChange = (type: string) => {
        setRadioAction(type);
    };
    const { maxOffset, min } = useMemo(() => {
        const { min, max } = getGradeMinMax(currentRank);
        return { maxOffset: max - min + 1, min };
    }, []);

    useEffect(() => {
        if (year && sem && classId) {
            apis
                .getCheckAllQuery(year, grade)
                .then((student) => {
                    // setStudent(student);
                })
                .catch(catchErrorForModal);
        }
    }, [year, sem, classId, grade]);
    console.log(students)


    if (!classId) return <></>;
    return (
        <>
            <Row className='mb-2'>
                <Col className='d-flex mr-3' xs={3} >
                    <FormCheck
                        className="mr-2"
                        value={'同意書'}
                        type="radio"
                        label={`同意書`}
                        name="list-action"
                        checked={radioAction === "同意書"}
                        onChange={() => handleRadioChange("同意書")}
                    />
                    <FormCheck
                        className="mr-2"
                        value={`通知單`}
                        type="radio"
                        label={`通知單`}
                        name="list-action"
                        checked={radioAction === `通知單`}
                        onChange={() => handleRadioChange(`通知單`)}
                    />
                    <FormCheck
                        className="mr-2"
                        value={`檢查結果清單`}
                        type="radio"
                        label={`檢查結果清單`}
                        name="list-action"
                        checked={radioAction === "檢查結果清單"}
                        onChange={() => handleRadioChange("檢查結果清單")}
                    />
                </Col>
                <Col className='d-flex mr-3' xs={8} >
                    <Button
                        className="ml-auto mb-2"
                        onClick={() => {
                            const pids = students.length != 0 ? students.map((s) => s.pid) : [];

                            if (radioAction === "同意書") {
                                const isJunior = (maxOffset == 6 ? true : false)
                                toast
                                    .promise(apis.downloadCheckAgreePDF(classId, pids, year, sem, isJunior, checkHospitalRef.current?.value, checkDate, checkagreefDate, checkselfDate), {
                                        pending: '下載中...',
                                        success: '下載成功！',
                                        error: '下載失敗！請查看錯誤資訊。',
                                    })
                                    .then((blob) => {
                                        const objlink = window.URL.createObjectURL(blob);
                                        const a = document.createElement('a');
                                        a.href = objlink;
                                        a.setAttribute('download', `身高體重測量結果通知單-${className}.pdf`);
                                        document.body.appendChild(a);
                                        a.click();

                                        a.parentNode?.removeChild(a);
                                    })
                                    .catch(catchErrorForModal);
                            }
                        }}
                    >
                        <span className="feather icon-download"></span>
                    </Button>
                </Col>
            </Row>

            {radioAction === "同意書" && (
                <>
                    <Row className='mb-2'>
                        日期格式：（ex.1999/01/01）
                    </Row>
                    <Row className='mb-2'>
                        健檢日期
                        <Col sm={1}>
                            <ReactDatePicker
                                className="wid-110"
                                dateFormat={'yyyy/MM/dd'}
                                selected={checkDate?.toJSDate()}
                                onChange={(date) => {
                                    if (date instanceof Date) {
                                        setCheckDate(DateTime.fromJSDate(date));
                                    } else {
                                        setCheckDate(undefined);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        自行檢查繳回期限
                        <Col sm={1}>
                            <ReactDatePicker
                                className="wid-110"
                                dateFormat={'yyyy/MM/dd'}
                                selected={checkselfDate?.toJSDate()}
                                onChange={(date) => {
                                    if (date instanceof Date) {
                                        setCheckSelfDate(DateTime.fromJSDate(date));
                                    } else {
                                        setCheckSelfDate(undefined);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        同意書繳回期限
                        <Col sm={1}>
                            <ReactDatePicker
                                className="wid-110"
                                dateFormat={'yyyy/MM/dd'}
                                selected={checkagreefDate?.toJSDate()}
                                onChange={(date) => {
                                    if (date instanceof Date) {
                                        setCheckAgreeDate(DateTime.fromJSDate(date));
                                    } else {
                                        setCheckAgreeDate(undefined);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        健檢醫院
                        <Col sm={1}>
                            <FormControl
                                type="string"
                                aria-label="studentInput"
                                aria-describedby="basic-addon1"
                                ref={checkHospitalRef}
                            />
                        </Col>
                    </Row>
                    {/* <Row className='mb-2'>
                        電話
                        <Col sm={1}>
                            <FormControl
                                type="string"
                                aria-label="studentInput"
                                aria-describedby="basic-addon1"
                                ref={checkHospitalTelRef}
                            />
                        </Col>
                        轉
                        <Col sm={1}>
                            <FormControl
                                type="string"
                                aria-label="studentInput"
                                aria-describedby="basic-addon1"
                                ref={checkHospitalTel0Ref}
                            />
                        </Col>
                    </Row> */}
                </>
            )}
            {radioAction === "檢查結果清單" && (
                <>
                    <Row className='mb-2'>
                        <div>
                            健檢同意書內有健檢日期及健檢單位，產生全身健檢同意書前，請做好健檢日期及健檢單位設定，並更新到學生資料
                        </div>
                    </Row>
                    <Row className='mb-2'>
                        <div>
                            統計表中齲齒經驗不列入異常人數
                        </div>
                    </Row>
                    <Row className='mb-2'>
                        <div>檢查結果通知單由健檢醫院提供</div>
                    </Row>
                </>
            )}
            {radioAction === "通知單" && (
                <>
                    <Row className='mb-2'>
                        日期格式：1999/01/01 繳回期限
                        <Col sm={1}>
                            <ReactDatePicker
                                className="wid-110"
                                dateFormat={'yyyy/MM/dd'}
                                selected={checkpdfDate?.toJSDate()}
                                onChange={(date) => {
                                    if (date instanceof Date) {
                                        setCheckPDFDate(DateTime.fromJSDate(date));
                                    } else {
                                        setCheckPDFDate(undefined);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <div>
                            健檢同意書內有健檢日期及健檢單位，產生全身健檢同意書前，請做好健檢日期及健檢單位設定，並更新到學生資料
                        </div>
                    </Row>
                    <Row className='mb-2'>
                        <div>
                            統計表中齲齒經驗不列入異常人數
                        </div>
                    </Row>
                    <Row className='mb-2'>
                        <div>檢查結果通知單由健檢醫院提供</div>
                    </Row>
                </>
            )}

            {radioAction === "檢查結果清單" && (
                <>
                    {/* 根據需要添加對應的HTML內容 */}
                </>
            )}


        </>
    );
};

function calculateAge(birthDate: DateTime): number {
    const birthDateObj = new Date(birthDate.year, birthDate.month - 1, birthDate.day);

    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
}

export const CheckNoteSel = connector(WrapClassBaseLayout(checkNoteSel));