import { z } from 'zod';
import { ExaminedResultEnum, ExaminedResultEnumZod } from './Enums';
import {
  CheckStudentBaseData,
  checkExaminedResultEnum,
  CheckCode
} from '../utils/checkFormat';

export const PhiXRayZod = z.object({
  examined: z.boolean(),
  xRay: ExaminedResultEnumZod,
  phthisis: ExaminedResultEnumZod,
  calcify: ExaminedResultEnumZod,
  c03: ExaminedResultEnumZod,
  water: ExaminedResultEnumZod,
  c02: ExaminedResultEnumZod,
  bigHeart: ExaminedResultEnumZod,
  brochus: ExaminedResultEnumZod,
  x99: ExaminedResultEnumZod,
  x99State: z.string().nullish(),
  memos: z.string().nullish(),
});

export const PhiXRayFileZod = z
  .object({
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    胸部X光檢查: z.string().nullish(),
    肺結核病徵: z.string().nullish(),
    肺結核鈣化: z.string().nullish(),
    胸廓異常: z.string().nullish(),
    肋膜腔積水: z.string().nullish(),
    脊柱側彎: z.string().nullish(),
    心臟肥大: z.string().nullish(),
    支氣管擴張: z.string().nullish(),
    其他: z.string().nullish(),
    其他陳述: z.string().nullish(),
  })
  .transform((arg) => {
    let _errorMsg = '';
    let _okUpload = true;
    const _code = ['0', '1', '2', '3', '9'];
    //檢查【身分證】、【學號】、【年級班級座號】格式
    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const _pid = checkStudentBaseDataResult.pid;
    const _sid = checkStudentBaseDataResult.sid;
    const _grade = checkStudentBaseDataResult.grade;
    const _no = checkStudentBaseDataResult.no;
    const _seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    const phthisisCheck = checkExaminedResultEnum(
      arg.肺結核病徵,
      '肺結核病徵'
    );
    if(phthisisCheck.isNull) {
      phthisisCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += phthisisCheck.errorMsg;
      _okUpload = _okUpload && phthisisCheck.okUpload;
    }
    const _phthisis = phthisisCheck.code;
    const calcifyCheck = checkExaminedResultEnum(
      arg.肺結核鈣化,
      '肺結核鈣化'
    );
    if(calcifyCheck.isNull) {
      calcifyCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += calcifyCheck.errorMsg;
      _okUpload = _okUpload && calcifyCheck.okUpload;
    }
    const _calcify = calcifyCheck.code;
    const c03Check = checkExaminedResultEnum(
      arg.胸廓異常,
      '胸廓異常'
    );
    if(c03Check.isNull) {
      c03Check.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += c03Check.errorMsg;
      _okUpload = _okUpload && c03Check.okUpload;
    }
    const _c03 = c03Check.code;
    const waterCheck = checkExaminedResultEnum(
      arg.肋膜腔積水,
      '肋膜腔積水'
    );
    if(waterCheck.isNull) {
      waterCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += waterCheck.errorMsg;
      _okUpload = _okUpload && waterCheck.okUpload;
    }
    const _water = waterCheck.code;
    const c02Check = checkExaminedResultEnum(
      arg.脊柱側彎,
      '脊柱側彎'
    );
    if(c02Check.isNull) {
      c02Check.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += c02Check.errorMsg;
      _okUpload = _okUpload && c02Check.okUpload;
    }
    const _c02 = c02Check.code;
    const bigHeartCheck = checkExaminedResultEnum(
      arg.心臟肥大,
      '心臟肥大'
    );
    if(bigHeartCheck.isNull) {
      bigHeartCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += bigHeartCheck.errorMsg;
      _okUpload = _okUpload && bigHeartCheck.okUpload;
    }
    const _bigHeart = bigHeartCheck.code;
    const brochusCheck = checkExaminedResultEnum(
      arg.支氣管擴張,
      '支氣管擴張'
    );
    if(brochusCheck.isNull) {
      brochusCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += brochusCheck.errorMsg;
      _okUpload = _okUpload && brochusCheck.okUpload;
    }
    const _brochus = brochusCheck.code;
    const x99Check = checkExaminedResultEnum(
      arg.其他,
      '其他'
    );
    if(x99Check.isNull) {
      x99Check.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += x99Check.errorMsg;
      _okUpload = _okUpload && x99Check.okUpload;
    }
    const _x99 = x99Check.code;
    const xRayCheck = checkExaminedResultEnum(
      arg.胸部X光檢查,
      '胸部X光檢查'
    );
    if (
        resultAbnormal(_phthisis) ||
        resultAbnormal(_calcify) ||
        resultAbnormal(_c03) ||
        resultAbnormal(_water) ||
        resultAbnormal(_c02) ||
        resultAbnormal(_bigHeart) ||
        resultAbnormal(_brochus) ||
        resultAbnormal(_x99)
    ) {
      const examinedResult = [_phthisis, _calcify, _c03, _water, _c02, _bigHeart, _brochus, _x99];
      const min = Math.min(...examinedResult.filter(num => num !== 0));
      xRayCheck.code = min;
    } else {
      xRayCheck.code = 0;
    }
    const _xRay = xRayCheck.code;
    //其他陳述
    const x99StateResult = CheckCode(
      arg.其他陳述,
      _code,
      '其他陳述'
    );
    const _x99State = x99StateResult.code;

    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid: _pid,
      sid: _sid,
      grade: _grade,
      no: _no,
      seat: _seat,
      examined: true,
      xRay: _xRay,
      phthisis: _phthisis,
      calcify: _calcify,
      c03: _c03,
      water: _water,
      c02: _c02,
      bigHeart: _bigHeart,
      brochus: _brochus,
      x99: _x99,
      x99State: _x99State,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

function resultAbnormal(result: ExaminedResultEnum) {
  return (
    result === ExaminedResultEnum.InitialAbnoraml ||
    result === ExaminedResultEnum.FollowUpAbnormal ||
    result === ExaminedResultEnum.FollowUpNormal
  );
}

export type PhiXRay = z.infer<typeof PhiXRayZod>;
export type PhiXRayFile = z.infer<typeof PhiXRayFileZod>;
