import { DateTime } from 'luxon';
import { Semester } from '../model';

export const SemesterUtils = Object.freeze({
  stringify(semester?: Semester): string {
    return `${semester?.year}-${semester?.sem}`;
  },
  destringify(str: string): Semester {
    const [year, sem] = str.split('-');
    return {
      year: +year,
      sem: +sem,
      isNow: false,
    };
  },
});


//SemesterUtility
const FirstSemester = [ 8, 9, 10, 11, 12, 1 ];
const SecondSemester = [ 2, 3, 4, 5, 6, 7];

export const GetSemester = getSemester()
export const GetAcademicYear = getAcademicYear()

export function getSemester( date: Date = new Date())
{
  const _mm = date.getMonth()+1

  if(FirstSemester.includes(_mm)){
    return '1'
  }else{
    return '2'
  }
}

export function getAcademicYear( date: Date = new Date())
{
  const _yyyy = date.getFullYear()
  const _mm = date.getMonth()+1

  let _academicYear = _yyyy-1911
  if(_mm >= 1 && _mm<=7 ){
    _academicYear = _academicYear-1
  }
  return _academicYear
}


