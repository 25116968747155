import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row as BSRow, FormCheck, Table } from 'react-bootstrap';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { I18N } from '../../../i18n-raw';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useCheckGrade, useClassGrade, useSemGrade } from '../../../hook';
import { UnpivotedBloodCheck } from '../../../model';
import apis from '../../../apis';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;
const bottomBorderStyle = {
    bottom: borderStyle,
};
const topBottomBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
};
const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const checkBloodStastic: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    const [radioAction, setRadioAction] = useState<boolean>(true);
    const { selectedYearGrade, element: selectgradeElement } = useCheckGrade({ year: year, isNowGrade: radioAction })
    const [students, setStudents] = useState<UnpivotedBloodCheck[]>([]);
    const handleRadioChange = (isNowGrade: boolean) => {
        setRadioAction(isNowGrade);
    };

    useEffect(() => {
        if (selectedYearGrade) {
            apis
                .getBloodStatistic(selectedYearGrade.year, selectedYearGrade?.grade)
                .then((r) =>
                    setStudents(r)
                )
                .catch(catchErrorForModal);
        }
    }, [selectedYearGrade?.year, selectedYearGrade?.grade]);

    const contentRows: Row[] = Object.values(students).flatMap(
        (gs, idx, allArray) => {
            // 同一年級下 loop每個班級
            return bloodchecktoRow(
                gs.items,
                gs.m0,
                gs.m1,
                gs.m2,
                gs.m3,
                gs.f0,
                gs.f1,
                gs.f2,
                gs.f3,
            );
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: `${selectedYearGrade?.year == null ? "" : selectedYearGrade?.year}學年7年級血液檢查統計表`,
                    merge: { column: 16 },
                },
            ],
        },
        {
            cells: [
                {
                    value: '檢驗項目(中文)',
                    merge: { column: 1, row: 2 },
                },
                {
                    value: '受檢人數',
                    merge: { column: 2 },
                },
                {
                    value: '異常(陽性)人數',
                    merge: { column: 3 },
                },
                {
                    value: '異常(陽性)%',
                    merge: { column: 3 },
                },
                {
                    value: '複檢正常(複檢陰性)',
                    merge: { column: 2 },
                },
                {
                    value: '複檢異常(複檢陽性)',
                    merge: { column: 2 },
                },
                {
                    value: '未就醫(陽性)',
                    merge: { column: 2 },
                },
                {
                    value: '就醫率',
                    merge: { column: 1 },
                },
            ],
        },
        {
            cells: [
                {
                    value: '男生',
                    merge: { column: 1 },
                },
                {
                    value: '女生',
                    merge: { column: 1 },
                },
                {
                    value: '男生',
                    merge: { column: 1 },
                },
                {
                    value: '女生',
                    merge: { column: 1 },
                },
                {
                    value: '合計',
                    merge: { column: 1 },
                },
                {
                    value: '男生%',
                    merge: { column: 1 },
                },
                {
                    value: '女生%',
                    merge: { column: 1 },
                },
                {
                    value: '合計%',
                    merge: { column: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1 },
                },
            ],
        },
        ...contentRows,
    ];


    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <Col className='d-flex mr-3' xs={3} >
                    <FormCheck
                        className="mr-2"
                        value={'在學'}
                        type="radio"
                        label={`在學`}
                        name="list-action"
                        checked={radioAction === true}
                        onChange={() => handleRadioChange(true)}
                    />
                    <FormCheck
                        className="mr-2"
                        value={`歷屆年級`}
                        type="radio"
                        label={`歷屆年級`}
                        name="list-action"
                        checked={radioAction === false}
                        onChange={() => handleRadioChange(false)}
                    />
                </Col>
                <Col xs={3} className="mr-3">
                    {selectgradeElement}
                </Col>
                <div>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            const workSheet = generateSheet(
                                [
                                    ...content,
                                    {
                                        cells: [
                                            {
                                                value:
                                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                                style: { alignment: { horizontal: 'left' } },
                                            },
                                        ],
                                    },
                                ],
                                {
                                    alignment: cellAlignStyle,
                                }
                            );
                            downloadSheets(`${selectedYearGrade?.year}學年7年級血液檢查統計表`, { sheet: workSheet, name: 'st' });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function bloodchecktoRow(
    items: string,
    m0: number,
    m1: number,
    m2: number,
    m3: number,
    f0: number,
    f1: number,
    f2: number,
    f3: number,
): Row[] {
    return [
        {
            cells: [
                {
                    value: I18N.Blood[items],
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m0 + m1,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: f0 + f1,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: f1,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + f1,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent(m1, (m0 + m1)),
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent(f1, (f0 + f1)),
                    style: { border: { left: borderStyle } },
                },
                {
                    value:  percent(m1 + f1, ((f0 + f1) + (m0 + m1))),
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + f1 == 0 ? 0 : m2 + f2,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + f1 == 0 ? "" : percent(m2 + f2, m1 + f1),
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + f1 == 0 ? 0 : m3 + f3,
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + f1 == 0 ? "" : percent(m3 + f3, m1 + f1),
                    style: { border: { left: borderStyle } },
                },
                {
                    value:m1 + f1 == 0 ? 0 : m1 + f1 - (m2 + f2 + m3 + f3),
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + f1 == 0 ? "" :percent(m1 + f1 - (m2 + f2 + m3 + f3), m1 + f1),
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + f1 == 0 ? "" :percent((m2 + f2 + m3 + f3), m1 + f1),
                    style: { border: { left: borderStyle } },
                },
            ],
        },
    ];
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}
export const CheckBloodStastic = connector(checkBloodStastic);