import React, { ReactNode, useEffect, useState } from 'react';
import { YearSem } from '../model';
import apis from '../apis';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { listToObject } from '../utils';
import { useDispatch } from 'react-redux';
import { ErrorDispatches } from '../redux/Dispatches';

export const useSemGrade = (): {
  yearSem?: YearSem;
  element: ReactNode;
} => {
  const [yearSems, setYearSems] = useState<{ [k: string]: YearSem }>({});
  const [selected, setSelected] = useState<string>('');
  const dispatch = useDispatch();
  useEffect(() => {
    apis
      .GetAllYearSem()
      .then((s) =>
        setYearSems(
          listToObject(
            s,
            (s) => `${s.year}學年 第${s.sem}學期`,
            (s) => s
          )
        )
      )
      .catch(dispatch(ErrorDispatches.globalCatch));
  }, []);

  return {
    yearSem: yearSems[selected],
    element: (
      <>
        <DropdownButton
          title={selected || '請選擇學年'}
          onSelect={(k: string | null) => {
            if (k == null || !yearSems[k]) return;
            setSelected(k);
          }}
        >
          {Object.entries(yearSems).map(([i]) => (
            <Dropdown.Item key={`semgrade-${i}`} eventKey={i}>
              {i}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </>
    ),
  };
};
