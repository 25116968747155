import React, { ReactNode, useEffect, useState } from 'react';
import { Semester } from '../model';
import { Form } from 'react-bootstrap';
import { useQuery } from './useQuery';
import { useHistory } from 'react-router';

const YEARSEM_QUERY_PARAM_KEY = 'year-sem';

export const useActiveSems = (
  semesters: { [k: string]: Semester },
  initSemesterKey: string
): {
  selectedSemester: Semester;
  yearSemElement: ReactNode;
} => {
  const searchParams = useQuery();
  const history = useHistory();
  const [selectedSemesterKey, setSelectedSemesterKey] = useState(
    searchParams.get(YEARSEM_QUERY_PARAM_KEY) ?? initSemesterKey
  );

  useEffect(() => {
    searchParams.set(YEARSEM_QUERY_PARAM_KEY, selectedSemesterKey);
    history.push({ search: searchParams.toString() });
  }, [selectedSemesterKey]);

  return {
    selectedSemester: semesters[selectedSemesterKey],
    yearSemElement: (
      <div className="d-flex">
        {Object.entries(semesters).map(([n, s]) => {
          return (
            <Form.Check
              key={`sem-${n}`}
              type="radio"
              className="mr-3"
              label={n}
              name="setSemester"
              checked={n === selectedSemesterKey}
              onChange={(e) => {
                setSelectedSemesterKey(n);
              }}
            />
          );
        })}
      </div>
    ),
  };
};
