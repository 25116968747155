import React, { FunctionComponent, RefObject } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { InputDropdownGroup } from '../InputDropdownGroup';

type Props<T extends 'text' | 'number'> = {
  className?: string;
  groupClassName?: string;
  resultClassName?: string;
  property: string;
  type?: T;
  defaultShow?: string;
  max?: number;
  min?: number;
  maxLength?: number;
  minLength?: number;
  disabled?: ((v: any) => boolean) | boolean;
  content: Record<T extends 'text' ? string : number, string>;
  resultShow?: 'key' | 'both' | 'value';
  selectShow?: 'key' | 'both' | 'value';
  containerRef?: RefObject<HTMLElement>;
  needGroup?: boolean;
  allowValueNotInContent?: boolean;
  parse?: (v: any, name: string) => unknown;
  format?: (v: any, name: string) => unknown;
  validate?: (
    v: T extends 'text' ? string : number,
    allValues: any
  ) => string | undefined;
};
export const InputDropdownGroupField = <T extends 'text' | 'number'>({
  property,
  validate,
  type,
  content,
  disabled,
  allowValueNotInContent,
  ...props
}: Props<T>) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <Field
          type={type}
          name={property}
          formatOnBlur
          format={(value, name) => {
            if (value && content[value]) {
              return type === 'number' ? +value : value;
            } else if (allowValueNotInContent) {
              return value;
            }
            return undefined;
          }}
          validate={validate}
        >
          {({ input, meta }) => {
            return (
              <>
                <InputDropdownGroup
                  {...input}
                  value={
                    meta.modified || input.value
                      ? input.value
                      : typeof input.value === 'number'
                      ? 0
                      : ''
                  }
                  disabled={
                    typeof disabled === 'function' ? disabled(values) : disabled
                  }
                  type={type}
                  options={content}
                  {...props}
                />
                {meta.error &&
                  (meta.modified || meta.submitFailed) &&
                  !meta.active && (
                    <span className="text-danger">{meta.error}</span>
                  )}
              </>
            );
          }}
        </Field>
      )}
    </FormSpy>
  );
};
