import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { AuthedLayout } from '../components';
import { ClassMapObj } from '../types';
import Apis from '../apis';
import { ApplicationState } from '../redux/States';
import { AuthDispatches, ErrorDispatches } from '../redux/Dispatches';
import { connect, ConnectedProps } from 'react-redux';
import { listToObject } from '../utils/transform';

export interface GradeSem {
  grade?: number;
  gradeName: string;
  year: number;
  sem: number;
}

type ChildRender = (classSem: GradeSem) => ReactElement;

type OwnProps = {
  children: ReactNode | ChildRender;
  onGradesLoaded?: () => void;
  onGradeChanged?: (year: number, sem: number, classId?: string) => void;
};
const mapState = (state: ApplicationState, ownProps: OwnProps) => ({
  ...state.auth,
  ...ownProps,
});
const mapDispatch = { ...ErrorDispatches, ...AuthDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const gradeBaseLayout: FunctionComponent<Props> = ({
  children,
  catchErrorForModal,
  onGradesLoaded = () => {},
  onGradeChanged = () => {},
  user,
}) => {
  const { currentSemester = '', semesters = {} } = user || {};
  const [showGrade, setGrade] = useState<string>();
  const [grades, setGrades] = useState<ClassMapObj>({ '': '無年級資料' });
  const [currentSem, setCurrentSem] = useState(currentSemester);

  const { year = -1, sem = -1 } = semesters[currentSem];

  useEffect(() => {
    refreshClasses();
  }, [user, year]);

  function refreshClasses() {
    Apis.GetGrades(year, sem)
      .then((grades) => {
        if (grades.length)
          setGrades(
            listToObject(
              grades,
              (c) => c.grade + '',
              (c) => c.name
            )
          );
        setGrade(undefined);
        onGradesLoaded();
      })
      .catch(catchErrorForModal);
  }
  return (
    <AuthedLayout>
      <Row>
        <Col xs={2}>
          <DropdownButton
            title={(showGrade && grades[showGrade]) || '請選擇'}
            onSelect={(k: string | null) => {
              if (k == null || !grades[k] || !semesters[currentSem]) return;
              setGrade(k);
              const semester = semesters[currentSem];
              onGradeChanged(semester.year, semester.sem, k);
            }}
          >
            {Object.entries(grades).map(([i, n]) => (
              <Dropdown.Item key={`class-${i}`} eventKey={i}>
                {n}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        {Object.entries(semesters).map(([n, s]) => {
          return (
            <Col key={`sem-${n}`} xs={1}>
              <Form.Check
                type="radio"
                label={n}
                name="setSemester"
                checked={n === currentSem}
                onChange={(e) => {
                  setCurrentSem(n);
                  const { year: newYear } = semesters[n];
                  const { year: currentYear } = semesters[currentSem];
                  if (newYear !== currentYear) {
                    // if year not same, then clear selected grade
                    setGrade(undefined);
                    onGradeChanged(s.year, s.sem);
                  } else {
                    onGradeChanged(s.year, s.sem, showGrade);
                  }
                }}
              />
            </Col>
          );
        })}
      </Row>
      <hr />
      {typeof children === 'function'
        ? (children as ChildRender)({
            grade: +(showGrade ?? '0'),
            year,
            sem,
            gradeName: grades[showGrade ?? ''] ?? '',
          })
        : children}
    </AuthedLayout>
  );
};

export const GradeBaseLayout = connector(gradeBaseLayout);

export function WrapGradeBaseLayout<T extends GradeSem>(
  Compoment: FunctionComponent<T>
): FunctionComponent<T> {
  return function WrapGradeBaseLayout(props: T): ReactElement {
    return (
      <GradeBaseLayout>
        {(classSem) => <Compoment {...classSem} {...props} />}
      </GradeBaseLayout>
    );
  };
}
