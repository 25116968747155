export function listToObject<S, K extends number | string, V>(
  sources: S[],
  keySelector: (src: S) => K,
  valueSelector: (src: S) => V
): { [k in K]: V } {
  return sources.reduce((pv, cv) => {
    const key = keySelector(cv);
    const value = valueSelector(cv);
    pv[key] = value;
    return pv;
  }, {} as { [k in K]: V });
}

// export function gradeNoToClassId(grade: number, no: number): string {
//   return `${grade}`.padStart(2, '0') + `${no}`.padStart(2, '0');
// }

// export function classIdToGradeNo(classId: string): [number, number] {
//   return [+classId.slice(0, 2), +classId.slice(2)];
// }

export function sexWordToValue(sex: string, returnNotMatch?: boolean) {
  switch (sex) {
    case '男':
      return '1';
    case '女':
      return '2';
    default:
      if (returnNotMatch) {
        return sex;
      }
      throw new Error(`${sex} is not sex word`);
  }
}

export function sexValueToWord(sex: string, returnNotMatch?: boolean) {
  switch (sex) {
    case '1':
      return '男';
    case '2':
      return '女';
    default:
      if (returnNotMatch) {
        return sex;
      }
      throw new Error(`${sex} is not sex word`);
  }
}
