import React, { ReactNode, useEffect, useState } from 'react';
import { CheckYearGrade } from '../model';
import apis from '../apis';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { listToObject } from '../utils';
import { useDispatch } from 'react-redux';
import { ErrorDispatches } from '../redux/Dispatches';

type Argument = {
    year?: number;
    isNowGrade?: boolean;
};

export const useCheckClass = ({
    year,
    isNowGrade
}: Argument): {
    selectedYearGrade?: CheckYearGrade;
    allYearGrade: Record<string, CheckYearGrade>;
    element: ReactNode;
} => {
    const [selected, setSelected] = useState<string>('');
    const [yearGrades, setYearGrade] = useState<{ [k: string]: CheckYearGrade }>({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (year) {
            apis
                .getCheckYearGrede(year)
                .then((s) => {
                    if (isNowGrade) {
                        setYearGrade(
                            listToObject(s.isNow, (c) => c.className, (c) => c)
                        );
                    } else {
                        setYearGrade(
                            listToObject(s.graduate, (c) => c.className, (c) => c)
                        );
                    }
                })
                .catch(dispatch(ErrorDispatches.globalCatch));
        }
    }, [year, isNowGrade, dispatch]);

    return {
        selectedYearGrade: yearGrades[selected],
        allYearGrade: yearGrades,
        element: (
            <>
                <DropdownButton
                    title={selected || '請選擇班級'}
                    onSelect={(k: string | null) => {
                        if (k == null || !yearGrades[k]) return;
                        setSelected(k);
                    }}
                >
                    {Object.entries(yearGrades).map(([i, n]) => (
                        <Dropdown.Item key={`semgrade-${i}`} eventKey={i}>
                            {n.className}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </>
        ),
    };
};
