import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, Col, Row as BRow, Table } from 'react-bootstrap';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
} from '../../../components';
import { useDownloadChart, useDownloadSheetChart, useSemGrade } from '../../../hook';
import { DiseaseChartStatistic } from '../../../model';
import apis from '../../../apis';
import useApexBarChart, { ChartState } from '../../../hook/useApexBarChart';

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

type DiseaseSummary = {
    diseaseId: number;
    diseaseName: string;
    gradeCounts: Record<number, number>;
    total: number;
};
const diseaseStaticChart: FunctionComponent<Props> = ({
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<DiseaseChartStatistic[]>([]);
    const chartRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (yearSem) {
            apis
                .getDiseaseStaticChart(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(
                        r.map(({ ...s }) => ({
                            ...s,
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);

    let gradearr = students.flatMap((gs, idx, allArray) => {
        return gs.grade
    })
    gradearr = gradearr.filter((value, index, array) => array.indexOf(value) === index)
    const gradeRow = gradearr.flatMap((gs, idx, allArray) => {
        return {
            value: gs,
            merge: { column: 1 },
            style: { border: fullBorderStyle, width: "5%" },
        }
    })

    const diseasearr: string[] = []
    const schooltotal: DiseaseSummary = {
        diseaseId: 0,
        diseaseName: '總計',
        gradeCounts: {},
        total: 0
    };
    
    gradearr.forEach((gs) => {
        schooltotal.gradeCounts[gs] = 0;
    });

    const calculateDiseaseSummary = (data: DiseaseChartStatistic[]): Record<string, DiseaseSummary> => {
        const summary: Record<string, DiseaseSummary> = {};

        data.forEach(item => {
            if (!summary[item.diseaseName]) {
                diseasearr.push(item.diseaseName);
                summary[item.diseaseName] = {
                    diseaseId: item.diseaseId,
                    diseaseName: item.diseaseName,
                    gradeCounts: {},
                    total: 0
                };
                gradearr.forEach((gs) => {
                    summary[item.diseaseName].gradeCounts[gs] = 0;
                });
            }

            summary[item.diseaseName].gradeCounts[item.grade] += item.count;
            summary[item.diseaseName].total += item.count;

            schooltotal.gradeCounts[item.grade] += item.count;
            schooltotal.total += item.count;
        });

        return summary;
    };

    const diseaseSummary = calculateDiseaseSummary(students);


    const contentRows: Row[] = Object.values(diseaseSummary).flatMap(
        (gradedata, gidx, allArray) => {
            return DiseaseToRows(
                gradedata.diseaseName,
                gradedata.gradeCounts,
                gradedata.total
            )
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '疾病種類/人數/年級',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                ...gradeRow,
                {
                    value: '總計',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
        ...DiseaseToRows(schooltotal.diseaseName, schooltotal.gradeCounts, schooltotal.total)
    ];
    const state: ChartState = {
        options: {
            chart: {
                id: 'diseasechart'
            },
            xaxis: {
                title: { text: "疾病" },
                categories: diseasearr
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [
            {
                name: '',
                data: Object.values(diseaseSummary).flatMap(x => x.total)
            }],
        charttype: 'bar'
    }
    const { element: barchart } = useApexBarChart(state);
    const tableworkSheet = generateSheet(
        [
            {
                cells: [
                    {
                        value: `${yearSem?.year}學年第${yearSem?.sem}學期_特殊疾病統計`,
                        style: { alignment: { horizontal: 'left' } },
                    },
                ],
            },
            ...content,
            {
                cells: [
                    {
                        value:
                            '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                        style: { alignment: { horizontal: 'left' } },
                    },
                ],
            },
        ],
        {
            alignment: cellAlignStyle,
        }
    );
    // const tableworkSheet = generateSheet(content, { alignment: cellAlignStyle });
    const chartRefs = [{ ref: "diseasechart", sheetname: "統計圖" }];
    const titles = [`${yearSem?.year}學年第${yearSem?.sem}學期_特殊疾病統計`];
    const tablesheets = [{ sheet: tableworkSheet, sheetname: "特殊疾病統計" }]
    const { element: downloadChart } = useDownloadSheetChart({ chartdata: Object.values(diseaseSummary).flatMap(x => x.total), chartRefs: chartRefs, titles: titles, tablesheets: content, tablesheetname: '特殊疾病統計' });


    return (
        <AuthedLayout>
            <BRow className="justify-content-between">
                <Col xs={3} className="mr-3">
                    {semGradeElement}
                </Col>
                <Col xs={2} className="mr-2">
                    {downloadChart}
                </Col>
            </BRow>
            <hr />
            <BRow>
                <Col className="text-center">
                    {yearSem?.year}學年第{yearSem?.sem}學期_特殊疾病統計
                </Col>
            </BRow>
            <BRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BRow>
            <BRow className="justify-content-center">
                <div ref={chartRef}>
                    {barchart}
                </div>
            </BRow>
        </AuthedLayout >
    );
};

function DiseaseToRows(
    diseaseName: string,
    gradedata: { [key: string]: number; },
    total: number,
    gradeMerge?: { row?: number; column?: number }
): Row[] {
    const gradeNo = [
        {
            value: diseaseName,
            merge: gradeMerge,
            style: { border: fullBorderStyle },
        },
    ];

    const gradedatarow = Object.values(gradedata).flatMap((data, idx, allArray) => {
        return {
            value: data ?? 0,
            merge: {},
            style: { border: { left: borderStyle } },
        }
    })

    return [
        {
            cells: [
                ...gradeNo,
                ...gradedatarow,
                {
                    value: total,
                    merge: {},
                    style: { border: fullBorderStyle },
                },
            ],
        },
    ];
}


export const DiseaseStaticChart = connector(diseaseStaticChart);
