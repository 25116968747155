import React, { FunctionComponent } from 'react';
import { FormControl } from 'react-bootstrap';
import { Field, FormSpy } from 'react-final-form';

type Props = {
  className?: string;
  property: string;
  disabled?: ((v: any) => boolean) | boolean;
  validate?: (value: string) => string | undefined;
};
export const TextField: FunctionComponent<Props> = ({
  className,
  property,
  disabled,
  validate,
}) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <Field name={property} type="string" validate={validate}>
          {({ input, meta }) => (
            <>
              <FormControl
                className={className}
                disabled={
                  typeof disabled === 'function' ? disabled(values) : disabled
                }
                autoComplete='off'
                {...input}
              />
              {meta.error &&
                (meta.modified || meta.submitFailed) &&
                !meta.active && (
                  <span className="text-danger">{meta.error}</span>
                )}
            </>
          )}
        </Field>
      )}
    </FormSpy>
  );
};
